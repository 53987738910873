import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { classNames, formatDatePtBR, isDateAfter, bugsnag } from 'utils';
import { useFavoriteBenefit, useUserPlan } from 'hooks';
import formatBenefitDescription from 'screens/apps/fidelity/utils/formatBenefitDescription';
import formatBenefitTile from 'screens/apps/fidelity/utils/formatBenefitTitle';
import numberFix from 'screens/apps/fidelity/utils/balanceFixValue';
import formatBenefitType from 'screens/apps/fidelity/utils/formatBenefitType';
import { PromotionTag } from 'components/promotionTag';
import { statusToast } from 'components/StatusToast';

import { useHistory } from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';

import Star from 'icons/star.svg';
import StarSolid from 'icons/star-solid.svg';
import coinIcon from 'icons/coin-icon.svg?url';
import ClockSvg from 'icons/clock.svg';

import EmptyImageSvg from 'icons/ticket-multi.svg';

import { colors } from 'theme';
import ChevronIcon from 'icons/chevron-right.svg';

import styles from './index.module.scss';

const SelectedBenefit = ({ data, variant, onClick, onlyOne, formatVariant, focus, index, fullWidth }) => {
  const history = useHistory();
  const toggleFavoriteInList = useFavoriteBenefit();
  const plan = useUserPlan();

  const isFormatVariant = formatVariant !== 'mvcHome';
  const isFormatVertical = formatVariant === 'Vertical';

  const imageUrl = useMemo(() => {
    let imageUrl = data.image?.type?.includes('image') && data.image?.url;
    if (data.store?.logoColor?.original) imageUrl = data.store.logoColor.original;
    return imageUrl;
  }, [data]);

  const isExpired = useMemo(() => {
    let value = false;
    if (data.expirationDate && isDateAfter(new Date(), new Date(data.expirationDate))) value = true;
    return value;
  }, [data]);

  const acceptedCategories = [
    'SEMANA DO CLIENTE',
    'SHOPPINGLOVER',
    'BLACK FRIDAY',
    'NATAL',
    'PÁSCOA',
    'DIA DAS MÃES',
    'DIA DOS NAMORADOS',
    'DIA DOS PAIS',
    'DIA DAS CRIANÇAS',
    'DIA DA PIZZA',
    'SEMANA DO CHOCOLATE',
    'SEMANA DA MASSA',
    'MÊS DO GAÚCHO',
  ];

  const uppercaseCategoriesList = data?.categories ? data?.categories.map((category) => category.toUpperCase()) : [];
  const firstAcceptedCategory = uppercaseCategoriesList.some((category) => acceptedCategories.includes(category));

  const sourceValues = {
    mvcHome: 'selecionados MVC',
    showcaseHome: 'selecionados MVC showcase',
    eventsHub: 'events hub',
    eventPage: 'events page',
    vantagens: 'hub vantagens',
    store: 'store',
    restaurant: 'restaurant',
    theatreHub: 'theatre hub',
    theatrePage: 'theatre page',
  };

  const validationTypes = {
    default: 'default',
    store: 'store',
    qrCode: 'qrCode',
    clientUnique: 'clientUnique',
    clientSeveral: 'clientSeveral',
  };

  const state = data?.validation?.type || 'default';

  const formatProperties = useCallback(() => {
    let properties;

    properties = {
      'Benefit Points': data.points ? data.points : 0,
      'Benefit Type': formatBenefitType(data),
      'Benefit Name': data.title,
      'Benefits Validation Type': validationTypes[state],
      Source: sourceValues[focus],
      'Card Position': index,
      'Redeem Type': data.type,
      'User Balance': plan.currentBalance >= data.points ? 'Enough points' : 'Not enough points',
    };

    if (data.store) properties['Benefit Store Name'] = data.store.name;
    if (data.categories) properties['Benefit Categories Names'] = data.categories;

    return properties;
  }, [data]);

  async function handleFavorite(event) {
    event?.stopPropagation();

    if (!data.isFavorite) {
      try {
        window.track({
          mixpanel: {
            name: 'Clicked To Favorite Benefit',
            properties: formatProperties(),
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }
      statusToast({
        type: 'success',
        title: 'Adicionado à sua lista de favoritos!',
        action: <SeeListButton />,
      });
    }

    toggleFavoriteInList(data.id, !data.isFavorite);

    if (onClick) onClick(event);
  }

  const handleClick = () => {
    try {
      window.track({
        mixpanel: {
          name: 'Clicked Benefit Card',
          properties: formatProperties(),
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }

    const trackingProperties = {
      source: sourceValues[focus],
      cardPosition: index,
    };
    multiStorage.setItem(`@multivc:benefit-tracking-properties`, JSON.stringify(trackingProperties));

    history.push(`/apps/multivoce/beneficios/tickets/${data.id}/${variant}`);
  };

  const handleClickFavoriteList = () => {
    history.push('/apps/multivoce/meusbeneficios/favorite');
  };

  const SeeListButton = () => {
    return (
      <button onClick={handleClickFavoriteList} className={styles.seeListButton}>
        Ver lista
        <ChevronIcon width="12px" height="12px" fill={colors.common.white} />
      </button>
    );
  };

  const LimitDate = () => {
    const benefitLimitDays = Math.round(new Date(data.endDate).getTime() / 1000 / 60 / 60 / 24);
    const todayInDays = Math.round(new Date().getTime() / 1000 / 60 / 60 / 24);

    const difference = benefitLimitDays - todayInDays;

    if (data.salePointsFrom && !isFormatVertical) {
      return <strong className={styles.able}>Aproveite!</strong>;
    }
    if (data.type === 'DISPLAY_ONLY' || !!data.parkingDiscountId || difference > 365) {
      return (
        <>
          <strong className={styles.able}>Benefício disponível </strong>
        </>
      );
    }
    if (difference <= 7) {
      if (difference === 0) {
        return (
          <>
            <ClockSvg className={styles.clock} /> Expira
            <strong className={styles.time}> Hoje</strong>
          </>
        );
      }

      return (
        <>
          <ClockSvg className={styles.clock} /> Resgate em
          <strong className={styles.time}>{difference === 1 ? ` ${difference} dia` : ` ${difference} dias!`} </strong>
        </>
      );
    } else {
      return (
        <>
          Resgate até
          <strong className={styles.time}> {formatDatePtBR(data.endDate)}</strong>
        </>
      );
    }
  };

  return (
    <>
      <div
        className={classNames(styles.cardContainer, {
          [styles[`cardContainer${formatVariant}`]]: isFormatVariant,
          [styles.fullWidth]: fullWidth,
          [styles.promotionContainer]: firstAcceptedCategory && !isFormatVertical,
        })}
      >
        <PromotionTag
          categories={data.categories}
          points={data.salePointsFrom}
          direction={isFormatVertical ? 'horizontal' : 'vertical'}
        />
        <div
          className={classNames(styles.card, onlyOne && styles.bigCard, styles[variant], {
            [styles[`card${formatVariant}`]]: isFormatVariant,
          })}
          onClick={handleClick}
          data-promotion={firstAcceptedCategory || data?.salePointsFrom > 0}
        >
          <div
            className={classNames(styles.header, {
              [styles[`header${formatVariant}`]]: isFormatVariant,
            })}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                className={classNames(styles.photo, { [styles[`photo${formatVariant}`]]: isFormatVertical })}
                data-promotion={firstAcceptedCategory || data?.salePointsFrom > 0}
              />
            ) : (
              <EmptyImageSvg
                className={classNames(styles.emptyPhoto, { [styles[`emptyPhoto${formatVariant}`]]: isFormatVertical })}
                data-promotion={firstAcceptedCategory || data?.salePointsFrom > 0}
              />
            )}

            <div
              className={classNames(styles.cardInfoContainer, {
                [styles[`cardInfoContainer${formatVariant}`]]: isFormatVariant,
              })}
            >
              <div className={styles.headerInfo}>
                <strong className={styles.title}>
                  {formatBenefitTile({ benefit: data, variant, isFromList: true })}
                </strong>
                <button
                  className={styles.starContainer}
                  onClick={!isExpired ? (event) => handleFavorite(event) : () => {}}
                >
                  {!isFormatVertical &&
                    (data.isFavorite ? <StarSolid className={styles.star} /> : <Star className={styles.star} />)}
                </button>
              </div>
              {!!data && (
                <LinesEllipsis
                  maxLine={3}
                  text={formatBenefitDescription({ benefit: data, showAll: true })}
                  className={styles.text}
                  basedOn="letters"
                />
              )}
            </div>
          </div>
          <div
            className={classNames(styles.footer, {
              [styles[`footer${formatVariant}`]]: isFormatVariant,
            })}
          >
            {!!data.endDate && (
              <div
                className={classNames(styles.limit, {
                  [styles[`limit${formatVariant}`]]: isFormatVariant,
                })}
              >
                <LimitDate />
              </div>
            )}
            {data.points || (data.salePointsFrom && data.points === 0) ? (
              <div
                className={classNames(styles.balanceValue, {
                  [styles[`balanceValue${formatVariant}`]]: isFormatVariant,
                  [styles[`balanceValueDiscount`]]: !!data.salePointsFrom,
                })}
              >
                <img src={coinIcon} className={styles.coinIcon} />
                <div className={classNames(styles.balanceValueContainer)}>
                  {!!data.salePointsFrom && (
                    <span
                      className={classNames(styles.discount, {
                        [styles['discountBigText']]: focus === 'mvcHome' || focus === 'showcaseHome',
                      })}
                    >
                      {numberFix(data.salePointsFrom)} pts
                    </span>
                  )}
                  <p>
                    {numberFix(data.points)}
                    <span className={styles.balanceValuePoints}>{data.points <= 1 ? 'pt' : 'pts'}</span>
                  </p>
                </div>
              </div>
            ) : (
              !isFormatVertical && <strong className={styles.enjoi}>Aproveite!</strong>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

SelectedBenefit.propTypes = {
  variant: PropTypes.oneOf(['green', 'silver', 'gold', 'black']).isRequired,
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onlyOne: PropTypes.bool.isRequired,
  formatVariant: PropTypes.oneOf(['mvcHome', 'Vertical', 'WhiteBackground']),
  focus: PropTypes.string.isRequired,
  index: PropTypes.number,
  fullWidth: PropTypes.bool,
};
SelectedBenefit.defaultProps = {
  formatVariant: 'mvcHome',
  index: null,
  fullWidth: false,
  onClick: null,
};

export { SelectedBenefit };
