import { useCart, useIsAnything, useDCFee, useDeliveryFee } from 'hooks';

import { getOrderTotalAmount, getTempForm } from 'utils';

const useOrderTotal = () => {
  const cart = useCart({ current: true });
  const isAnything = useIsAnything();
  const dcFee = useDCFee();
  const { price } = getTempForm() || {};
  const deliveryFee = useDeliveryFee();

  return getOrderTotalAmount({ cart, isAnything, price, dcFee, deliveryFee });
};

export default useOrderTotal;
