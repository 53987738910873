const formatBenefitTile = ({ benefit = {}, variant = 'green', isFromList = false }) => {
  if (benefit.store?.name) return benefit.store?.name;
  if (benefit.parkingDiscountId) return 'Estacionamento';
  if (benefit.type === 'DISPLAY_ONLY')
    return isFromList ? benefit.title.slice(0, 30) : `Vantagens ${variant.charAt(0).toUpperCase() + variant.slice(1)}`;
  if (benefit.categories?.[0]) return benefit.categories[0];
  if (benefit.isSoldOut) return 'Benefício não disponível';
  return 'Benefício disponível';
};

export default formatBenefitTile;
