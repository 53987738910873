import { useQuery } from '@apollo/react-hooks';
import ORDER from './graphql/queries';

const useOrder = (id) => {
  const { loading, data: { order } = {}, refetch, error } = useQuery(ORDER, { variables: { id } });

  return { loading, order, refetch, error };
};

export default useOrder;
