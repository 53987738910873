import { useEffect, useMemo, useState } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { useUser, useCurrentShopping, useParkingTracking } from 'hooks';
import { IS_OSA_USER, GET_OSA_USER_AVAILABLE_LICENSE_PLATES } from 'screens/apps/parking/graphql/queries';
import { CREATE_LPR_REGISTRATIONS } from 'screens/apps/parking/graphql/mutations';

import { getAvailableOsas } from 'screens/apps/parking/lpr/helpers/getAvailableOsas';
import { useParkingToast } from 'screens/apps/parking/lpr/components/parkingToast/useParkingToast';

import { bugsnag } from 'utils';
import { useParking } from '../useParking';

const storage = {
  getItem: (name) => JSON.parse(sessionStorage.getItem(name) || {}),
  setItem: (name, value) => sessionStorage.setItem(name, value),
};

export const useOsasStates = create(
  persist(
    (set) => ({
      isOsaModalsOpen: false,
      toggleIsOpenOsasModal: () => set((state) => ({ isOsaModalsOpen: !state.isOsaModalsOpen })),
      paymentMethod: '',
      setPaymentMethod: (paymentMethod) => set(() => ({ paymentMethod })),
      osas: [],
      setOsas: (osas) => set(() => ({ osas })),
      hasActiveOsas: {},
      setHasActiveOsas: (hasActiveOsas) => set(() => ({ hasActiveOsas })),
    }),
    {
      name: 'paymentMethod',
      partialize: (state) => ({ paymentMethod: state.paymentMethod }),
      getStorage: () => storage,
    },
  ),
);

export const useOsas = () => {
  const user = useUser();
  const history = useHistory();
  const { userHasLprRegistration, refetchLprRegistration } = useParking();
  const currentShopping = useCurrentShopping();
  const osasViewedModal = multiStorage.getItem('osasViewedModal');
  const { hasActiveOsas, toggleIsOpenOsasModal, setHasActiveOsas } = useOsasStates();
  const location = useLocation();
  const parkingHome = location.pathname === '/apps/estacionamento/home';
  const { openParkingToast, setErrorMessage, closeParkingToast } = useParkingToast();
  const { sendTracking } = useParkingTracking();

  const osaToShow = useMemo(() => {
    const availablePaymentOsas = getAvailableOsas({ activeOsas: hasActiveOsas }).filter((osa) => osa !== 'taggy');

    if (availablePaymentOsas.length > 1) return null;

    return availablePaymentOsas[0];
  }, [hasActiveOsas]);

  const handleCloseOsasModal = () => {
    toggleIsOpenOsasModal();
    multiStorage.setItem('osasViewedModal', `${osaToShow}`);
  };

  const handleIdentifyClientModal = (availableLicensePlates) => {
    const hasAvailableLicensePlates = availableLicensePlates && availableLicensePlates.length > 0;
    const shouldShowModal = hasAvailableLicensePlates && !osasViewedModal && !userHasLprRegistration && parkingHome;

    if (!shouldShowModal) return;
    toggleIsOpenOsasModal();
  };

  const { data: { isOsaUser } = {} } = useQuery(IS_OSA_USER, {
    fetchPolicy: 'cache-and-network',
    skip: !user || !!userHasLprRegistration,
    onCompleted: ({ isOsaUser }) => {
      if (parkingHome) setHasActiveOsas(isOsaUser);
    },
  });

  const { data: { osaUserAvailableLicensePlates } = {}, loading: loadingLicensePlates } = useQuery(
    GET_OSA_USER_AVAILABLE_LICENSE_PLATES,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        osa: osaToShow,
      },
      skip: !osaToShow,
      onCompleted: (data) => handleIdentifyClientModal(data?.osaUserAvailableLicensePlates),
    },
  );

  const sendErrorTracking = (error, context = {}) => {
    try {
      sendTracking({ name: 'LPR Signed Up Error', details: error });
      window.track({
        firebase: {
          name: 'lpr_signed_up_error',
          properties: {
            details: error,
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
    bugsnag?.notify(error, {
      severity: 'error',
      beforeSend: (report) => {
        report.updateMetaData('params', {
          error,
          ...context,
        });
        report.groupingHash = 'lprRegistration#handleSubmitSemPararLicensePlates';
      },
    });
  };

  const [createLprRegistrations, { loading: loadingCreatingRegistrations, error: osaRegistrationError }] = useMutation(
    CREATE_LPR_REGISTRATIONS,
    {
      onError: (error) => {
        const errorCode = error.graphQLErrors[0]?.extensions?.code;
        openParkingToast();
        setErrorMessage(errorCode);
        setTimeout(() => {
          closeParkingToast();
        }, 4100);

        sendErrorTracking(error, {
          paymentMethod: osaToShow,
          currentShopping: currentShopping?.slug,
        });
      },
    },
  );

  const handleRegisterOsas = async (formValues) => {
    const licensePlatesToRegister = formValues.osasLicensePlates
      .filter(({ checked }) => checked)
      .map(({ licensePlate }) => licensePlate);
    const licensePlatesInParkingLot = [];

    sendTracking({
      name: 'Clicked Confirm Sign Up Button',
    });

    try {
      const response = await createLprRegistrations({
        variables: {
          shoppingId: currentShopping?.id,
          terms: formValues.terms,
          licensePlates: licensePlatesToRegister,
          source: 'LPR',
          paymentMethod: osaToShow,
        },
      });

      const lprRegistrations = response?.data?.createLprRegistrations || [];

      lprRegistrations.forEach(
        (lprRegistration) =>
          lprRegistration.hasLooseTicket && licensePlatesInParkingLot.push(lprRegistration.licensePlate?.licensePlate),
      );

      if (lprRegistrations.length) {
        try {
          sendTracking({
            name: 'LPR Signed Up',
            'Payment Method': osaToShow,
            'License Plates': licensePlatesToRegister,
            'In Parking Lot License Plates': licensePlatesInParkingLot,
            'In Parking Lot License Plates Count': licensePlatesInParkingLot?.length,
          });
          window.track({
            firebase: {
              name: 'lpr_signed_up',
            },
            adjust: {
              name: 'LprSignedUp',
              token: '2xoj2a',
            },
          });
        } catch (e) {
          bugsnag?.notify(e);
        }
        await refetchLprRegistration();
        history.push({ pathname: '/apps/estacionamento/home', state: { lastRoute: 'osas-license-plates' } });
      }
    } catch (error) {
      sendErrorTracking(error, {
        licensePlates: licensePlatesToRegister,
        paymentMethod: osaToShow,
        currentShopping: currentShopping?.slug,
      });
    }
  };
  return {
    osaUserAvailableLicensePlates,
    loadingLicensePlates,
    loadingCreatingRegistrations,
    handleRegisterOsas,
    loading: loadingLicensePlates,
    handleCloseOsasModal,
    isOsaUser,
    osaToShow,
    osaRegistrationError,
  };
};
