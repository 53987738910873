import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Query, Mutation } from 'react-apollo';
import getDistance from 'geolib/es/getDistance';
import { useApolloClient } from '@apollo/react-hooks';

import LocationAlert from 'components/locationAlert';
import Screen from 'components/screen';
import Button from 'components/button';
import RegisterDevice from 'components/registerDevice';
import Loading from 'components/loading';
import { useCurrentShopping } from 'hooks';

import { USER, CURRENT_SHOPPING, CURRENT_LOCATION, SHOPPING } from 'services/apollo/queries';
import { REGISTER_DEVICE } from 'services/apollo/mutations';
import { getNearestShopping, informationsDevice, goToShoppingsAndSetLocation, bugsnag } from 'utils';

import logo from 'images/logos/mts-bege-white.svg?url';
import cellPhone from './images/celular-pag-inicial.png';
import styles from './index.module.scss';

const Home = () => {
  const currentShopping = useCurrentShopping();
  const history = useHistory();
  const location = useLocation();
  const client = useApolloClient();
  const [state, setState] = useState({
    isRegistered: false,
    locationAlert: false,
    isLoading: false,
  });
  const { isRegistered, locationAlert, isLoading } = state;
  const { currentLocation } = client.readQuery({ query: CURRENT_LOCATION });

  useEffect(() => {
    const { user } = client.readQuery({ query: USER });
    const getShopping = async (client, currentLocation) => {
      const shopping = await getNearestShopping(client, currentLocation);
      client.writeQuery({ query: CURRENT_SHOPPING, data: { currentShopping: shopping } });
      multiStorage.setItem('shopping', JSON.stringify(shopping));
      try {
        window.identifyShopping();
      } catch (e) {
        bugsnag?.notify(e);
      }
      history.replace('/showcase');
    };

    const updateShopping = async () => {
      const { data: { shopping } = {} } = await client.query({
        query: SHOPPING,
        variables: { slug: currentShopping.slug },
        fetchPolicy: 'network-only',
      });
      if (shopping) {
        client.writeQuery({ query: CURRENT_SHOPPING, data: { currentShopping: shopping } });
        multiStorage.setItem('shopping', JSON.stringify(shopping));
      }
    };

    try {
      window.track({
        firebase: {
          name: 'screen',
          properties: {
            type: 'screenview',
            screenName: location.pathname,
          },
        },
        mixpanel: {
          name: 'Viewed Page',
          properties: {
            'Virtual Page Path': location.pathname,
            'Is First Page View': !sessionStorage.getItem('lastRoute'),
          },
        },
        googleTagManager: {
          global: {
            name: 'viewedPage',
            properties: {
              virtualPagePath: location.pathname,
              isFirstPageView: !sessionStorage.getItem('lastRoute'),
            },
          },
          globalWithoutMixpanel: {
            name: 'viewedPageWithoutMixpanel',
            properties: {
              virtualPagePath: location.pathname,
              isFirstPageView: !sessionStorage.getItem('lastRoute'),
            },
          },
        },
        salesForce: {
          name: 'Viewed Page',
          properties: {
            'Virtual Page Path': location.pathname,
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }

    if (user) sendTracking(user);

    if (currentShopping) {
      updateShopping();
      history.replace('/showcase');
    } else if (!currentShopping && currentLocation?.latitude && currentLocation?.longitude) {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      getShopping(client, currentLocation);
    } else if (location.pathname === '/') {
      history.replace('/home');
    } else if (location.pathname !== '/home' || location.pathname !== '/') {
      history.replace('/home');
    }
  }, []);

  const sendTracking = (user) => {
    try {
      window.setUserProperty('user_id', user.id);
    } catch (e) {
      bugsnag?.notify(e);
    }
  };

  const handleClick = (currentShopping, currentLocation, registration) => {
    if (registration) multiStorage.setItem('goToRegistration', true);
    const name = registration ? ' Clicked Start Sign Up' : 'Clicked Start';
    try {
      window.track({
        mixpanel: {
          name: name,
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
    if (currentShopping && currentLocation && currentLocation.accept) {
      const distance = getDistance(
        {
          latitude: currentLocation.latitude,
          longitude: currentLocation.longitude,
        },
        {
          latitude: currentShopping.location.latitude,
          longitude: currentShopping.location.longitude,
        },
      );
      if (distance > 10000 && multiStorage.getItem('lastShopping') !== currentShopping.slug) {
        history.push({
          pathname: 'shoppings',
          state: { from: 'home' },
        });
      } else history.push('/showcase');
    } else if (!currentLocation || !currentLocation.accept) {
      if (window?.webkit?.messageHandlers?.requestPermissionLocation) {
        goToShoppingsAndSetLocation(client, history, true);
      } else setState((prevState) => ({ ...prevState, locationAlert: true }));
    } else {
      history.push({
        pathname: 'shoppings',
        state: { from: 'home' },
      });
    }
  };

  const { deviceToken, appIdentifier } = informationsDevice(location);

  return (
    (isLoading && <Loading fullscreen />) || (
      <Query query={CURRENT_SHOPPING}>
        {({ data: { currentShopping } }) => (
          <Query query={CURRENT_LOCATION}>
            {({ data: { currentLocation } }) => (
              <Screen className={[styles.root, 'home'].join(' ')}>
                <Mutation
                  mutation={REGISTER_DEVICE}
                  variables={{
                    deviceToken,
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    appVersion: VERSION,
                    appIdentifier,
                  }}
                  onCompleted={() => !isRegistered && setState((prevState) => ({ ...prevState, isRegistered: true }))}
                >
                  {(registerDevice) => (
                    <RegisterDevice registerDevice={!isRegistered ? registerDevice : null}>
                      <div className={styles.container}>
                        <img className={styles.cellPhone} src={cellPhone} alt="logo" />
                        <div className={styles.circle} />
                        <div className={styles.content}>
                          <img className={styles.logo} src={logo} alt="logo" />
                          <h1 className={styles.title}>
                            O shopping inteiro <br /> onde você estiver
                          </h1>
                          <p>Cadastre-se e tenha acesso facilitado a todos os benefícios do Multi!</p>
                          <Button
                            round
                            type="success"
                            id="enterButton"
                            className={styles.enterButton}
                            onClick={() => handleClick(currentShopping, currentLocation, true)}
                          >
                            {'Entrar'}
                          </Button>
                        </div>
                        {locationAlert && <LocationAlert isOpen={locationAlert} portal history={history} />}
                      </div>
                    </RegisterDevice>
                  )}
                </Mutation>
              </Screen>
            )}
          </Query>
        )}
      </Query>
    )
  );
};

export default Home;
