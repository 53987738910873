const UPDATE_BENEFITS = 'UPDATE_BENEFITS';
const TOGGLE_FAVORITE = 'TOGGLE_FAVORITE';

export const updateBenefits = (benefits) => ({
  type: UPDATE_BENEFITS,
  payload: benefits,
});

export const toggleFavorite = (id, isFavorite) => ({
  type: TOGGLE_FAVORITE,
  payload: { id, isFavorite },
});

const initialState = {
  benefits: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_BENEFITS:
      return {
        ...state,
        benefits: action.payload,
      };
    case TOGGLE_FAVORITE:
      return {
        ...state,
        benefits: state.benefits.map((item) =>
          item.id === action.payload.id ? { ...item, isFavorite: action.payload.isFavorite } : item,
        ),
      };
    default:
      return state;
  }
};

export default reducer;
export { initialState };
