import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { bool, oneOfType, func, object } from 'prop-types';

import AppSlider from 'components/appSlider';
import SlideModal from 'components/slideModal';
import Scroller from 'components/scroller';

import CloseIcon from 'icons/close.svg';

import styles from './index.module.scss';

const AllAppsModal = forwardRef(({ isOpen, onClose }, ref) => {
  const [open, changeOpen] = useState(isOpen);

  useEffect(() => {
    changeOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    changeOpen(false);
    if (onClose) onClose();
  };

  const handleOpen = () => {
    changeOpen(true);
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  return (
    <SlideModal hasOutsideClick onClose={handleClose} isOpen={!!open} className={styles.modal}>
      <Scroller>
        <>
          <CloseIcon className={styles.modalIcon} onClick={handleClose} />
          <h1 className={styles.modalHeader}>Atalhos</h1>
          <AppSlider variant="default" className={styles.modalSlider} from={'all-apps-modal'} />
        </>
      </Scroller>
    </SlideModal>
  );
});

AllAppsModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: oneOfType([func, object]),
};

AllAppsModal.defaultProps = {
  onClose: () => {},
};

export default AllAppsModal;
