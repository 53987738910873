import { useApolloClient } from '@apollo/react-hooks';
import { useQuery } from '@apollo/react-hooks';
import { CURRENT_SEGMENT_IDS } from 'services/apollo/queries';
import { useCurrentShopping } from 'hooks';

const useCurrentSegments = () => {
  const client = useApolloClient();
  const currentShopping = useCurrentShopping();
  const { data: { currentSegmentIds } = {} } = useQuery(CURRENT_SEGMENT_IDS);

  const setCurrentSegments = (segmentIds) => {
    client.writeQuery({
      query: CURRENT_SEGMENT_IDS,
      data: {
        currentSegmentIds: {
          ...currentSegmentIds,
          shoppingId: currentShopping.id,
          segmentIds: segmentIds,
        },
      },
    });
  };

  const clearCurrentSegments = () => {
    setCurrentSegments([]);
  };

  return { currentSegmentIds, setCurrentSegments, clearCurrentSegments };
};

export default useCurrentSegments;
