import gql from 'graphql-tag';
import { onError } from 'apollo-link-error';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

import { initialState } from './helpers/variables';
import dataIdFromObject from './helpers/dataIdFromObject';
import checkUser from './helpers/checkUser';
import checkProjectVersion from './helpers/checkProjectVersion';
import getSelectedMovieDate from './helpers/getSelectedMovieDate';
import introspectionQueryResultData from './helpers/fragmentTypes.json';
import {
  getCartToken,
  getLastPaymentType,
  getLocation,
  getShopping,
  setNewShoppingProperties,
  getUser,
} from '../storage';
import { bugsnag } from '../../utils';

const GRAPHQL_URL = process.env.GRAPHQL_URL || 'http://localhost:4000/api';

const location = getLocation();
const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData });
const cache = new InMemoryCache({ fragmentMatcher, dataIdFromObject });

const uploadLink = createUploadLink({
  uri: GRAPHQL_URL,
  credentials: 'same-origin',
});

const authLink = setContext(async (req, { headers }) => {
  bugsnag?.leaveBreadcrumb(`Call ${req.operationName}`);

  const user = getUser();
  const deviceToken = multiStorage.getItem('deviceToken');
  const data = {
    headers: {
      ...headers,
      'user-token': user && user.sessionToken,
      'cart-token': getCartToken(),
    },
  };
  if (deviceToken) data.headers['device-Token'] = deviceToken;
  return data;
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      if (message === 'Context creation failed: Token de acesso inválido.') {
        multiStorage.removeItem('user');
        window.location.reload();
      }
      // eslint-disable-next-line no-console
      return console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`); // eslint-disable-line no-console
});

const typeDefs = gql`
  input LocationInput {
    latitude: Float
    longitude: Float
  }
  input ImageInput {
    url: String
    name: String
  }
`;

const data = {
  pathname: null,
  currentCartStoreId: null,
  phoneNumber: null,
  selectedAddress: null,
  selectedDeliveryType: null,
  selectedMenuItem: null,
  currentShopping: getShopping(),
  selectedMovieDate: getSelectedMovieDate(),
  user: getUser(),
  cartToken: getCartToken(),
  currentSegmentIds: {
    shoppingId: null,
    gastronomy: null,
    segmentIds: null,
    __typename: 'CurrentSegmentIds',
  },
  currentLocation: {
    latitude: (location && location.latitude) || null,
    longitude: (location && location.longitude) || null,
    accept: location && location.accept,
    skip: location && location.skip,
    __typename: 'CurrentLocation',
  },
  plan: {
    name: null,
    shoppingSlug: null,
    __typename: 'Plan',
  },
  scrollPosition: {
    y: 0,
    pathname: null,
    __typename: 'ScrollPosition',
  },
  searchText: '',
  orderFilter: initialState.orderFilter,
  orderFilterLLV: initialState.orderFilterLLV,
  paymentSelected: getLastPaymentType(),
  creditCardSelected: null,
  dialogChangeOpen: false,
  paymentChange: null,
  deliveryNotice: null,
  orderOrChatUpdated: null,
  orderUpdated: null,
  campaignStarted: null,
  currentCoupon: {
    name: null,
    discount: null,
    noFee: false,
    status: null,
    freeShipping: false,
    __typename: 'CurrentCoupon',
  },
  currentFilters: {
    genres: [],
    stores: [],
    categories: [],
    __typename: 'LLVFilter',
  },
  currentLLVQ: '',
  currentThirdPartyDelivery: {
    name: null,
    phoneNumber: null,
    active: false,
    __typename: 'CurrentThirdPartyDelivery',
  },
  changePhoneNumber: {
    cpf: '',
    email: '',
    provider: '',
    oldPhoneNumber: '',
    newPhoneNumber: '',
    __typename: 'ChangePhoneNumber',
  },
};

const link = errorLink.concat(authLink.concat(uploadLink));

const client = new ApolloClient({
  connectToDevTools: true,
  link,
  cache,
  typeDefs,
  resolvers: {},
});

cache.writeData({ data });
client.onResetStore(() => cache.writeData({ data }));

checkUser(client, data.user);
setNewShoppingProperties(client);
checkProjectVersion();

multiStorage.setItem('GRAPHQL_URL', GRAPHQL_URL);

export default client;
