import gql from 'graphql-tag';

const ORDER = gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      fee
      text
      amount
      createdAt
      orderNumber
      discount
      totalAmount
      deliveryAmount
      updatedAt
      orderUpdatedAt
      feedbackResponse {
        id
      }
      agent {
        id
        name
      }
      deliveryType {
        id
        value
      }
      stores {
        id
        name
        images
        gastronomy
        floors {
          name
          number
        }
        shopping {
          id
          slug
        }
      }
      status {
        id
        slug
        value
      }
      chat {
        id
      }
      address {
        id
        formattedAddress
      }
      payment {
        id
        paymentType {
          id
          name
        }
        creditCard {
          id
          brand
          lastNumbers
        }
      }
      items {
        id
        price
        quantity
        description
        product {
          ... on Product {
            id
            name
            cover
            store {
              id
              name
            }
            variations {
              type
              values {
                label
                value
              }
            }
            customAttributes {
              label
              value
            }
          }
          ... on Menu {
            id
            name
            items {
              id
              options {
                id
                name
              }
            }
            store {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default ORDER;
