import React, { useMemo } from 'react';
import { classNames, formatDatePtBR } from 'utils';
import { bool, object, string } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { clickedWelcomeBanner } from '../../screens/showcase/tracking';

import ChevronRight from 'icons/angle-right.svg';

import styles from './multivcbanner.module.scss';

const MultivcBanner = ({ type, isOld, user, plan }) => {
  const history = useHistory();
  const lowercaseType = isOld ? `${type.toLowerCase()}-old` : type.toLowerCase();
  const variants = {
    [styles.green]: lowercaseType === 'green',
    [styles.silver]: lowercaseType === 'silver',
    [styles.gold]: lowercaseType === 'gold',
    [styles.black]: lowercaseType === 'black',
    [styles['green-old']]: lowercaseType === 'green-old',
    [styles['gold-old']]: lowercaseType === 'gold-old',
  };

  function handleClick() {
    clickedWelcomeBanner();
    history.push('/apps/multivoce');
  }

  const nameShortener = () => {
    const nameBreak = user.name.split(' ');
    const nameLast = parseInt(nameBreak.length - 1);

    return nameLast > 0 ? nameBreak[0] + ' ' + nameBreak[nameLast] : user.name;
  };

  const isValueSmallerThenZero = useMemo(() => (plan?.currentTierMaintenanceBalance < 0 ? true : false), [plan]);

  return (
    <div className={classNames(styles.container, variants)} onClick={handleClick}>
      <div className={styles.cardContainer}>
        <div className={styles.infoContainer}>
          <strong className={styles.infoContainerTitle} data-testid={'firstNameSpan'}>
            {nameShortener()}
          </strong>
          <div className={styles.infoContainerType}>
            <span className={styles.infoContainerTypeText}>MultiVocê {type}</span>
            {plan.name !== 'Green' && !isValueSmallerThenZero && (
              <span className={styles.infoContainerTypeDate}>até {formatDatePtBR(plan.nextTierAssessmentDate)}</span>
            )}
          </div>
        </div>
        <div className={styles.seeBenefit}>
          <ChevronRight className={styles.chevronRight} />
        </div>
      </div>
    </div>
  );
};

MultivcBanner.propTypes = {
  type: string.isRequired,
  isOld: bool.isRequired,
  user: object.isRequired,
  plan: object.isRequired,
};

export { MultivcBanner };
