const trackClickedStoreReservation = (storeName) => {
  const data = {
    mixpanel: {
      name: 'Clicked Store Reservation',
      properties: {
        'Store Name': storeName,
      },
    },
  };

  window.track(data);
};

export default trackClickedStoreReservation;
