import { useQuery } from '@apollo/react-hooks';

import { useCurrentShopping } from 'hooks';
import { STORE_SEGMENTS } from './graphql/queries';

const useSegments = ({ appSlug = 'marketplace', isService = undefined, gastronomy = false }) => {
  const currentShopping = useCurrentShopping();
  const { data: { storeSegments } = {}, loading } = useQuery(STORE_SEGMENTS, {
    variables: {
      appSlug,
      shoppingId: currentShopping.id,
      isService,
      gastronomy,
    },
  });

  const segments = storeSegments?.segments;

  return { segments, loading };
};

export default useSegments;
