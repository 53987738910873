import gql from 'graphql-tag';

const fields = `
  id
  dcId
  name
  slug
  images
  opened
  delivery
  gastronomy
  hasContract
  deliveryTime
  mainPhone
  whatsappNumber
  shippingValue
  isService
  featured
  floors {
    name
    number
    map
  }
  sale {
    active
    text
  }
  logoColor {
    id
    original
  }
  segments {
    id
    name
  }
  shopping {
    id
    businessHours {
      store {
        start
        end
      }
      restaurant {
        start
        end
      }
    }
  }
`;

const STORE_SEGMENTS = gql`
  query storeSegments(
    $appSlug: String!,
    $shoppingId: ID!,
    $gastronomy: Boolean,
    $segmentIds: [ID!],
    $location: LocationInput,
    $limit: Int,
    $skip: Int,
    $isService: Boolean,
    $hasReservation: Boolean,
    $hasQueue: Boolean,
  ) {
    storeSegments(
      appSlug: $appSlug,
      shoppingId: $shoppingId,
      gastronomy: $gastronomy,
      segmentIds: $segmentIds,
      location: $location,
      limit: $limit,
      skip: $skip,
      isService: $isService,
      hasReservation: $hasReservation,
      hasQueue: $hasQueue,
    ) {
      stores {
        ... on Store {
          ${fields}
        }
        ... on Restaurant {
          ${fields}
          businessHours {
            dayOfWeek
            openAt
            closeAt
          }
          reservation{
            active
            phoneNumber
            whatsappNumber
            siteUrl
          }
          queue {
            active
            siteUrl
          }
          reservation{
            active
            phoneNumber
            whatsappNumber
            siteUrl
          }
        }
      }
    }
  }
`;

export default STORE_SEGMENTS;
export { STORE_SEGMENTS };
