const translateType = (type) => {
  const typesMap = new Map([
    ['aceito', 'accepted'],
    ['novo', 'new'],
    ['aprovado', 'approved'],
    ['em-transito', 'ongoing'],
    ['retirado-em-loja', 'pickedup'],
    ['em-preparo', 'preparation'],
    ['cancelado', 'canceled'],
    ['entregue', 'delivered'],
    ['retirado-no-locker', 'pickedUpInLocker'],
    ['pronto-para-retirada-no-locker', 'readyForLocker'],
    ['pronto-para-sair', 'readyToShipment'],
    ['preparando-envio', 'shipmentPreparation'],
  ]);

  return typesMap.get(type);
};

export default translateType;
