import { lazy } from 'react';

const Convenience = lazy(() => import(/* webpackChunkName: "convenience" */ 'screens/apps/convenience'));

export default [
  {
    path: '/apps/comodidades',
    exact: true,
    component: Convenience,
  },
];
