import React, { useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import ElementInput from 'element-react/dist/npm/es5/src/input';

import 'element-theme-chalk/src/input.scss';
import 'element-theme-chalk/src/input-number.scss';
import './index.scss';

const FancyInput = ({ onClick, isNative, elRef, ...restProps }) => {
  const focusInput = (e) => {
    elRef.current?.focus();
    if (onClick) onClick(e);
  };

  const TagName = isNative ? 'input' : ElementInput;

  return <TagName ref={elRef} {...restProps} onClick={focusInput} />;
};

FancyInput.propTypes = {
  onClick: PropTypes.func,
  isNative: PropTypes.bool,
  elRef: PropTypes.object.isRequired,
};

FancyInput.defaultProps = {
  onClick: () => null,
  isNative: false,
};

const Input = (props, ref) => {
  const elRef = ref || useRef();
  return <FancyInput {...{ elRef }} {...props} />;
};

export default forwardRef(Input);
