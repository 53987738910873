const trackClickedDirectSellingGastronomyPage = ({ name }) => {
  const data = {
    mixpanel: {
      name: 'Clicked Direct Selling Gastronomy Page',
      properties: {
        'Store Name': name,
      },
    },
    googleTagManager: {
      global: {
        name: 'clickedDirectSellingGastronomyPage',
        properties: {
          StoreName: name,
        },
      },
    },
  };

  window.track(data);
};

export default trackClickedDirectSellingGastronomyPage;
