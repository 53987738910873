import React from 'react';
import PropTypes from 'prop-types';

import Times from 'icons/times.svg';
import Parking from 'icons/estacionamento-modal-ic.svg';
import Modal from 'components/modal';
import styles from './index.module.scss';

const ParkingModal = ({ isOpen, onClose }) => (
  <Modal className="class" onClose={onClose} isOpen={isOpen}>
    <div className={styles.root}>
      <button className={styles.timesCloseButton} onClick={onClose}>
        <Times />
      </button>
      <div className={styles.avatar}>
        <Parking />
      </div>
      <h1>Comunicado</h1>
      <span>
        Pagamento online <strong>não disponível no momento.</strong>
      </span>
    </div>
  </Modal>
);

ParkingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ParkingModal;
