import { useLocation } from 'react-router-dom';
import { useParking } from '../useParking';
import { bugsnag } from 'utils';

const useParkingTracking = () => {
  const { userHasLprRegistration, userVehiclesCount, userPaymentMethod, hasLooseTicket, userVehicles } = useParking();
  const location = useLocation();

  const hasVehiclePending = userVehicles?.some(
    (vehicle) => vehicle.licensePlate?.status === 'PENDING' || vehicle.licensePlate?.status === 'PENDING_UNBLOCKED',
  );
  const allVehiclesApproved = userVehicles?.every((vehicle) => vehicle.licensePlate?.status === 'APPROVED');
  const hasVehicleBlocked = userVehicles?.some(
    (vehicle) => vehicle.licensePlate?.status === 'BLOCKED' || vehicle.licensePlate?.status === 'PENDING_BLOCKED',
  );

  const userStatus = () => {
    if (!userVehicles.length) return undefined;
    if (hasVehiclePending && !hasVehicleBlocked) return 'Processing';
    if (allVehiclesApproved) return 'Active';
    if (hasVehicleBlocked) return 'Blocked';
    if (hasLooseTicket) return 'Easy Out';
  };

  const sendTracking = ({ name, type, action, reason, from, ...rest }) => {
    try {
      window.track({
        mixpanel: {
          name,
          properties: {
            action,
            type,
            reason,
            from,
            ['Virtual Page Path']: location?.pathname,
            ['Has Acesso Multi']: userHasLprRegistration,
            ['Acesso Multi Vehicles Count']: userVehiclesCount,
            ['Acesso Multi Payment Method']: userPaymentMethod,
            ['Acesso Multi User Status']: userStatus(),
            ...rest,
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
  };

  return {
    sendTracking,
  };
};

export { useParkingTracking };
