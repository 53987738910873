import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const RegisterDevice = (props) => {
  const { children, registerDevice } = props;

  useEffect(() => {
    registerDevice && registerDevice();
  }, []);

  return <>{children}</>;
};

RegisterDevice.propTypes = {
  children: PropTypes.node.isRequired,
  registerDevice: PropTypes.func,
};

RegisterDevice.defaultProps = {
  registerDevice: null,
};

export default RegisterDevice;
