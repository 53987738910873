import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { useQuery } from '@apollo/react-hooks';
import { useCurrentShopping, useUser } from 'hooks';
import { bugsnag } from 'utils';
import Announcement from 'components/announcement';
import { ANNOUNCEMENT } from './graphql/queries';

const AnnouncementModal = () => {
  const currentShopping = useCurrentShopping();
  const history = useHistory();
  const user = useUser();
  const { pathname } = useLocation();

  currentShopping?.slug === 'barrashopping' ? 'villagemall' : currentShopping?.slug;
  const { data: { announcement = {} } = {}, loading } = useQuery(ANNOUNCEMENT, {
    variables: { shoppingSlug: currentShopping?.slug, path: pathname },
    fetchPolicy: 'network-only',
    skip: !currentShopping,
  });

  const [showModal, changeModal] = useState(false);
  const { id, path, hash, frequencyToShow, image, title, content, button, buttonText, buttonLink } = announcement || {};
  const storageId = `announcement_${currentShopping?.slug}_${path && path}_${hash}`;
  let storageIdCheck;

  useEffect(() => {
    if (!loading) {
      if (frequencyToShow === 'once') {
        if (!multiStorage.getItem(storageId) || multiStorage.getItem(storageId) === 'false') {
          storageIdCheck = false;
        } else storageIdCheck = true;
        changeModal(!storageIdCheck);
      } else if (frequencyToShow === 'always') {
        if (!sessionStorage.getItem(storageId) || sessionStorage.getItem(storageId) === 'false') {
          storageIdCheck = false;
        } else storageIdCheck = true;
        changeModal(!storageIdCheck);
      }
    }
  }, [frequencyToShow, loading]);

  const handleCloseModal = (trackClose) => {
    changeModal(false);
    if (trackClose) {
      try {
        window.track({
          mixpanel: {
            name: 'Closed Announcement',
            properties: {
              'Announcement ID': id,
              'Annoucement Title': title,
              'User ID': user?.id,
              'Shopping Slug': currentShopping?.slug,
              'Virtual Page Path': history?.location.pathname,
            },
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }
    }

    if (frequencyToShow === 'once') {
      sessionStorage.getItem(storageId) && sessionStorage.removeItem(storageId);
      multiStorage.setItem(storageId, true);
    } else if (frequencyToShow === 'always') {
      multiStorage.getItem(storageId) && multiStorage.removeItem(storageId);
      sessionStorage.setItem(storageId, false);
    }
  };

  useEffect(() => {
    if (!loading && showModal) {
      try {
        window.track({
          mixpanel: {
            name: 'Viewed Announcement',
            properties: {
              'Announcement ID': id,
              'Annoucement Title': title,
              'User Id': user?.id,
              'Shopping Slug': currentShopping?.slug,
              'Virtual Page Path': history?.location.pathname,
            },
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }
    }
  }, [showModal, loading]);

  if (loading || !currentShopping) return null;

  return (
    (id && (
      <Announcement
        onClose={(trackClose = true) => handleCloseModal(trackClose)}
        id={id}
        image={image ? image.original : undefined}
        isOpen={showModal}
        title={title}
        button={button}
        buttonText={buttonText}
        buttonLink={buttonLink}
      >
        {ReactHtmlParser(content)}
      </Announcement>
    )) || <></>
  );
};

export default AnnouncementModal;
