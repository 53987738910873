import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';

import Button from 'components/button';
import IconStar from 'icons/star.svg';
import Ticket from 'icons/ictickets.svg';
import IconWallet from 'icons/button-wallet.svg';
import IconCheck from 'icons/check.svg';
import IconTicket from 'icons/ticket.svg';

import styles from './index.module.scss';

const types = {
  star: {
    label: 'Favoritos',
    icon: <IconStar className={classNames(styles.star, styles.icon)} />,
  },
  wallet: {
    label: 'Prontos para usar',
    icon: <IconWallet className={classNames(styles.wallet, styles.icon)} />,
  },
  check: {
    label: 'Utilizados',
    icon: <IconCheck className={classNames(styles.check, styles.icon)} />,
  },
  ticket: {
    label: 'Vantagens',
    icon: <IconTicket className={classNames(styles.ticket, styles.icon)} />,
    buttonStyle: styles.ticketButton,
  },
  hubWallet: {
    buttonStyle: styles.walletButton,
    icon: <Ticket className={styles.wallet} />,
  },
};

const colorStyles = {
  green: styles.green,
  silver: styles.silver,
  gold: styles.gold,
  black: styles.black,
  lightgreen: styles.lightgreen,
};

const IconButtonPopUpNumber = ({ type, color, popUpCount, onClick }) => {
  return (
    <div id={`iconBtn${types[type].label}`} className={classNames(styles.content, colorStyles[color])}>
      <Button
        className={classNames(styles.iconButton, types[type].buttonStyle)}
        onClick={onClick}
        data-testid={`buttonMyBenefits${type}`}
      >
        {!!popUpCount && (
          <div className={styles['pop-up-number']}>
            <div className={styles['pop-up-number-container']}>
              <span className={styles.number}>{popUpCount}</span>
            </div>
          </div>
        )}
        {types[type].icon}
      </Button>
      <span className={type === 'ticket' ? styles.ticketLabel : styles.label}>
        {types[type].label} <strong className={styles.labelStrong}>{type === 'ticket' && color}</strong>
      </span>
    </div>
  );
};

IconButtonPopUpNumber.propTypes = {
  type: PropTypes.string.isRequired,
  popUpCount: PropTypes.number,
  color: PropTypes.oneOf(['green', 'silver', 'gold', 'black', 'lightgreen']).isRequired,
  onClick: PropTypes.func,
};

IconButtonPopUpNumber.defaultProps = {
  popUpCount: 0,
  onClick: () => {},
};

export default IconButtonPopUpNumber;
