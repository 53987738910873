import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Query, withApollo } from 'react-apollo';

import CampaignModal from './components/campaignModal';
import { bugsnag } from 'utils';
import { setCampaign } from './functions';
import { MY_COUPONS } from './graphql/queries';
import { SAVE_MY_COUPON } from './graphql/mutations';
import { CAMPAIGN_STARTED, HAVE_VALID_COUPON, CURRENT_SHOPPING, USER } from 'services/apollo/queries';

const Campaign = ({ client }) => {
  useEffect(() => {
    setCampaign();
  }, []);

  const handleCloseModal = (campaignId) => {
    multiStorage.setItem(`campaign_${campaignId}`, true);
    client.writeQuery({ query: CAMPAIGN_STARTED, data: { campaignStarted: null } });
  };

  const handleViewedModal = async ({ campaignStarted: campaign }) => {
    if (campaign) {
      const { coupon, shopping } = campaign;
      const { currentShopping } = client.readQuery({ query: CURRENT_SHOPPING });

      if (shopping.id !== currentShopping.id) {
        client.writeQuery({ query: CURRENT_SHOPPING, data: { currentShopping: shopping } });
        multiStorage.setItem('shopping', JSON.stringify(shopping));
      }

      if (currentShopping && shopping && coupon.name) {
        await client.mutate({
          mutation: SAVE_MY_COUPON,
          variables: {
            name: coupon.name,
            shoppingSlug: shopping.slug,
          },
          refetchQueries: [{ query: HAVE_VALID_COUPON }, { query: MY_COUPONS }],
        });

        try {
          window.track({
            mixpanel: {
              name: 'Viewed Campaign Modal',
              properties: {
                'Coupon Name': coupon.name,
                'Campaign Name': campaign.title,
                'Campaign Message': campaign.message,
              },
            },
            googleTagManager: {
              global: {
                name: 'viewedCampaignModal',
                properties: {
                  couponName: coupon.name,
                  campaignName: campaign.title,
                  campaignMessage: campaign.message,
                },
              },
            },
          });
        } catch (e) {
          bugsnag?.notify(e);
        }
      }
    }
  };

  const handleLoginRedirect = () => {
    const path = location.hash.substr(1);
    client.writeData({ data: { pathname: path } });
    client.writeQuery({ query: CAMPAIGN_STARTED, data: { campaignStarted: null } });
    location.replace('/#/signup/phone');
  };

  const renderCampaignModal = (campaign) => {
    const { user } = client.readQuery({ query: USER });

    if (campaign) {
      const { id, title, message, rules, buttonText, coupon, shopping, image } = campaign;

      if (!user) {
        return <CampaignModal show onClose={() => handleLoginRedirect()} {...{ title, message, buttonText, image }} />;
      }

      const couponName = coupon && coupon.name ? coupon.name : null;

      return (
        <CampaignModal
          show
          onClose={() => handleCloseModal(id, shopping)}
          {...{ couponName, title, rules, message, buttonText, image }}
        />
      );
    }

    return null;
  };

  return (
    <Query query={CAMPAIGN_STARTED} onCompleted={handleViewedModal}>
      {({ data: { campaignStarted } }) => renderCampaignModal(campaignStarted)}
    </Query>
  );
};

Campaign.propTypes = {
  client: PropTypes.object.isRequired,
};

export default withApollo(Campaign);
