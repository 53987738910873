const trackClickedStoreContact = ({ contactType, storeName }) => {
  const data = {
    mixpanel: {
      name: 'Clicked Store Contact',
      properties: {
        'Store Name': storeName,
        'Contact Type': contactType,
      },
    },
    googleTagManager: {
      global: {
        name: 'clickedStoreContact',
        properties: {
          storeName,
          contactType,
        },
      },
    },
  };

  window.track(data);
};

export default trackClickedStoreContact;
