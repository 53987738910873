import { GET_USER_CHECK_INS } from 'services/apollo/queries';
import { useQuery } from '@apollo/react-hooks';

const useGetUserCheckIns = (userId, shoppingId) => {
  const { data: { userCheckIns = [] } = {} } = useQuery(GET_USER_CHECK_INS, {
    variables: {
      userId: userId,
      shoppingId: shoppingId,
      hasIssued: true,
      hasUsed: false,
    },
    fetchPolicy: 'network-only',
  });

  return { userCheckIns };
};

export default useGetUserCheckIns;
