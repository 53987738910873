const getVariant = (plan) => {
  switch (plan) {
    case 'Green':
      return 'green';
    case 'Silver':
      return 'silver';
    case 'Gold':
      return 'gold';
    case 'Black':
      return 'black';
  }
};

export default getVariant;
