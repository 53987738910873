import { useState } from 'react';
import _ from 'lodash';

const useInfiniteScroll = () => {
  const [newData, changeData] = useState(null);
  const [page, changePagination] = useState(1);
  const getMoreData = ({ fetchMore, data }) => {
    const objName = _.keys(data)[0];
    fetchMore({
      variables: { skip: data[objName].length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        changeData(_.get(fetchMoreResult, objName).length);
        changePagination(page + 1);
        if (_.isArray(fetchMoreResult[objName])) {
          return Object.assign({}, prev, {
            [objName]: [...prev[objName], ...fetchMoreResult[objName]],
          });
        } else {
          return _.mergeWith(prev, fetchMoreResult, (objValue, srcValue) => {
            if (_.isArray(objValue)) {
              return objValue.concat(srcValue);
            }
          });
        }
      },
    });
  };

  const resetInfiniteScrollControl = () => {
    changeData(null);
    changePagination(1);
  };

  return { page, changePagination, resetInfiniteScrollControl, getMoreData, newData, changeData };
};

export default useInfiniteScroll;
