import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import styles from './index.module.scss';

export default class Overlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: props.isVisible };
  }

  componentDidUpdate(prevProps) {
    if (this.willChangeVisibility(prevProps, this.props)) {
      this.props.isVisible && this.open();
      !this.props.isVisible && this.close();
    }
  }

  willChangeVisibility(prevProps, nextProps) {
    return !prevProps.isVisible && nextProps.isVisible;
  }

  open() {
    const { onOpen } = this.props;
    this.setState({ visible: true });
    if (onOpen) onOpen();
  }

  close() {
    const { onClose, preventClosing } = this.props;
    if (preventClosing) return;
    this.setState({ visible: false });
    if (onClose) onClose();
  }

  render() {
    const { visible } = this.state;
    const { withBackdrop, children, type, rootClassName, animation, delay } = this.props;

    return (
      <div
        className={classNames(styles.root, rootClassName, {
          [styles.visible]: visible,
          [`animate__animated animate__faster ${animation} ${delay ? `delay-half` : ``}`]: animation,
        })}
      >
        <div className={styles.wrapper}>
          {withBackdrop ? <div className={styles.backdrop} onClick={() => this.close()} /> : null}
          <div className={classNames(styles.overlay, styles[type], 'overlay')}>{children}</div>
        </div>
      </div>
    );
  }
}

Overlay.propTypes = {
  onOpen: PropTypes.func,
  isVisible: PropTypes.bool,
  withBackdrop: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  type: PropTypes.oneOf(['modal', 'aside-left', 'aside-right', 'normal']),
  rootClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  animation: PropTypes.string,
  delay: PropTypes.bool,
  preventClosing: PropTypes.bool,
};

Overlay.defaultProps = {
  onOpen: () => null,
  type: 'modal',
  isVisible: false,
  withBackdrop: true,
  rootClassName: null,
  animation: null,
  delay: false,
  preventClosing: false,
};
