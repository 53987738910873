import gql from 'graphql-tag';

const CATEGORIES_LIST = gql`
  query benefitFilters($shoppingSlug: String, $modalities: [LoyaltyModality]) {
    benefitFilters(shoppingSlug: $shoppingSlug, modalities: $modalities) {
      categories
    }
  }
`;
export { CATEGORIES_LIST };
