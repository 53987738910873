import propTypes from 'prop-types';
import React from 'react';

import ProgressiveImage from 'components/progressiveImage';

import styles from './index.module.scss';

const IconBox = ({ title, description, icon }) => {
  return (
    <div className={styles.iconContainer}>
      <div className={styles.iconBox}>
        <ProgressiveImage withLoader={false} src={icon} />
      </div>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );
};

IconBox.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  icon: propTypes.string.isRequired,
};

export default IconBox;
