import React from 'react';
import { PatternFormat } from 'react-number-format';
import PropTypes from 'prop-types';

import { classNames } from '../../utils';

import styles from './index.module.scss';

const InputMask = ({ className, classNameInput, append, ...props }) => (
  <div className={classNames('el-input', classNameInput)}>
    <PatternFormat className={classNames('el-input__inner', className)} {...props} />
    {append && <span className={styles.append}>{append}</span>}
  </div>
);

InputMask.propTypes = {
  className: PropTypes.string,
  classNameInput: PropTypes.string,
  append: PropTypes.element,
};

InputMask.defaultProps = {
  className: null,
  classNameInput: null,
  append: null,
};

export default InputMask;
