const deg2rad = (deg) => deg * (Math.PI / 180);

const getDistanceFromLatLongInKm = (userLocation, shoppingLocation) => {
  const { latitude, longitude } = userLocation;
  const { lat: latShopping, long: longShopping } = shoppingLocation;
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(latShopping - latitude); // deg2rad below
  const dLong = deg2rad(longShopping - longitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(latitude)) * Math.cos(deg2rad(latShopping)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return Number((R * c).toFixed(2)); // Distance in km
};

export default getDistanceFromLatLongInKm;
