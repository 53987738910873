import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useCurrentShopping, useApp } from 'hooks';
import { clickedTreadItemTracking } from '../../screens/showcase/tracking';

import Card from 'components/card';
import ProgressiveImage from 'components/progressiveImage';
import Slider from 'components/slider';
import TreadSlider from 'components/treadSlider';

import { POSTS } from './graphql/queries';

import NoticeIcon from 'icons/notice.svg';

import { classNames, bugsnag } from 'utils';

import styles from './index.module.scss';
import './index.scss';

const PostItem = ({ image, name, onClick }) => {
  const handleClick = useCallback(onClick, []);

  return useMemo(
    () => (
      <div className={classNames('post-card', styles.postCard)} onClick={handleClick}>
        <Card>
          <ProgressiveImage src={image} className={styles.postCardImage} />
        </Card>
        <div className={classNames('title', styles.postCardText)}>{name}</div>
      </div>
    ),
    [name, image, handleClick],
  );
};

const PostSlider = () => {
  const slug = 'dicas-e-novidades';
  const app = useApp({ slug });
  const history = useHistory();
  const currentShopping = useCurrentShopping();
  const seeAllPath = `/apps/${slug}`;

  const { data: { posts } = {} } = useQuery(POSTS, {
    variables: {
      shoppingId: currentShopping.id,
      typesSlugs: ['novidade', 'evento'],
      limit: 3,
      skip: 0,
    },
  });

  const handleSliderClick = (post, index) => {
    clickedTreadItemTracking(slug, post.name, index);
    try {
      window.track({
        firebase: {
          name: 'view_post',
          properties: {
            type: 'event',
            eventCategory: 'post',
            eventAction: 'click',
            eventLabel: post.name,
          },
        },
        mixpanel: {
          name: 'Viewed Post',
          properties: {
            'Post Name': post.name,
          },
        },
        googleTagManager: {
          global: {
            name: 'viewedPost',
            properties: {
              postName: post.name,
            },
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
    history.push(`${seeAllPath}/${post.slug}`);
  };

  if (!app || !posts || posts.length <= 0) return null;

  return (
    <TreadSlider
      color={'#cb3d92'}
      icon={<NoticeIcon className={styles.icon} />}
      path={seeAllPath}
      title={'Acontece no Shopping'}
      wordEmphasis={'last'}
      slug={slug}
    >
      <div className={classNames(styles.posts, 'post-slider animate__animated animate__bounceInRight animate__fast')}>
        <Slider itemsToShow={1.5}>
          {posts.map((post, index) => (
            <PostItem
              key={post.name}
              name={post.name}
              image={post.images[0]}
              onClick={() => handleSliderClick(post, index)}
            />
          ))}
        </Slider>
      </div>
    </TreadSlider>
  );
};

export default PostSlider;
