import { winBenefitPromotionToast } from 'components/winBenefitPromotionToast';
import { bugsnag } from 'utils';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { useCurrentShopping, useUserPlan } from 'hooks';

import { USED_BENEFITS } from '../../screens/apps/fidelity/benefits/graphql/queries';

export const usePromotionToast = () => {
  const history = useHistory();
  const currentShopping = useCurrentShopping(true);
  const plan = useUserPlan();
  const variant = plan?.name;

  useQuery(USED_BENEFITS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: 3,
      shoppingSlug: currentShopping?.slug,
      statusesValues: ['issued'],
      ordering: [
        {
          field: 'promotion',
          sort: 'DESC',
        },
      ],
    },
    onCompleted: (data) => seePromotionToast(data),
  });

  const seePromotionToast = (benefitPromotionData) => {
    const hasClicked = multiStorage.getItem('multivc-click-promotion-toast');
    const hasPromotionId = benefitPromotionData.usedBenefits.some((benefit) => benefit.promotion?.id);

    if (hasPromotionId && hasClicked === null) {
      try {
        window.track({
          mixpanel: {
            name: 'Displayed Salesforce Promo Toast',
            properties: {
              Source: 'Home MVC',
            },
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }

      winBenefitPromotionToast({
        variant,
        handleClick: () => {
          multiStorage.setItem('multivc-click-promotion-toast', true);
          try {
            window.track({
              mixpanel: {
                name: 'Clicked Salesforce Promo Toast',
                properties: {
                  Source: 'Home MVC',
                },
              },
            });
          } catch (e) {
            bugsnag?.notify(e);
          }
          goToReadyToUse();
        },
      });
    }
  };

  const goToReadyToUse = () => {
    history.push('/apps/multivoce/meusbeneficios/ready');
  };
};
