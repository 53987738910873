import { useQuery } from '@apollo/react-hooks';
import { CURRENT_COUPON } from 'services/apollo/queries';

const useCurrentCoupon = () => {
  const { data: { currentCoupon } = {} } = useQuery(CURRENT_COUPON);

  return currentCoupon;
};

export default useCurrentCoupon;
