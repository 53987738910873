import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import { Link } from 'react-router-dom';

import 'element-theme-chalk/src/button.scss';
import 'element-theme-chalk/src/button-group.scss';
import './index.scss';

const buttonTypes = [
  'default',
  'primary',
  'success',
  'secondary',
  'info',
  'warning',
  'danger',
  'search',
  'llv',
  'parking',
  'noBorder',
];
const buttonSizes = ['default', 'medium', 'small', 'mini', 'full'];

const Button = ({
  loading,
  onClick,
  type,
  size,
  className: classNameProps,
  disabled,
  style,
  nativeType,
  icon,
  elRef,
  flat,
  plain,
  round,
  circle,
  to,
  children,
  shade,
  ...restProps
}) => {
  const handleClick = (e) => {
    if (!loading && onClick) onClick(e);
  };
  const setStyle = useMemo((args) => Object.assign({}, args, style), [style]);
  const className = classNames('el-button', classNameProps, {
    [`el-button--${type}`]: buttonTypes.includes(type),
    [`el-button--${size}`]: buttonSizes.includes(size),
    'is-disabled': disabled,
    'is-loading': loading,
    'is-flat': flat,
    'is-plain': plain,
    'is-round': round,
    'is-circle': circle,
    'is-shade': shade,
  });

  return to ? (
    <Link
      style={{ setStyle }}
      ref={elRef}
      type={nativeType}
      onClick={handleClick}
      to={to}
      {...{ disabled, className }}
      {...restProps}
    >
      {loading && <i className="el-icon-loading" />}
      {icon && !loading && <i className={`el-icon-${icon}`} />}
      {children}
    </Link>
  ) : (
    <button
      style={setStyle}
      ref={elRef}
      type={nativeType}
      onClick={handleClick}
      {...{ disabled, className }}
      {...restProps}
    >
      {loading && <i className="el-icon-loading" />}
      {icon && !loading && <i className={`el-icon-${icon}`} />}
      {children}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(buttonTypes),
  size: PropTypes.oneOf(buttonSizes),
  icon: PropTypes.string,
  nativeType: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  plain: PropTypes.bool,
  style: PropTypes.object,
  elRef: PropTypes.object,
  flat: PropTypes.bool,
  round: PropTypes.bool,
  circle: PropTypes.bool,
  children: PropTypes.any,
  to: PropTypes.string,
  shade: PropTypes.bool,
  isUpperCase: PropTypes.bool,
};

Button.defaultProps = {
  className: '',
  onClick: () => null,
  type: 'default',
  size: 'default',
  icon: '',
  nativeType: 'button',
  loading: false,
  disabled: false,
  plain: false,
  style: null,
  elRef: null,
  flat: false,
  round: false,
  circle: false,
  children: null,
  to: '',
  shade: false,
  isUpperCase: false,
};

export default Button;
