import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import Modal from 'components/modal';
import Button from 'components/button';

import CouponLogo from 'images/logos/coupon-modal.svg?url';

import styles from './index.module.scss';

const CampaignModal = ({ title, couponName, message, rules, buttonText, onClose, show, image }) => (
  <Modal className={styles.slideModal} isOpen={show} onClose={onClose}>
    <div className={styles.root}>
      <img src={image?.original || CouponLogo} className={styles.logo} />
      <div className={styles.title}>
        <strong>{ReactHtmlParser(title)}</strong>
      </div>
      {couponName && <div className={styles.codeBox}>{couponName}</div>}
      <div className={styles.message}>{ReactHtmlParser(message)}</div>
      {rules && <div className={styles.rules}>{ReactHtmlParser(rules)}</div>}
      <Button className={styles.button} type="success" onClick={onClose}>
        {buttonText}
      </Button>
    </div>
  </Modal>
);

CampaignModal.propTypes = {
  title: PropTypes.node.isRequired,
  couponName: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  show: PropTypes.bool,
  rules: PropTypes.string,
  image: PropTypes.object,
};

CampaignModal.defaultProps = {
  show: false,
  rules: undefined,
  couponName: undefined,
  image: undefined,
};

export default CampaignModal;
