import React from 'react';
import { SHOWS } from './graphql/queries';
import { useQuery } from '@apollo/react-hooks';
import { formatUTCDate } from 'utils';
import { useCurrentShopping } from 'hooks';

import MovieCard from 'components/movieCard';
import TreadSlider from 'components/treadSlider';
import Slider from 'components/slider';
import MovieLogo from 'icons/ic-cinema.svg';

import { clickedTreadItemTracking } from '../../screens/showcase/tracking';

import styles from './index.module.scss';

const FeaturedMovies = () => {
  const currentShopping = useCurrentShopping();
  const dateToQuery = formatUTCDate(Date.now());
  const treadSlug = 'cinema';
  const path = '/apps/cinema';

  const { data: { shows } = {} } = useQuery(SHOWS, {
    skip: !currentShopping,
    variables: {
      limit: 20,
      skip: 0,
      shoppingId: currentShopping.id,
      typeSlug: 'filme',
      date: dateToQuery,
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <>
      {shows && shows.length > 0 && (
        <TreadSlider
          color={'#4A4A4A'}
          icon={<MovieLogo className={styles.icon} />}
          path={path}
          title={'Em cartaz no Cinema'}
          wordEmphasis={'last'}
          slug={treadSlug}
        >
          <Slider autoScrollbarHeight className={styles.slider}>
            {shows.map((movie, index) => (
              <MovieCard
                key={movie.id}
                movie={movie}
                index={index}
                basepath={path}
                date={dateToQuery.toString()}
                tracking={() => clickedTreadItemTracking(treadSlug, movie.name, index)}
              />
            ))}
          </Slider>
        </TreadSlider>
      )}
    </>
  );
};

export default FeaturedMovies;
