import { useQuery } from '@apollo/react-hooks';

import { APP } from 'services/apollo/queries';
import { useCurrentShopping } from 'hooks';

const useApp = ({ query = APP, slug }) => {
  const currentShopping = useCurrentShopping();
  const { data: { app } = {} } = useQuery(query, {
    variables: { slug, shoppingId: currentShopping.id },
  });

  return app;
};

export default useApp;
