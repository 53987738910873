import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Flex from 'components/flex';
import Typography from 'components/typography';
import Modal from 'components/modal';
import { getVariant } from 'utils';
import { useUserPlan } from 'hooks';

import { colors } from 'theme';
import ChevronRight from 'icons/chevron-right.svg';
import CloseIcon from 'icons/close.svg';

import filterImage from './images/filter-find-image.png';

import styles from './styles.module.scss';

const TutorialFilterModal = () => {
  const plan = useUserPlan(true);
  const history = useHistory();

  const variant = plan?.name ? getVariant(plan?.name) : 'green';

  const storeTutorialValue = multiStorage.getItem('@multivc:hide-filter-tutorial-value');
  const comesFromMarketingPlace = history?.location?.state?.lastRoute === '/apps/marketplace';

  const showTutorial = !storeTutorialValue && comesFromMarketingPlace;

  const [isOpen, setIsOpen] = useState(showTutorial);

  const textAndArrowColor = {
    green: colors.olive[5],
    silver: colors.gray[5],
    gold: colors.yellow[13],
  };

  const handleClose = () => {
    multiStorage.setItem('@multivc:hide-filter-tutorial-value', true);

    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} className={styles.modal} onClose={handleClose}>
      <Flex direction="column" bgColor={colors.common.white} padding="16px" borderRadius="15px">
        <Flex justify="flex-end">
          <div onClick={handleClose}>
            <CloseIcon width="12px" height="12px" fill={colors.gray[5]} />
          </div>
        </Flex>
        <Flex direction="column" gap="8px" mb="16px">
          <img src={filterImage} />
          <Typography align="center" color={colors.gray[7]}>
            <strong>
              Filtre por lojas, <br /> categorias e mais
            </strong>
          </Typography>
          <Typography align="center">
            Use os filtros disponíveis <br /> para encontrar benefícios
          </Typography>
        </Flex>

        <button className={styles.modalButton} onClick={handleClose}>
          <Typography color={textAndArrowColor[variant]}>Entendi</Typography>
          <ChevronRight width="5px" height="9px" fill={textAndArrowColor[variant]} />
        </button>
      </Flex>
    </Modal>
  );
};

export { TutorialFilterModal };
