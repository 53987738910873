import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import { useUserPlan } from 'hooks';
import Modal from 'components/modal';
import modalityImage from '../../images/modality-image.webp';
import CheckImage from '../../images/check-image.svg';
import CloseImage from '../../images/close-image.svg';
import CheckIcon from '../../images/new-check.svg';

import { useSessionToken } from 'hooks';
import { bugsnag } from 'utils';

import styles from './index.module.scss';

const FirstStep = ({ handleClick }) => {
  const [bubblesSelecteds, setBubblesSelecteds] = useState([]);

  const bubbleTexts = [
    'Comparativo entre as modalidades',
    'Informações sobre o programa',
    'Beneficios',
    'Outros',
    'Nada específico',
  ];

  const handleSelectBubble = (bubble) => {
    const activityValue = bubblesSelecteds.find((double) => double === bubble);

    if (activityValue) {
      setBubblesSelecteds(bubblesSelecteds.filter((bubbleSelected) => bubbleSelected !== bubble));
    } else {
      setBubblesSelecteds((old) => [...old, bubble]);
    }
  };

  const activeButton = (bubble) => {
    const activityValue = bubblesSelecteds.find((double) => double === bubble);

    if (activityValue) {
      return true;
    } else {
      return false;
    }
  };

  const handleClickSend = () => {
    multiStorage.setItem('multivc-research-modal-step', 2);

    try {
      window.track({
        mixpanel: {
          name: 'Answered MVC Survey Modal',
          properties: {
            'Question Name': 'O que você procurava ao acessar a área de modalidades?',
            'Survey Name': 'Viewed_Tiers_Table_Aug_24',
            Answers: bubblesSelecteds,
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
    handleClick();
  };

  useEffect(() => {
    try {
      window.track({
        mixpanel: {
          name: 'Displayed MVC Survey Modal',
          properties: {
            Step: 1,
            'Survey Name': 'Viewed_Tiers_Table_Aug_24',
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
  }, []);

  return (
    <>
      <img src={modalityImage} className={styles.image} />

      <h3 className={styles.title}>O que você procurava ao acessar a área de modalidades?</h3>
      <div className={styles.bubblesContainer}>
        {bubbleTexts.map((bubble) => (
          <button
            key={bubble}
            className={styles.bubble}
            onClick={() => handleSelectBubble(bubble)}
            data-active={activeButton(bubble)}
          >
            {bubble}
          </button>
        ))}
      </div>
      <button className={styles.sendButton} disabled={bubblesSelecteds.length === 0} onClick={handleClickSend}>
        ENVIAR
      </button>
    </>
  );
};

const SecondStep = ({ handleClick }) => {
  const handleClickYes = () => {
    multiStorage.setItem('multivc-research-modal-step', 3);

    try {
      window.track({
        mixpanel: {
          name: 'Answered MVC Survey Modal',
          properties: {
            'Question Name': 'Conseguiu encontrar o que procurava?',
            'Survey Name': 'Viewed_Tiers_Table_Aug_24',
            Answers: 'Sim',
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }

    handleClick();
  };

  const handleClickNo = () => {
    multiStorage.setItem('multivc-research-modal-step', 3);

    try {
      window.track({
        mixpanel: {
          name: 'Answered MVC Survey Modal',
          properties: {
            'Question Name': 'Conseguiu encontrar o que procurava?',
            'Survey Name': 'Viewed_Tiers_Table_Aug_24',
            Answers: 'Não',
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
    handleClick();
  };

  useEffect(() => {
    try {
      window.track({
        mixpanel: {
          name: 'Displayed MVC Survey Modal',
          properties: {
            Step: 2,
            'Survey Name': 'Viewed_Tiers_Table_Aug_24',
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
  }, []);

  return (
    <>
      <img src={modalityImage} className={styles.image} />

      <h3 className={styles.title}>
        Conseguiu encontrar <br /> o que procurava?
      </h3>
      <div className={styles.chooseButtonsContainer}>
        <div className={styles.chooseButtonContent}>
          <button className={styles.chooseButton} onClick={handleClickNo}>
            <CloseImage />
          </button>
          Não
        </div>
        <div className={styles.chooseButtonContent}>
          <button className={styles.chooseButton} onClick={handleClickYes}>
            <CheckImage />
          </button>
          Sim
        </div>
      </div>
    </>
  );
};

const ThirdStep = ({ handleClick }) => {
  const [input, setInput] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const handleClickSend = () => {
    multiStorage.setItem('multivc-research-modal-step', 4);

    try {
      window.track({
        mixpanel: {
          name: 'Commented on MVC Survey Modal',
          properties: {
            Comment: input,
            'Survey Name': 'Viewed_Tiers_Table_Aug_24',
            'Willing To Get In Touch': isChecked,
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }

    handleClick();
  };

  useEffect(() => {
    try {
      window.track({
        mixpanel: {
          name: 'Displayed MVC Survey Modal',
          properties: {
            Step: 3,
            'Survey Name': 'Viewed_Tiers_Table_Aug_24',
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
  }, []);

  return (
    <>
      <h3 className={styles.title}>
        Obrigado pela <br /> sua resposta!
      </h3>
      <span className={styles.subTitle}>Deixe um comentário pra gente!</span>

      <div className={styles.inputContainer}>
        <textarea
          name="comment"
          id="comment"
          cols="20"
          rows="4"
          placeholder="Conte-nos um pouco mais sobre sua avaliação"
          className={styles.input}
          onChange={(event) => setInput(event.target.value)}
        />

        <div className={styles.checkboxContainer}>
          <div className={styles.checkbox} onClick={() => setIsChecked(!isChecked)} data-checked={isChecked}>
            {isChecked && <CheckIcon className={styles.checkIcon} />}
          </div>
          <p className={styles.checkboxText}>Autorizo a Multiplan a entrar em contato para saber mais detalhes</p>
        </div>

        <button className={styles.sendButton} disabled={input.length === 0} onClick={handleClickSend}>
          ENVIAR
        </button>
      </div>
    </>
  );
};

export const ResearchModalityModal = () => {
  const { sessionToken } = useSessionToken();
  const plan = useUserPlan();
  const variant = plan.variant;

  const [step, setStep] = useState(1);
  const [modalityModalIsOpen, setModalityModalIsOpen] = useState(false);

  const lastRoute = sessionStorage.getItem('lastRoute');

  const getResearchModalityModalValue = () => {
    const lastRouteObj = lastRoute ? JSON.parse(lastRoute) : null;
    if (!lastRouteObj) {
      return;
    }
    const lastRouteValue = lastRouteObj?.route;
    const isModalityLastRoute = lastRouteValue.split('/').filter((item) => item === 'abas').length === 1;

    if (isModalityLastRoute) {
      const sessionTokenValue = sessionToken;

      const researchModalTokenValue = multiStorage.getItem('multivc-research-modal-token');
      const researchModalValueStep = Number(multiStorage.getItem('multivc-research-modal-step'));

      const hasSessionTokenValid = sessionTokenValue === researchModalTokenValue;

      if (!researchModalValueStep && !hasSessionTokenValid) {
        setModalityModalIsOpen(true);

        multiStorage.setItem('multivc-research-modal-token', sessionTokenValue);
      }
    }
  };

  const onClose = () => {
    setModalityModalIsOpen(false);
  };

  useEffect(() => {
    getResearchModalityModalValue();
  }, []);

  return (
    <Modal isOpen={modalityModalIsOpen} onClose={onClose} className={classNames(styles.modal, styles[variant])} slide>
      <div className={styles.modalContainer}>
        {step === 1 && <FirstStep handleClick={() => setStep(2)} />}
        {step === 2 && <SecondStep handleClick={() => setStep(3)} />}
        {step === 3 && <ThirdStep handleClick={onClose} />}
      </div>
    </Modal>
  );
};

ResearchModalityModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

FirstStep.propTypes = {
  handleClick: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
};

SecondStep.propTypes = {
  handleClick: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
};

ThirdStep.propTypes = {
  handleClick: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
};
