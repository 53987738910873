import React from 'react';
import FancyLoadingComponent from 'components/fancyLoadingComponent';
import WordTicker from 'components/wordTicker';
import LprLoadingAnimation from '../../../../../../lottie/animations/lpr-loading-animation.json';
import { colors } from 'theme';
import { object } from 'prop-types';

const ParkingLoadingScreen = ({ style: styleProp }) => {
  return (
    <FancyLoadingComponent
      animation={LprLoadingAnimation}
      animationHeight={'144px'}
      animationWidth={'167px'}
      style={{
        position: 'absolute',
        backgroundColor: colors.common.white,
        zIndex: 1000000,
        height: '100%',
        ...styleProp,
      }}
    >
      <WordTicker>
        <WordTicker.Item>
          <WordTicker.Title>Carregando...</WordTicker.Title>
        </WordTicker.Item>
      </WordTicker>
    </FancyLoadingComponent>
  );
};
ParkingLoadingScreen.propTypes = { style: object };
ParkingLoadingScreen.defaultProps = { style: {} };

export default ParkingLoadingScreen;
