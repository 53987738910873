import React, { useState, useEffect } from 'react';
import { isAndroid, isIOS, isChrome, isSafari } from 'react-device-detect';

import Button from 'components/button';
import SlideModal from 'components/slideModal';
import Scroller from 'components/scroller';
import { checkStoreVersion, bugsnag } from 'utils';

import styles from './index.module.scss';
import UpdateIcon from 'images/utils/update-icon.svg';
//import UpdateParkingIcon from 'images/utils/update-parking-icon.svg';

const devMode = process.env.NODE_ENV !== 'production';

const AppUpdateModal = () => {
  const [showModal, toggleModal] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const isApp = !devMode && !!((isIOS && !isSafari) || (isAndroid && !isChrome));

  useEffect(() => {
    checkAppVersion();
  }, []);

  useEffect(() => {
    if (showModal) {
      try {
        window.track({
          mixpanel: {
            name: 'Show App Update Modal',
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }
    }
  }, [showModal]);

  const checkAppVersion = () => {
    // using "then" as iOS/Swift calls to JS functions cannot be async/await
    window.onReceivedAppInfo = (payload) => {
      multiStorage.setItem('@multi:device-version-data', payload);
      checkStoreVersion(JSON.parse(payload)).then(({ showModal, force }) => {
        toggleModal(showModal);
        setForceUpdate(force);
      });
    };

    if (window.webkit?.messageHandlers?.getAppInfo) {
      window.webkit?.messageHandlers?.getAppInfo.postMessage({});
    } else if (window?.MultiShoppingAndroid?.getAppInfo) {
      window?.MultiShoppingAndroid?.getAppInfo();
    } else {
      if (isApp) {
        toggleModal(true);
        setForceUpdate(true);
      }
    }
  };

  const handleUpdateClick = () => {
    try {
      window.track({
        mixpanel: {
          name: 'Click to Update App',
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }

    const url = isIOS
      ? 'https://apps.apple.com/us/app/multi-multiplan/id1459566420?l=pt&ls=1'
      : 'https://play.google.com/store/apps/details?id=br.com.multiplan.mind.multishopping&hl=pt_BR';

    window.open(url);
  };

  const handleClose = () => {
    try {
      window.track({
        mixpanel: {
          name: 'Click to Close App Update Modal',
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }

    if (!forceUpdate) toggleModal(false);
  };

  return (
    <>
      {showModal && (
        <SlideModal isOpen={showModal} onClose={handleClose}>
          <Scroller className={styles.scroller}>
            <div className={styles.contentWrapper}>
              <UpdateIcon />
              <p className={styles.title}>
                Temos uma <strong>atualização</strong>{' '}
                <strong>
                  <span>necessária</span>
                </strong>
              </p>
              <p className={styles.text}>
                <strong>Atualize</strong> a versão do seu aplicativo para aproveitar as últimas{' '}
                <strong>novidades do Multi</strong>
              </p>
            </div>
            <Button className={styles.button} type="success" onClick={handleUpdateClick}>
              Atualizar
            </Button>
            <br />
            {!forceUpdate && (
              <Button className={styles.button} onClick={handleClose}>
                Mais tarde
              </Button>
            )}
          </Scroller>
        </SlideModal>
      )}
    </>
  );
};

export default AppUpdateModal;
