import React from 'react';
import { node, oneOf, shape, string } from 'prop-types';
import Typography from 'components/typography';
import Flex from 'components/flex';

const PromotionFlag = ({ bgColor, color, children, borderColors }) => {
  const { left, right } = borderColors || {};

  return (
    <Flex
      style={{
        width: 'max-content',
        borderLeft: left ? `6px solid ${left}` : 'none',
        borderRight: right ? `6px solid ${right}` : 'none',
      }}
      bgColor={bgColor}
      borderRadius="3px"
      padding="3px 8px"
    >
      <Typography color={color} variant="caption">
        {children}
      </Typography>
    </Flex>
  );
};

PromotionFlag.propTypes = {
  bgColor: string.isRequired,
  color: string.isRequired,
  children: node.isRequired,
  borderColors: shape({
    left: string,
    right: string,
  }),
};

PromotionFlag.defaultProps = {
  borderColors: null,
};

const BorderPromotionFlag = ({ bgColor, color, children, direction, borderColors }) => {
  const { left, right } = borderColors || {};

  const styles = {
    width: '164px',
    height: '20px',
    transform: 'none',
    margin: '0 -60px',
    borderLeft: left ? `6px solid ${left}` : 'none',
    borderRight: right ? `6px solid ${right}` : 'none',
  };
  const stylesVertical = {
    width: '140px',
    height: '20px',
    transform: 'rotate(-90deg)',
    margin: '0 -60px',
    borderLeft: left ? `6px solid ${left}` : 'none',
    borderRight: right ? `6px solid ${right}` : 'none',
  };

  return (
    <div>
      <Flex
        style={direction === 'vertical' ? stylesVertical : styles}
        borderRadius="8px 8px 0 0"
        bgColor={bgColor}
        direction="column"
      >
        <Typography className={styles.borderPromotionText} direction={direction} color={color} variant="caption">
          {children}
        </Typography>
      </Flex>
    </div>
  );
};

BorderPromotionFlag.propTypes = {
  bgColor: string.isRequired,
  color: string.isRequired,
  children: node.isRequired,
  direction: oneOf(['vertical', 'horizontal']).isRequired,
  borderColors: shape({
    left: string,
    right: string,
  }),
};

BorderPromotionFlag.defaultProps = {
  borderColors: null,
};

export { PromotionFlag, BorderPromotionFlag };
