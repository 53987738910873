import React, { Fragment, useState } from 'react';
import { string, number, bool, arrayOf, func, object } from 'prop-types';

import { useQuery } from '@apollo/react-hooks';
import SuperCard from 'components/superCard';
import OfferSlider from 'components/offerSlider';
import ProductCard from 'components/productCard';
import Button from 'components/button';
import ProductShowcaseFooter from './components/productShowcaseFooter';

import { useHistory } from 'react-router-dom';
import { classNames, bugsnag } from 'utils';
import { useCurrentShopping } from 'hooks';
import { LLV_SHOWCASE } from './graphql/queries';

import styles from './index.module.scss';
import { clickLlvSeeAllTracking, clickLlvFooterTracking } from '../../screens/advantage/tracking';
import { clickedTreadItemTracking } from '../../screens/showcase/tracking';

const ProductShowcase = ({ path, position, className, items, productsCount, from }) => {
  const history = useHistory();
  const currentShopping = useCurrentShopping();
  const treadSlug = 'lapis-vermelho';

  const handleClick = (product, productPosition) => {
    if (from === 'llvTread') clickedTreadItemTracking(treadSlug, product.name, productPosition, product.store?.name);
    try {
      window.track({
        mixpanel: {
          name: 'Clicked Product Showcase',
          properties: {
            'Product Position': productPosition,
            'Product Name': product.name,
            'Product Sku': product?.sku,
            'Product Slug': product.slug,
            'Product Price': product.price,
            'Product Previous Price': product.previousPrice,
            'Product Percentage': product.percentage,
            'Product End Date': product.endDate,
            'Store Name': product.store?.name,
            'Showcase Path': path,
            'Showcase Position': position,
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }

    history.push(`/apps/lapis-vermelho/${product?.slug}`);
  };

  const handleClickAllLLV = (from) => {
    if (from === 'Anchor') clickLlvSeeAllTracking(currentShopping);
    if (from === 'footer') clickLlvFooterTracking(currentShopping);
    history.push('/apps/lapis-vermelho');
  };

  return (
    <div className={classNames(styles.root, className)}>
      {from !== 'llvTread' && (
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <strong className={classNames(styles.title, styles.llvTitle)}>Ofertas imperdíveis</strong>
          </div>

          <Button className={styles.buttonAllLLV} onClick={() => handleClickAllLLV('Anchor')}>
            {`Ver tudo (${productsCount})`}
          </Button>
        </div>
      )}
      {from === 'llvTread' ? (
        <>
          {!!items[0].products.length && (
            <OfferSlider variant={'llv'} title={items[0].name} className={styles.slider} itemsToShow={1.9} from={from}>
              {items[0].products.map((item, productPosition) => (
                <ProductCard
                  key={productPosition}
                  onClick={() => {
                    handleClick(item, productPosition);
                  }}
                  cover={item.images[0]}
                  name={item.name}
                  price={item.price}
                  sku={item?.sku}
                  storeName={item.store.name}
                  percentage={item?.percentage}
                  previousPrice={item?.previousPrice ? item?.previousPrice : -1}
                  endDate={item?.endDate}
                  className={styles.card}
                />
              ))}
            </OfferSlider>
          )}
        </>
      ) : (
        items.map((item, i) => (
          <>
            {!!item.products.length && (
              <OfferSlider
                variant={'llv'}
                key={i}
                title={item.name}
                className={styles.slider}
                itemsToShow={1.9}
                from={from}
              >
                {item.products.map((item, productPosition) => (
                  <ProductCard
                    key={productPosition}
                    onClick={() => {
                      handleClick(item, productPosition);
                    }}
                    cover={item.images[0]}
                    name={item.name}
                    price={item.price}
                    sku={item?.sku}
                    storeName={item.store.name}
                    percentage={item?.percentage}
                    previousPrice={item?.previousPrice ? item?.previousPrice : -1}
                    endDate={item?.endDate}
                    className={styles.card}
                  />
                ))}
              </OfferSlider>
            )}
          </>
        ))
      )}
      {from !== 'llvTread' && (
        <div className={styles.footer}>
          <ProductShowcaseFooter productsCount={productsCount} onClick={() => handleClickAllLLV('footer')} />
        </div>
      )}
    </div>
  );
};

ProductShowcase.propTypes = {
  path: string.isRequired,
  position: number.isRequired,
  className: string,
  items: arrayOf(object).isRequired,
  llv: bool,
  icon: string,
  productsCount: number,
  from: string,
};

ProductShowcase.defaultProps = {
  className: null,
  llv: false,
  icon: null,
  productsCount: null,
  from: 'hub',
};

export { ProductShowcase };

const ProductShowcaseProvider = ({ path, position, withCard, handleHasProducts, ...props }) => {
  const currentShopping = useCurrentShopping();

  const [productShowcases, setProductShowcases] = useState([]);
  const [totalLLVProducts, setTotalLLVProducts] = useState(0);
  const [hasProducts, setHasProducts] = useState(false);

  useQuery(LLV_SHOWCASE, {
    variables: {
      shoppingId: currentShopping.id,
    },
    onCompleted: (data) => {
      setProductShowcases(data.llvShowcase.llvShowcase);
      setTotalLLVProducts(data.llvShowcase.total);
      let foundProducts = false;
      for (const llvRail of data.llvShowcase.llvShowcase) {
        if (llvRail?.products?.length) {
          foundProducts = true;
          break;
        }
      }
      handleHasProducts(foundProducts);
      setHasProducts(foundProducts);
    },
    onError: () => handleHasProducts(false),
  });

  const ParentComponent = withCard ? SuperCard : Fragment;

  return (
    hasProducts && (
      <ParentComponent>
        <ProductShowcase
          path={path}
          position={position}
          items={productShowcases}
          productsCount={totalLLVProducts}
          {...props}
        />
      </ParentComponent>
    )
  );
};

ProductShowcaseProvider.propTypes = {
  path: string.isRequired,
  position: number.isRequired,
  withCard: bool,
  hasProducts: func,
  handleHasProducts: func,
};

ProductShowcaseProvider.defaultProps = {
  withCard: true,
  hasProducts: () => {},
  handleHasProducts: () => {},
};

export default ProductShowcaseProvider;
