import React, { useState, useMemo } from 'react';
import { any, bool, func, object, string, shape } from 'prop-types';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import { classNames, checkUserData } from 'utils';
import { useQuery } from '@apollo/react-hooks';
import { CHECK_USER_REGISTER } from 'services/apollo/queries';

import BottomTab from './components/bottomTab';
import CloseButton from './components/closeButton';
import CartSummary from './components/cartSummary';
import AnnouncementModal from 'screens/showcase/components/announcementModal';
import PrePrivacyPolicy from '../../screens/prePrivacyPolicy';
import UpdateRegistrationModal from 'components/updateRegistrationModal';
import FancyLoadingComponent from 'components/fancyScreenLoading';

import { useUser } from 'hooks';

import styles from './index.module.scss';

const Screen = ({
  elRef,
  loading: externalLoading,
  onClose,
  history,
  children,
  className,
  hasBottomMenu,
  hasCloseButton,
  cartSummary,
  location: { state },
  style,
  ticketPage,
  hasSafeArea,
}) => {
  const user = useUser();
  const historyFromHook = useHistory();
  const { pathname } = useLocation();
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showUpdateRegistration, setShowUpdateRegistration] = useState(false);
  const pathToSkip = [
    '/',
    '/home',
    '/shoppings',
    '/signup/identity/confirmation',
    '/signup/update-registration',
    '/terms',
  ];

  const Navbar = Array.isArray(children) && children.find((child) => child?.type?.WrappedComponent?.name === 'Navbar');

  const skipUserVerification = useMemo(() => {
    const lastUserUpdate = multiStorage.getItem('lastUserUpdate');
    return pathToSkip.includes(pathname) || !user || (lastUserUpdate && Date.now() - lastUserUpdate < 600000);
  }, []);

  const { loading: checkUserRegisterLoading } = useQuery(CHECK_USER_REGISTER, {
    fetchPolicy: 'network-only',
    skip: skipUserVerification,
    onError: (err) => {
      if (err.graphQLErrors[0]?.message === 'Usuário não encontrado') {
        multiStorage.removeItem('user');
        multiStorage.removeItem('shopping');
        historyFromHook.replace('/');
        window.location.reload();
      }
    },
    onCompleted: (data) => handleCheckUserRegister(data),
  });

  const loading = useMemo(() => {
    return externalLoading || checkUserRegisterLoading || false;
  }, [externalLoading, checkUserRegisterLoading]);

  const handleCheckUserRegister = (data) => {
    const { isUserRegisterValid, isPrivacyPolicyValid } = checkUserData({ ...data.checkUserRegister, pathname });
    if (isUserRegisterValid && isPrivacyPolicyValid) multiStorage.setItem('lastUserUpdate', Date.now());
    if (!isUserRegisterValid) setShowUpdateRegistration(true);
    if (!isPrivacyPolicyValid && isUserRegisterValid) setShowPrivacyPolicy(true);
  };

  return (
    <main
      ref={elRef}
      className={classNames('screen', 'page', className, {
        'page--prev': state?.prev,
        [styles.hasBottomMenu]: hasBottomMenu,
        [styles.hasSafeArea]: hasSafeArea,
      })}
      style={style}
    >
      <AnnouncementModal />
      {(!!Navbar && loading && !ticketPage && (
        <>
          {Navbar}
          <div className={styles.loadingContainer}>
            <FancyLoadingComponent />
          </div>
        </>
      )) ||
        (!!Navbar && loading && ticketPage && (
          <>
            {Navbar}
            <div className={styles.loadingContainer}>
              <FancyLoadingComponent>
                <p>
                  Estamos processando a sua solicitação,
                  <br /> por favor aguarde...
                </p>
              </FancyLoadingComponent>
            </div>
          </>
        )) ||
        (loading && <FancyLoadingComponent fullscreen />)}

      {!loading && children}
      {cartSummary.show && <CartSummary size={cartSummary.size} />}
      {hasBottomMenu && <BottomTab />}
      {hasSafeArea && <div className={styles.safeArea} />}
      {hasCloseButton && <CloseButton history={history} onClick={onClose} data-testid="onclose-button" />}
      {showUpdateRegistration && <UpdateRegistrationModal />}
      {showPrivacyPolicy && <PrePrivacyPolicy isModal={true} />}
    </main>
  );
};
Screen.propTypes = {
  loading: bool,
  onClose: func,
  elRef: object,
  className: string,
  hasBottomMenu: bool,
  hasCloseButton: bool,
  children: any.isRequired,
  history: object.isRequired,
  style: object,
  location: shape({
    state: shape({
      prev: object,
    }),
  }).isRequired,
  cartSummary: bool,
  ticketPage: bool,
  hasSafeArea: bool,
};
Screen.defaultProps = {
  loading: false,
  hasBottomMenu: false,
  style: {},
  hasCloseButton: false,
  className: null,
  onClose: () => {},
  elRef: null,
  cartSummary: {},
  ticketPage: false,
  hasSafeArea: false,
};

export default withRouter(Screen);
