import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'components/modal';
import Button from 'components/button';

import { bugsnag, getCurrentPath } from 'utils';

import UpdateRegistrationIcon from 'icons/update-registration-icon.svg';

import styles from './index.module.scss';

const UpdateRegistrationModal = () => {
  const history = useHistory();

  const trackClickedUpdateRegistration = () => {
    try {
      const data = {
        mixpanel: {
          name: 'Opened Registration Update',
        },
      };
      window.track(data);
    } catch (e) {
      bugsnag.notify(e);
    }
  };

  const handleRedirect = () => {
    trackClickedUpdateRegistration();
    history.push('/signup/update-registration');
  };

  const trackUpdateRegisterModal = () => {
    try {
      const data = {
        mixpanel: {
          name: 'Update Register Modal',
          path: getCurrentPath(),
        },
      };
      window.track(data);
    } catch (e) {
      bugsnag?.notify(e);
    }
  };

  useEffect(() => {
    trackUpdateRegisterModal();
  }, []);

  return (
    <Modal isOpen className={styles.modal} hasOutsideClick={false}>
      <div className={styles.modalRoot}>
        <UpdateRegistrationIcon className={styles.icon} />
        <h1 className={styles.reTryTitle}>Precisamos atualizar seu cadastro</h1>
        <p className={styles.reTryText}>
          Para prosseguir, é necessário que suas informações pessoais estejam completas e atualizadas
        </p>
        <Button className={styles.acceptButton} onClick={() => handleRedirect()}>
          vamos lá
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateRegistrationModal;
