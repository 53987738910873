import React from 'react';
import { useHistory } from 'react-router-dom';
import { node, element, oneOf, string } from 'prop-types';

import { formatTitle } from './helpers';

import { clickedTreadSeeMoreTracking } from '../../screens/showcase/tracking';

import styles from './index.module.scss';

const TreadSlider = ({ children, color, icon, path, title, wordEmphasis, slug }) => {
  const history = useHistory();
  const headerEmphasisClass = styles.headerTitle;
  const headerTextClass = styles.headerText;

  const handleSeeMoreClick = (path, slug) => {
    clickedTreadSeeMoreTracking(slug);
    history.push(path);
  };

  return (
    <div className={styles.slider}>
      <div className={styles.sliderBody}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            {icon}
            <h1>{formatTitle(title, wordEmphasis, headerEmphasisClass, headerTextClass, color)}</h1>
          </div>
          {path && (
            <div className={styles.headerRight} style={{ color: color }} onClick={() => handleSeeMoreClick(path, slug)}>
              Ver mais
            </div>
          )}
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

TreadSlider.propTypes = {
  children: element.isRequired,
  color: string.isRequired,
  icon: node.isRequired,
  path: string,
  title: node.isRequired,
  wordEmphasis: oneOf(['all', 'first', 'last', 'last two', 'none']),
  slug: string.isRequired,
};

TreadSlider.defaultProps = {
  path: '',
  wordEmphasis: 'none',
};

export default TreadSlider;
