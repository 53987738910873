import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { checkVersion, bugsnag } from 'utils';
import Button from 'components/button';

import ImageError from './images/error.svg';
import styles from './index.module.scss';

export const ErrorContainer = ({ message, onClickReload, onClickReturn }) => {
  const onReload = async () => {
    await checkVersion();
    onClickReload();
  };

  return (
    <div className={styles.error}>
      <ImageError className="image-error" />
      <h3>Parece que algo deu errado =/</h3>
      {message && <p>{message}</p>}
      <div className={styles.actions}>
        {onClickReload && (
          <Button type="secondary" onClick={onReload}>
            Recarregar página
          </Button>
        )}
        {onClickReturn && (
          <Button type="primary" onClick={onClickReturn}>
            Voltar para o início
          </Button>
        )}
      </div>
    </div>
  );
};

ErrorContainer.propTypes = {
  history: PropTypes.object.isRequired,
  onClickReturn: PropTypes.func,
  message: PropTypes.string,
  onClickReload: PropTypes.func,
};

ErrorContainer.defaultProps = {
  code: 0,
  message: '',
  onClickReload: null,
  onClickReturn: null,
};

const ErrorContainerProvider = ({ history, code, message, onClickReload }) => {
  useEffect(() => {
    try {
      window.track({
        mixpanel: {
          name: 'Error Page',
          properties: {
            'Page Path': history?.location?.pathname,
            'Error Code': code,
            'Error Message': message,
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
  }, []);

  const onClickReturn = () => history.push('/showcase');

  return <ErrorContainer message={message} onClickReload={onClickReload} onClickReturn={onClickReturn} />;
};

ErrorContainerProvider.propTypes = {
  history: PropTypes.object.isRequired,
  code: PropTypes.number,
  message: PropTypes.string,
  onClickReload: PropTypes.func,
};

ErrorContainerProvider.defaultProps = {
  code: 0,
  message: '',
  onClickReload: null,
};

export default withRouter(ErrorContainerProvider);
