import propTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';

import { classNames } from 'utils';

const BottomCard = ({ children, className }) => {
  return <div className={classNames(styles.footer, className)}>{children}</div>;
};

BottomCard.propTypes = {
  children: propTypes.node.isRequired,
  className: propTypes.string,
};

BottomCard.defaultProps = {
  className: null,
};
export default BottomCard;
