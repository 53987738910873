export const getFilterURLParams = (value) => {
  const params = new URLSearchParams(value);

  const categories = params.get('categories');
  const stores = params.get('stores');
  const initialValueParam = params.get('initialValue') ? params.get('initialValue') : 0;
  const lastValueParam = params.get('lastValue') ? params.get('lastValue') : 0;
  const isWithoutPointParam = params.get('isWithoutPoint');
  const recentParam = !!params.get('recent');
  const redeemParam = !!params.get('redeem');
  const scrollToFilter = !!params.get('scrollToFilter');

  const isWithoutPoint = isWithoutPointParam === 'true' ? true : false;
  const initialValue = initialValueParam === undefined || initialValueParam === null ? 0 : initialValueParam;
  const lastValue = lastValueParam === undefined || lastValueParam === null ? 0 : lastValueParam;

  const pointsFilterParam = { initialValue, lastValue, isWithoutPoint };
  const categoriesParam = categories ? categories.split(',') : [];
  const storesParam = stores ? stores.split(',') : [];

  return { categoriesParam, storesParam, pointsFilterParam, recentParam, redeemParam, scrollToFilter };
};
