import { bugsnag, maskUserName, maskUserCpf } from 'utils';

const trackingOnSubmit = (userData, signUpDelay) => {
  try {
    window.alias({
      mixpanel: {
        id: userData.id,
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }

  try {
    window.identify({
      mixpanel: {
        id: userData.id,
      },
      salesForce: {
        profileId: userData.multiplanId,
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }

  try {
    window.peopleSet({
      mixpanel: {
        properties: {
          ID: userData.id,
          CPF: maskUserCpf(userData.cpf),
          $name: maskUserName(userData.name),
          $email: userData.email,
          $phone: userData.phoneNumber,
          'DC Terms': userData.dcTerm,
          'Is Beta': userData.isBeta,
        },
      },
      salesForce: {
        properties: {
          FirstName: maskUserName(userData.name),
          Email: userData.email,
          Phone: userData.phoneNumber,
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }

  try {
    window.track({
      firebase: {
        name: 'complete_registration',
      },
      mixpanel: {
        name: 'Signed Up',
        properties: {
          'Sign Up Delay': signUpDelay,
        },
      },
      googleTagManager: {
        global: {
          name: 'signedUp',
          properties: {
            userId: userData.id,
            userCpf: maskUserCpf(userData.cpf),
            userName: maskUserName(userData.name),
            userEmail: userData.email,
            userPhone: userData.phoneNumber,
            userDcTerms: userData.dcTerm,
            userIsBeta: userData.isBeta,
          },
        },
        globalWithoutMixpanel: {
          name: 'signedUpWithoutMixpanel',
          properties: {
            userId: userData.id,
            userCpf: maskUserCpf(userData.cpf),
            userName: maskUserName(userData.name),
            userEmail: userData.email,
            userPhone: userData.phoneNumber,
            userDcTerms: userData.dcTerm,
            userIsBeta: userData.isBeta,
          },
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

const trackingCreateCode = (provider) => {
  try {
    window.track({
      firebase: {
        name: 'pre_signup',
        properties: {
          provider,
        },
      },
      mixpanel: {
        name: 'Pre Signed Up',
        properties: {
          Provider: provider,
        },
      },
      googleTagManager: {
        global: {
          name: 'preSignedUp',
          properties: {
            provider,
          },
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

const trackingClickChangeNumber = (flow) => {
  try {
    window.track({
      mixpanel: {
        name: 'Click Change Number Button',
        properties: {
          Flow: flow,
        },
      },
      googleTagManager: {
        global: {
          name: 'clickChangeNumberButton',
          properties: {
            flow,
          },
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

export { trackingCreateCode, trackingClickChangeNumber, trackingOnSubmit };
