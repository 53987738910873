import gql from 'graphql-tag';

const STORES_LIST = gql`
  query benefitFilters($shoppingSlug: String, $modalities: [LoyaltyModality]) {
    benefitFilters(shoppingSlug: $shoppingSlug, modalities: $modalities) {
      stores {
        name
        code
      }
    }
  }
`;
export { STORES_LIST };
