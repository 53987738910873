import React, { memo, useState } from 'react';
import { bool, element, func, string } from 'prop-types';

import { classNames, bugsnag } from 'utils';
import { useSafeArea } from 'hooks';

import Modal from 'components/modal';

import CloseIcon from 'icons/close.svg';
import ChevronIcon from 'icons/chevron-right.svg';
import TailSvg from 'icons/tip-tail.svg';
import jumpToGold from 'images/fidelity/jump-to-gold.webp';

import phoneSvg from './images/phone-image.webp';
import statusImage from './images/status-image.webp';
import statementImage from './images/statement-image.webp';
import NotesFocusSvg from './images/notes-focus.svg';
import styles from './styles.module.scss';

const TutorialModalComponent = ({ isOpen, handleClose, variant, summaryCard }) => {
  const [tutorialPosition, setTutorialPosition] = useState(1);
  const safeArea = useSafeArea();

  const handleNext = () => {
    try {
      window.track({
        mixpanel: {
          name: `TutorialMVC Next${tutorialPosition} Button`,
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
    setTutorialPosition((oldValue) => oldValue + 1);
  };

  const getTutorialPositionText = () => {
    if (tutorialPosition === 1) {
      return 'first';
    } else if (tutorialPosition === 2) {
      return 'second';
    } else if (tutorialPosition === 3) {
      return 'third';
    } else {
      return 'fourth';
    }
  };

  const handleCloseTutorial = () => {
    try {
      window.track({
        mixpanel: {
          name: 'TutorialMVC Close Button',
          properties: {
            Page: getTutorialPositionText(),
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
    handleClose();
  };

  const handleEndTutorial = () => {
    try {
      window.track({
        mixpanel: {
          name: 'TutorialMVC End Button',
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
    handleClose();
  };

  const STEPS = [
    {
      id: 1,
      className: styles.firstStepContainer,
      image: phoneSvg,
      title: 'Enviar suas notas <br /> ficou mais prático',
      text: 'Mande suas notas por aqui <br /> ou diretamente da tela <br /> inicial do Multi!',
      tailClass: styles.tutorialTail,
      isTailTop: false,
    },
    {
      id: 2,
      className: styles.secondStepContainer,
      contentClass: styles.secondContent,
      image: statusImage,
      title: 'De olho no status',
      text: 'Acompanhe em detalhes o <br /> processo de análise das <br /> notas enviadas.',
      tailClass: styles.tailRight,
      isTailTop: false,
    },
    {
      id: 3,
      className: styles.thirdStepContainer,
      contentClass: styles.thirdContent,
      image: statementImage,
      title: 'Confira seu extrato',
      text: 'Veja suas movimentações <br /> no programa e os próximos <br /> pontos a expirar.',
      tailClass: styles.tailLeft,
      isTailTop: false,
    },
    {
      id: 4,
      className: styles.fourthStepContainer,
      contentClass: styles.fourthContent,
      image: jumpToGold,
      title: 'Acompanhe a jornada',
      text: 'Confira de perto seu <br /> progresso e fique por dentro <br /> dos detalhes do MultiVocê.',
      tailClass: styles.tailTop,
      isTailTop: true,
    },
  ];

  const renderStep = ({ id, className, contentClass, image, title, text, tailClass, isTailTop }) => (
    <div data-show={tutorialPosition === id} className={classNames(className, { [styles.safeArea]: safeArea })}>
      {id === 4 && <div className={styles.tutorialButtonOutline}>{summaryCard}</div>}
      {isTailTop && (
        <div className={tailClass}>
          <TailSvg />
        </div>
      )}
      <div className={classNames(styles.stepsContainer, contentClass)}>
        <div className={styles.closeButtonContainer}>
          <div onClick={handleCloseTutorial} data-testid={`tutorialCloseButton-${id}`}>
            <CloseIcon className={styles.closeIcon} />
          </div>
        </div>
        <img src={image} className={styles.image} />
        <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
        <span className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
        <div className={styles.footer}>
          <span className={styles.text}>{`${id}/${STEPS.length}`}</span>
          <button className={styles.button} onClick={id < STEPS.length ? handleNext : handleEndTutorial}>
            {id < STEPS.length ? 'Próximo' : 'Concluir'} <ChevronIcon className={styles.chevronIcon} />
          </button>
        </div>
      </div>
      {!isTailTop && (
        <div className={tailClass}>
          <TailSvg />
        </div>
      )}
      {id === 1 && <NotesFocusSvg className={styles.noteFocusIcon} />}
      {id === 2 && (
        <div className={styles.buttonsOutlineContainer}>
          <div className={styles.emptyButton} />
          <button className={styles.buttonOutline}>Notas enviadas</button>
        </div>
      )}
      {id === 3 && (
        <div className={styles.buttonsOutlineContainer}>
          <button className={styles.buttonOutline}>Ver extrato</button>
          <div className={styles.emptyButton} />
        </div>
      )}
    </div>
  );

  return (
    <Modal isOpen={isOpen} className={classNames(styles.modal, styles[variant])} onClose={handleClose}>
      {STEPS.map(renderStep)}
    </Modal>
  );
};

TutorialModalComponent.propTypes = {
  isOpen: bool.isRequired,
  handleClose: func.isRequired,
  variant: string.isRequired,
  summaryCard: element.isRequired,
};

const TutorialModal = memo(TutorialModalComponent, (prevProps, nextProps) => {
  return Object.is(prevProps.isOpen, nextProps.isOpen);
});

export { TutorialModal };
