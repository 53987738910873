import { useSessionToken } from 'hooks';
import { hasDifferenceDays } from 'utils';

const useNPS = () => {
  const { sessionToken } = useSessionToken();

  const thresholdDays = 90;

  const npsAction = () => {
    const npsObj = JSON.parse(multiStorage.getItem('nps'));
    const today = new Date();

    if (!npsObj || hasDifferenceDays(npsObj.date, today, thresholdDays)) {
      const nps = {
        date: today,
        eligible: true,
        sessionToken,
      };
      multiStorage.setItem('nps', JSON.stringify(nps));
    }
  };

  const npsCheck = () => {
    const npsObj = JSON.parse(multiStorage.getItem('nps'));
    if (!npsObj) return false;
    const val = npsObj.eligible && npsObj.sessionToken !== sessionToken;
    return val;
  };

  const npsSend = () => {
    const today = new Date();

    const nps = {
      date: today,
      eligible: false,
      sessionToken,
    };

    multiStorage.setItem('nps', JSON.stringify(nps));
  };

  return { npsAction, npsCheck, npsSend };
};

export default useNPS;
