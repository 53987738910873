const textTruncate = (str, length, ending) => {
  const thisLength = length || 100;
  const thisEnding = ending || '...';

  if (str.length > thisLength) {
    return str.substring(0, thisLength - thisEnding.length) + thisEnding;
  }
  return str;
};

export default textTruncate;
