const inAppReviewDateKey = 'inAppReviewDate';

const updateInAppReviewDate = (date) => multiStorage.setItem(inAppReviewDateKey, date.toString());

const shouldOpenInAppReview = (minIntervalDays) => {
  const inAppReviewDateString = multiStorage.getItem(inAppReviewDateKey);
  if (!inAppReviewDateString) return true;
  const inAppReviewDate = new Date(inAppReviewDateString);
  const today = new Date();
  const diffTime = Math.abs(today - inAppReviewDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays <= minIntervalDays;
};

const hasInAppReview = () =>
  !!(window?.MultiShoppingAndroid?.openInAppReview || window?.webkit?.messageHandlers?.openInAppReview?.postMessage);

const openInAppReview = (force = false, minIntervalDays = 90) => {
  if (!hasInAppReview() || (!force && !shouldOpenInAppReview(minIntervalDays))) return;

  window.onReceivedInAppReview = (isSuccessful) => {
    if (isSuccessful) updateInAppReviewDate(new Date());
  };

  window?.MultiShoppingAndroid?.openInAppReview?.() ||
    window?.webkit?.messageHandlers?.openInAppReview?.postMessage?.({});
};

export default openInAppReview;
