import { lazy } from 'react';

const Reservation = lazy(() => import(/* webpackChunkName: "gastronomy" */ 'screens/apps/reservation'));

export default [
  {
    path: '/reserva-em-restaurantes',
    exact: true,
    component: Reservation,
  },
];
