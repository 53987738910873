const getShoppingBusinessHours = ({ shopping, typename, splited = false }) => {
  const today = new Date();
  const formattedTypename = typename.toLowerCase();
  const shoppingBusinessHours = shopping.businessHours[formattedTypename][today.getDay()];

  if (splited) return { start: shoppingBusinessHours.start, end: shoppingBusinessHours.end };

  const startArray = shoppingBusinessHours.start.toString().split('.');
  const start =
    startArray.length < 2
      ? shoppingBusinessHours.start
      : startArray[0] + ':' + `${60 / (10 / parseInt(startArray[1]))}`;

  const endArray = shoppingBusinessHours.end.toString().split('.');
  const end =
    endArray.length < 2 ? shoppingBusinessHours.end : endArray[0] + ':' + `${60 / (10 / parseInt(endArray[1]))}`;

  return `${start}h - ${end}h`;
};

export default getShoppingBusinessHours;
