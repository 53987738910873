import { lazy } from 'react';

const Home = lazy(() => import(/* webpackChunkName: "llv" */ 'screens/apps/llv/home'));
const Offer = lazy(() => import(/* webpackChunkName: "llv" */ 'screens/apps/llv/offer'));
const Search = lazy(() => import(/* webpackChunkName: "llv" */ 'screens/apps/llv/search'));
const Filter = lazy(() => import(/* webpackChunkName: "llv" */ 'screens/apps/llv/search/filter'));

export default [
  {
    path: '/apps/lapis-vermelho',
    exact: true,
    component: Home,
  },
  {
    path: '/apps/lapis-vermelho/busca',
    exact: true,
    component: Search,
  },
  {
    path: '/apps/lapis-vermelho/filtro',
    exact: true,
    component: Filter,
  },
  {
    path: '/apps/lapis-vermelho/:slug',
    exact: true,
    component: Offer,
  },
];
