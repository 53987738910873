import gql from 'graphql-tag';

const BENEFITS_CAROUSEL = gql`
  query benefits($shoppingSlug: String!, $modalities: [LoyaltyModality], $limit: Int, $featured: Boolean) {
    benefits(shoppingSlug: $shoppingSlug, modalities: $modalities, limit: $limit, featured: $featured) {
      id
      title
      description
      image {
        url
        type
      }
      store {
        name
        logoColor {
          original
        }
      }
      points
      isFavorite
      startDate
      endDate
      daysLeftToRedeem
      parkingDiscountId
      categories
      type
      salePointsFrom
      points
      endDate
    }
  }
`;

export { BENEFITS_CAROUSEL };
