import { defaultDataIdFromObject } from 'apollo-cache-inmemory';

export default (object) => {
  const getStoreDataId = (store) =>
    store?.location?.token ? `${store.id}:${store.location.token}` : defaultDataIdFromObject(store);

  const getUserVip = (user) =>
    typeof user?.isVip !== 'undefined' ? `${user.id}:${user.isVip.toString()}` : defaultDataIdFromObject(user);

  switch (object.__typename) {
    case 'User':
      return getUserVip(object);
    case 'Store':
      return getStoreDataId(object);
    case 'Restaurant':
      return getStoreDataId(object);
    default:
      return defaultDataIdFromObject(object);
  }
};
