import { lazy } from 'react';

const DelayedRedirect = lazy(() => import(/* webpackChunkName: "movie" */ 'screens/delayedRedirect'));

export default [
  {
    path: '/redirect/:url',
    exact: true,
    component: DelayedRedirect,
  },
  {
    path: '/redirect/:url/:forceBrowser',
    exact: true,
    component: DelayedRedirect,
  },
];
