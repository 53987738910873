import gql from 'graphql-tag';

const BANNERS = gql`
  query banners($shoppingsIds: [ID], $limit: Int, $position: Int!, $path: String, $modalities: [LoyaltyModality]) {
    banners(shoppingsIds: $shoppingsIds, limit: $limit, position: $position, path: $path, modalities: $modalities) {
      id
      name
      link
      position
      showLoginScreen
      cover {
        id
        original
      }
    }
  }
`;

export { BANNERS };
