import { bugsnag } from 'utils';

const clickShoppingServicesTracking = (slug) => {
  try {
    window.track({
      mixpanel: {
        name: 'Showcase mall information',
        properties: {
          Slug: slug,
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

export { clickShoppingServicesTracking };
