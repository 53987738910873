import React, { createContext, useContext, useState, useMemo } from 'react';
import { node } from 'prop-types';

const LicensePlateHistoryContext = createContext();

export const LicensePlateHistoryProvider = ({ children }) => {
  const [licensePlateHistory, setLicensePlateHistory] = useState(() => {
    const storageLicensePlateHistory = multiStorage.getItem('licensePlateHistory');
    const initialLicensePlateArray = storageLicensePlateHistory ? JSON.parse(storageLicensePlateHistory) : [];
    return initialLicensePlateArray;
  });

  const handleLicensePlateHistory = (inputValue) => {
    if (!licensePlateHistory.includes(inputValue)) {
      const newHistory = [...licensePlateHistory, inputValue];
      if (newHistory.length > 3) newHistory.shift();

      setLicensePlateHistory(newHistory);
      multiStorage.setItem('licensePlateHistory', JSON.stringify(newHistory));
    }
  };

  const memoizedLicensePlateHistory = useMemo(() => licensePlateHistory, [licensePlateHistory]);

  return (
    <LicensePlateHistoryContext.Provider
      value={{ licensePlateHistory: memoizedLicensePlateHistory, handleLicensePlateHistory }}
    >
      {children}
    </LicensePlateHistoryContext.Provider>
  );
};

export const useLicensePlateHistory = () => useContext(LicensePlateHistoryContext);

LicensePlateHistoryProvider.propTypes = {
  children: node.isRequired,
};
