import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';

import { bugsnag, openParkingSDK } from 'utils';
import SlideModal from 'components/slideModal';
import Button from 'components/button';
import Exclamation from 'icons/attention-illustration.svg';
import Reload from 'icons/reload.svg';

import styles from './index.module.scss';

const ParkingExceptionModule = () => {
  const history = useHistory();
  const client = useApolloClient();

  useEffect(() => {
    try {
      window.track({
        mixpanel: {
          name: 'Show Parking Exception Modal',
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
  }, []);

  const handleRefresh = async () => {
    try {
      window.track({
        mixpanel: {
          name: 'Reload Parking information',
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }

    const path = await openParkingSDK({ history, client });
    history.push(path);
  };

  const handleCloseModal = () => {
    try {
      window.track({
        mixpanel: {
          name: 'Close Parking Modal',
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }

    history.push('/showcase');
  };

  return (
    <SlideModal isOpen onClose={handleCloseModal}>
      <Exclamation className={styles.icon} />
      <div className={styles.header}>
        <strong>Erro ao verificar descontos disponíveis para estacionamento</strong>
      </div>
      <div className={styles.content}>
        Se você é elegível a algum desconto, por favor <strong> clique em recarregar </strong>
      </div>
      <Button onClick={handleRefresh} size="full" type="primary" className={styles.refreshButton}>
        <Reload />
        RECARREGAR
      </Button>
      <Button onClick={handleCloseModal} size="full" type="primary" className={styles.stayButton}>
        FECHAR
      </Button>
    </SlideModal>
  );
};

export default ParkingExceptionModule;
