import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import Modal from 'components/modal';

import CloseIcon from 'icons/times.svg';

import { useCategoriesFilterModal } from './useCategoriesFilterModal';

import styles from './index.module.scss';

export const CategoriesFilterModal = ({ isOpen, onClose, variant, handleApplyCategoryFilterModal, initialValue }) => {
  const {
    benefitFilters,

    selectedCategories,
    handleSelectCategory,
    activeButton,
    activeApplyButton,
    handleClean,
    handleApply,
  } = useCategoriesFilterModal({
    onClose,
    variant,
    handleApplyCategoryFilterModal,
    initialValue,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={classNames(styles.modal, styles[variant])} slide>
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <div />
          <h2 className={styles.modalTitle}>Categorias</h2>
          <button className={styles.modalCloseButton} onClick={onClose}>
            <CloseIcon className={styles.closeIcon} />
          </button>
        </div>

        <div className={styles.modalContent}>
          <div className={styles.categoriesContainer}>
            {benefitFilters?.categories?.map((category) => (
              <button
                key={category}
                className={styles.categoryButton}
                onClick={() => handleSelectCategory(category)}
                data-active={activeButton(category)}
                data-testid={category}
              >
                {category}
              </button>
            ))}
          </div>

          <div className={styles.buttonsContainer}>
            <button
              className={styles.cleanButton}
              onClick={handleClean}
              disabled={selectedCategories.length === 0}
              data-testid="cleanCategoryFilter"
            >
              Limpar seleção
            </button>
            <button
              className={styles.applyButton}
              disabled={activeApplyButton()}
              onClick={handleApply}
              data-testid="applyCategoryFilter"
            >
              APLICAR
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

CategoriesFilterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
  handleApplyCategoryFilterModal: PropTypes.func.isRequired,
  initialValue: PropTypes.object.isRequired,
};
