import gql from 'graphql-tag';

const LLV_SHOWCASE = gql`
  query llvShowcase($shoppingId: ID!) {
    llvShowcase(shoppingId: $shoppingId) {
      llvShowcase {
        id
        name
        products {
          id
          slug
          name
          images
          price
          previousPrice
          percentage
          endDate
          store {
            ... on Store {
              id
              name
            }
            ... on Restaurant {
              id
              name
            }
          }
        }
      }
      total
    }
  }
`;

export { LLV_SHOWCASE };
