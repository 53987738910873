import React, { useState, useMemo, useCallback } from 'react';

import { useUserPlan, useUser, useFavoriteBenefit } from 'hooks';
import { bugsnag, capitalize } from 'utils';
import { useHistory, useLocation } from 'react-router-dom';
import { statusToast } from 'components/StatusToast';

import formatBenefitType from '../../../utils/formatBenefitType';

import { colors } from 'theme';
import ChevronIcon from 'icons/chevron-right.svg';

import styles from './index.module.scss';

export const useBenefitMvcCard = ({
  benefit,
  userVipBenefitId,
  status,
  variant,
  onClickFavorite,
  endDate,
  focus,
  index,
  redeemDate,
}) => {
  const user = useUser();
  const plan = useUserPlan();
  const history = useHistory();
  const location = useLocation();

  const [openModal, setOpenModal] = useState(false);
  const [openBlockedBenefitModal, setOpenBlockedBenefitModal] = useState(false);

  const toggleFavoriteInList = useFavoriteBenefit();
  const [isFavorite, setIsFavorite] = useState(benefit.isFavorite);

  const isPromotion = benefit.categories?.some((category) => category.toUpperCase() === 'DIA DAS MÃES');

  const isLoggedIn = !!user && !!plan;

  const imageUrl = useMemo(() => {
    let imageUrl = benefit.image?.type?.includes('image') && benefit.image?.url;
    if (benefit.store?.logoColor?.original) imageUrl = benefit.store.logoColor.original;
    return imageUrl;
  }, [benefit]);

  const userVariant = plan?.name ? plan.variant : 'visitant-green';

  const isExpired = status === 'expired';

  const isABlockedBenefit = () => {
    if (userVariant === 'gold') {
      return false;
    }
    if (userVariant === 'silver' && (variant === 'green' || variant === 'silver')) {
      return false;
    }
    if ((userVariant === 'green' && variant === 'green') || (userVariant === 'visitant-green' && variant === 'green')) {
      return false;
    }

    return true;
  };

  const isBlocked = isABlockedBenefit();

  const showPoints = benefit.points > 0 || (benefit.salePointsFrom > 0 && benefit.points === 0);

  const showFavoriteButton = !isBlocked && ['favorite', 'claimed', 'home'].includes(focus);

  const showIsSoldOut = !['ready', 'used'].includes(focus) && benefit.isSoldOut;

  const showLimitDate = (endDate || redeemDate) && !['table', 'benefit-list'].includes(focus);

  const benefitLimitDays = Math.round(new Date(endDate).getTime() / 1000 / 60 / 60 / 24);
  const todayInDays = Math.round(new Date().getTime() / 1000 / 60 / 60 / 24);

  const difference = benefitLimitDays - todayInDays;

  const showAvailableBenefit =
    (benefit.type === 'DISPLAY_ONLY' || !!benefit.parkingDiscountId || difference > 365) &&
    focus !== 'ready' &&
    focus !== 'used';

  const sourceValues = {
    home: 'home MVC',
    table: 'tiers table',
    favorite: 'favorite',
    claimed: 'vantagens MVC',
    'benefit-list': `${capitalize(variant)} benefit list`,
  };

  const formatProperties = useCallback(() => {
    let properties;

    properties = {
      'Benefit Points': benefit.points ? benefit.points : 0,
      'Benefit Type': formatBenefitType(benefit),
      'Benefit Name': benefit.title,
      Source: sourceValues[focus],
      'Card Position': index,
      'Redeem Type': benefit.type,
      'User Balance': plan.currentBalance >= benefit.points ? 'Enough points' : 'Not enough points',
    };

    if (benefit.store) properties['Benefit Store Name'] = benefit.store.name;
    if (benefit.categories) properties['Benefit Categories Names'] = benefit.categories;

    return properties;
  }, [benefit]);

  const handleClickBenefit = () => {
    if (isBlocked) {
      handleOpenBlockedBenefitModal();
      return;
    }

    const trackingProperties = {
      source: sourceValues[focus],
      cardPosition: index,
    };
    multiStorage.setItem(`@multivc:benefit-tracking-properties`, JSON.stringify(trackingProperties));

    if (!['used', 'ready', 'expired'].includes(focus)) {
      try {
        window.track({
          mixpanel: {
            name: 'Clicked Benefit Card',
            properties: formatProperties(),
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }
    }

    if (['ready', 'used'].includes(focus)) {
      history.push({
        pathname: `/apps/multivoce/beneficios/resgatados/ticket/${userVipBenefitId}`,
        state: { lastRoute: location.pathname },
      });
    }

    if (['table', 'benefit-list', 'home', 'claimed', 'favorite'].includes(focus)) {
      history.push({
        pathname: `/apps/multivoce/beneficios/tickets/${benefit.id}/${variant}`,
        state: { lastRoute: location.pathname },
      });
    }
  };

  const handleFavorite = useCallback(
    async (event) => {
      event?.stopPropagation();
      if (!isLoggedIn) {
        setOpenModal(true);
      } else {
        if (!isFavorite) {
          try {
            window.track({
              mixpanel: {
                name: 'Clicked To Favorite Benefit',
                properties: formatProperties(),
              },
            });
          } catch (e) {
            bugsnag?.notify(e);
          }
          statusToast({
            type: 'success',
            title: 'Adicionado à sua lista de favoritos!',
            action: <SeeListButton />,
          });
        }
        if (focus === 'favorite') {
          setIsFavorite(!isFavorite);
        }
        toggleFavoriteInList(benefit.id, !isFavorite);
        if (onClickFavorite) onClickFavorite(event);
      }
    },
    [isFavorite, benefit.id, isLoggedIn, plan?.shoppingSlug],
  );

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseBlockedBenefitModal = () => {
    setOpenBlockedBenefitModal(false);
  };
  const handleOpenBlockedBenefitModal = () => {
    try {
      window.track({
        mixpanel: {
          name: 'Clicked Blocked Benefit Card',
          properties: formatProperties(),
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
    setOpenBlockedBenefitModal(true);
  };

  const removeOneDay = (date) => {
    const newDate = new Date(date);

    newDate.setDate(newDate.getDate() - 1);

    return newDate;
  };

  const differenceDayText = (value) => {
    if (value === 0) {
      return '';
    }

    return value === 1 ? ` ${value} dia` : ` ${value} dias`;
  };

  const handleClickFavoriteList = () => {
    history.push('/apps/multivoce/meusbeneficios/favorite');
  };

  const SeeListButton = () => {
    return (
      <button onClick={handleClickFavoriteList} className={styles.seeListButton}>
        Ver lista
        <ChevronIcon width="12px" height="12px" fill={colors.common.white} />
      </button>
    );
  };

  return {
    plan,
    openModal,
    openBlockedBenefitModal,
    isPromotion,
    imageUrl,
    difference,
    showAvailableBenefit,
    isExpired,
    isBlocked,
    showPoints,
    showFavoriteButton,
    showIsSoldOut,
    showLimitDate,
    isFavorite,

    handleClickBenefit,
    handleFavorite,
    handleCloseModal,
    removeOneDay,
    differenceDayText,
    handleCloseBlockedBenefitModal,
  };
};
