const hasNativeCamera = () => {
  try {
    return (
      !!window?.webkit?.messageHandlers?.onImagePickerTap?.postMessage ||
      !!window?.MultiShoppingAndroid?.onImagePickerTap
    );
  } catch (e) {
    return false;
  }
};

export default hasNativeCamera;
