import React from 'react';
import ReactDOM from 'react-dom';

import './utils/dates';
import './utils/numbers';
import './utils/strings';
import App from './App';
import './styles/index.scss';
import ErrorBoundary from './errorBoundary';

const AppRender = () => {
  return (
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  );
};

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(<AppRender />);
