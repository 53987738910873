import gql from 'graphql-tag';

const SAVE_MY_COUPON = gql`
  mutation saveMyCoupon($name: String!, $shoppingSlug: String!) {
    saveMyCoupon(name: $name, shoppingSlug: $shoppingSlug)
  }
`;

export default SAVE_MY_COUPON;
export { SAVE_MY_COUPON };
