import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const OutsideClickHandler = ({ onOutsideClick, children, className }) => {
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onOutsideClick();
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('touchstart', handleClickOutside);
    };
  });

  return (
    <div ref={wrapperRef} className={className}>
      {children}
    </div>
  );
};

OutsideClickHandler.propTypes = {
  onOutsideClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

OutsideClickHandler.defaultProps = {
  className: '',
};

export default OutsideClickHandler;
