import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { isAndroid } from 'react-device-detect';

import { useCurrentShopping, useUser, useRedirectModal } from 'hooks';
import { openParking, bugsnag, classNames, goToPromotions } from 'utils';

import ParkingModal from 'components/parkingModal';

import styles from './index.module.scss';

const Banner = ({ name, cover, link, index, position, path, showLoginScreen }) => {
  const [parkingModal, setParkingModal] = useState(false);
  const currentShopping = useCurrentShopping();
  const openRedirectModal = useRedirectModal();
  const user = useUser();
  const history = useHistory();
  const client = useApolloClient();

  const handleClick = async () => {
    try {
      window.track({
        mixpanel: {
          name: 'Clicked Banner',
          properties: {
            'Banner Name': name,
            'Banner Index': index,
            'Banner Position': position,
            'Banner Path': path,
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }

    if (link === '/apps/promocoes') {
      const path = await goToPromotions(history, client);
      history.push(path);
    } else if (link === '/apps/estacionamento') {
      if (openParking(currentShopping.slug, user?.isBeta)) {
        history.push('/apps/estacionamento/home');
      } else {
        setParkingModal(true);
      }
    } else if (link.startsWith('http')) {
      if (!user && showLoginScreen) {
        client.writeData({ data: { pathname: `/redirect/${encodeURIComponent(link)}/${isAndroid}` } });
        history.push('/signup/phone');
      } else {
        openRedirectModal({ url: encodeURIComponent(link), forceBrowser: isAndroid });
      }
    } else {
      history.push(link);
    }
  };

  return (
    <>
      <img
        className={classNames(styles.banner, { [styles.bannerBottom]: position === 3 })}
        onClick={handleClick}
        src={cover.original}
      />
      <ParkingModal isOpen={parkingModal} onClose={() => setParkingModal(false)} />
    </>
  );
};

Banner.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  cover: PropTypes.shape({
    id: PropTypes.string.isRequired,
    original: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  position: PropTypes.number,
  path: PropTypes.string,
  showLoginScreen: PropTypes.bool,
};

Banner.defaultProps = {
  position: null,
  path: null,
  showLoginScreen: false,
};

export default Banner;
