import { compressImage } from 'utils';
import { UPLOAD_MULTIPLE_FILES } from 'services/apollo/mutations';
import { useMutation } from '@apollo/react-hooks';

const useUploadFiles = ({ onError } = {}) => {
  const handleErrorUpload = (err) => {
    if (onError) onError(err);
  };

  const [uploadMultipleFiles, { loading }] = useMutation(UPLOAD_MULTIPLE_FILES, {
    onError: handleErrorUpload,
  });

  const uploadFiles = async (files) => {
    try {
      if (!files) return [];
      const attachments = [];
      let uploadMultipleFilesIds = [];

      for (const file of files) {
        attachments.push(await compressImage(file, false));
      }

      if (attachments.length > 0) {
        const { data: { uploadMultipleFiles: files } = {} } = await uploadMultipleFiles({
          variables: { files: attachments },
        });
        uploadMultipleFilesIds = files.map((item) => item.id);
      }

      return uploadMultipleFilesIds;
    } catch (e) {
      return [];
    }
  };
  return [uploadFiles, { loading }];
};

export default useUploadFiles;
