import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import { bugsnag } from 'utils';
import { useUserPlan, useCurrentShopping, useUserViewHistory } from 'hooks';
import SlideModal from 'components/slideModal';
import Button from 'components/button';
import Checkbox from 'components/checkbox';

import styles from './index.module.scss';
import MultiVCIllustration from 'images/utils/notes_fidelity_illustration.svg';

const FidelityModal = ({ show, onClose }) => {
  const history = useHistory();
  const plan = useUserPlan();
  const currentShopping = useCurrentShopping(true);

  const modalLoyaltyPromotion = currentShopping?.promotions?.find((promo) => promo.type === 'is-user-vip');
  const modalNotLoyaltyPromotion = currentShopping?.promotions?.find((promo) => promo.type === 'is-not-user-vip');
  const modalPromotion = plan ? modalLoyaltyPromotion : modalNotLoyaltyPromotion;

  const { getHistory, saveHistory } = useUserViewHistory();
  const { data: userViewPromotion } = getHistory(modalPromotion?.__typename, modalPromotion?.id, 'fidelity-modal');
  const saveUserViewPromotion = saveHistory(modalPromotion?.__typename, modalPromotion?.id, 'fidelity-modal');
  const variant = plan.variant;

  const [acceptNotShow, setAcceptNotShow] = useState(false);

  const handleAcceptNotShowChange = useCallback(() => {
    setAcceptNotShow(!acceptNotShow);
  }, [acceptNotShow]);

  useEffect(() => {
    if (!userViewPromotion) {
      if (plan?.name) {
        try {
          window.track({
            mixpanel: {
              name: 'Viewed Page',
              properties: {
                'Virtual Page Path': history.location.pathname,
                'Is First Page View': !sessionStorage.getItem('lastRoute'),
              },
            },
            salesForce: {
              name: 'Viewed Page',
              properties: {
                'Virtual Page Path': history.location.pathname,
              },
            },
          });
        } catch (e) {
          bugsnag?.notify(e);
        }
      }
    } else {
      history.push('/apps/multivoce/enviar-notas');
    }
  }, [show, variant]);

  const goToPromotion = useCallback(() => {
    if (acceptNotShow) {
      try {
        window.track({
          mixpanel: {
            name: 'Dont Show Modal Again',
            properties: {
              Entity: 'Promotion',
              'Entity Id': modalPromotion.id,
              'Promotion Type': modalPromotion.type,
              'Virtual Page Path': history.location.pathname,
            },
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }
      saveUserViewPromotion();
    }
    try {
      window.track({
        mixpanel: {
          name: 'Go to Promotion From Invoice Modal',
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
    history.push('/apps/promotion/modal');
  }, [acceptNotShow]);

  const goToSendNotes = useCallback(() => {
    if (acceptNotShow) {
      try {
        window.track({
          mixpanel: {
            name: 'Dont Show Modal Again',
            properties: {
              Entity: 'Promotion',
              'Entity Id': modalPromotion.id,
              'Promotion Type': modalPromotion.type,
              'Virtual Page Path': history.location.pathname,
            },
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }
      saveUserViewPromotion();
    }
    try {
      window.track({
        mixpanel: {
          name: 'Sent Invoice From Invoice Modal',
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
    history.push('/apps/multivoce/enviar-notas');
  }, [acceptNotShow]);

  return (
    <SlideModal isOpen={show} onClose={onClose}>
      <div className={styles.root}>
        <div className={styles.rootTab} />
        <MultiVCIllustration className={styles.rootLogo} />
        <p className={styles.rootText}>
          Notas enviadas através da <strong>{modalPromotion?.name}</strong> também serão cadastradas no{' '}
          <strong>MultiVocê</strong>, acumulando pontos. Não é necessário envia-las outra vez.
        </p>
        <Button className={styles.rootPurpleButton} type="default" size="full" onClick={goToPromotion}>
          ir para a promoção
        </Button>
        <Button className={styles.rootGreenButton} type="primary" size="full" onClick={goToSendNotes}>
          continuar envio
        </Button>
        <div className={styles.check}>
          <Checkbox checked={acceptNotShow} onChange={handleAcceptNotShowChange}>
            <p className={styles.checkOption}>Não exibir novamente</p>
          </Checkbox>
        </div>
      </div>
    </SlideModal>
  );
};

FidelityModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FidelityModal;
