import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import ProgressiveImage from 'components/progressiveImage';
import Button from 'components/button';

import BottomCard from '../components/bottomCard';
import onboardingEnd from './images/onboarding_end.png';
import TutorialWrapper from '../components/tutorialWrapper';

import { bugsnag } from 'utils';

import styles from './index.module.scss';

const FinishOnboarding = () => {
  const history = useHistory();

  const sendClickedOnboardingSignUpTracking = () => {
    try {
      window.track({
        mixpanel: {
          name: 'Clicked Onboarding Sign Up',
        },
        googleTagManager: {
          global: {
            name: 'clickedOnboardingSignUp',
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
  };

  const sendClickedOnboardingNoSignUpTracking = () => {
    try {
      window.track({
        mixpanel: {
          name: 'Clicked Onboarding No Sign Up',
        },
        googleTagManager: {
          global: {
            name: 'clickedOnboardingNoSignUp',
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
  };

  const handleSkipClick = useCallback(() => {
    sendClickedOnboardingNoSignUpTracking();
    multiStorage.setItem('onboardingDone', true);
    history.replace('/showcase');
  }, [history]);

  const handleSignUpClick = useCallback(() => {
    sendClickedOnboardingSignUpTracking();
    multiStorage.setItem('onboardingDone', true);
    history.push('/signup/phone');
  }, [history]);

  return (
    <TutorialWrapper closeButton={false}>
      <BottomCard className={styles.wrapper}>
        <ProgressiveImage withLoader={false} src={onboardingEnd} className={styles.endingImage} />
        <h1 className={styles.title}>
          Maravilha! <b>Cadastre-se e aproveite tudo isso e muito mais!</b>
        </h1>
        <Button type="primary" size="full" className={styles.startButton} onClick={handleSignUpClick}>
          Cadastrar
        </Button>
        <Button type="default" size="full" className={styles.skipButton} onClick={handleSkipClick}>
          Continuar sem cadastro
        </Button>
      </BottomCard>
    </TutorialWrapper>
  );
};

export default FinishOnboarding;
