import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { bugsnag } from 'utils';
import ArrowRight from 'icons/chevron-right.svg';

import PostSlider from 'components/postSlider';
import { colors } from 'theme';
import Flex from 'components/flex';
import Typography from 'components/typography';
import ShoppingInfo from '../shoppingInfo';
import ShoppingServices from '../shoppingServices';
import Image from 'images/fidelity/ilustra-aba-shopping.png';

import { useApps } from '../../contexts/apps';
import { useCurrentShopping } from 'hooks';
import Button from 'components/button';
import styles from './index.module.scss';

const ShoppingTab = () => {
  const history = useHistory();
  const currentShopping = useCurrentShopping();
  const footerRef = useRef(null);
  const { shoppingHasApp } = useApps();

  const handleClickComponentA = () => {
    try {
      window.track({
        mixpanel: {
          name: 'Clicked Send Invoice',
          properties: {
            Source: 'Shopping Tab',
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
    history.push('/apps/multivoce/enviar-notas');
  };

  return (
    <>
      <Flex padding="32px 16px 8px" bgColor={colors.common.white}>
        <Flex bgColor={colors.green[1]} borderRadius="8px" onClick={handleClickComponentA} padding="16px" gap="16px">
          <img src={Image} width={44} height={112} />
          <Flex direction="column" align="flex-start" gap="5px">
            <Typography color={colors.gray[7]}>
              <strong>Suas compras valem pontos no Multivocê!</strong>
            </Typography>
            <Typography color={colors.gray[7]} variant="body2">
              Envie suas notas fiscais, acumule pontos e troque por benefícios exclusivos!
            </Typography>
          </Flex>
          <Button flat className={styles.seeMoreButton}>
            <ArrowRight />
          </Button>
        </Flex>
      </Flex>
      <ShoppingServices shopping={currentShopping} footerRef={footerRef} />
      {shoppingHasApp('dicas-e-novidades') && <PostSlider />}
      <ShoppingInfo shopping={currentShopping} ref={footerRef} />
    </>
  );
};

export default ShoppingTab;
