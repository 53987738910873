import { lazy } from 'react';

const MyProfile = lazy(() => import(/* webpackChunkName: "menu" */ 'screens/myProfile'));
const PersonalInformation = lazy(() => import(/* webpackChunkName: "menu" */ 'screens/myProfile/personalInformation'));
const ContactData = lazy(() => import(/* webpackChunkName: "menu" */ 'screens/myProfile/contacts/contactInformation'));
const PhoneConfirmation = lazy(() => import(/* webpackChunkName: "menu" */ 'screens/myProfile/phoneConfirmation'));
const ConfirmationProblem = lazy(() => import(/* webpackChunkName: "menu" */ 'screens/myProfile/phoneProblem'));
const PrePhoneConfirmation = lazy(() =>
  import(/* webpackChunkName: "menu" */ 'screens/myProfile/prePhoneConfirmation'),
);
// const Cards = lazy(() => import(/* webpackChunkName: "menu" */ 'screens/myProfile/creditCards'));
const PreDeleteAccount = lazy(() =>
  import(/* webpackChunkName: "menu" */ 'screens/myProfile/deleteAccount/preDeleteAccount'),
);
const DeleteAccount = lazy(() =>
  import(/* webpackChunkName: "menu" */ 'screens/myProfile/deleteAccount/deleteAccount'),
);
const DeleteConfirmation = lazy(() =>
  import(/* webpackChunkName: "menu" */ 'screens/myProfile/deleteAccount/deleteAccount/deleteConfirmation'),
);

export default [
  {
    path: '/meu-perfil',
    exact: true,
    component: MyProfile,
  },
  {
    path: '/meu-perfil/informacoes-pessoais',
    exact: true,
    component: PersonalInformation,
  },
  {
    path: '/meu-perfil/dados-contato',
    exact: true,
    component: ContactData,
  },
  {
    path: '/meu-perfil/pre-confirmacao',
    exact: true,
    component: PrePhoneConfirmation,
  },
  {
    path: '/meu-perfil/confirmacao',
    exact: true,
    component: PhoneConfirmation,
  },
  {
    path: '/meu-perfil/problema',
    exact: true,
    component: ConfirmationProblem,
  },
  // {
  //   path: '/meu-perfil/cartoes',
  //   exact: true,
  //   component: Cards,
  // },
  {
    path: '/meu-perfil/pre-exclusao',
    exact: true,
    component: PreDeleteAccount,
  },
  {
    path: '/meu-perfil/exclusao',
    exact: true,
    component: DeleteAccount,
  },
  {
    path: '/meu-perfil/exclusao/confirmacao',
    exact: true,
    component: DeleteConfirmation,
  },
];
