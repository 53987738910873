import { lazy } from 'react';

const Event = lazy(() => import(/* webpackChunkName: "event" */ 'screens/events/event'));
const EventsHub = lazy(() => import(/* webpackChunkName: "event" */ 'screens/events/eventsHub'));

export default [
  {
    path: '/apps/eventos/:slug',
    exact: true,
    component: Event,
  },
  {
    path: '/apps/eventos',
    exact: true,
    component: EventsHub,
  },
];
