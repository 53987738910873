import gql from 'graphql-tag';
import { meSelect } from 'services/apollo/helpers/variables';

const CREATE_VERIFICATION_CODE = gql`
  mutation createVerificationCode($phoneNumber: String!, $provider: String!, $newPhone: Boolean) {
    createVerificationCode(phoneNumber: $phoneNumber, provider: $provider, newPhone: $newPhone) {
      phoneNumber
    }
  }
`;

const PHONE_VERIFICATION = gql`
  mutation phoneVerification($phoneNumber: String!, $verificationCode: Int!, $captchaToken: String) {
    phoneVerification(phoneNumber: $phoneNumber, verificationCode: $verificationCode, captchaToken: $captchaToken) {
      hash
      maskedCpf
      maskedEmail
    }
  }
`;

const SIGN_UP = gql`
  mutation signUp($shoppingSlug: String!, $name: String!, $email: String!, $cpf: String!, $phoneNumber: String!, $birthdate: Date, $gender: String, $terms: Boolean, $privacyPolicy: Boolean) {
    signUp(shoppingSlug: $shoppingSlug, name: $name, email: $email, cpf: $cpf, phoneNumber: $phoneNumber, birthdate: $birthdate, gender: $gender, terms: $terms, privacyPolicy: $privacyPolicy) {
      ${meSelect}
    }
  }
`;

const CONFIRM_IDENTITY = gql`
  mutation verifyPhoneCpf($cpf: String!, $hash: String!, $shoppingSlug: String!) {
    verifyPhoneCpf(cpf: $cpf, hash: $hash, shoppingSlug: $shoppingSlug) {
      ${meSelect}
    }
  }
`;

export { CREATE_VERIFICATION_CODE, PHONE_VERIFICATION, SIGN_UP, CONFIRM_IDENTITY };
