import React from 'react';

import MapIcon from 'icons/map.svg';
import MedicIcon from 'icons/medic.svg';
import ConvenienceIcon from 'icons/convenience.svg';
import ServiceIcon from 'icons/service.svg';
import ClockIcon from 'icons/clock.svg';
import WiFiIcon from 'icons/wifi.svg';

import styles from './index.module.scss';

const services = [
  {
    icon: <MapIcon className={styles.serviceIcon} />,
    title: 'Mapa',
    slug: 'mapa',
    description: 'Localize-se no shopping através do mapa',
  },
  {
    icon: <WiFiIcon className={styles.serviceIcon} />,
    title: 'Wi-fi',
    slug: 'wi-fi',
    description: 'Habilite para navegar com mais velocidade e por mais tempo',
    path: '/wifi',
  },
  {
    icon: <ClockIcon className={styles.serviceIcon} />,
    title: 'Horários',
    slug: 'horarios',
    description: 'Consulte os horários de funcionamento do Shopping',
  },
  {
    icon: <ServiceIcon className={styles.serviceIcon} />,
    title: 'Serviços',
    slug: 'servico',
    description: 'Bancos, caixa eletrônico, casa de câmbio e mais...',
    path: '/apps/servico',
  },
  {
    icon: <ConvenienceIcon className={styles.serviceIcon} />,
    title: 'Comodidades',
    slug: 'comodidades',
    description: 'Acessibilidade, praticidade, família e conveniência',
    path: '/apps/comodidades',
  },
  {
    icon: <MedicIcon className={styles.serviceIcon} />,
    title: 'Centro Médico',
    slug: 'centro-medico',
    description: 'Clinicas, consultórios, laboratórios e exames',
    path: '/apps/centro-medico',
  },
];

export default services;
