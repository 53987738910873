import { lazy } from 'react';

const registerOnboarding = lazy(() => import(/* webpackChunkName: "newOnboard" */ 'screens/registerOnboarding'));

export default [
  {
    path: '/register-onboarding',
    exact: true,
    component: registerOnboarding,
  },
];
