import React from 'react';
import { useHistory } from 'react-router-dom';

import { useCurrentShopping } from 'hooks';

import { PLAYS } from './graphql/queries';
import { useQuery } from '@apollo/react-hooks';

import { clickedTreadItemTracking } from '../../tracking';

import PlayCard from 'components/playCard';
import Slider from 'components/slider';
import TreadSlider from 'components/treadSlider';

import TheaterIcon from 'icons/theater.svg';

import styles from './index.module.scss';

const MAX_PLAYS_TO_SHOW = 5;

const TheaterSlider = () => {
  const currentShopping = useCurrentShopping();
  const history = useHistory();
  const path = '/apps/teatro';
  const treadSlug = 'teatro';

  const isBRSorVLM = currentShopping.slug === 'barrashopping' || currentShopping.slug === 'villagemall';

  const { data: { shows } = [], loading } = useQuery(PLAYS, {
    skip: !currentShopping,
    variables: {
      shoppingId: currentShopping.slug === 'barrashopping' ? 'mavtGolgOe' : currentShopping.id,
      typeSlug: 'peca',
      limit: MAX_PLAYS_TO_SHOW,
    },
  });

  if (!shows || shows.length < 1) return null;

  const handleTreadItemClick = (path, itemSlug, treadSlug, title, position) => {
    clickedTreadItemTracking(treadSlug, title, position);
    history.push(`${path}/${itemSlug}`);
  };

  return (
    <>
      {!loading && (
        <TreadSlider
          color={'#d73a4e'}
          icon={<TheaterIcon className={styles.icon} />}
          path={path}
          title={isBRSorVLM ? 'Agenda Teatro Multiplan' : 'Em cartaz no Teatro'}
          wordEmphasis={isBRSorVLM ? 'last two' : 'last'}
          slug={treadSlug}
        >
          <>
            {shows.length === 1 ? (
              <div className={styles.singleContent}>
                <PlayCard
                  key={shows[0].id}
                  title={shows[0].name}
                  image={shows[0].images[0]}
                  onClick={() => handleTreadItemClick(path, shows[0].slug, treadSlug, shows[0].name, 0)}
                  startDate={shows[0].startDate}
                  endDate={shows[0].endDate}
                  className={styles.playCard}
                />
              </div>
            ) : (
              <Slider className={styles.slider} fixedItemWidth autoScrollbarHeight>
                {shows.map((play, index) => (
                  <PlayCard
                    key={play.id}
                    title={play.name}
                    image={play.images[0]}
                    onClick={() => handleTreadItemClick(path, play.slug, treadSlug, play.name, index)}
                    startDate={play.startDate}
                    endDate={play.endDate}
                    className={styles.playCard}
                  />
                ))}
              </Slider>
            )}
          </>
        </TreadSlider>
      )}
    </>
  );
};

export default TheaterSlider;
