import { bugsnag } from 'utils';

const clickBannerTracking = ({ name, currentShopping }) => {
  try {
    window.track({
      firebase: {
        name: 'click_advantage_top_banner',
        properties: {
          banner_name: name,
          shopping_name: currentShopping.name,
        },
      },
      mixpanel: {
        name: 'Clicked Advantage Top Banner',
        properties: {
          'Banner Name': name,
          'Shopping Name': currentShopping.name,
        },
      },
      googleTagManager: {
        global: {
          name: 'clickedAdvantageTopBanner',
          properties: {
            bannerName: name,
            ShoppingName: currentShopping.name,
          },
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

const clickSliderBannerTracking = ({ name, currentShopping, position }) => {
  try {
    window.track({
      firebase: {
        name: 'click_advantage_slider_banner',
        properties: {
          banner_name: name,
          shopping_name: currentShopping.name,
          banner_position: position,
        },
      },
      mixpanel: {
        name: 'Clicked Advantage Slider Banner',
        properties: {
          'Banner Name': name,
          'Shopping Name': currentShopping.name,
          'Banner Position': position,
        },
      },
      googleTagManager: {
        global: {
          name: 'clickedAdvantageSliderBanner',
          properties: {
            bannerName: name,
            ShoppingName: currentShopping.name,
            bannerPosition: position,
          },
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

const clickBottomBannerTracking = ({ name, currentShopping }) => {
  try {
    window.track({
      firebase: {
        name: 'click_advantage_bottom_banner',
        properties: {
          banner_name: name,
          shopping_name: currentShopping.name,
        },
      },
      mixpanel: {
        name: 'Clicked Advantage Bottom Banner',
        properties: {
          'Banner Name': name,
          'Shopping Name': currentShopping.name,
        },
      },
      googleTagManager: {
        global: {
          name: 'clickedAdvantageBottomBanner',
          properties: {
            bannerName: name,
            ShoppingName: currentShopping.name,
          },
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

const clickLlvAnchorTracking = (currentShopping) => {
  try {
    window.track({
      mixpanel: {
        name: 'Clicked LLV anchor',
        properties: {
          'Shopping Name': currentShopping.name,
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

const clickLlvFooterTracking = (currentShopping) => {
  try {
    window.track({
      mixpanel: {
        name: 'clicked see all LLV',
        properties: {
          'Shopping Name': currentShopping.name,
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

const clickLlvSeeAllTracking = (currentShopping) => {
  try {
    window.track({
      mixpanel: {
        name: 'clicked see all llv button',
        properties: {
          'Shopping Name': currentShopping.name,
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

const clickBenefitsAnchorTracking = (currentShopping) => {
  try {
    window.track({
      mixpanel: {
        name: 'Clicked MVC Anchor',
        properties: {
          shopping_name: currentShopping.name,
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

export {
  clickBannerTracking,
  clickSliderBannerTracking,
  clickLlvAnchorTracking,
  clickBottomBannerTracking,
  clickLlvSeeAllTracking,
  clickLlvFooterTracking,
  clickBenefitsAnchorTracking,
};
