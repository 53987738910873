import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button';

import LlvIcon from 'icons/pencil-small-llv.svg';

import styles from './index.module.scss';

const ProductShowcaseFooter = ({ productsCount, onClick }) => {
  if (!productsCount) return null;
  return (
    <Button type="default" className={styles.button} onClick={() => onClick('footer')}>
      <LlvIcon className={styles.icon} />
      <p className={styles.description}>
        Ver todas as ofertas <strong>{`(${productsCount})`}</strong>
      </p>
    </Button>
  );
};

ProductShowcaseFooter.propTypes = {
  productsCount: PropTypes.number,
  onClick: PropTypes.func,
};

ProductShowcaseFooter.defaultProps = {
  productsCount: null,
  onClick: () => {},
};

export default ProductShowcaseFooter;
