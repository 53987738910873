import React from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from 'hooks';
import { clickedWelcomeBanner } from '../../tracking';

import ChevronRight from 'icons/chevron-right.svg';

import styles from './index.module.scss';

const WelcomeModal = () => {
  const user = useUser();
  const getFirstName = () => user.name.split(' ')[0];
  const history = useHistory();

  const getText = () => {
    if (user) return 'Aproveite todos os benefícios do MultiVocê';
    return 'Cadastre-se e aproveite o melhor do Multi';
  };

  const userText = `Olá${(user && `, ${getFirstName()}`) || ''}`;

  const handleClick = () => {
    clickedWelcomeBanner();
    if (!user) history.push('/signup/phone');
    else history.push('/apps/multivoce');
  };
  return (
    <div id="multivcSummaryDiv" className={styles.container} onClick={handleClick}>
      <div className={styles.containerTextContainer}>
        <strong id="firstNameSpan" data-testid={'firstNameSpan'} className={styles.containerUserText}>
          {userText}
        </strong>
        <span className={styles.containerDescription}>{getText()}</span>
      </div>
      {!user && <ChevronRight className={styles.containerChevron} viewBox="0 0 500 500" />}
    </div>
  );
};

export default WelcomeModal;
