import { lazy } from 'react';

const Home = lazy(() => import(/* webpackChunkName: "movie" */ 'screens/apps/movie/home'));
const Movie = lazy(() => import(/* webpackChunkName: "movie" */ 'screens/apps/movie/movie'));

export default [
  {
    path: '/apps/cinema',
    exact: true,
    component: Home,
  },
  {
    path: '/apps/cinema/:slug',
    exact: true,
    component: Movie,
  },
];
