import { lazy } from 'react';
const path = '/after-sale/:orderId';
const AfterSale = lazy(() => import(/* webpackChunkName: "directSales" */ 'screens/afterSale'));

export default [
  {
    path: path,
    exact: true,
    component: AfterSale,
  },
];
