import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { bugsnag, classNames } from 'utils';

import styles from './index.module.scss';
import AngleRight from 'icons/angle-right.svg';

const SeeAll = ({ route, iconColor, female, text, replace, anything, className }) => {
  const history = useHistory();
  const handleSeeAll = () => {
    try {
      window.track({
        mixpanel: {
          name: 'Clicked See All',
          properties: {
            'Virtual Page Path': route,
          },
        },
        googleTagManager: {
          global: {
            name: 'clickedSeeAll',
            properties: {
              virtualPagePath: route,
            },
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }

    history[replace ? 'replace' : 'push'](route);
  };

  return (
    <div className={classNames(styles.all, className, { [styles.anything]: anything })} onClick={() => handleSeeAll()}>
      {text.length > 0 ? text : `Ver tod${female ? 'a' : 'o'}s`}
      <AngleRight className={styles.icon} style={{ fill: iconColor || '#19AC7F' }} />
    </div>
  );
};

SeeAll.propTypes = {
  text: PropTypes.string,
  female: PropTypes.bool,
  replace: PropTypes.bool,
  iconColor: PropTypes.string,
  className: PropTypes.string,
  route: PropTypes.string.isRequired,
  anything: PropTypes.bool,
};

SeeAll.defaultProps = {
  text: '',
  female: false,
  className: null,
  replace: false,
  iconColor: null,
  anything: false,
};

export default SeeAll;
