import gql from 'graphql-tag';

const APPS = gql`
  query apps($shoppingId: ID!, $skip: Int) {
    apps(shoppingId: $shoppingId, skip: $skip) {
      id
      name
      slug
      externalUrl
      color
      featured
      cover {
        id
        original
      }
      icon {
        id
        original
      }
      showLoginScreen
    }
  }
`;

export default APPS;
export { APPS };
