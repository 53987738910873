import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button';
import styles from './index.module.scss';
import propTypes from 'prop-types';

const ButtonTabs = ({ activeIndex, className, tabs, onTabChange }) => {
  const tabsArray = typeof tabs === 'number' ? [...Array(tabs).keys()] : tabs;

  const handleActiveTabChange = useCallback((index) => onTabChange(index), [activeIndex]);

  return (
    <div className={styles.root}>
      {tabsArray?.map((tab, index) => (
        <Button
          data-testid="stepTab"
          key={index}
          className={`${styles.btn} ${activeIndex === index && styles.active} ${className}`}
          size="small"
          onClick={() => handleActiveTabChange(index)}
        >
          {typeof tabs === 'object' ? tab : null}
        </Button>
      ))}
    </div>
  );
};

ButtonTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired || PropTypes.number.isRequired,
  onTabChange: PropTypes.func,
  className: PropTypes.string,
  activeIndex: propTypes.number,
};

ButtonTabs.defaultProps = {
  tabs: [],
  onTabChange: null,
  className: null,
  activeIndex: 0,
};

export default ButtonTabs;
