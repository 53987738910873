import React from 'react';
import PropTypes from 'prop-types';

import Card from 'components/card';
import ProgressiveImage from 'components/progressiveImage';

import Pencil from 'icons/pencil-alt.svg';
import defaultCover from './images/defaultCover.png';
import { currencyFormat, textTruncate, classNames, formatDayAndMonth } from 'utils';

import trackingProductCard from './tracking';
import styles from './index.module.scss';

const customAttributesNames = { color: 'Cor', size: 'Tamanho' };

const ProductCard = ({
  inline,
  attributes,
  price,
  cover,
  id,
  sku,
  name,
  showStoreName,
  storeName,
  storeId,
  onClick,
  quantity,
  className,
  position,
  previousPrice,
  endDate,
  percentage,
}) => {
  const handleClick = () => {
    trackingProductCard({ name, price, storeName });
    if (onClick) onClick({ name, price, attributes, storeName, storeId, id, sku, cover, position });
  };

  return (
    (inline && (
      <Card className={styles.itemCard}>
        <Card className={classNames(styles.cover, className)}>
          <ProgressiveImage src={cover} />
        </Card>
        <div className={styles.data}>
          <strong>{name}</strong>
          {attributes.map(({ label, value }) => (
            <div key={value}>
              <span>{customAttributesNames[label]}: </span>
              {value}
            </div>
          ))}
          {previousPrice && <div className={styles.oldPrice}>De {currencyFormat(previousPrice)}</div>}
          <div className={classNames(previousPrice ? styles.offerPrice : styles.price)}>{currencyFormat(price)}</div>
        </div>
        {quantity && (
          <div className={styles.itemQuantity}>
            <span>{quantity}x</span>
          </div>
        )}
      </Card>
    )) || (
      <button className={styles.btnItem} onClick={handleClick}>
        <div className={classNames(styles.productCard, styles.image, className)}>
          <ProgressiveImage src={cover} />
          {percentage && (
            <div className={styles.tag}>
              <Pencil />
              <span>
                {percentage}
                <b>%</b>
              </span>
            </div>
          )}
        </div>
        <div className={styles.data}>
          <div className={styles.name}>{textTruncate(name)}</div>
          {showStoreName && <div className={styles.storeName}>{storeName}</div>}
          {!previousPrice ? (
            <div className={classNames(styles.price)}>{currencyFormat(price)}</div>
          ) : (
            <>
              {previousPrice > 0 && <div className={styles.previousPrice}>{`De ${currencyFormat(previousPrice)}`}</div>}
              {!!(price > 0) && <div className={styles.offerPrice}>{`Por ${currencyFormat(price)}`}</div>}
              {endDate && <div className={styles.expiresIn}>{`Oferta válida até: ${formatDayAndMonth(endDate)}`}</div>}
            </>
          )}
        </div>
      </button>
    )
  );
};

ProductCard.propTypes = {
  inline: PropTypes.bool,
  price: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  previousPrice: PropTypes.number.isRequired,
  id: PropTypes.string,
  sku: PropTypes.string,
  cover: PropTypes.string,
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      __typename: PropTypes.string,
    }),
  ),
  onClick: PropTypes.func,
  storeName: PropTypes.string,
  storeId: PropTypes.string,
  showStoreName: PropTypes.bool,
  quantity: PropTypes.number,
  className: PropTypes.string,
  position: PropTypes.number,
  endDate: PropTypes.string,
  percentage: PropTypes.number,
};

ProductCard.defaultProps = {
  price: null,
  previousPrice: null,
  id: null,
  sku: null,
  inline: false,
  showStoreName: true,
  onClick: () => null,
  cover: defaultCover,
  attributes: [],
  storeName: null,
  storeId: null,
  quantity: null,
  className: '',
  position: null,
  endDate: '',
  percentage: null,
};

export default ProductCard;
