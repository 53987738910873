import { lazy } from 'react';
const path = '/venda-direta';
const DirectSelling = lazy(() => import(/* webpackChunkName: "directSales" */ 'screens/apps/directSelling'));
const DirectSellingSearch = lazy(() =>
  import(/* webpackChunkName: "directSales" */ 'screens/apps/directSelling/components/search'),
);

export default [
  {
    path: path,
    exact: true,
    component: DirectSelling,
  },
  {
    path: `${path}/busca`,
    exact: true,
    component: DirectSellingSearch,
  },
];
