import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const storage = {
  getItem: (name) => JSON.parse(sessionStorage.getItem(name) || {}),
  setItem: (name, value) => sessionStorage.setItem(name, value),
};

export const useLicensePlateBlocked = create(
  persist(
    (set) => ({
      closedAlertBox: false,
      toggleAlertBox: (closedAlertBox) => set(() => ({ closedAlertBox })),
    }),
    {
      name: 'licensePlateBlocked',
      getStorage: () => storage,
    },
  ),
);
