import gql from 'graphql-tag';

const POSTS = gql`
  query posts($shoppingId: ID!, $typesSlugs: [String], $limit: Int, $skip: Int) {
    posts(shoppingId: $shoppingId, typesSlugs: $typesSlugs, limit: $limit, skip: $skip) {
      id
      slug
      name
      images
    }
  }
`;

export default POSTS;
export { POSTS };
