import { useQuery } from '@apollo/react-hooks';
import ORDERS from './graphql/queries';

const useOrderList = (statusesValues) => {
  const {
    loading,
    data: { orders = [] } = {},
    refetch,
    error,
  } = useQuery(ORDERS, {
    variables: { me: true, limit: 999, statusesValues },
    fetchPolicy: 'cache-and-network',
  });

  return { loading, orders, refetch, error };
};

export default useOrderList;
