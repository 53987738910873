import React from 'react';
import { object } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { clickShoppingServicesTracking } from './tracking';
import { useApps } from '../../contexts/apps';

import services from './services';

import styles from './index.module.scss';

const ShoppingServices = ({ shopping, footerRef }) => {
  const history = useHistory();
  const { shoppingHasApp } = useApps();

  const handleServiceClick = ({ title, path, slug }) => {
    clickShoppingServicesTracking(slug);
    if (title === 'Mapa') window.openExternalContent(shopping.map);
    else if (title === 'Horários') footerRef.current.scrollIntoView({ behavior: 'smooth' });
    else history.push(path);
  };

  return (
    <div className={styles.services}>
      <div className={styles.servicesContent}>
        {services
          .filter((service) => service.slug === 'horarios' || shoppingHasApp(service.slug))
          .map((service, index) => (
            <div key={index} className={styles.service} onClick={() => handleServiceClick(service)}>
              <div className={styles.serviceContent}>
                {service.icon}
                <div className={styles.serviceTitle}>{service.title}</div>
                <div>{service.description}</div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

ShoppingServices.propTypes = {
  shopping: object.isRequired,
  footerRef: object.isRequired,
};

export default ShoppingServices;
