import { createClient } from 'graphql-ws';
import { Observable } from 'rxjs';
import { getUser } from 'services/storage';
import { bugsnag } from 'utils';

const GRAPHQL_WS_URL = process.env.GRAPHQL_WS_URL;

const useSubscription = ({ query, variables, nextCallback, errorCallback, completeCallback }) => {
  const client = createClient({
    url: GRAPHQL_WS_URL,
    connectionParams: { 'user-token': getUser()?.sessionToken || null },
  });

  const toObservable = () => {
    return new Observable((observer) =>
      client.subscribe(
        { query, variables },
        {
          next: (data) => observer.next(data),
          error: (err) => observer.error(err),
          complete: () => observer.complete(),
        },
      ),
    );
  };

  const observable = toObservable();

  const subscription = observable.subscribe({
    next: (data) => {
      nextCallback(data);
    },
    error: (data) => {
      if (errorCallback) errorCallback(data);
      else {
        bugsnag?.notify(new Error('useSubscription without errorCallback'), {
          severity: 'error',
          beforeSend: (report) => {
            report.updateMetaData('params', { query, variables, data });
            report.groupingHash = 'useSubscription';
          },
        });
      }
    },
    complete: (data) => {
      completeCallback(data);
    },
  });

  return { client, subscription };
};

export default useSubscription;
