import { lazy } from 'react';

const Service = lazy(() => import(/* webpackChunkName: "service" */ 'screens/apps/service'));
const ServiceSearch = lazy(() => import(/* webpackChunkName: "service" */ 'screens/apps/service/components/search'));
const ServicesCategoriesFilter = lazy(() =>
  import(/* webpackChunkName: "service" */ 'screens/apps/service/components/categories'),
);

export default [
  {
    path: '/apps/servico',
    exact: true,
    component: Service,
  },
  {
    path: '/apps/servico/lojas/busca',
    exact: true,
    component: ServiceSearch,
  },
  {
    path: '/apps/servico/lojas/categorias',
    exact: true,
    component: ServicesCategoriesFilter,
  },
];
