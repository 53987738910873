import { lazy } from 'react';

const Home = lazy(() => import(/* webpackChunkName: "checkin" */ 'screens/checkIn/home'));
const Item = lazy(() => import(/* webpackChunkName: "checkin" */ 'screens/checkIn/item'));
const Favorite = lazy(() => import(/* webpackChunkName: "checkin" */ 'screens/checkIn/favoriteCheckIns'));
const Redeemed = lazy(() => import(/* webpackChunkName: "checkin" */ 'screens/checkIn/redeemedCheckIns'));

export default [
  {
    path: '/check-ins',
    exact: true,
    component: Home,
  },
  {
    path: '/check-in/:slug',
    exact: true,
    component: Item,
  },
  {
    path: '/check-ins/favoritos',
    exact: true,
    component: Favorite,
  },
  {
    path: '/check-ins/:type',
    exact: true,
    component: Redeemed,
  },
];
