export const tracking = (mixpanelName, gtmName) => {
  window.track({
    mixpanel: {
      name: mixpanelName,
    },
    googleTagManager: {
      global: {
        name: gtmName,
      },
    },
  });
};
