const differenceInHours = (date) => {
  const currentTime = new Date();
  const dateToCompare = new Date(date);

  const diff = (currentTime - dateToCompare) / (1000 * 60 * 60);

  return diff;
};

export default differenceInHours;
