import { useQuery, useApolloClient } from '@apollo/react-hooks';

import { bugsnag } from 'utils';

import { CURRENT_SHOPPING, SHOPPING } from 'services/apollo/queries';

const useCurrentShopping = (reload = false) => {
  const client = useApolloClient();

  const { data: { currentShopping } = {} } = useQuery(CURRENT_SHOPPING);

  let reloadCurrentShopping = false;
  const currentShoppingDateKey = 'currentShoppingDate';
  const currentShoppingDateString = multiStorage.getItem(currentShoppingDateKey);
  const currentDate = new Date();
  if (currentShoppingDateString) {
    const currentShoppingDate = new Date(currentShoppingDateString);
    const fiveMinutes = 1000 * 60 * 5;
    const dateDiff = currentDate.getTime() - currentShoppingDate.getTime();
    reloadCurrentShopping = dateDiff > fiveMinutes && reload;
  } else reloadCurrentShopping = true;

  if (reloadCurrentShopping) multiStorage.setItem(currentShoppingDateKey, currentDate);

  useQuery(SHOPPING, {
    variables: { slug: currentShopping?.slug },
    fetchPolicy: 'network-only',
    skip: !reloadCurrentShopping || !currentShopping,
    onCompleted: ({ data: { shopping } = {} }) => {
      client.writeQuery({ query: CURRENT_SHOPPING, data: { currentShopping: shopping } });
    },
    onError: (e) => {
      bugsnag?.notify(e, {
        severity: 'error',
        beforeSend: (report) => {
          report.updateMetaData('params', {
            reload,
            currentShoppingDateString,
            reloadCurrentShopping,
            currentShopping: currentShopping?.slug,
          });
          report.groupingHash = 'useCurrentShopping';
        },
      });
    },
  });

  return currentShopping;
};

export default useCurrentShopping;
