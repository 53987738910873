const daysDifference = (date1, date2) => {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;

  diff /= 60;
  return Math.round(diff);
};

export const hasDifferenceDays = (date1, date2, daysDifference) => {
  const dt1 = date1 instanceof Date ? date1 : new Date(date1);
  const dt2 = date2 instanceof Date ? date2 : new Date(date2);
  const diff = Math.abs(dt1.getTime() - dt2.getTime());
  const days = Math.ceil(diff / (1000 * 60 * 60 * 24));

  return days > daysDifference;
};

export default daysDifference;
