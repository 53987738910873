import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button';
import styles from './index.module.scss';
import ArrowLeft from 'icons/arrow-left.svg';
import { classNames } from 'utils';

const GoBack = ({ onClick, className }) => (
  <Button className={classNames(styles.goBack, className)} {...{ onClick }}>
    <ArrowLeft />
  </Button>
);

GoBack.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

GoBack.defaultProps = {
  className: null,
};

export default GoBack;
