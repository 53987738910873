const trackClickedDirectSellingStorePage = ({ name }) => {
  const data = {
    mixpanel: {
      name: 'Clicked Direct Selling Store Page',
      properties: {
        'Store Name': name,
      },
    },
    googleTagManager: {
      global: {
        name: 'clickedDirectSellingStorePage',
        properties: {
          storeName: name,
        },
      },
    },
  };

  window.track(data);
};

export default trackClickedDirectSellingStorePage;
