import React from 'react';

import { classNames } from 'utils';

const formatTitle = (title, wordEmphasis, headerEmphasisClass, headerTextClass, color) => {
  const titleWords = title.split(' ');

  switch (wordEmphasis) {
    case 'all':
      return (
        <span className={classNames(headerTextClass, headerEmphasisClass)} style={{ color: color }}>
          {title}
        </span>
      );

    case 'first': {
      const titleFirstWord = titleWords.shift();
      return (
        <span className={headerTextClass}>
          <span className={headerEmphasisClass} style={{ color: color }}>
            {titleFirstWord}
          </span>{' '}
          {titleWords.join(' ')}
        </span>
      );
    }

    case 'last': {
      const titleLastWord = titleWords.pop();
      return (
        <span className={headerTextClass}>
          {titleWords.join(' ')}{' '}
          <span className={headerEmphasisClass} style={{ color: color }}>
            {titleLastWord}
          </span>
        </span>
      );
    }

    case 'last two': {
      const titleLastTwoWords = titleWords.splice(-2);
      return (
        <span className={headerTextClass}>
          {titleWords.join(' ')}{' '}
          <span className={headerEmphasisClass} style={{ color: color }}>
            {titleLastTwoWords[0] + ' ' + titleLastTwoWords[1]}
          </span>
        </span>
      );
    }

    default:
      return <span className={headerTextClass}>{title}</span>;
  }
};

export { formatTitle };
