import { useState, useEffect } from 'react';
import { useInfiniteScroll, useBenefits } from 'hooks';

import { capitalize } from 'utils';

import { createFilterURL } from '../../utils/createFilterURL';
import { benefitListTracking } from './tracking';

export const useBenefitListComponent = ({ path, focus, variant }) => {
  const { newData, changeData, getMoreData, page, changePagination } = useInfiniteScroll();
  const {
    data,
    benefits,
    setBenefits,
    fetchMore,
    benefitLoading,
    limit,
    storesFilter,
    setStoresFilter,
    categoriesFilter,
    setCategoriesFilter,
    pointsFilter,
    setPointsFilter,
    recentFilter,
    setRecentFilter,
    redeemedFilter,
    setRedeemedFilter,
  } = useBenefits(variant, focus);

  const [storesFilterModalIsOpen, setStoresFilterModalIsOpen] = useState(false);

  const [categoriesFilterModalIsOpen, setCategoriesFilterModalIsOpen] = useState(false);
  const [pointsFilterModalIsOpen, setPointsFilterModalIsOpen] = useState(false);
  const [isFilterTabsFixed, setLetFilterTabsFixed] = useState(false);

  useEffect(() => {
    if (newData > 0 && data?.benefits.length > benefits.length) {
      const fetchedBenefits = data.benefits.slice(benefits.length);
      const updatedBenefits = [...benefits, ...fetchedBenefits];
      setBenefits(updatedBenefits);
    }
    changeData(null);
  }, [data, newData, benefits, setBenefits, changeData]);

  const hasMoreBenefits = newData !== 0 && !benefitLoading && benefits.length >= limit * page;
  const showLoadingMore = !benefitLoading && page <= benefits.length / limit;

  const handleOpenStoresModal = () => {
    benefitListTracking({
      type: 'STORE',
      focusPath: focus,
      variant,
      userPlan: capitalize(variant),
    });

    setStoresFilterModalIsOpen(true);
  };
  const handleCloseStoresModal = () => {
    setStoresFilterModalIsOpen(false);
  };

  const handleOpenCategoriesModal = () => {
    benefitListTracking({
      type: 'CATEGORY',
      focusPath: focus,
      variant,
      userPlan: capitalize(variant),
    });

    setCategoriesFilterModalIsOpen(true);
  };
  const handleCloseCategoriesModal = () => {
    setCategoriesFilterModalIsOpen(false);
  };

  const handleApplyStoreFilterModal = (stores) => {
    setStoresFilter(stores);
    changePagination(1);

    const { initialValue, lastValue, isWithoutPoint } = pointsFilter;

    createFilterURL(path, {
      storesFilter: stores,
      categoriesFilter,
      initialValue,
      lastValue,
      isWithoutPoint,
      redeemedFilter,
      recentFilter,
    });
  };

  const handleApplyCategoryFilterModal = (categories) => {
    setCategoriesFilter(categories);
    changePagination(1);

    const { initialValue, lastValue, isWithoutPoint } = pointsFilter;

    createFilterURL(path, {
      storesFilter,
      categoriesFilter: categories,
      initialValue,
      lastValue,
      isWithoutPoint,
      redeemedFilter,
      recentFilter,
    });
  };

  const handleOpenPointsModal = () => {
    benefitListTracking({
      type: 'POINTS',
      focusPath: focus,
      variant,
      userPlan: capitalize(variant),
    });

    setPointsFilterModalIsOpen(true);
  };
  const handleClosePointsModal = () => {
    setPointsFilterModalIsOpen(false);
  };

  const handleApplyPointsFilterModal = (value) => {
    setPointsFilter(value);
    changePagination(1);

    const { initialValue, lastValue, isWithoutPoint } = value;

    createFilterURL(path, {
      storesFilter,
      categoriesFilter,
      initialValue,
      lastValue,
      isWithoutPoint,
      redeemedFilter,
      recentFilter,
    });
  };

  const pointsFilterActive = () => {
    if (pointsFilter.lastValue || pointsFilter.isWithoutPoint) return true;
    else return false;
  };

  const inputMaskNumber = (value) => {
    const valueFormated = parseFloat(value);
    const numString = valueFormated.toLocaleString('pt-BR');
    return numString;
  };

  const pointsFilterText = () => {
    if (pointsFilterActive()) {
      return pointsFilter.isWithoutPoint
        ? 'Benefícios sem pontos'
        : `De ${inputMaskNumber(pointsFilter.initialValue)} até ${inputMaskNumber(pointsFilter.lastValue)} pontos`;
    } else {
      return 'Filtro por pontos';
    }
  };

  const handleClickMoreRecent = (value) => {
    benefitListTracking({
      type: 'RECENT',
      focusPath: focus,
      variant,
      userPlan: capitalize(variant),
    });

    setRecentFilter(value);
    setRedeemedFilter(false);

    const { initialValue, lastValue, isWithoutPoint } = pointsFilter;

    changePagination(1);

    createFilterURL(path, {
      storesFilter,
      categoriesFilter,
      initialValue,
      lastValue,
      isWithoutPoint,
      redeemedFilter: false,
      recentFilter: value,
    });
  };

  const handleClickRedeemed = (value) => {
    if (value) {
      benefitListTracking({
        type: 'DATA',
        focusPath: focus,
        variant,
        userPlan: capitalize(variant),
      });
    }

    setRedeemedFilter(value);
    setRecentFilter(false);

    const { initialValue, lastValue, isWithoutPoint } = pointsFilter;

    changePagination(1);

    createFilterURL(path, {
      storesFilter,
      categoriesFilter,
      initialValue,
      lastValue,
      isWithoutPoint,
      redeemedFilter: value,
      recentFilter: false,
    });
  };

  const hasSomeFilterActive = () => {
    if (recentFilter || redeemedFilter || pointsFilterActive() || categoriesFilter.length > 0) return true;
    else return false;
  };

  const handleLetFilterTabsFixed = (waypointValue) => {
    const isFixed = waypointValue.currentPosition === 'above' ? true : false;
    setLetFilterTabsFixed(isFixed);
  };

  return {
    showLoadingMore,
    hasMoreBenefits,

    benefits,

    storesFilterModalIsOpen,
    storesFilter,
    categoriesFilterModalIsOpen,
    categoriesFilter,
    pointsFilterModalIsOpen,
    pointsFilter,
    recentFilter,
    redeemedFilter,
    isFilterTabsFixed,

    data,
    getMoreData,

    fetchMore,
    benefitLoading,

    handleOpenStoresModal,
    handleCloseStoresModal,
    handleOpenCategoriesModal,
    handleCloseCategoriesModal,
    handleApplyStoreFilterModal,
    handleApplyCategoryFilterModal,
    handleClosePointsModal,
    handleOpenPointsModal,
    handleApplyPointsFilterModal,
    handleClickRedeemed,
    handleClickMoreRecent,
    handleLetFilterTabsFixed,

    pointsFilterActive,
    hasSomeFilterActive,
    pointsFilterText,
  };
};
