import React from 'react';
import PropTypes from 'prop-types';
import { cold } from 'react-hot-loader';
import { Route } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';

import SignUpPhone from 'screens/signup/phone';
import FidelityHome from 'screens/apps/fidelity/home';

import { useCurrentShopping, useUserPlan } from 'hooks';

const VipRoute = ({ component: Component, location, ...rest }) => {
  const client = useApolloClient();
  const currentShopping = useCurrentShopping();
  const plan = useUserPlan();
  const { pathname } = location;
  const renderScreen = (props) => {
    if (!currentShopping) {
      client.writeData({ data: { pathname: '/apps/multivoce' } });
      return <SignUpPhone {...props} />;
    }

    if (pathname === 'apps/multivoce') {
      return <FidelityHome {...props} />;
    }

    return <Component {...props} planName={plan?.name} modality={plan?.name} />;
  };

  return <Route {...rest} render={(props) => renderScreen(props)} />;
};

VipRoute.defaultProps = {
  location: null,
};

VipRoute.propTypes = {
  component: PropTypes.object.isRequired,
  location: PropTypes.object,
  privateRoute: PropTypes.bool.isRequired,
};

export default cold(VipRoute);
