const getDCFee = ({ isAnything, currentCoupon, selectedDeliveryType, stores, price, items }) => {
  let total = 0;

  if (currentCoupon?.noFee || selectedDeliveryType?.noFee) return 0;

  if (isAnything) {
    if (stores.length > 0 && stores[0].hasContract) {
      return 0;
    }
    total = Number(price.replace(/[R$.,]/g, '')) / 100;
  } else {
    if (items[0]?.product.store.hasContract) {
      return 0;
    }
    total = items.map((i) => i.price * i.quantity).reduce((total, elem) => total + elem, 0);
  }
  const v10percent = total * 0.1;
  return v10percent < 20 ? v10percent : 20;
};

export default getDCFee;
