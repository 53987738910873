import { lazyWithPreload } from 'react-lazy-with-preload';

export const ParkingHome = lazyWithPreload(() =>
  import(/* webpackChunkName: "parking-home" */ 'screens/apps/parking/home'),
);
export const Onboarding = lazyWithPreload(() =>
  import(/* webpackChunkName: "parking-onboarding" */ 'screens/apps/parking/lpr/onboarding'),
);
export const LicensePlateRegistration = lazyWithPreload(() =>
  import(/* webpackChunkName: "parking-registration" */ 'screens/apps/parking/lpr/licensePlateRegistration'),
);
export const CreditCardInsertion = lazyWithPreload(() =>
  import(/* webpackChunkName: "parking-card-insertion" */ 'screens/apps/parking/lpr/creditCardInsertion'),
);
export const PaymentHistory = lazyWithPreload(() =>
  import(/* webpackChunkName: "parking-payment-history" */ 'screens/apps/parking/lpr/paymentHistory'),
);
export const RetryPayment = lazyWithPreload(() =>
  import(/* webpackChunkName: "parking-retry-payment" */ 'screens/apps/parking/lpr/retryPayment'),
);
export const PaymentReceipt = lazyWithPreload(() =>
  import(/* webpackChunkName: "parking-payment-receipt" */ 'screens/apps/parking/lpr/paymentReceipt'),
);
export const ParkingPlateManagement = lazyWithPreload(() =>
  import(/* webpackChunkName: "parking-license-plate-management" */ 'screens/apps/parking/lpr/licensePlateManagement'),
);
export const CreditCardEdition = lazyWithPreload(() =>
  import(/* webpackChunkName: "parking-card-edition" */ 'screens/apps/parking/lpr/creditCardEdition'),
);
export const FinishRegistrationScreen = lazyWithPreload(() =>
  import(/* webpackChunkName: "parking-finish-registration" */ 'screens/apps/parking/lpr/finishRegistration'),
);
export const ParkingRegions = lazyWithPreload(() =>
  import(/* webpackChunkName: "parking-regions" */ 'screens/apps/parking/lpr/parkingRegions'),
);
export const PaymentMethod = lazyWithPreload(() =>
  import(/* webpackChunkName: "parking-payment-method" */ 'screens/apps/parking/lpr/paymentMethod'),
);
export const LprUnregister = lazyWithPreload(() =>
  import(/* webpackChunkName: "parking-unregistration" */ 'screens/apps/parking/lpr/unregistration'),
);
export const ManageLicensePlates = lazyWithPreload(() =>
  import(/* webpackChunkName: "parking-manage-license-plates" */ 'screens/apps/parking/lpr/manageLicensePlates'),
);
export const VirtualTicketExternalPage = lazyWithPreload(() =>
  import(/* webpackChunkName: "parking-virtual-ticket-external" */ 'screens/apps/parking/virtualTicketExternal'),
);
export const VirtualTicket = lazyWithPreload(() =>
  import(/* webpackChunkName: "parking-virtual-ticket" */ 'screens/apps/parking/virtualTicket'),
);
export const HowItWorks = lazyWithPreload(() =>
  import(/* webpackChunkName: "parking-how-it-works" */ 'screens/apps/parking/lpr/howItWorks'),
);
export const OsasLicensePlates = lazyWithPreload(() =>
  import(/* webpackChunkName: "parking-osas-license-plates" */ 'screens/apps/parking/lpr/osasLicensePlates'),
);
export const PaymentRoute = lazyWithPreload(() =>
  import(
    /* webpackChunkName: "parking-payment-route" */ 'screens/apps/parking/lpr/paymentMethod/components/paymentRoute'
  ),
);

export default [
  {
    path: '/apps/estacionamento/home',
    exact: true,
    parking: true,
    component: ParkingHome,
  },
  {
    path: '/apps/estacionamento/lpr/onboarding',
    exact: true,
    parking: true,
    component: Onboarding,
    redirectToParkingHome: true,
  },
  {
    path: '/apps/estacionamento/lpr/cadastro-de-placa',
    exact: true,
    parking: true,
    component: LicensePlateRegistration,
    redirectToParkingHome: true,
  },
  {
    path: '/apps/estacionamento/lpr/metodo-de-pagamento/:action',
    exact: true,
    parking: true,
    component: PaymentMethod,
  },
  {
    path: '/apps/estacionamento/lpr/cadastro-de-cartão/:action',
    exact: true,
    parking: true,
    component: CreditCardInsertion,
    redirectToParkingHome: true,
  },
  {
    path: '/apps/estacionamento/lpr/conclusao-cadastro',
    exact: true,
    parking: true,
    component: FinishRegistrationScreen,
    redirectToParkingHome: true,
  },
  {
    path: '/apps/estacionamento/lpr/gerenciamento-de-veiculo/:action/:id',
    exact: true,
    parking: true,
    component: ParkingPlateManagement,
  },
  {
    path: '/apps/estacionamento/lpr/edicao-de-cartao',
    exact: true,
    parking: true,
    component: CreditCardEdition,
  },
  {
    path: '/apps/estacionamento/lpr/historico-pagamento/:failedPaymentDetails?',
    exact: true,
    parking: true,
    component: PaymentHistory,
  },
  {
    path: '/apps/estacionamento/lpr/retentativa/:id',
    exact: true,
    parking: true,
    component: RetryPayment,
  },
  {
    path: '/apps/estacionamento/lpr/recibo/:id',
    exact: true,
    parking: true,
    component: PaymentReceipt,
  },
  {
    path: '/apps/estacionamento/lpr/shoppings',
    exact: true,
    parking: true,
    component: ParkingRegions,
  },
  {
    path: '/apps/estacionamento/lpr/descadastro',
    exact: true,
    parking: true,
    component: LprUnregister,
  },
  {
    path: '/apps/estacionamento/lpr/gerenciar-veiculos',
    exact: true,
    parking: true,
    component: ManageLicensePlates,
  },
  {
    path: '/apps/estacionamento/lpr/como-funciona',
    exact: true,
    parking: true,
    component: HowItWorks,
  },
  {
    path: '/apps/estacionamento/virtual-ticket/:shoppingSlug',
    exact: true,
    component: VirtualTicketExternalPage,
  },
  {
    path: '/apps/estacionamento/virtual-ticket',
    exact: true,
    parking: true,
    component: VirtualTicket,
  },
  {
    path: '/apps/estacionamento/lpr/osas-license-plates',
    exact: true,
    component: OsasLicensePlates,
    parking: true,
  },
  {
    path: '/apps/estacionamento/lpr/pagamento',
    exact: true,
    component: PaymentRoute,
    parking: true,
  },
];
