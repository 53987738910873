import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getLocation, bugsnag } from 'utils';

const TrackingLocationContext = createContext({});

const TrackingLocationProvider = ({ children }) => {
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
  });

  const getCurrentPosition = async () => {
    try {
      const position = await getLocation();
      if (position) {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      }
    } catch (e) {
      setLocation({
        latitude: null,
        longitude: null,
      });
    }
  };

  useEffect(() => {
    getCurrentPosition();
    setInterval(getCurrentPosition, 300000);
  }, []);

  useEffect(() => {
    if (location.latitude && location.longitude) {
      try {
        window.register({
          mixpanel: {
            properties: {
              $latitude: location.latitude,
              $longitude: location.longitude,
              Latitude: location.latitude,
              Longitude: location.longitude,
              'Coordinates Date': new Date(),
            },
          },
        });
      } catch (error) {
        bugsnag?.notify(error);
      }
    }
  }, [location]);

  return <TrackingLocationContext.Provider>{children}</TrackingLocationContext.Provider>;
};

const useTrackingLocation = () => {
  const context = useContext(TrackingLocationContext);

  if (!context) {
    throw new Error("You don't have access to tracking location context");
  }

  return context;
};

TrackingLocationProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { TrackingLocationProvider, useTrackingLocation };
