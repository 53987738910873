import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoadingEllipsis from 'components/loadingEllipsis';
import { classNames } from 'utils';
import styles from './index.module.scss';

const ProgressiveImage = ({ src, className, withLoader, ...props }) => {
  const [imageLoaded, changeImageLoaded] = useState(false);

  return (
    <figure className={classNames(styles.root, className)}>
      <span className={classNames(styles.overlayImage, { [styles.loaded]: imageLoaded })}>
        {withLoader && <LoadingEllipsis />}
      </span>
      <img
        {...props}
        onLoad={() => {
          changeImageLoaded(true);
        }}
        src={src}
      />
    </figure>
  );
};

ProgressiveImage.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  withLoader: PropTypes.bool,
};

ProgressiveImage.defaultProps = {
  className: null,
  withLoader: true,
};

export default ProgressiveImage;
