import React, { createContext, useContext, useMemo } from 'react';
import { node } from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { STORES } from 'services/apollo/queries';
import { useCurrentShopping } from 'hooks';
import { compareValues } from 'utils';

export const FeaturedStoresContext = createContext();

const FeaturedStoresProvider = ({ children }) => {
  const currentShopping = useCurrentShopping(true);

  const { data = {} } = useQuery(STORES, {
    variables: {
      featured: true,
      shoppingId: currentShopping.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const filteredFeaturedStores = useMemo(
    () =>
      data?.stores
        ?.filter((item) => item.logoColor?.original)
        .sort(compareValues('name'))
        .splice(0, 16),
    [data?.stores],
  );

  const featuredStores = filteredFeaturedStores?.filter((store) => store?.gastronomy === false);
  const featuredRestaurants = filteredFeaturedStores?.filter((store) => store?.gastronomy === true);

  return (
    <FeaturedStoresContext.Provider value={{ featuredStores, featuredRestaurants }}>
      {children}
    </FeaturedStoresContext.Provider>
  );
};

const useStores = () => {
  const context = useContext(FeaturedStoresContext);

  if (!context) {
    throw new Error("You don't have access to featured stores context.");
  }

  return context;
};

export { FeaturedStoresProvider, useStores };

FeaturedStoresProvider.propTypes = {
  children: node.isRequired,
};
