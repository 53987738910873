import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { bugsnag, capitalize } from 'utils';
import { useCurrentShopping } from 'hooks';
import { useHistory } from 'react-router-dom';

import { STORES_LIST } from './graphql/queries';

export const useStoresFilterModal = ({ variant, initialValue, handleApplyFilterModal, onClose }) => {
  const currentShopping = useCurrentShopping(true);
  const history = useHistory();

  const [selectedStoreCodes, setSelectedStoreCodes] = useState(initialValue);
  const [selectedStoreNames, setSelectedStoreNames] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const [typingTimeout, setTypingTimeout] = useState(null);

  const pathname = history?.location.pathname;

  const source = pathname.includes('lista-de-beneficios') ? `${capitalize(variant)} benefit list` : 'home MVC';

  const { data: { benefitFilters } = {}, loading } = useQuery(STORES_LIST, {
    fetchPolicy: 'cache-and-network',
    variables: {
      shoppingSlug: currentShopping?.slug,
      modalities: [variant],
    },
  });

  const stores = benefitFilters ? benefitFilters?.stores.filter((store) => store !== null) : [];

  const filteredStores = useMemo(() => {
    const storesSearched = stores.filter(
      (store) => store && store.name.toLowerCase().includes(inputValue.toLowerCase()),
    );

    return storesSearched;
  }, [stores, inputValue]);

  const onChange = (event) => {
    const input = event.currentTarget.value;
    setInputValue(input);
    clearTimeout(typingTimeout);

    const newTimeout = setTimeout(() => {
      handleSearchedTracking(input);
    }, 1000);

    setTypingTimeout(newTimeout);
  };

  const handleSearchedTracking = (value) => {
    try {
      window.track({
        mixpanel: {
          name: 'Searched',
          properties: {
            'Search Query': value,
            'Search Source': source,
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
  };

  const handleClearInput = () => {
    setInputValue('');
  };

  const handleSelectStore = (store) => {
    handleClearInput();

    const activityValue = selectedStoreCodes.find((double) => double === store.code);

    if (activityValue) {
      setSelectedStoreCodes(selectedStoreCodes.filter((filterItem) => filterItem !== store.code));
      setSelectedStoreNames(selectedStoreNames.filter((filterItem) => filterItem !== store.name));
    } else {
      try {
        window.track({
          mixpanel: {
            name: 'Stores Filter Selected',
            properties: {
              Selection: store.name,
              Source: source,
            },
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }

      setSelectedStoreNames((old) => [...old, store.name]);
      setSelectedStoreCodes((old) => [...old, store.code]);
    }
  };

  const activeButton = (store) => {
    const activityValue = selectedStoreCodes.find((double) => double === store.code);

    if (activityValue) {
      return true;
    } else {
      return false;
    }
  };

  const activeApllyButton = () => {
    if (selectedStoreCodes.length !== initialValue.length) {
      return false;
    }

    const sortedArray1 = selectedStoreCodes.slice().sort();
    const sortedArray2 = initialValue.slice().sort();

    for (let i = 0; i < sortedArray1.length; i++) {
      if (sortedArray1[i] !== sortedArray2[i]) {
        return false;
      }
    }

    return true;
  };

  const handleClean = () => {
    setSelectedStoreCodes([]);
    setSelectedStoreNames([]);
    handleApplyFilterModal([]);

    onClose();
  };

  const handleApply = () => {
    try {
      window.track({
        mixpanel: {
          name: 'Stores Filter Applied',
          properties: {
            Selections: selectedStoreNames,
            Source: source,
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }

    handleApplyFilterModal(selectedStoreCodes);
    onClose();
  };

  return {
    benefitFilters,
    loading,

    selectedStoreCodes,
    inputValue,
    filteredStores,

    onChange,
    handleClearInput,
    handleSelectStore,
    activeButton,
    activeApllyButton,
    handleClean,
    handleApply,
  };
};
