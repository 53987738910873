import { lazy } from 'react';

const HomeSearch = lazy(() => import(/* webpackChunkName: "search" */ 'screens/search/homeSearch'));
const Search = lazy(() => import(/* webpackChunkName: "search" */ 'screens/search'));

export default [
  {
    path: '/onde-achar',
    exact: true,
    component: HomeSearch,
  },
  {
    path: '/onde-achar/:text/:category',
    exact: true,
    component: Search,
  },
];
