import { lazy } from 'react';

const newOnboarding = lazy(() => import(/* webpackChunkName: "newOnboard" */ 'screens/newOnboarding'));

export default [
  {
    path: '/novo-onboarding',
    exact: true,
    component: newOnboarding,
  },
];
