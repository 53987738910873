export const checkStoreBySegment = (store) => {
  return !store?.segments?.find((segment) => segment.name === 'Farmácia');
};

const isOpenStore = (open, closed) => {
  const today = new Date();
  const hour = parseInt(today.getHours());

  return hour >= open && hour <= closed;
};

export default isOpenStore;
