import React from 'react';
import { string, number, node } from 'prop-types';
import Slider from 'components/slider';
import { classNames } from 'utils';
import styles from './index.module.scss';
import Tag from '../tag';

const OfferSlider = ({ title, children, variant, className, itemsToShow, from }) => {
  return (
    <div className={classNames(styles.root, className)}>
      {title && from !== 'llvTread' && (
        <Tag variant={variant} className={styles.tag}>
          <strong>{title}</strong>
        </Tag>
      )}
      <Slider itemsToShow={itemsToShow} itemClassName={styles.item}>
        {children}
      </Slider>
    </div>
  );
};

OfferSlider.propTypes = {
  title: string,
  children: node.isRequired,
  variant: string,
  className: string,
  itemsToShow: number,
  from: string,
};

OfferSlider.defaultProps = {
  title: null,
  className: null,
  variant: 'primary',
  itemsToShow: 2.5,
  from: 'productShowCase',
};

export default OfferSlider;
