import { lazy } from 'react';

const Home = lazy(() => import(/* webpackChunkName: "medicalcenter" */ 'screens/apps/medicalCenter/home'));
const Clinic = lazy(() => import(/* webpackChunkName: "medicalcenter" */ 'screens/apps/medicalCenter/clinic'));
const Doctor = lazy(() => import(/* webpackChunkName: "medicalcenter" */ 'screens/apps/medicalCenter/doctor'));
const Search = lazy(() => import(/* webpackChunkName: "medicalcenter" */ 'screens/apps/medicalCenter/search'));

export default [
  {
    path: '/apps/centro-medico',
    exact: true,
    component: Home,
  },
  {
    path: '/apps/centro-medico/busca',
    exact: true,
    component: Search,
  },
  {
    path: '/apps/centro-medico/:slug',
    exact: true,
    component: Clinic,
  },
  {
    path: '/apps/centro-medico/doctor/:slug',
    exact: true,
    component: Doctor,
  },
];
