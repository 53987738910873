import React, { useCallback, useState, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { classNames } from 'utils';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/css';
import ReactHtmlParser from 'react-html-parser';

import { ONBOARDINGSTEPS } from './graphql/queries';

import ArrowRight from 'icons/arrow-right.svg';
import BannerIcon from 'icons/bannerIcon.svg';

import Button from '../../../components/button';
import ProgressiveImage from 'components/progressiveImage';
import ButtonTabs from '../../../components/buttonTabs';
import useCurrentShopping from '../../../hooks/useCurrentShopping';
import TutorialWrapper from '../components/tutorialWrapper';
import BottomCard from '../components/bottomCard';
import IconBox from '../components/IconBox';
import BannerBox from '../components/bannerBox';

import styles from './index.module.scss';
import FinishOnboarding from '../finish';

const Tutorial = () => {
  const [step, setStep] = useState(0);
  const [onboardingDone, setOnboardingDone] = useState();
  const history = useHistory();
  const currentShopping = useCurrentShopping();

  const { data, loading } = useQuery(ONBOARDINGSTEPS, {
    variables: { shoppingsIds: [currentShopping?.id] },
    onCompleted: (data) => {
      if (!data?.onboardingSteps?.length) {
        history.push('/showcase');
      }
    },
  });
  const handleTabClick = useCallback((index) => setStep(index), [step]);

  const handleNextClick = useCallback(() => {
    if (step === data?.onboardingSteps.length - 1) {
      setOnboardingDone(true);
    }
    setStep(step + 1);
  }, [step, history, data?.onboardingSteps]);

  const onboardingStep = useMemo(
    () => data?.onboardingSteps.find((item) => item.order === step + 1 && item),
    [data?.onboardingSteps, step],
  );

  if (loading) return null;
  if (onboardingDone)
    return (
      <TutorialWrapper>
        <FinishOnboarding />
      </TutorialWrapper>
    );
  return (
    <TutorialWrapper>
      {onboardingStep?.entity?.icon ? (
        <IconBox
          title={onboardingStep?.entity?.name}
          description={ReactHtmlParser(onboardingStep?.subtitle)}
          icon={onboardingStep?.entity?.icon?.original}
        />
      ) : (
        <BannerBox
          banner={onboardingStep?.entity?.cover?.original}
          description={ReactHtmlParser(onboardingStep?.subtitle)}
        />
      )}
      <BottomCard>
        <ButtonTabs
          tabs={data?.onboardingSteps.length}
          activeIndex={step}
          onTabChange={(index) => handleTabClick(index)}
          className={classNames(
            styles.buttonTabs,
            onboardingStep?.order === step + 1 &&
              css`
                &:nth-of-type(${onboardingStep?.order}) {
                  background-color: ${onboardingStep?.entity?.color ? onboardingStep?.entity?.color : '#008258'};
                }
              `,
          )}
        />
        <div className={styles.bottomBox}>
          <div className={styles.infoBox}>
            {onboardingStep?.entity?.__typename === 'Banner' ? (
              <BannerIcon className={styles.infoIcon} />
            ) : (
              <ProgressiveImage
                withLoader={false}
                className={styles.infoIcon}
                src={
                  onboardingStep?.icon?.original
                    ? onboardingStep?.icon?.original
                    : onboardingStep?.entity?.icon?.original
                }
              />
            )}
            <h3 className={classNames(styles.description)} style={{ color: onboardingStep?.entity?.color }}>
              {ReactHtmlParser(onboardingStep?.description)}
            </h3>
          </div>
          <Button round type="primary" className={styles.buttonNext} onClick={handleNextClick}>
            <ArrowRight className={styles.arrow} />
          </Button>
        </div>
      </BottomCard>
    </TutorialWrapper>
  );
};

export default Tutorial;
