import React, { useState, useEffect } from 'react';
import { node, number, string } from 'prop-types';

import Typography from 'components/typography';
import Flex from 'components/flex';

const Item = ({ children }) => {
  return (
    <Flex direction="column" gap="8px" style={{ width: '100vw' }}>
      {children}
    </Flex>
  );
};

Item.propTypes = { children: node.isRequired };

const Title = ({ children }) => (
  <Typography variant="body1" align="center" style={{ fontWeight: '600' }}>
    {children}
  </Typography>
);
Title.propTypes = { children: node.isRequired };

const Subtitle = ({ children }) => (
  <Typography variant="body1" align="center">
    {children}
  </Typography>
);
Subtitle.propTypes = { children: node.isRequired };

const Ticker = ({ children, interval, width }) => {
  const items = React.Children.toArray(children);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, interval);

    return () => clearInterval(timer);
  }, [items.length, interval]);

  return (
    <div style={{ width, position: 'relative' }}>
      {items.map((item, idx) => (
        <div
          key={idx}
          style={{
            position: 'absolute',
            transition: 'opacity 0.3s ease',
            opacity: idx === index ? 1 : 0,
            height: '100%',
          }}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

Ticker.propTypes = {
  interval: number,
  width: string,
  children: node.isRequired,
};
Ticker.defaultProps = {
  interval: 3000,
  width: '100%',
};

const WordTicker = Ticker;
WordTicker.Item = Item;
WordTicker.Title = Title;
WordTicker.Subtitle = Subtitle;

export default WordTicker;
