import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { SELECTED_MOVIE_DATE } from 'services/apollo/queries';
import { classNames, bugsnag } from 'utils';

import styles from './index.module.scss';

const MovieCard = ({ movie, basepath, index, date, small, className, tracking }) => {
  const client = useApolloClient();
  const history = useHistory();
  const handleClick = useCallback(() => {
    if (date) {
      const { selectedMovieDate } = client.readQuery({ query: SELECTED_MOVIE_DATE });
      client.writeQuery({
        query: SELECTED_MOVIE_DATE,
        data: {
          selectedMovieDate: { ...selectedMovieDate, movieDate: date.toString() },
        },
      });
    }
    try {
      window.track({
        firebase: {
          name: 'view_movie',
          properties: {
            type: 'event',
            eventCategory: 'movies',
            eventAction: 'click',
            eventLabel: movie.name,
          },
        },
        mixpanel: {
          name: 'Viewed Movie',
          properties: {
            'Movie Name': movie.name,
          },
        },
        googleTagManager: {
          global: {
            name: 'viewedMovie',
            properties: {
              movieName: movie.name,
            },
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
    tracking();
    history.push(`${basepath}/${movie.slug}`);
  }, [date, basepath, movie]);

  const isPair = index % 2 === 0;

  return useMemo(
    () => (
      <div className={classNames(styles.movie, 'movie-card', className)}>
        <button
          className={[
            small ? styles.small : null,
            (typeof index !== 'undefined' && isPair && styles.pair) || (index && styles.odd) || null,
          ].join(' ')}
          onClick={handleClick}
        >
          <img src={movie.images && movie.images[0]} />
          <div className={styles.mask}>
            <div className={styles.text}>{movie.name}</div>
          </div>
        </button>
      </div>
    ),
    [movie, className, index, basepath, small, handleClick],
  );
};

MovieCard.propTypes = {
  small: PropTypes.bool,
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  index: PropTypes.number,
  client: PropTypes.object,
  history: PropTypes.object,
  movie: PropTypes.object.isRequired,
  basepath: PropTypes.string.isRequired,
  className: PropTypes.string,
  tracking: PropTypes.func,
};

MovieCard.defaultProps = {
  small: false,
  date: null,
  className: null,
  index: undefined,
  tracking: () => null,
};

export default MovieCard;
