import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { bugsnag, trackClickedClaimedBenefit, getCurrentPath } from 'utils';
import { useCurrentShopping, useUserPlan, useUser, usePromotionToast, useCountBenefits } from 'hooks';
import { BENEFITS_COUNT, COUNT_USED_BENEFITS } from '../benefits/graphql/queries';

import { getFilterURLParams } from './utils/getFilterURLParams';

export const useFidelityHome = () => {
  const plan = useUserPlan();
  const user = useUser();
  const client = useApolloClient();
  const history = useHistory();
  const { countBenefits, setCountBenefits } = useCountBenefits();

  const currentShopping = useCurrentShopping(true);

  const [showFidelityModal, setShowFidelityModal] = useState(false);
  const [tutorialModal, setTutorialModal] = useState(false);

  const [showShoppingDisplay, setShowShoppingDisplay] = useState(true);

  const { filter } = useParams();
  const { scrollToFilter } = getFilterURLParams(filter);

  const variant = plan.variant;
  const variantStyle = plan?.name ? variant : 'visitant-green';

  const destinyBackButton = history?.location?.state?.lastRoute || '/showcase';

  const isLogged = !!user && !!plan;

  const { data: { countUsedBenefits = 0 } = {}, loading: countUsedBenefitsLoading } = useQuery(COUNT_USED_BENEFITS, {
    variables: {
      shoppingSlug: currentShopping?.slug,
      statusesValues: ['issued'],
    },
    skip: !user,
  });

  const { data: benefitsAdvantageCount = {}, loading: benefitsAdvantageCountLoading } = useQuery(BENEFITS_COUNT, {
    variables: {
      shoppingSlug: currentShopping?.slug,
      modalities: [variant],
      type: 'DISPLAY_ONLY',
    },
  });

  useQuery(BENEFITS_COUNT, {
    fetchPolicy: 'network-only',
    variables: {
      shoppingSlug: currentShopping?.slug,
      statusesValues: ['issued'],
      modalities: [plan.variant],
      onlyFavorites: true,
    },
    onCompleted: (data) => setCountBenefits(data.countBenefits),
  });

  const fidelityPageLoading = countUsedBenefitsLoading || benefitsAdvantageCountLoading;

  usePromotionToast();

  const handleCloseFidelityModal = () => {
    setShowFidelityModal(false);
  };

  const goToStatement = () => {
    try {
      window.track({
        mixpanel: {
          name: 'Clicked Points History',
          properties: {
            Source: 'Home MVC',
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
    history.push('/apps/multivoce/consulta');
  };

  const goToFavorite = () => {
    history.push('/apps/multivoce/meusbeneficios/favorite');
  };

  const goToClaimed = () => {
    trackClickedClaimedBenefit();
    history.push('/apps/multivoce/meusbeneficios/claimed');
  };

  const goToReadyToUse = () => {
    history.push('/apps/multivoce/meusbeneficios/ready');
  };

  const goToUsed = () => {
    history.push('/apps/multivoce/meusbeneficios/used');
  };

  const sendTrackingClickedSeeInvoices = () => {
    try {
      window.track({
        mixpanel: {
          name: 'Clicked See Invoices',
        },
        googleTagManager: {
          global: {
            name: 'clickedSeeInvoices',
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
  };

  const handleSeeInvoices = () => {
    sendTrackingClickedSeeInvoices();
    history.push('/apps/multivoce/ver-notas');
  };

  const handleCloseLoginModal = useCallback(() => {
    history.push('/apps/multivoce/');
  });

  function handleGoToSignIn() {
    const currentPath = getCurrentPath();
    client.writeData({ data: { pathname: currentPath } });
    history.push('/signup/phone');
  }

  const handleCloseTutorial = () => {
    setTutorialModal(false);
    multiStorage.setItem('multivc-tutorial-value', false);
  };

  const getTuturialValue = () => {
    const isTuturialActive = multiStorage.getItem('multivc-tutorial-value');

    const lastRoute = sessionStorage.getItem('lastRoute');
    const lastRouteObj = JSON.parse(lastRoute);
    const comesFromBenefit = lastRouteObj?.route.includes('/apps/multivoce/beneficios/tickets/');
    const comesFromMarketingPlace = history?.location?.state?.lastRoute === '/apps/marketplace';

    if (!isTuturialActive && !comesFromBenefit && !comesFromMarketingPlace) {
      setTutorialModal(true);
      multiStorage.setItem('multivc-tutorial-value', true);
      try {
        window.track({
          mixpanel: {
            name: 'Tutorial Displayed',
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }
    } else {
      setTutorialModal(JSON.parse(isTuturialActive));
    }
  };

  const handleHideShoppingDisplay = () => {
    setShowShoppingDisplay(false);
  };
  const handleShowShoppingDisplay = () => {
    setShowShoppingDisplay(true);
  };

  useEffect(() => {
    getTuturialValue();
  }, []);

  const scrollToDivFilter = () => {
    setTimeout(() => {
      var element = document.getElementById('benefitFilters');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 2000);
  };

  useEffect(() => {
    if (scrollToFilter && !fidelityPageLoading) scrollToDivFilter();
  }, [scrollToFilter, fidelityPageLoading]);

  return {
    variant,
    variantStyle,
    isLogged,
    destinyBackButton,
    showShoppingDisplay,

    fidelityPageLoading,

    showFidelityModal,
    countBenefits,
    tutorialModal,

    countUsedBenefits,
    benefitsAdvantageCount,

    handleCloseFidelityModal,
    goToStatement,
    goToFavorite,
    goToClaimed,
    goToReadyToUse,
    goToUsed,
    handleHideShoppingDisplay,
    handleShowShoppingDisplay,

    handleSeeInvoices,
    handleCloseLoginModal,
    handleGoToSignIn,
    handleCloseTutorial,
  };
};
