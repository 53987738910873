import { lazy } from 'react';

const Chat = lazy(() => import(/* webpackChunkName: "marketplace-order" */ 'screens/apps/marketplace/chat'));
const Order = lazy(() => import(/* webpackChunkName: "marketplace-order" */ 'screens/apps/marketplace/order'));
const StoreCategories = lazy(() =>
  import(/* webpackChunkName: "marketplace-order" */ 'screens/apps/marketplace/order/storeCategories'),
);
const OrderList = lazy(() => import(/* webpackChunkName: "marketplace-order" */ 'screens/apps/marketplace/orderList'));
const OrderDetail = lazy(() =>
  import(/* webpackChunkName: "marketplace-order" */ 'screens/apps/marketplace/orderList/detail'),
);
const OrderWaiting = lazy(() =>
  import(/* webpackChunkName: "marketplace-order" */ 'screens/apps/marketplace/order/waiting'),
);
const OrderAccepted = lazy(() =>
  import(/* webpackChunkName: "marketplace-order" */ 'screens/apps/marketplace/order/accepted'),
);
const ShippingRules = lazy(() =>
  import(/* webpackChunkName: "marketplace-order" */ 'screens/apps/marketplace/order/shippingRules'),
);
const PickupStore = lazy(() =>
  import(/* webpackChunkName: "marketplace-order" */ 'screens/apps/marketplace/order/pickupStore'),
);
const GoingOutScreen = lazy(() =>
  import(/* webpackChunkName: "marketplace-order" */ 'screens/apps/marketplace/order/goingOutScreen'),
);
const CallbackStore = lazy(() =>
  import(/* webpackChunkName: "marketplace-order" */ 'screens/apps/marketplace/order/callbackStore'),
);
const ChooseProduct = lazy(() =>
  import(/* webpackChunkName: "marketplace-order" */ 'screens/apps/marketplace/order/chooseProduct'),
);
const ContactStore = lazy(() =>
  import(/* webpackChunkName: "marketplace-order" */ 'screens/apps/marketplace/order/contactStore'),
);
const OrderDetails = lazy(() =>
  import(/* webpackChunkName: "marketplace-order" */ 'screens/apps/marketplace/order/orderDetails'),
);
const NotFoundProducts = lazy(() =>
  import(/* webpackChunkName: "marketplace-order" */ 'screens/apps/marketplace/order/notFoundProducts'),
);

const Home = lazy(() => import(/* webpackChunkName: "marketplace" */ 'screens/apps/marketplace/home'));
const Search = lazy(() => import(/* webpackChunkName: "marketplace" */ 'screens/showcase/search'));
const Store = lazy(() => import(/* webpackChunkName: "marketplace" */ 'screens/apps/marketplace/store'));
const ProductSearch = lazy(() => import(/* webpackChunkName: "marketplace" */ 'screens/apps/marketplace/store/search'));
const Category = lazy(() => import(/* webpackChunkName: "marketplace" */ 'screens/apps/marketplace/category'));
const CategorySearch = lazy(() =>
  import(/* webpackChunkName: "marketplace" */ 'screens/apps/marketplace/category/search'),
);
const Product = lazy(() => import(/* webpackChunkName: "marketplace" */ 'screens/apps/marketplace/product'));
const StoreList = lazy(() => import(/* webpackChunkName: "marketplace" */ 'screens/apps/marketplace/storeList'));
const StoreSearch = lazy(() =>
  import(/* webpackChunkName: "marketplace" */ 'screens/apps/marketplace/storeList/search'),
);
const CategoriesFilter = lazy(() =>
  import(/* webpackChunkName: "marketplace" */ 'screens/apps/marketplace/storeList/categoriesFilter'),
);
const DeliveryMap = lazy(() => import(/* webpackChunkName: "marketplace" */ 'screens/apps/marketplace/deliveryMap'));
const Checkout = lazy(() => import(/* webpackChunkName: "marketplace-order" */ 'screens/checkout'));
const afterSale = lazy(() => import(/* webpackChunkName: "marketplace-order" */ 'screens/afterSale'));

const AnythingTutorial = lazy(() =>
  import(/* webpackChunkName: "marketplace-order" */ 'screens/apps/marketplace/order/anythingTutorial'),
);

const CheckoutTutorial = lazy(() =>
  import(/* webpackChunkName: "marketplace-order" */ 'screens/apps/marketplace/order/checkoutTutorial'),
);

export default [
  {
    path: '/apps/marketplace',
    exact: true,
    component: Home,
  },
  {
    path: '/apps/marketplace/stores/:slug',
    exact: true,
    component: Store,
  },
  {
    path: '/apps/marketplace/stores/:slug/checkout',
    exact: true,
    component: Checkout,
  },
  {
    path: '/apps/marketplace/stores/:slug/search/:storeId',
    exact: true,
    component: ProductSearch,
  },
  {
    path: '/apps/marketplace/stores/:slug/search/:storeId/:categoryId',
    exact: true,
    component: ProductSearch,
  },
  {
    path: '/apps/marketplace/categories/:slug',
    exact: true,
    component: Category,
  },
  {
    path: '/apps/marketplace/categories/:slug/checkout',
    exact: true,
    component: Checkout,
  },
  {
    path: '/apps/marketplace/categories/:slug/search/:categoryId',
    exact: true,
    component: CategorySearch,
  },
  {
    path: '/apps/marketplace/categories/:slug/search/:categoryId/:subCategoryId',
    exact: true,
    component: CategorySearch,
  },
  {
    path: '/order',
    exact: true,
    component: Order,
  },
  {
    path: '/order/categories',
    exact: true,
    component: StoreCategories,
  },
  {
    path: '/orders/:id/waiting',
    component: OrderWaiting,
  },
  {
    path: '/orders/:id/accepted',
    component: OrderAccepted,
  },
  {
    path: '/shipping-rules',
    component: ShippingRules,
  },
  {
    path: '/orders',
    exact: true,
    component: OrderList,
  },
  {
    path: '/orders/:id',
    exact: true,
    component: OrderDetail,
  },
  {
    path: '/orders/:id/pos-venda',
    exact: true,
    component: afterSale,
  },
  {
    path: '/orders/:id/delivery-map',
    exact: true,
    component: DeliveryMap,
  },
  {
    path: '/orders/:id/chat',
    exact: true,
    component: Chat,
  },
  {
    path: '/product/:sku',
    exact: true,
    component: Product,
  },
  {
    path: '/apps/marketplace/lojas',
    exact: true,
    component: StoreList,
  },
  {
    path: '/apps/marketplace/lojas/busca',
    exact: true,
    component: StoreSearch,
  },
  {
    path: '/apps/marketplace/lojas/categorias',
    exact: true,
    component: CategoriesFilter,
  },
  {
    path: '/apps/marketplace/lojas/:slug/checkout',
    exact: true,
    component: Checkout,
  },
  {
    path: '/apps/marketplace/lojas/:slug/search/:storeId',
    exact: true,
    component: ProductSearch,
  },
  {
    path: '/apps/marketplace/lojas/:slug/search/:storeId/:categoryId',
    exact: true,
    component: ProductSearch,
  },
  {
    path: '/apps/marketplace/lojas/:slug/:tab?',
    exact: true,
    component: Store,
  },
  {
    path: '/apps/marketplace/busca',
    exact: true,
    component: Search,
  },
  {
    path: '/apps/peca-qualquer-coisa',
    exact: true,
    component: Order,
  },
  {
    path: '/apps/checkout-tutorial',
    exact: true,
    component: CheckoutTutorial,
  },
  {
    path: '/apps/peca-qualquer-coisa/introducao',
    exact: true,
    component: AnythingTutorial,
  },
  {
    path: '/apps/peca-qualquer-coisa/categorias',
    exact: true,
    component: StoreCategories,
  },
  {
    path: '/apps/peca-qualquer-coisa/contato-loja',
    exact: true,
    component: ContactStore,
  },
  {
    path: '/apps/peca-qualquer-coisa/detalhes-pedido',
    exact: true,
    component: OrderDetails,
  },
  {
    path: '/apps/peca-qualquer-coisa/escolha-produto',
    exact: true,
    component: ChooseProduct,
  },
  {
    path: '/apps/peca-qualquer-coisa/falar-com-a-loja',
    exact: true,
    component: GoingOutScreen,
  },
  {
    path: '/apps/peca-qualquer-coisa/comprar-pessoalmente',
    exact: true,
    component: PickupStore,
  },
  {
    path: '/apps/peca-qualquer-coisa/falei-com-a-loja',
    exact: true,
    component: CallbackStore,
  },
  {
    path: '/apps/peca-qualquer-coisa/nao-tem-o-produto',
    exact: true,
    component: NotFoundProducts,
  },
  {
    path: '/checkout',
    exact: true,
    component: Checkout,
  },
  {
    path: '/checkout',
    exact: true,
    component: Checkout,
  },
];
