import React from 'react';
import PropTypes from 'prop-types';
import ImageEmptyTicket from './images/empty-ticket.svg';
import FilterFile from './images/filter-file.svg';
import ImageEmptyBenefit from './images/empty-benefit.svg';
import styles from './index.module.scss';

const EmptyBenefits = ({ focus, hasFilter }) => {
  if (focus === 'Home' || focus === 'claimed') {
    if (hasFilter) {
      return (
        <div className={styles.filterContainer}>
          <FilterFile className={styles.filterSvg} />
          <strong className={styles.textFilterStrong}>
            Não encontramos benefícios para <br /> os filtros selecionados
          </strong>
          <span className={styles.textFilter}>
            Tente uma nova pesquisa para <br /> exibir outros resultados
          </span>
        </div>
      );
    } else {
      return (
        <div className={styles.container}>
          <ImageEmptyTicket />
          <strong className={styles.textStrong}>
            Estamos preparando <br /> os melhores benefícios para você!
          </strong>
          <span className={styles.text}>Aguarde! Novidades em breve.</span>
        </div>
      );
    }
  }
  if (focus === 'filter') {
    return (
      <div className={styles.container}>
        <FilterFile />
        <strong className={styles.textStrong}>Não encontramos benefícios para os filtros selecionados</strong>
        <span className={styles.text}>Tente uma nova pesquisa para exibir outros resultados</span>
      </div>
    );
  }
  if (focus === 'favorite') {
    return (
      <div className={styles.container}>
        <ImageEmptyBenefit />
        <strong className={styles.textStrong}>
          Nenhum benefício foi <br /> adicionado aos seus favoritos
        </strong>
      </div>
    );
  }
  if (focus === 'ready') {
    return (
      <div className={styles.container}>
        <ImageEmptyBenefit />
        <strong className={styles.textStrong}>
          Você não possui <br /> benefícios a serem utilizados
        </strong>
      </div>
    );
  }
  if (focus === 'used') {
    return (
      <div className={styles.container}>
        <ImageEmptyBenefit />
        <strong className={styles.textStrong}>
          Você ainda não utilizou <br /> nenhum benefício
        </strong>
      </div>
    );
  }
  return <></>;
};

EmptyBenefits.propTypes = {
  focus: PropTypes.string.isRequired,
  hasFilter: PropTypes.bool,
};

EmptyBenefits.defaultProps = {
  hasFilter: false,
};

export default EmptyBenefits;
