import { bugsnag } from 'utils';

export const benefitListTracking = ({ type, focusPath, variant, userPlan }) => {
  switch (type) {
    case 'STORE':
      if (focusPath === 'home') {
        try {
          window.track({
            mixpanel: {
              name: 'Clicked Stores Filter',
              properties: {
                Source: 'home MVC',
                Modality: variant,
              },
            },
          });
        } catch (e) {
          bugsnag?.notify(e);
        }
      } else {
        try {
          window.track({
            mixpanel: {
              name: 'Clicked Stores Filter',
              properties: {
                Source: `${userPlan} benefit List`,
              },
            },
          });
        } catch (e) {
          bugsnag?.notify(e);
        }
      }
      break;
    case 'CATEGORY':
      if (focusPath === 'home') {
        try {
          window.track({
            mixpanel: {
              name: 'Clicked Categories Filter',
              properties: {
                Source: 'home MVC',
                Modality: variant,
              },
            },
          });
        } catch (e) {
          bugsnag?.notify(e);
        }
      } else {
        try {
          window.track({
            mixpanel: {
              name: 'Clicked Categories Filter',
              properties: {
                Source: `${userPlan} benefit List`,
              },
            },
          });
        } catch (e) {
          bugsnag?.notify(e);
        }
      }
      break;
    case 'POINTS':
      if (focusPath === 'home') {
        try {
          window.track({
            mixpanel: {
              name: 'Clicked Points Filter',
              properties: {
                Source: 'home MVC',
                Modality: variant,
              },
            },
          });
        } catch (e) {
          bugsnag?.notify(e);
        }
      } else {
        try {
          window.track({
            mixpanel: {
              name: 'Clicked Points Filter',
              properties: {
                Source: `${userPlan} benefit List`,
              },
            },
          });
        } catch (e) {
          bugsnag?.notify(e);
        }
      }
      break;
    case 'RECENT':
      if (focusPath === 'home') {
        try {
          window.track({
            mixpanel: {
              name: 'Clicked Update Date Sort',
              properties: {
                Source: 'home MVC',
                Modality: variant,
              },
            },
          });
        } catch (e) {
          bugsnag?.notify(e);
        }
      } else {
        try {
          window.track({
            mixpanel: {
              name: 'Clicked Update Date Sort',
              properties: {
                Source: `${userPlan} benefit List`,
              },
            },
          });
        } catch (e) {
          bugsnag?.notify(e);
        }
      }
      break;
    case 'DATA':
      if (focusPath === 'home') {
        try {
          window.track({
            mixpanel: {
              name: 'Clicked Redemption Date Sort',
              properties: {
                Source: 'home MVC',
                Modality: variant,
              },
            },
          });
        } catch (e) {
          bugsnag?.notify(e);
        }
      } else {
        try {
          window.track({
            mixpanel: {
              name: 'Clicked Redemption Date Sort',
              properties: {
                Source: `${userPlan} benefit List`,
              },
            },
          });
        } catch (e) {
          bugsnag?.notify(e);
        }
      }
      break;
    default:
      break;
  }
};
