import { lazy } from 'react';

const Home = lazy(() => import(/* webpackChunkName: "news" */ 'screens/apps/news/home'));
const News = lazy(() => import(/* webpackChunkName: "news" */ 'screens/apps/news/news'));

export default [
  {
    path: '/apps/dicas-e-novidades',
    exact: true,
    component: Home,
  },
  {
    path: '/apps/dicas-e-novidades/:slug',
    exact: true,
    component: News,
  },
];
