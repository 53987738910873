const formatBenefitDescription = ({ benefit, showAll = false }) => {
  const description = showAll && benefit.type === 'DISPLAY_ONLY' ? benefit.description : benefit.title;
  if (!description) return '';
  if (showAll) {
    return description;
  } else if (description.length > 65) {
    return `${description.slice(0, 62)}...`;
  } else {
    return description.slice(0, 65);
  }
};

export default formatBenefitDescription;
