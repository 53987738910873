import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import Modal from 'components/modal';

import CloseIcon from 'icons/times.svg';

import { useFilterByPointsModal } from './useFilterByPointsModal';

import styles from './index.module.scss';

export const FilterByPointsModal = ({ isOpen, onClose, variant, handleApplyPointsFilterModal, initialValue }) => {
  const {
    firstInput,
    secondInput,
    isChecked,
    errorInputs,

    inputMaskNumber,
    handleFirstInput,
    handleSecondInput,
    handleClean,
    handleCheck,
    handleApply,
    isButtonCleanDisabled,
    isButtonDisabled,
  } = useFilterByPointsModal({
    onClose,
    variant,
    handleApplyPointsFilterModal,
    startValue: initialValue,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={classNames(styles.modal, styles[variant])} slide>
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <div />
          <h2 className={styles.modalTitle}>Filtro por pontos</h2>
          <button className={styles.modalCloseButton} onClick={onClose}>
            <CloseIcon className={styles.closeIcon} />
          </button>
        </div>

        <div className={styles.modalContent}>
          <div className={styles.checkContainer} onClick={handleCheck}>
            <div className={styles.checkBorder}>
              <div className={styles.check} data-checked={isChecked} />
            </div>
            <span className={styles.checkText}> Somente benefícios sem pontos</span>
          </div>
          <div className={styles.inputPointsContainer}>
            <span className={styles.inputText}>De</span>
            <input
              type="text"
              inputMode="decimal"
              placeholder="0"
              onChange={(e) => handleFirstInput(e.target.value)}
              value={inputMaskNumber(firstInput)}
              className={styles.input}
              data-error={errorInputs}
              data-testid="firstInputPointsFilter"
            />
            <span className={styles.inputText}>até</span>
            <input
              type="text"
              inputMode="decimal"
              placeholder="0"
              onChange={(e) => handleSecondInput(e.target.value)}
              value={inputMaskNumber(secondInput)}
              className={styles.input}
              data-error={errorInputs}
              data-testid="secondInputPointsFilter"
            />
            <span className={styles.inputText}>pontos</span>
          </div>
          {errorInputs && <span className={styles.errorText}>O menor valor deve ser indicado no primeiro campo</span>}

          <div className={styles.buttonsContainer}>
            <button
              className={styles.cleanButton}
              onClick={handleClean}
              disabled={isButtonCleanDisabled()}
              data-testid="cleanPointsFilter"
            >
              Limpar seleção
            </button>
            <button
              className={styles.applyButton}
              disabled={isButtonDisabled()}
              onClick={handleApply}
              data-testid="applyPointsFilter"
            >
              APLICAR
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

FilterByPointsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
  handleApplyPointsFilterModal: PropTypes.func.isRequired,
  initialValue: PropTypes.object.isRequired,
};
