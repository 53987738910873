import { create } from 'zustand';

const countBenefitsZustand = create((set) => ({
  countBenefits: 0,
  increment: () => set((state) => ({ countBenefits: state.countBenefits + 1 })),
  decrease: () => set((state) => ({ countBenefits: state.countBenefits - 1 })),
  setCountBenefits: (state) => set({ countBenefits: state }),
}));

export const useCountBenefits = () => {
  const setCountBenefits = countBenefitsZustand((state) => state.setCountBenefits);
  const increment = countBenefitsZustand((state) => state.increment);
  const decrease = countBenefitsZustand((state) => state.decrease);
  const countBenefits = countBenefitsZustand((state) => state.countBenefits);

  return { countBenefits, setCountBenefits, increment, decrease };
};
