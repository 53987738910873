import { bugsnag } from 'utils';

const clickedTreadSeeMoreTracking = (slug) => {
  try {
    window.track({
      mixpanel: {
        name: 'Tread Showcase - see more',
        properties: {
          'Tread Name': slug,
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

const clickedTreadItemTracking = (treadName, title, position, llvStore) => {
  const isLlv = treadName === 'lapis-vermelho';

  let properties = {
    'Tread Name': treadName,
    Title: title,
    Position: position + 1,
  };

  if (isLlv) {
    properties = { ...properties, 'Store name': llvStore };
  }

  try {
    window.track({
      mixpanel: {
        name: 'Tread Showcase',
        properties,
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

const clickedTreadFloatingButtonTracking = (type) => {
  try {
    window.track({
      mixpanel: {
        name: 'Floating button showcase',
        properties: {
          Type: type,
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

const clickedWelcomeBanner = () => {
  try {
    window.track({
      mixpanel: {
        name: 'Clicked Fidelity Showcase',
      },
      googleTagManager: {
        global: {
          name: 'clickedFidelityShowcase',
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

export {
  clickedTreadSeeMoreTracking,
  clickedTreadItemTracking,
  clickedTreadFloatingButtonTracking,
  clickedWelcomeBanner,
};
