const hasWebView = () =>
  !!window?.MultiShoppingAndroid?.openExternalContent ||
  !!window?.webkit?.messageHandlers?.openExternalContent?.postMessage ||
  false;

const openWebView = (url) =>
  window?.MultiShoppingAndroid?.openExternalContent?.(url) ||
  window?.webkit?.messageHandlers?.openExternalContent?.postMessage?.(url);

const openBrowser = (url, target = '_blank') => window?.open?.(url, target);

const openExternalContent = (url, { forceBrowser = false, target = '_blank' } = {}) =>
  hasWebView() && !forceBrowser ? openWebView(url) : openBrowser(url, target);

export default openExternalContent;
