import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { create } from 'zustand';

import { groupingTransactionsByMonth, bugsnag } from 'utils';

import { PARKING_TRANSACTIONS } from 'screens/apps/parking/graphql/queries';

export const usePayments = create((set) => ({
  payments: [],
  setPayments: (payments) => set(() => ({ payments: [...payments] })),
  currentPosition: 0,
  setCurrentPosition: (currentPosition) => set(() => ({ currentPosition })),
}));

export const usePaymentHistory = () => {
  const { payments, setPayments, setCurrentPosition } = usePayments();
  const { failedPaymentDetails } = useParams();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [paymentsFiltered, setPaymentsFiltered] = useState([]);
  const [hasFailedPayment, setHasFailedPayment] = useState([]);

  const handleCloseModal = useCallback(() => setIsOpenModal(!isOpenModal), [isOpenModal]);

  const { data: { lprTransactions: transactions } = {}, loading: loadingPayments } = useQuery(PARKING_TRANSACTIONS, {
    fetchPolicy: 'network-only',
    variables: { descending: 'transactionDate' },
    onCompleted: () => setMonthPayments(),
    onError: (e) => bugsnag?.notify(e),
  });

  const setMonthPayments = useCallback(async () => {
    const monthPayments = groupingTransactionsByMonth(transactions);
    setPayments(monthPayments);
  }, [payments, transactions]);

  const getPaymentList = useCallback(
    (index) => {
      setCurrentPosition(index);
      const month = payments[index]?.month;
      const year = payments[index]?.year;
      setPaymentsFiltered(
        transactions?.filter(
          (item) =>
            new Date(item.transactionDate).getMonth() + 1 === month &&
            new Date(item.transactionDate).getFullYear() === year,
        ),
      );
    },
    [setPaymentsFiltered, payments, loadingPayments, transactions],
  );

  useEffect(() => {
    if (!payments.length) {
      payments.push({
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        payment: 0,
      });
    }

    const failedPaymentIndex = Number(payments.findIndex((payment) => payment.failedPayment));
    const index = failedPaymentDetails ? failedPaymentIndex : 0;
    getPaymentList(index);
  }, [payments, failedPaymentDetails, transactions]);

  useEffect(() => {
    setHasFailedPayment(paymentsFiltered?.some((payment) => payment.status === 'FAILED'));
  }, [paymentsFiltered]);

  return {
    isOpenModal,
    paymentsFiltered,
    hasFailedPayment,
    handleCloseModal,
    loadingPayments,
    getPaymentList,
  };
};
