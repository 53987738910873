import { useQuery } from '@apollo/react-hooks';
import { SHOPPINGS_MODALITIES } from 'services/apollo/queries';

export const useShoppingModalities = (currentShoppingSlug) => {
  const { data } = useQuery(SHOPPINGS_MODALITIES, {
    variables: {
      shoppingSlug: currentShoppingSlug,
    },
  });

  const modalities = data?.plan?.modalities;

  return modalities;
};
