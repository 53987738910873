import gql from 'graphql-tag';

const ORDERS = gql`
  query orders($limit: Int, $skip: Int, $statusesValues: [String!], $me: Boolean) {
    orders(limit: $limit, skip: $skip, statusesValues: $statusesValues, me: $me) {
      id
      text
      amount
      createdAt
      totalAmount
      discount
      status {
        id
        slug
        description
      }
      items {
        id
      }
    }
  }
`;

export default ORDERS;
export { ORDERS };
