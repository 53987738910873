import React from 'react';
import { arrayOf, number, shape, string } from 'prop-types';

import goToStoreDetails from 'components/storeScreen/helpers/goToStoreDetails';
import { useStores } from '../../contexts/featuredStores';
import { useApps } from '../../contexts/apps';

import { useCurrentShopping } from 'hooks';
import { classNames } from 'utils';

import { clickedTreadItemTracking, clickedTreadFloatingButtonTracking } from '../../tracking';

import styles from './index.module.scss';
import { useHistory } from 'react-router-dom';
import StoreLogo from 'icons/ic-store-simple.svg';
import RestaurantLogo from 'icons/ic-foodsrestaurant-ic.svg';
import ChevronRight from 'icons/chevron-right.svg';
import ReservationLogo from 'icons/ic-reservation.svg';
import WppLogo from 'icons/ic-wppstore.svg';
import Slider from 'components/slider';
import TreadSlider from 'components/treadSlider';

const FeaturedStores = ({ slug }) => {
  const currentShopping = useCurrentShopping();
  const history = useHistory();
  const { featuredStores, featuredRestaurants } = useStores();
  const { loading, shoppingHasApp } = useApps();
  const stores = slug === 'gastronomia' ? featuredRestaurants : featuredStores;
  const isGastronomy = slug === 'gastronomia';

  const trackClickedFeaturedStore = (name) => {
    const data = {
      mixpanel: {
        name: 'Clicked Featured Store',
        properties: {
          'Store Name': name,
        },
      },
    };
    window.track(data);
  };

  const trackClickedFeaturedRestaurant = (name) => {
    const data = {
      mixpanel: {
        name: 'Clicked Featured Restaurant',
        properties: {
          'Store Name': name,
        },
      },
    };
    window.track(data);
  };

  const handleOnClick = ({ store, slug }, position) => {
    clickedTreadItemTracking(isGastronomy ? 'restaurantes' : 'lojas', store.name, position);
    if (isGastronomy) {
      trackClickedFeaturedRestaurant(store.name);
    } else {
      trackClickedFeaturedStore(store.name);
    }

    goToStoreDetails({
      history,
      appSlug: slug,
      store,
      hasMarketplace: currentShopping.hasMarketplace,
    });
  };
  const handleFloat = () => {
    clickedTreadFloatingButtonTracking(isGastronomy ? 'Reserva e fila' : 'Venda direta');
    isGastronomy ? history.replace('/reserva-em-restaurantes') : history.replace('/venda-direta');
  };

  return (
    !loading &&
    stores?.length > 0 && (
      <TreadSlider
        color={isGastronomy ? '#D67C2D' : '#008258'}
        icon={
          isGastronomy ? (
            <RestaurantLogo className={classNames(styles.icon, styles.iconOrange)} />
          ) : (
            <StoreLogo className={classNames(styles.icon, styles.iconGreen)} />
          )
        }
        path={isGastronomy ? '/apps/gastronomia' : '/apps/marketplace'}
        title={isGastronomy ? 'Restaurantes' : 'Lojas'}
        wordEmphasis={'first'}
        slug={isGastronomy ? 'Restaurantes' : 'Lojas'}
      >
        <>
          <Slider autoScrollbarHeight className={styles.slider}>
            {stores?.map((store, index) => (
              <div className={styles.featuredBox} key={store.id} onClick={() => handleOnClick({ store, slug }, index)}>
                <div className={styles.featuredImageBox}>
                  {store?.logoColor ? (
                    <img src={store.logoColor.original} className={styles.featuredImg} />
                  ) : (
                    <h2>{store.name}</h2>
                  )}
                </div>
              </div>
            ))}
          </Slider>
          {shoppingHasApp(isGastronomy ? 'reserva-em-restaurantes' : 'venda-direta') && (
            <div
              className={classNames(styles.floatingButtonContainer, {
                [styles.floatingButtonContainerRestaurant]: isGastronomy,
              })}
              onClick={() => handleFloat()}
            >
              <div className={classNames(styles.circle, { [styles.circleRestaurant]: isGastronomy })}>
                {isGastronomy ? (
                  <ReservationLogo className={styles.circleIcon} />
                ) : (
                  <WppLogo className={styles.circleIcon} />
                )}
              </div>
              <h3 className={styles.floatingButtonContainerDescription}>
                {isGastronomy ? (
                  <>
                    <span className={classNames(styles.highlights, styles.highlightsRestaurant)}>Reserve sua mesa</span>{' '}
                    ou entre em uma
                    <span className={classNames(styles.highlights, styles.highlightsRestaurant)}> fila online </span>
                  </>
                ) : (
                  <>
                    <span className={styles.highlights}>Compre das suas lojas favoritas </span> pelo whatsapp
                  </>
                )}
              </h3>
              <ChevronRight
                className={classNames(styles.chevron, { [styles.chevronRestaurant]: isGastronomy })}
                viewBox="0 0 500 500"
              />
            </div>
          )}
        </>
      </TreadSlider>
    )
  );
};

FeaturedStores.propTypes = {
  stores: shape({
    name: string,
    images: arrayOf(string),
    segments: arrayOf(
      shape({
        name: string,
      }),
    ),
    deliveryTime: number,
  }),
  slug: string,
};

FeaturedStores.defaultProps = {
  stores: {},
  slug: '',
};

export default FeaturedStores;
