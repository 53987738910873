import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { classNames, capitalize, bugsnag } from 'utils';
import { useShoppingModalities, useUserPlan } from 'hooks';

import Modal from 'components/modal';
import { SignInMultiModal } from 'components/SignInMultiModal';
import numberFix from 'screens/apps/fidelity/utils/balanceFixValue';

import jumpToGoldImage from 'images/fidelity/jump-to-gold.webp';
import jumpToSilver from 'images/fidelity/jump-to-silver.webp';

import styles from './index.module.scss';

export const BlockedBenefitModal = ({ isOpen, onClose, variant, focus }) => {
  if (!isOpen) {
    setTimeout(() => {
      return null;
    }, 1000);
  }

  const plan = useUserPlan();

  const userVariant = plan.variant;
  const history = useHistory();

  const modalities = useShoppingModalities(plan?.shoppingSlug);

  const sourceValues = {
    table: 'tiers table',
    'benefit-list': `${capitalize(variant)} benefit list`,
  };

  const silverValue = modalities?.find((modality) => modality.name === 'silver')?.upgradePoints - plan?.upgradePoints;
  const goldValue = modalities?.find((modality) => modality.name === 'gold')?.upgradePoints - plan?.upgradePoints;

  const handleGoToMyPoints = () => {
    try {
      window.track({
        mixpanel: {
          name: 'Clicked To View My Points',
          properties: {
            Source: sourceValues[focus],
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }

    onClose();
    dispatchEvent(
      new CustomEvent('@mvc/route-change', {
        detail: {
          value: 'pontos',
        },
      }),
    );
    history.push('/apps/multivoce/abas/pontos');
  };

  const Title = () => {
    if (variant === 'gold') {
      return (
        <h2 className={styles.title}>
          Esse benefício <br /> ainda pode ser seu!
        </h2>
      );
    }
    if (variant === 'silver') {
      return (
        <h2 className={styles.title}>
          Você está no <br /> caminho certo!
        </h2>
      );
    }

    return null;
  };

  const Text = () => {
    if (variant === 'silver') {
      return (
        <span className={styles.modalText}>
          Junte <strong className={styles.textStrongSilver}>+ {numberFix(silverValue)} pts</strong> e torne-se{' '}
          <strong className={styles.textStrongSilver}>Cliente MultiVocê Silver</strong> para aproveitar benefícios como
          este.
        </span>
      );
    }

    if (variant === 'gold') {
      return (
        <span className={styles.modalText}>
          Faltam <strong className={styles.textStrong}>+ {numberFix(goldValue)} pontos,</strong> para se tornar{' '}
          <strong className={styles.textStrong}>Cliente MultiVocê Gold</strong> e ter acesso a esse e outros benefícios
          incríveis!
        </span>
      );
    }
    return null;
  };

  const Image = () => {
    if (variant === 'gold') {
      return <img src={jumpToGoldImage} className={styles.jumpImage} />;
    }
    if (variant === 'silver') {
      return <img src={jumpToSilver} className={styles.jumpImage} />;
    }

    return null;
  };

  if (!userVariant) return <SignInMultiModal show={isOpen} onClose={onClose} />;

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={classNames(styles.modal, styles[userVariant])} slide>
      <div className={styles.modalContainer}>
        <div>
          <Image />
        </div>
        <Title />
        <div className={styles.textContainer}>
          <Text />
        </div>
        <div className={styles.buttonsContainer}>
          <button className={styles.button} onClick={handleGoToMyPoints}>
            ver meus pontos
          </button>
          <button className={styles.notNowButton} onClick={onClose}>
            Agora não
          </button>
        </div>
      </div>
    </Modal>
  );
};

BlockedBenefitModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
  focus: PropTypes.string.isRequired,
};
