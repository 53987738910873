import React from 'react';
import PropTypes from 'prop-types';
import SeeAll from 'components/seeAll';
import { classNames } from 'utils';

import styles from './index.module.scss';

const SuperCard = ({ title, children, iconColor, route, className }) => (
  <div className={classNames(styles.appcard, className, { [styles.hide]: !route && !title })}>
    <div className={styles.container}>
      {title && <h3 className={styles.title}>{title}</h3>}
      {children}
      {route && <SeeAll iconColor={iconColor} className={styles.seeAll} route={route} />}
    </div>
  </div>
);

SuperCard.propTypes = {
  title: PropTypes.node,
  className: PropTypes.string,
  iconColor: PropTypes.string,
  route: PropTypes.string,
  children: PropTypes.element.isRequired,
};

SuperCard.defaultProps = {
  title: null,
  iconColor: '#19AC7F',
  className: null,
  route: '',
};

export default SuperCard;
