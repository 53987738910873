import React from 'react';
import { string, func, array, bool } from 'prop-types';

import { classNames } from 'utils';
import styles from './index.module.scss';

import AngleRight from 'icons/angle-right.svg';

import LinesEllipsis from 'react-lines-ellipsis';

const EventsCard = ({ image, title, duration, className, onClick, categories, isSingleEvent }) => {
  return (
    <div className={classNames(styles.card, className)} onClick={onClick}>
      <div className={styles.cardImgWrapper}>
        <img className={styles.cardMainImg} src={image} />
      </div>
      <div className={classNames(styles.cardWrapper, { [styles.cardWrapperHasDate]: duration })}>
        <div>
          {duration && <span className={styles.cardDate}>{duration}</span>}
          <div>
            <LinesEllipsis maxLine={duration ? '2' : '3'} text={title} className={styles.cardTitle} />
          </div>
          <div>
            {categories.map((category, index) => (
              <>
                <span key={category.id} className={styles.categories}>
                  {category?.name}
                </span>
                {categories[index + 1] && (
                  <span className={classNames(styles.categories, styles.categoriesSpace)}>.</span>
                )}
              </>
            ))}
          </div>
        </div>
        {isSingleEvent && <AngleRight className={styles.cardArrow} />}
      </div>
    </div>
  );
};

EventsCard.propTypes = {
  image: string.isRequired,
  title: string.isRequired,
  duration: string,
  className: string,
  onClick: func.isRequired,
  categories: array,
  isSingleEvent: bool,
};

EventsCard.defaultProps = {
  duration: '',
  className: null,
  categories: [],
  isSingleEvent: false,
};

export default EventsCard;
