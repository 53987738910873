import { useQuery } from '@apollo/react-hooks';
import { useSelectedAddress, useCurrentShopping } from 'hooks';
import { CURRENT_LOCATION } from 'services/apollo/queries';

const useUserLocation = () => {
  const selectedAddress = useSelectedAddress();
  const { data: { currentLocation } = {} } = useQuery(CURRENT_LOCATION);
  const currentShopping = useCurrentShopping();
  if (selectedAddress) {
    return {
      latitude: selectedAddress.location.latitude,
      longitude: selectedAddress.location.longitude,
    };
  }

  if (currentLocation && currentLocation.latitude && currentLocation.longitude)
    return {
      latitude: currentLocation.latitude,
      longitude: currentLocation.longitude,
    };

  if (currentShopping && currentShopping.location)
    return {
      latitude: currentShopping.location.latitude,
      longitude: currentShopping.location.longitude,
    };

  return null;
};

export default useUserLocation;
