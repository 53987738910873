import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import Card from 'components/card';
import Button from 'components/button';
import Loading from 'components/loading';
import Overlay from 'components/overlay';

import { goToShoppingsAndSetLocation, classNames, bugsnag } from 'utils';

import LocationIcon from './images/icon-location.svg';
import styles from './index.module.scss';
import './index.scss';

const LocationAlert = ({ client, history, isOpen, onClose, portal }) => {
  const [loading, changeLoading] = useState(false);

  const sendActiveLocationTracking = () => {
    try {
      window.track({
        mixpanel: {
          name: 'Clicked Permission Location',
        },
        googleTagManager: {
          global: {
            name: 'clickedPermissionLocation',
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
  };

  const activeLocation = () => {
    sendActiveLocationTracking();
    changeLoading(true);
    goToShoppingsAndSetLocation(client, history, true);
  };

  const skipLocation = () => {
    changeLoading(true);
    goToShoppingsAndSetLocation(client, history, false);
  };

  const Component = () =>
    (loading && <Loading fullscreen />) || (
      <Overlay
        animation="animate__fadeIn"
        isVisible={isOpen}
        onClose={onClose}
        rootClassName={(portal && styles.root) || null}
        preventClosing
      >
        <div className={styles.container}>
          <div className={styles.card}>
            <Card className={styles.cardContent}>
              <div className={styles.titleContent}>
                <div className={styles.title} id="modalLocation">
                  {'O shopping mais perto de você'}
                </div>
              </div>
              <div className={styles.content}>
                <div className={styles.text}>
                  {'Verifique nas configurações do seu celular se o '}
                  <b>GPS está ativado.</b>
                </div>
                <LocationIcon />
                <div className={styles.text}>
                  Agora basta permitir que o Multi tenha <span>acesso a sua localização</span> para obter uma
                  experiência completa.
                </div>
              </div>
              <div className={styles.contentBtn}>
                <Button
                  className={classNames(styles.button, 'enable-location')}
                  onClick={activeLocation}
                  id="allowLocation"
                >
                  {'Permitir localização'}
                </Button>
              </div>
              <Button className={classNames(styles.button, styles.skipButton)} onClick={skipLocation} id="skipLocation">
                {'Ativar depois'}
              </Button>
            </Card>
          </div>
        </div>
      </Overlay>
    );

  return (portal && ReactDOM.createPortal(<Component />, document.getElementById('overlay-root'))) || <Component />;
};

LocationAlert.propTypes = {
  client: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  portal: PropTypes.bool,
};

LocationAlert.defaultProps = {
  onClose: () => null,
  isOpen: false,
  portal: false,
};

export default withRouter(withApollo(LocationAlert));
