const getDescription = ({ slug, description }) => {
  const availableStatus = [
    'cancelado',
    'entregue',
    'em-transito',
    'em-preparo',
    'retirado-em-loja',
    'pronto-para-retirada-no-locker',
    'pronto-para-retirada-de-carro',
    'retirado-de-carro',
    'retirado-no-locker',
    'pronto-para-sair',
    'preparando-envio',
  ];
  return (availableStatus.includes(slug) && description) || 'Pedido em andamento';
};

export default getDescription;
