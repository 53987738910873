import gql from 'graphql-tag';

const CREATE_PARKING_LICENSE_PLATE = gql`
  mutation createParkingLicensePlate($licensePlate: String!, $source: PossibleSources) {
    createParkingLicensePlate(licensePlate: $licensePlate, source: $source) {
      id
    }
  }
`;

const UPDATE_PARKING_PLATE = gql`
  mutation updateParkingLicensePlate($newLicensePlate: String) {
    updateParkingLicensePlate(newLicensePlate: $newLicensePlate) {
      id
      licensePlate
      status
    }
  }
`;

const DELETE_PARKING_PLATE = gql`
  mutation disableParkingLicensePlate($licensePlate: String) {
    disableParkingLicensePlate(licensePlate: $licensePlate) {
      id
    }
  }
`;

const CREATE_LPR_REGISTRATION = gql`
  mutation createLprRegistration(
    $licensePlate: String!
    $vehicleName: String
    $source: PossibleSources!
    $shoppingId: ID!
    $paymentMethod: String
    $terms: Boolean!
  ) {
    createLprRegistration(
      licensePlate: $licensePlate
      vehicleName: $vehicleName
      source: $source
      shoppingId: $shoppingId
      paymentMethod: $paymentMethod
      terms: $terms
    ) {
      id
      hasLooseTicket
    }
  }
`;

const CREATE_LPR_REGISTRATIONS = gql`
  mutation createLprRegistrations(
    $shoppingId: ID!
    $source: PossibleSources!
    $terms: Boolean!
    $paymentMethod: String
    $licensePlates: [String!]
  ) {
    createLprRegistrations(
      shoppingId: $shoppingId
      source: $source
      terms: $terms
      paymentMethod: $paymentMethod
      licensePlates: $licensePlates
    ) {
      id
      hasLooseTicket
      licensePlate(shoppingId: $shoppingId) {
        id
        licensePlate
      }
    }
  }
`;

const DELETE_LPR_REGISTRATION = gql`
  mutation disableLprRegistration($id: ID!) {
    disableLprRegistration(id: $id)
  }
`;

const DELETE_LPR_REGISTRATIONS = gql`
  mutation disableLprRegistrations {
    disableLprRegistrations
  }
`;

const UPDATE_LPR_REGISTRATION = gql`
  mutation updateLprRegistration($id: ID!, $newLicensePlate: String, $vehicleName: String, $shoppingId: ID) {
    updateLprRegistration(
      id: $id
      newLicensePlate: $newLicensePlate
      vehicleName: $vehicleName
      shoppingId: $shoppingId
    ) {
      id
      hasLooseTicket
    }
  }
`;

const UPDATE_PAYMENT_METHOD = gql`
  mutation updatePaymentMethod($paymentMethod: String!, $deleteCreditCardId: ID) {
    updatePaymentMethod(paymentMethod: $paymentMethod, deleteCreditCardId: $deleteCreditCardId)
  }
`;

const RETRY_PAYMENT = gql`
  mutation retryPaymentRequest($id: ID!) {
    retryPaymentRequest(id: $id) {
      status
    }
  }
`;

const TOGGLE_ENABLE_NFSE_CPF = gql`
  mutation toggleEnableNfseCpf($enable: Boolean!) {
    toggleEnableNfseCpf(enable: $enable)
  }
`;

export {
  CREATE_PARKING_LICENSE_PLATE,
  UPDATE_PARKING_PLATE,
  DELETE_PARKING_PLATE,
  CREATE_LPR_REGISTRATION,
  CREATE_LPR_REGISTRATIONS,
  DELETE_LPR_REGISTRATION,
  DELETE_LPR_REGISTRATIONS,
  UPDATE_LPR_REGISTRATION,
  UPDATE_PAYMENT_METHOD,
  RETRY_PAYMENT,
  TOGGLE_ENABLE_NFSE_CPF,
};
