import { lazy } from 'react';

const Onboarding = lazy(() => import(/* webpackChunkName: "onboard" */ 'screens/onboarding/start'));

export default [
  {
    path: '/onboarding',
    exact: true,
    component: Onboarding,
  },
];
