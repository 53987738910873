import React, { useEffect, Suspense } from 'react';
import _ from 'lodash';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import {
  fadeOut,
  bugsnag,
  informationsDevice,
  trackFirebaseEvents,
  skipEnterAnimationPaths,
  skipExitAnimationPaths,
} from 'utils';
import LoginRoute from 'components/loginRoute';
import ShoppingRoute from 'components/shoppingRoute';
import ParkingRoute from 'components/parking/parkingRoute';
import { setCampaign } from 'components/campaign/functions';
import VipRoute from 'screens/apps/fidelity/components/vipRoute';

import appRoutes from './apps/routes/app';
import convenienceRoutes from './apps/routes/convenience';
import gastronomyRoutes from './apps/routes/gastronomy';
import fidelityRoutes from './apps/routes/fidelity';
import checkinRoutes from './apps/routes/checkin';
import llvRoutes from './apps/routes/llv';
import medicalCenterRoutes from './apps/routes/medicalCenter';
import marketplaceRoutes from './apps/routes/marketplace';
import movieRoutes from './apps/routes/movie';
import newsRoutes from './apps/routes/news';
import storeRoutes from './apps/routes/store';
import theaterRoutes from './apps/routes/theater';
import redirectRoutes from './apps/routes/redirect';
import directSellingRoutes from './apps/routes/directSelling';
import serviceRoutes from './apps/routes/service';
import advantageRoutes from './apps/routes/advantage';
import onboardRoutes from './apps/routes/onboard';
import reservationRoutes from './apps/routes/reservation';
import profileRoutes from './apps/routes/profile';
import marketingRoutes from './apps/routes/marketing';
import search from './apps/routes/search';
import eventsRoutes from './apps/routes/event';
import parkingRoutes from './apps/routes/parking';
import shareRoutes from './apps/routes/share';
import newOnboarding from './apps/routes/newOnboarding';
import registerOnboarding from './apps/routes/registerOnboarding';
import FancyLoadingComponent from 'components/fancyScreenLoading';

import afterSale from '../config/apps/routes/afterSale';

import Error from 'components/error';

const Routes = () => {
  const routes = _.concat(
    onboardRoutes,
    convenienceRoutes,
    gastronomyRoutes,
    fidelityRoutes,
    medicalCenterRoutes,
    marketplaceRoutes,
    movieRoutes,
    storeRoutes,
    newsRoutes,
    theaterRoutes,
    llvRoutes,
    directSellingRoutes,
    serviceRoutes,
    advantageRoutes,
    eventsRoutes,
    checkinRoutes,
    redirectRoutes,
    reservationRoutes,
    afterSale,
    profileRoutes,
    marketingRoutes,
    search,
    shareRoutes,
    appRoutes,
    parkingRoutes,
    newOnboarding,
    registerOnboarding,
  );

  const checkPageEvent = (currentPage, pages) => {
    pages.forEach((p) => {
      if (currentPage.includes(p)) {
        trackFirebaseEvents(p);
        trackFirebaseEvents('all_verticais');
        return;
      }
    });
  };

  let hasHistoryListener;

  const setSplashAnimation = () => {
    const splash = document.getElementById('splash');
    if (splash) {
      const svg = splash.querySelector('svg');
      svg.classList.add('animate__animated', 'animate__bounceOut');
      setTimeout(() => {
        fadeOut(splash, (splash) => {
          if (splash.parentNode) {
            splash.parentNode.removeChild(splash);
            try {
              const currentPage = location.hash.substr(1).split('?')[0];

              try {
                setTimeout(() => trackFirebaseEvents('20_seconds_on_page'), 20000);
                !sessionStorage.getItem('lastRoute')
                  ? trackFirebaseEvents('is_first_page_view')
                  : trackFirebaseEvents('is_not_first_page_view');

                checkPageEvent(currentPage, [
                  'marketplace',
                  'gastronomia',
                  'lapis-vermelho',
                  'venda-direta',
                  'multivoce',
                ]);

                if (currentPage !== '/apps/promotion/modal') {
                  window.track({
                    firebase: {
                      name: 'screen',
                      properties: {
                        type: 'screenview',
                        screenName: currentPage,
                      },
                    },
                    mixpanel: {
                      name: 'Viewed Page',
                      properties: {
                        'Virtual Page Path': currentPage,
                        'Is First Page View': !sessionStorage.getItem('lastRoute'),
                      },
                    },
                    googleTagManager: {
                      global: {
                        name: 'viewedPage',
                        properties: {
                          virtualPagePath: currentPage,
                          isFirstPageView: !sessionStorage.getItem('lastRoute'),
                        },
                      },
                      globalWithoutMixpanel: {
                        name: 'viewedPageWithoutMixpanel',
                        properties: {
                          virtualPagePath: currentPage,
                          isFirstPageView: !sessionStorage.getItem('lastRoute'),
                        },
                      },
                    },
                    salesForce: {
                      name: 'Viewed Page',
                      properties: {
                        'Virtual Page Path': currentPage,
                      },
                    },
                  });
                }
              } catch (e) {
                bugsnag?.notify(e);
              }

              try {
                window.track({
                  firebase: {
                    name: 'finished_splash_screen',
                  },
                  mixpanel: {
                    name: 'Finished Splash Screen',
                  },
                  googleTagManager: {
                    global: {
                      name: 'finishedSplashScreen',
                    },
                  },
                });
              } catch (e) {
                bugsnag?.notify(e);
              }

              if (multiStorage.getItem('isForceRefreshing')) multiStorage.removeItem('isForceRefreshing');
            } catch (e) {
              bugsnag?.notify(e);
            }
          }
        });
      }, 1000);
    }
  };

  const setStorageInformations = () => {
    informationsDevice();

    try {
      window.identifyDeviceToken();
    } catch (e) {
      bugsnag?.notify(e);
    }

    sessionStorage.removeItem('lastRoute');
  };

  useEffect(() => {
    setSplashAnimation();
    setStorageInformations();
  }, []);

  const setTrackingPageView = (location) => {
    try {
      if (location.pathname !== '/apps/promotion/modal') {
        window.track({
          firebase: {
            name: 'screen',
            properties: {
              type: 'screenview',
              screenName: location.pathname,
            },
          },
          mixpanel: {
            name: 'Viewed Page',
            properties: {
              'Virtual Page Path': location.pathname,
              'Is First Page View': !sessionStorage.getItem('lastRoute'),
            },
          },
          googleTagManager: {
            global: {
              name: 'viewedPage',
              properties: {
                virtualPagePath: location.pathname,
                isFirstPageView: !sessionStorage.getItem('lastRoute'),
              },
            },
            globalWithoutMixpanel: {
              name: 'viewedPageWithoutMixpanel',
              properties: {
                virtualPagePath: location.pathname,
                isFirstPageView: !sessionStorage.getItem('lastRoute'),
              },
            },
          },
          salesForce: {
            name: 'Viewed Page',
            properties: {
              'Virtual Page Path': location.pathname,
            },
          },
        });
      }
    } catch (e) {
      bugsnag?.notify(e);
    }
  };

  const createHistoryListener = (history) => {
    const registeredActions = ['POP', 'PUSH'];
    history.listen((location, action) => {
      if (registeredActions.includes(action))
        sessionStorage.setItem(
          'lastRoute',
          JSON.stringify({
            action,
            route: location.pathname + location.search + location.hash,
          }),
        );

      setCampaign(location);

      if (action === 'REPLACE' && location.pathname === '/showcase') return;
      setTrackingPageView(location);
    });
  };

  const tryCreateHistoryListener = (history) => {
    if (!hasHistoryListener) {
      hasHistoryListener = true;
      createHistoryListener(history);
    }
  };

  const renderRoutes = (history) => {
    tryCreateHistoryListener(history);
    return routes.map((route) =>
      route.login ? (
        <LoginRoute key={route.path} {...route} />
      ) : route.vip ? (
        <VipRoute key={route.path} {...route} />
      ) : route.parking ? (
        <ParkingRoute key={route.path} {...route} />
      ) : route.noMall ? (
        <Route key={route.path} {...route} />
      ) : (
        <ShoppingRoute key={route.path} {...route} />
      ),
    );
  };

  const hasEnterAnimation = (location) => !skipEnterAnimationPaths.find((path) => location.pathname.includes(path));

  const hasExitAnimation = (location) => !skipExitAnimationPaths.find((path) => location.pathname.includes(path));

  return (
    <div className="screen-wrapper">
      <Router>
        <Route
          render={({ location, history }) => (
            <TransitionGroup>
              <CSSTransition
                classNames="page"
                key={location.pathname}
                timeout={{ enter: 500, exit: 500 }}
                enter={hasEnterAnimation(location)}
                exit={hasExitAnimation(location)}
              >
                <Suspense fallback={<FancyLoadingComponent fullscreen />}>
                  <Switch location={location}>
                    {renderRoutes(history)}
                    <Route render={() => <Error code={404} />} />
                  </Switch>
                </Suspense>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </div>
  );
};

export default Routes;
