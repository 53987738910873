import React, { useRef, useEffect, useState } from 'react';
import { string, func } from 'prop-types';
import styles from './index.module.scss';
import { classNames } from 'utils';

const Tabs = ({ selectedTab, setSelectedTab }) => {
  const tabsRef = useRef([]);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[selectedTab];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
    }

    setTabPosition();
    window.addEventListener('resize', setTabPosition);

    return () => window.removeEventListener('resize', setTabPosition);
  }, [selectedTab]);

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <div
          className={classNames(styles.tabsTab, { [styles.tabsSelectedTab]: selectedTab === 'multi' })}
          onClick={() => setSelectedTab('multi')}
          ref={(el) => (tabsRef.current['multi'] = el)}
        >
          Multi
        </div>
        <div
          className={classNames(styles.tabsTab, { [styles.tabsSelectedTab]: selectedTab === 'oshopping' })}
          onClick={() => setSelectedTab('oshopping')}
          ref={(el) => (tabsRef.current['oshopping'] = el)}
        >
          O Shopping
        </div>
      </div>
      <span className={styles.slider} style={{ left: tabUnderlineLeft }}></span>
    </div>
  );
};

Tabs.propTypes = {
  selectedTab: string.isRequired,
  setSelectedTab: func.isRequired,
};

export default Tabs;
