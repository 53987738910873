import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { bool, oneOf, func, string } from 'prop-types';

import { useCurrentShopping } from 'hooks';
import { classNames, bugsnag } from 'utils';

import SHOPPINGSTATE from 'constants/shoppingState';

// import ChristmasHat from './images/christmasHat.svg';
import LocationIcon from 'icons/location-icon.svg';
import ChevronRight from 'icons/chevron-right.svg';

import styles from './index.module.scss';

const ShoppingDisplay = ({
  // showChristmasHat,
  variant,
  isFixed,
  className,
}) => {
  const currentShopping = useCurrentShopping();
  const history = useHistory();
  const location = useLocation();

  const handleClick = () => {
    try {
      const shoppingSlug = currentShopping?.slug;
      const splittedLocation = location.split('/');
      const from = splittedLocation[splittedLocation.length - 1];

      window.track({
        mixpanel: {
          name: 'Clicked Change Mall',
          properties: {
            'Shopping Slug': shoppingSlug,
            from,
          },
        },
        googleTagManager: {
          global: {
            name: 'clickedChangeMall',
            properties: {
              shoppingSlug,
              from,
            },
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
    history.push({ pathname: '/shoppings', state: { lastRoute: location.pathname } });
  };

  return (
    <div
      className={classNames(styles.mainUserInfo, styles[variant], className, {
        [styles.mainUserInfoFixed]: isFixed,
        [styles.mainUserInfoSticky]: !isFixed,
      })}
      onClick={handleClick}
    >
      {/* {showChristmasHat && <ChristmasHat className={styles.hat} />} */}
      <LocationIcon className={styles.iconLocation} />
      <div className={styles.content}>
        <span className={classNames(styles.shoppingName)}>{currentShopping?.name + ' | '}</span>
        <span className={classNames(styles.shoppingName, styles.state)}>
          {SHOPPINGSTATE.get(currentShopping?.state)}
        </span>
      </div>
      <ChevronRight className={styles.chevronIcon} />
    </div>
  );
};

ShoppingDisplay.propTypes = {
  shoppingName: string.isRequired,
  onClick: func,
  showChristmasHat: bool,
  variant: oneOf(['green', 'red', 'home']),
  shoppingState: string.isRequired,
  isFixed: bool,
  className: string,
};

ShoppingDisplay.defaultProps = {
  onClick: () => null,
  showChristmasHat: false,
  variant: 'green',
  isFixed: true,
  className: null,
};

export default ShoppingDisplay;
