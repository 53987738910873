import React, { useRef, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getDistance } from 'geolib';
import { useApolloClient } from '@apollo/react-hooks';
import { HashRouter as Switch, Route } from 'react-router-dom';
import { FeaturedStoresProvider } from './contexts/featuredStores';
import { AppsProvider } from './contexts/apps';

import Screen from 'components/screen';
import Scroller from 'components/scroller';
import Tutorial from '../onboarding/tutorial';
import ParkingExceptionModule from './components/parkingExceptionModal';
// import NpsModal from 'components/npsModal';
import { WelcomeRegisterModal } from './components/WelcomeRegisterModal';

import { Toast } from 'components/Toast';

import { bugsnag, getLocation, classNames, getDistanceFromLatLongInKm } from 'utils';
import { useCurrentShopping, useSelectedAddress, useUser, usePromotionToast, useParking } from 'hooks';
import { useLicensePlateBlocked } from 'hooks/useParking/useLicensePlateBlocked';
import { CURRENT_LOCATION, USER_VIP } from 'services/apollo/queries';

import PersonSvg from '../../images/fidelity/person.svg';

import styles from './index.module.scss';
import Header from './components/header';
import Tabs from './components/tabs';
import ShoppingTab from './components/shoppingTab';
import MultiTab from './components/multiTab';
import AlertBox from '../../components/alertBox';
import Typography from 'components/typography';
import { colors } from 'theme';

import CloseIcon from 'icons/times.svg';
import Car from 'icons/car-alt.svg';

import { ParkingHome } from 'config/apps/routes/parking';

const Showcase = () => {
  const [userLocation, setUserLocation] = useState({});
  const { tab } = useParams();
  const [selectedTab, setSelectedTab] = useState(tab || 'multi');
  const history = useHistory();
  const scrollerRef = useRef();
  const client = useApolloClient();
  const user = useUser();
  const currentShopping = useCurrentShopping(true);
  const { isLprRegistrationBlocked } = useParking();
  const { closedAlertBox, toggleAlertBox } = useLicensePlateBlocked();

  ParkingHome.preload();

  useSelectedAddress();
  usePromotionToast();

  client.query({
    query: USER_VIP,
    skip: !currentShopping || !user,
    variables: { shoppingSlug: currentShopping?.slug, upsert: true, source: 'Showcase' },
    fetchPolicy: 'network-only',
  });

  const handleSelectTab = (tab) => {
    try {
      window.track({
        mixpanel: {
          name: tab === 'multi' ? 'Clicked Showcase Tab' : 'Clicked Shopping Tab',
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
    setSelectedTab(tab);
  };

  useEffect(() => {
    const setLocation = async () => {
      try {
        const geoLocation = await getLocation();
        if (geoLocation) {
          const currentLocation = {
            latitude: geoLocation.coords.latitude,
            longitude: geoLocation.coords.longitude,
            accept: true,
            skip: false,
            __typename: 'CurrentLocation',
          };
          client.writeQuery({
            query: CURRENT_LOCATION,
            data: { currentLocation: currentLocation },
          });
          multiStorage.setItem('location', JSON.stringify(currentLocation));
          setUserLocation({ latitude: geoLocation.coords.latitude, longitude: geoLocation.coords.longitude });
          const distance = getDistance(
            {
              latitude: currentLocation.latitude,
              longitude: currentLocation.longitude,
            },
            {
              latitude: currentShopping.location.latitude,
              longitude: currentShopping.location.longitude,
            },
          );
          if (distance > 10000 && multiStorage.getItem('lastShopping') !== currentShopping.slug) {
            history.replace('/shoppings');
          }
        }
      } catch (e) {
        if (e?.code !== 1) {
          const errorText = JSON.stringify(e);
          if (errorText && errorText !== 'null' && errorText !== '{}' && errorText !== '{"skip":true}')
            bugsnag?.notify(`Error: set Location - ${errorText}`);
        }
      }
    };
    setLocation();
    try {
      if (window?.MultiShoppingAndroid?.requestNotificationsPermission)
        window?.MultiShoppingAndroid?.requestNotificationsPermission();
    } catch (e) {
      bugsnag?.notify(e);
    }
  }, []);

  useEffect(() => {
    if (userLocation && Object.keys(userLocation).length > 0 && !sessionStorage.getItem('openedMultiLocation')) {
      try {
        window.track({
          mixpanel: {
            name: 'Opened Multi Location',
            properties: {
              $latitude: userLocation?.latitude,
              $longitude: userLocation?.longitude,
              Latitude: userLocation?.latitude,
              Longitude: userLocation?.longitude,
            },
          },
        });

        sessionStorage.setItem('openedMultiLocation', true);
      } catch (error) {
        bugsnag?.notify(error);
      }
    }
  }, [userLocation]);

  useEffect(() => {
    if (userLocation && Object.keys(userLocation).length > 0 && !sessionStorage.getItem('trackingLocation')) {
      const shoppingLocation = {
        lat: currentShopping?.location.latitude,
        long: currentShopping?.location.longitude,
      };

      const distance = getDistanceFromLatLongInKm(userLocation, shoppingLocation);

      if (distance <= 0.4 && user && Object.keys(user).length > 0) {
        try {
          window.track({
            mixpanel: {
              name: 'Shopping Nearby',
              properties: {
                Distance: distance,
                $latitude: userLocation?.latitude,
                $longitude: userLocation?.longitude,
                Latitude: userLocation?.latitude,
                Longitude: userLocation?.longitude,
              },
            },
            firebase: {
              name: 'shopping_nearby',
              properties: {
                value: 120,
              },
            },
            adjust: {
              name: 'ShoppingNearby',
              token: 'r02ytz',
              properties: {
                value: 120,
              },
            },
          });
          sessionStorage.setItem('trackingLocation', true);
        } catch (error) {
          bugsnag?.notify(error);
        }
      }
    }
  }, [userLocation]);

  return (
    <Screen cartSummary={{ show: true }} className={classNames(styles.screen, 'showcase')} hasBottomMenu>
      <Scroller className={styles.scroller} ref={scrollerRef}>
        {isLprRegistrationBlocked && !closedAlertBox && (
          <AlertBox variant={'error'}>
            <AlertBox.Icon icon={Car} width={24} height={24} />
            <AlertBox.Card
              onClick={() => {
                history.push('apps/estacionamento/home');
                toggleAlertBox(true);
              }}
            >
              <Typography color={colors.common.white} bold>
                Acesso Multi bloqueado
              </Typography>
              <Typography color={colors.common.white}>Toque e saiba como resolver</Typography>
            </AlertBox.Card>
            <AlertBox.Divider height="32px" />
            <AlertBox.Icon icon={CloseIcon} width={24} height={24} onClick={() => toggleAlertBox(true)} />
          </AlertBox>
        )}
        <Header />
        <Tabs selectedTab={selectedTab} setSelectedTab={handleSelectTab} />
        {selectedTab === 'multi' ? (
          <AppsProvider>
            <FeaturedStoresProvider>
              <MultiTab />
            </FeaturedStoresProvider>
          </AppsProvider>
        ) : (
          <AppsProvider>
            <ShoppingTab />
          </AppsProvider>
        )}
      </Scroller>
      <Switch>
        <Route path="/showcase/onboarding" exact render={() => <Tutorial />} />
        <Route path="/showcase/parking-exception-modal" exact render={() => <ParkingExceptionModule />} />
      </Switch>
      {/* <NpsModal /> */}
      <WelcomeRegisterModal />
      {history.location?.state?.lastRoute === '/signup/identity/confirmation' && (
        <Toast animationType="top">
          <div className={styles.toastContent}>
            <div>
              <PersonSvg className={styles.toastContentPhoto} />
            </div>
            <div>
              <span className={styles.toastTitle}>Olá {user ? user.name.split(' ')[0] : ''},</span>
              <p className={styles.toastText}>É bom ter você de volta!</p>
            </div>
          </div>
        </Toast>
      )}
    </Screen>
  );
};

const ShowCasePage = () => <Showcase />;

export default ShowCasePage;
