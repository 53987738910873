import React from 'react';
import { array, number, oneOf } from 'prop-types';
import { colors } from 'theme';

import { BorderPromotionFlag, PromotionFlag } from '../PromotionFlag';

const PromotionTag = ({ categories, points, direction }) => {
  const ClientTag = () => {
    if (direction === 'center') {
      return (
        <PromotionFlag bgColor={colors.ambar[5]} color={colors.common.white}>
          Semana do Cliente
        </PromotionFlag>
      );
    } else {
      return (
        <BorderPromotionFlag bgColor={colors.ambar[5]} color={colors.common.white} direction={direction}>
          Semana do Cliente
        </BorderPromotionFlag>
      );
    }
  };

  const ShoppingLoverTag = () => {
    if (direction === 'center') {
      return (
        <PromotionFlag bgColor={colors.purple[5]} color={colors.common.white}>
          Shopping<strong>Lover</strong>
        </PromotionFlag>
      );
    } else {
      return (
        <BorderPromotionFlag bgColor={colors.purple[5]} color={colors.common.white} direction={direction}>
          Shopping<strong>Lover</strong>
        </BorderPromotionFlag>
      );
    }
  };

  const BlackFridayTag = () => {
    if (direction === 'center') {
      return (
        <PromotionFlag bgColor={colors.gray[8]} color={colors.common.white}>
          <strong>Black</strong>Friday
        </PromotionFlag>
      );
    } else {
      return (
        <BorderPromotionFlag bgColor={colors.gray[8]} color={colors.common.white} direction={direction}>
          <strong>Black</strong>Friday
        </BorderPromotionFlag>
      );
    }
  };

  const NatalTag = () => {
    if (direction === 'center') {
      return (
        <PromotionFlag bgColor={colors.red[7]} color={colors.common.white}>
          <strong>Natal</strong> MultiVocê
        </PromotionFlag>
      );
    } else {
      return (
        <BorderPromotionFlag bgColor={colors.red[7]} color={colors.common.white} direction={direction}>
          <strong>Natal</strong> MultiVocê
        </BorderPromotionFlag>
      );
    }
  };

  const PascoaTag = () => {
    if (direction === 'center') {
      return (
        <PromotionFlag bgColor={colors.ambar[8]} color={colors.common.white}>
          <strong>Páscoa</strong> Multi
        </PromotionFlag>
      );
    } else {
      return (
        <BorderPromotionFlag bgColor={colors.ambar[8]} color={colors.common.white} direction={direction}>
          <strong>Páscoa</strong> Multi
        </BorderPromotionFlag>
      );
    }
  };

  const MomsDayTag = () => {
    if (direction === 'center') {
      return (
        <PromotionFlag bgColor={colors.red[2]} color={colors.red[8]}>
          Dia das <strong>Mães</strong>
        </PromotionFlag>
      );
    } else {
      return (
        <BorderPromotionFlag bgColor={colors.red[2]} color={colors.red[8]} direction={direction}>
          Dia das <strong>Mães</strong>
        </BorderPromotionFlag>
      );
    }
  };

  const ValentineTag = () => {
    if (direction === 'center') {
      return (
        <PromotionFlag bgColor={colors.salmon[8]} color={colors.red[2]}>
          Dia dos <strong>Namorados</strong>
        </PromotionFlag>
      );
    } else {
      return (
        <BorderPromotionFlag bgColor={colors.salmon[8]} color={colors.red[2]} direction={direction}>
          Dia dos <strong>Namorados</strong>
        </BorderPromotionFlag>
      );
    }
  };

  const FathersDayTag = () => {
    if (direction === 'center') {
      return (
        <PromotionFlag bgColor={colors.green[2]} color={colors.green[8]}>
          Dia dos <strong>Pais</strong>
        </PromotionFlag>
      );
    } else {
      return (
        <BorderPromotionFlag bgColor={colors.green[2]} color={colors.green[8]} direction={direction}>
          Dia dos <strong>Pais</strong>
        </BorderPromotionFlag>
      );
    }
  };

  const KidsDayTag = () => {
    if (direction === 'center') {
      return (
        <PromotionFlag bgColor={colors.yellow[2]} color={colors.orange[9]}>
          Dia das <strong>Crianças</strong>
        </PromotionFlag>
      );
    } else {
      return (
        <BorderPromotionFlag bgColor={colors.yellow[2]} color={colors.orange[9]} direction={direction}>
          Dia das <strong>Crianças</strong>
        </BorderPromotionFlag>
      );
    }
  };

  const OfferTag = () => {
    if (direction === 'center') {
      return (
        <PromotionFlag bgColor={colors.ocre[9]} color={colors.common.white}>
          Oferta <strong>MultiVocê</strong>
        </PromotionFlag>
      );
    } else {
      return (
        <BorderPromotionFlag bgColor={colors.ocre[9]} color={colors.common.white} direction={direction}>
          Oferta <strong>MultiVocê</strong>
        </BorderPromotionFlag>
      );
    }
  };

  const PizzaTag = () => {
    if (direction === 'center') {
      return (
        <PromotionFlag bgColor={colors.ambar[9]} color={colors.red[8]}>
          Dia da <strong>Pizza</strong>
        </PromotionFlag>
      );
    } else {
      return (
        <BorderPromotionFlag bgColor={colors.ambar[9]} color={colors.red[8]} direction={direction}>
          Dia da <strong>Pizza</strong>
        </BorderPromotionFlag>
      );
    }
  };

  const ChocolateTag = () => {
    if (direction === 'center') {
      return (
        <PromotionFlag bgColor={colors.ambar[8]} color={colors.ocre[2]}>
          Semana do <strong>Chocolate</strong>
        </PromotionFlag>
      );
    } else {
      return (
        <BorderPromotionFlag bgColor={colors.ambar[8]} color={colors.ocre[2]} direction={direction}>
          Semana do <strong>Chocolate</strong>
        </BorderPromotionFlag>
      );
    }
  };

  const PastaTag = () => {
    if (direction === 'center') {
      return (
        <PromotionFlag bgColor={colors.ambar[9]} color={colors.ambar[8]}>
          Semana da <strong>Massa</strong>
        </PromotionFlag>
      );
    } else {
      return (
        <BorderPromotionFlag bgColor={colors.ambar[9]} color={colors.ambar[8]} direction={direction}>
          Semana da <strong>Massa</strong>
        </BorderPromotionFlag>
      );
    }
  };

  const GauchoMonthTag = () => {
    if (direction === 'center') {
      return (
        <PromotionFlag
          bgColor={colors.red[7]}
          color={colors.common.white}
          borderColors={{ left: colors.lemon[4], right: colors.ambar[3] }}
        >
          Mês do <strong>Gaúcho</strong>
        </PromotionFlag>
      );
    } else {
      return (
        <BorderPromotionFlag
          bgColor={colors.red[7]}
          color={colors.common.white}
          borderColors={{ left: colors.lemon[4], right: colors.ambar[3] }}
          direction={direction}
        >
          Mês do <strong>Gaúcho</strong>
        </BorderPromotionFlag>
      );
    }
  };

  const categoryFlag = {
    'SEMANA DO CLIENTE': <ClientTag />,
    SHOPPINGLOVER: <ShoppingLoverTag />,
    'BLACK FRIDAY': <BlackFridayTag />,
    NATAL: <NatalTag />,
    PÁSCOA: <PascoaTag />,
    'DIA DAS MÃES': <MomsDayTag />,
    'DIA DOS NAMORADOS': <ValentineTag />,
    'DIA DOS PAIS': <FathersDayTag />,
    'DIA DAS CRIANÇAS': <KidsDayTag />,
    'DIA DA PIZZA': <PizzaTag />,
    'SEMANA DO CHOCOLATE': <ChocolateTag />,
    'SEMANA DA MASSA': <PastaTag />,
    'MÊS DO GAÚCHO': <GauchoMonthTag />,
  };

  const acceptedCategories = Object.keys(categoryFlag);

  const uppercaseCategoriesList = categories ? categories.map((category) => category.toUpperCase()) : [];

  const firstAcceptedCategory = uppercaseCategoriesList.find((category) => acceptedCategories.includes(category));

  if (firstAcceptedCategory) return categoryFlag[firstAcceptedCategory];

  if (points) return <OfferTag />;

  return null;
};

PromotionTag.propTypes = {
  categories: array,
  points: number.isRequired,
  direction: oneOf(['vertical', 'horizontal', 'center']),
};

PromotionTag.defaultProps = {
  direction: 'center',
  categories: null,
};

export { PromotionTag };
