const notDetermined = 'NotDetermined';

const requestTrackingAuthorization = () => {
  if (window.webkit?.messageHandlers?.requestTrackingAuthorization) {
    window.webkit?.messageHandlers?.requestTrackingAuthorization.postMessage({});
  } else {
    return;
  }
};

const TrackingTransparency = () => {
  window.onReceivedTrackingAuthorizationStatus = (resp) => {
    if (resp === notDetermined) {
      setTimeout(() => {
        requestTrackingAuthorization();
      }, 1000);
    } else {
      multiStorage.setItem('iosTrackingAuthorizationStatus', resp);
    }
  };

  const status = multiStorage.getItem('iosTrackingAuthorizationStatus');
  if (status && status !== notDetermined) return;

  requestTrackingAuthorization();
};

export default TrackingTransparency;
