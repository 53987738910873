import React, { forwardRef, Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { object } from 'prop-types';

import { isIphoneX, bugsnag, classNames, handlePhone } from 'utils';

import Typography from 'components/typography';
import Flex from 'components/flex';

import { colors } from 'theme';

import Clock from 'icons/clock.svg';
import MapMarkerAlt from 'icons/location.svg';
import Email from 'icons/mail.svg';
import Phone from 'icons/phone.svg';
import ArrowRight from 'icons/arrow-right-thick.svg';

import styles from './index.module.scss';

const ShoppingInfo = forwardRef(({ shopping }, ref) => {
  const handleClickEmail = () => {
    sendTrackingContactedUs('email');
    window.openExternalContent(shopping?.email);
  };

  const sendTrackingContactedUs = (contactOption) => {
    try {
      window.track({
        mixpanel: {
          name: 'Contacted Us',
          properties: {
            'Contact Option': contactOption,
          },
        },
        googleTagManager: {
          global: {
            name: 'contactedUs',
            properties: {
              contactOption,
            },
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
  };

  const shoppingInformation = [
    {
      title: 'Endereço',
      info: shopping?.address,
      icon: <MapMarkerAlt className={classNames(styles.icon, styles.iconLocation)} />,
    },
    {
      title: 'Horário de Funcionamento',
      info: ReactHtmlParser(shopping?.operatingHours),
      icon: <Clock className={classNames(styles.icon, styles.iconSquare)} />,
    },
    {
      title: 'Telefones',
      info: shopping?.phoneNumbers?.map((item, index) => (
        <>
          <div key={index}>{`${item.name}: ${item.phoneNumber}`}</div>
          <Flex justify="flex-start" gap="7px">
            <Typography
              variant="body2"
              weight="bold"
              color={colors.green[5]}
              onClick={() => handlePhone(item.phoneNumber)}
            >{`Iniciar Chamada`}</Typography>
            <ArrowRight className={styles.arrowRight} />
          </Flex>
        </>
      )),
      icon: <Phone className={classNames(styles.icon, styles.iconSquare)} />,
    },
    {
      title: 'E-mail',
      info: (
        <>
          <Flex justify="flex-start" gap="7px">
            <Typography
              variant="body2"
              weight="bold"
              color={colors.green[5]}
              onClick={handleClickEmail}
            >{`Entrar Em Contato`}</Typography>
            <ArrowRight className={styles.arrowRight} />
          </Flex>
        </>
      ),
      icon: <Email className={classNames(styles.icon, styles.iconSquare)} />,
    },
  ];

  return (
    <>
      <div className={styles.footer} ref={ref}>
        <img
          src={shopping?.logo?.original}
          className={classNames(styles.logo, { [styles.logoPqm]: shopping.slug === 'parque-shopping-maceio' })}
        />
        <img src={shopping?.cover?.original} className={styles.cover} />
        <div className={styles.content}>
          {shoppingInformation.map((value, index) => {
            const { icon, title, info } = value;

            return (
              <Fragment key={index}>
                {info && (
                  <div className={styles.block}>
                    <div className={styles.blockIconContainer}>{icon}</div>
                    <div className={styles.blockContent}>
                      <strong>{title}</strong>
                      <div>{info}</div>
                      {isIphoneX() ? <br /> : null}
                    </div>
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
      <div className={styles.disclaimer}>
        <div className={styles.disclaimerText}>
          O Multi é de titularidade da Multiplan Empreendimentos Imobiliários S.A., inscrita no CNPJ/ME sob nº
          07.816.890/0001-53.
        </div>
      </div>
    </>
  );
});

ShoppingInfo.propTypes = {
  shopping: object.isRequired,
};

export default ShoppingInfo;
