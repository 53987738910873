import { useState } from 'react';
import { bugsnag, capitalize } from 'utils';
import { useCurrentShopping } from 'hooks';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { CATEGORIES_LIST } from './graphql/queries';

export const useCategoriesFilterModal = ({ variant, initialValue, handleApplyCategoryFilterModal, onClose }) => {
  const history = useHistory();

  const currentShopping = useCurrentShopping(true);
  const [selectedCategories, setSelectedCategories] = useState(initialValue);

  const pathname = history?.location.pathname;

  const source = pathname.includes('lista-de-beneficios') ? `${capitalize(variant)} benefit list` : 'home MVC';

  const { data: { benefitFilters } = {} } = useQuery(CATEGORIES_LIST, {
    fetchPolicy: 'cache-and-network',
    variables: {
      shoppingSlug: currentShopping?.slug,
      modalities: [variant],
    },
  });

  const handleSelectCategory = (category) => {
    const activityValue = selectedCategories.find((double) => double === category);

    if (activityValue) {
      setSelectedCategories(selectedCategories.filter((filterItem) => filterItem !== category));
    } else {
      try {
        window.track({
          mixpanel: {
            name: 'Categories Filter Selected',
            properties: {
              Selection: category,
              Source: source,
            },
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }

      setSelectedCategories((old) => [...old, category]);
    }
  };

  const activeButton = (category) => selectedCategories.some((double) => double === category);

  const activeApplyButton = () => {
    if (selectedCategories.length !== initialValue.length) {
      return false;
    }

    const sortedSelectedCategories = selectedCategories.slice().sort();
    const sortedInitialValue = initialValue.slice().sort();

    return sortedSelectedCategories.every((value, index) => value === sortedInitialValue[index]);
  };

  const handleClean = () => {
    setSelectedCategories([]);
    handleApplyCategoryFilterModal([]);

    onClose();
  };

  const handleApply = () => {
    try {
      window.track({
        mixpanel: {
          name: 'Categories Filter Applied',
          properties: {
            Selections: selectedCategories,
            Source: source,
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }

    handleApplyCategoryFilterModal(selectedCategories);
    onClose();
  };

  return {
    benefitFilters,

    selectedCategories,
    handleSelectCategory,
    activeButton,
    activeApplyButton,
    handleClean,
    handleApply,
  };
};
