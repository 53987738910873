import { lazy } from 'react';

const Home = lazy(() => import(/* webpackChunkName: "llv" */ 'screens/advantage'));

export default [
  {
    path: '/vantagens',
    exact: true,
    component: Home,
  },
];
