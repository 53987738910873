import { bugsnag } from 'utils';

const clickPointsExplanationTracking = (variant) => {
  try {
    window.track({
      mixpanel: {
        name: 'Clicked Points Explained Button',
        properties: {
          Modality: variant,
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

export { clickPointsExplanationTracking };
