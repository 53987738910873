import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const WrongShoppingContext = createContext({});

const WrongShoppingProvider = ({ children }) => {
  const [entity, setEntity] = useState({ __typename: undefined });

  return <WrongShoppingContext.Provider value={{ entity, setEntity }}>{children}</WrongShoppingContext.Provider>;
};

const useWrongShopping = () => {
  const context = useContext(WrongShoppingContext);

  if (!context) {
    throw new Error("You don't have access to wrong shopping context");
  }

  return context;
};

WrongShoppingProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { WrongShoppingProvider, useWrongShopping };
