const skipEnterAnimationPaths = [
  '/apps/multivoce/seller-code-modal',
  '/register-onboarding',
  'check-ins/resgatados',
  'check-ins/usados',
];

const skipExitAnimationPaths = [
  '/apps/multivoce/seller-code-modal',
  '/register-onboarding',
  'check-ins/resgatados',
  'check-ins/usados',
];

export { skipEnterAnimationPaths, skipExitAnimationPaths };
