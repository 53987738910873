import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { cold } from 'react-hot-loader';
import { useLocation } from 'react-router-dom';

import BottomMenu from 'components/bottomMenu';

import { classNames } from 'utils';
import { useCurrentShopping, useOrderList } from 'hooks';

import styles from './index.module.scss';

const Menu = () => {
  const statuses = ['new', 'accepted', 'ongoing', 'pickedup', 'locker'];
  const { orders = [] } = useOrderList(statuses);
  const location = useLocation();
  const currentShopping = useCurrentShopping();

  const highlighted = currentShopping?.footerApps?.length > 4 ? true : false;

  return (
    <div
      className={classNames(styles.container, {
        [styles.highlighted]: highlighted,
      })}
      id={'bottomMenu'}
    >
      {currentShopping?.footerApps?.map((item, index) => (
        <BottomMenu
          key={item.id}
          active={location.pathname === item.path}
          href={item.path}
          icon={item.iconFooter?.icon?.original}
          text={item.text}
          badge={orders.length > 0 && location.pathname !== '/orders' && item.href === '/orders'}
          className={styles.bottomMenu}
          highlighted={highlighted}
          index={index}
        />
      ))}
    </div>
  );
};

const PortalMenu = ({ isPortal, ...props }) => {
  return (isPortal && ReactDOM.createPortal(<Menu {...props} />, document.getElementById('overlay-root'))) || <Menu />;
};

PortalMenu.propTypes = {
  isPortal: PropTypes.bool,
};

PortalMenu.defaultProps = {
  isPortal: true,
};

export default cold(PortalMenu);
