import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { Query, ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloProviderHooks } from '@apollo/react-components';
import { BenefitListProvider } from './reducer/benefitList';

import Routes from 'config/routes';
import Loading from 'components/loading';
import Campaign from 'components/campaign';
import AppUpdateModal from 'components/appUpdateModal';
import TrackingTransparency from 'components/trackingTransparency';
import ApolloClient from 'services/apollo';
import { AppProvider } from './hooks';
import { checkVersion, openExternalContent, bugsnag } from 'utils';

const App = () => {
  useEffect(() => {
    checkVersion();
    window.checkVersion = checkVersion;
    window.openExternalContent = openExternalContent;

    TrackingTransparency();

    try {
      window.identifyWebVersion();
    } catch (e) {
      bugsnag?.notify(e);
    }
  }, []);

  Query.defaultProps = {
    notifyOnNetworkStatusChange: true,
  };

  return (
    <ApolloProvider client={ApolloClient}>
      <ApolloProviderHooks client={ApolloClient}>
        <AppProvider>
          <BenefitListProvider>
            <Routes />
            <Campaign />
            <AppUpdateModal />
            <div id="generalLoading">
              <Loading fullscreen />
            </div>
          </BenefitListProvider>
        </AppProvider>
      </ApolloProviderHooks>
    </ApolloProvider>
  );
};

export default hot(module)(App);
