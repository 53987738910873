import React from 'react';
import { useLocation } from 'react-router-dom';

import Loading from 'components/loading';
import ParkingLoadingScreen from 'screens/apps/parking/lpr/components/parkingLoading';

const FancyLoadingComponent = ({ fullscreen }) => {
  const { pathname } = useLocation();
  const route = pathname.split('/')[2];
  const routePatterns = { estacionamento: <ParkingLoadingScreen /> };

  const getLoadingComponent = () => {
    if (route in routePatterns) return routePatterns[route];
    return <Loading fullscreen={fullscreen} />;
  };

  const LoadingComponentForCurrentRoute = getLoadingComponent(pathname);
  return LoadingComponentForCurrentRoute;
};

export default FancyLoadingComponent;
