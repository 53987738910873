import { useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';

import { CHANGE_PHONE_NUMBER } from './graphql/queries';

const useRecoverAccount = () => {
  const client = useApolloClient();

  const { changePhoneNumber } = client.readQuery({ query: CHANGE_PHONE_NUMBER });

  const setRecoverPhoneNumber = useCallback(async (data) => {
    await client.writeQuery({
      query: CHANGE_PHONE_NUMBER,
      data: {
        changePhoneNumber: {
          ...changePhoneNumber,
          ...data,
        },
      },
    });
  }, []);

  return [changePhoneNumber, setRecoverPhoneNumber];
};

export default useRecoverAccount;
