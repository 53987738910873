import gql from 'graphql-tag';

const PHONE_NUMBER = gql`
  {
    phoneNumber @client
  }
`;

const CHANGE_PHONE_NUMBER = gql`
  query changePhoneNumber {
    changePhoneNumber @client {
      cpf
      email
      provider
      oldPhoneNumber
      newPhoneNumber
    }
  }
`;

export default PHONE_NUMBER;
export { PHONE_NUMBER, CHANGE_PHONE_NUMBER };
