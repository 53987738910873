import { lazy } from 'react';

const Home = lazy(() => import(/* webpackChunkName: "theater" */ 'screens/theater/theaterHub'));
const Play = lazy(() => import(/* webpackChunkName: "theater" */ 'screens/theater/theaterPlay'));
const LegacyHome = lazy(() => import(/* webpackChunkName: "theater" */ 'screens/apps/theater/home'));
const LegacyPlay = lazy(() => import(/* webpackChunkName: "theater" */ 'screens/apps/theater/play'));

export default [
  {
    path: '/apps/teatro',
    exact: true,
    component: LegacyHome,
  },
  {
    path: '/apps/teatro/:slug',
    exact: true,
    component: LegacyPlay,
  },
  {
    path: '/apps/teatro-multi',
    exact: true,
    component: Home,
  },
  {
    path: '/apps/teatro-multi/:slug',
    exact: true,
    component: Play,
  },
];
