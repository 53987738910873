import React from 'react';

import PropTypes from 'prop-types';

import { SessionTokenProvider } from './useSessionToken';
import { TrackingLocationProvider } from './useTrackingLocation';
import { FavoriteCheckInRedirectProvider } from './useFavoriteCheckInRedirect';
import { WrongShoppingProvider } from './useWrongShopping';
import { LicensePlateHistoryProvider } from './useLicensePlateHistory';

const AppProvider = ({ children }) => (
  <SessionTokenProvider>
    <TrackingLocationProvider>
      <FavoriteCheckInRedirectProvider>
        <WrongShoppingProvider>
          <LicensePlateHistoryProvider>{children}</LicensePlateHistoryProvider>
        </WrongShoppingProvider>
      </FavoriteCheckInRedirectProvider>
    </TrackingLocationProvider>
  </SessionTokenProvider>
);

AppProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default AppProvider;
