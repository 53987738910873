const copyToClipboard = (text) => {
  if (window.webkit?.messageHandlers?.copyToClipboard) {
    window.webkit.messageHandlers.copyToClipboard.postMessage(text);
  } else if (window?.MultiShoppingAndroid?.copyToClipboard) {
    window?.MultiShoppingAndroid?.copyToClipboard(text);
  } else {
    navigator.clipboard.writeText(text);
  }
};

export default copyToClipboard;
