import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import RedirectModal from 'components/redirectModal';
import { useHistory } from 'react-router-dom';

const useRedirectModal = () => {
  const [modalConfig, setModalConfig] = useState(null);
  const history = useHistory();

  const openModal = useCallback(({ type, onClose, url, forceBrowser }) => {
    setModalConfig({
      type,
      onClose,
      url,
      forceBrowser,
      history,
    });
  }, []);

  const closeModal = useCallback(() => {
    if (modalConfig && modalConfig.onClose) {
      modalConfig.onClose();
    }
    setModalConfig(null);
  }, [modalConfig]);

  useEffect(() => {
    const modalRoot = document.getElementById('app');

    if (modalConfig) {
      const el = document.createElement('div');
      modalRoot.appendChild(el);

      ReactDOM.render(
        <RedirectModal
          type={modalConfig.type}
          onClose={closeModal}
          forceBrowser={modalConfig.forceBrowser}
          url={modalConfig.url}
          history={modalConfig.history}
        />,
        el,
      );

      return () => {
        ReactDOM.unmountComponentAtNode(el);
        modalRoot.removeChild(el);
      };
    }
  }, [modalConfig]);

  return openModal;
};

export default useRedirectModal;
