import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { cold } from 'react-hot-loader';
import { Route } from 'react-router-dom';

import Showcase from 'screens/showcase';
import { USER } from 'services/apollo/queries';

const LoginRoute = ({ component: Component, ...rest }) => {
  const [newRoute, setNewRoute] = useState(true);
  const renderScreen = (props, user) => (user && newRoute ? <Showcase {...props} /> : <Component {...props} />);

  useEffect(() => setNewRoute(false), []);

  return (
    <Query query={USER}>
      {({ data: { user } }) => <Route {...rest} render={(props) => renderScreen(props, user)} />}
    </Query>
  );
};

LoginRoute.propTypes = {
  component: PropTypes.object.isRequired,
};

export default cold(LoginRoute);
