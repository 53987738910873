import gql from 'graphql-tag';

const STORE_SEGMENTS = gql`
  query storeSegments($appSlug: String!, $shoppingId: ID!, $gastronomy: Boolean, $isService: Boolean) {
    storeSegments(appSlug: $appSlug, shoppingId: $shoppingId, gastronomy: $gastronomy, isService: $isService) {
      segments {
        id
        name
      }
    }
  }
`;

export { STORE_SEGMENTS };
