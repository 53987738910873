import gql from 'graphql-tag';

const CAMPAIGN = gql`
  query campaign($id: ID!) {
    campaign(id: $id) {
      id
      title
      rules
      message
      buttonText
      image {
        id
        original
      }
      coupon {
        id
        name
      }
      shopping {
        id
        name
        slug
        location {
          latitude
          longitude
        }
      }
    }
  }
`;

const MY_COUPONS = gql`
  query myCoupons {
    myCoupons {
      id
      name
      dcCoupon
    }
  }
`;

export { CAMPAIGN, MY_COUPONS };
