import React from 'react';
import PropTypes from 'prop-types';
import { Mutation, withApollo } from 'react-apollo';

import Form from 'components/form';
import Input from 'components/input';
import Screen from 'components/screen';
import Navbar from 'components/navbar';
import Button from 'components/button';
import InputMask from 'components/inputMask';

import { CREATE_VERIFICATION_CODE } from '../graphql/mutations';
import { PHONE_NUMBER } from '../graphql/queries';
import { classNames, bugsnag } from 'utils';

import SMS from 'icons/comments-alt.svg';
import AngleRight from 'icons/angle-right.svg';

import { trackingCreateCode } from '../tracking';
import styles from './index.module.scss';
import './index.scss';

class PhoneLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryCode: '+55',
      form: {
        nationalNumber: '',
      },
    };

    this.rules = {
      ...['nationalNumber'].reduce((ctx, name) => {
        ctx[name] = [
          {
            required: true,
            message: 'O número do seu celular é obrigatório. Favor preencher.',
            trigger: 'blur',
          },
        ];
        return ctx;
      }, {}),
    };

    this.form = React.createRef();
  }

  componentDidMount() {
    const { client } = this.props;
    const { phoneNumber } = client.readQuery({ query: PHONE_NUMBER });
    if (phoneNumber) this.setState({ form: { nationalNumber: phoneNumber.toString().substr(3) } });
  }

  componentWillUnmount() {
    const { client } = this.props;
    const { phoneNumber } = client.readQuery({ query: PHONE_NUMBER });
    if (phoneNumber) multiStorage.setItem('phoneNumber', JSON.stringify(phoneNumber));
  }

  async onSubmit(e, createVerificationCode, provider) {
    e.preventDefault();

    const { countryCode } = this.state;
    const { nationalNumber } = this.state.form;
    this.form.current.validate(async (valid) => {
      if (valid) {
        await createVerificationCode({
          variables: { phoneNumber: `${countryCode}${nationalNumber}`, provider },
        });
        trackingCreateCode(provider);
      }
    });
  }

  savePhoneNumber({ cache, data }) {
    const { history } = this.props;
    const {
      createVerificationCode: { phoneNumber },
    } = data;
    cache.writeQuery({ query: PHONE_NUMBER, data: { phoneNumber } });
    history.push('/signup/phone/confirmation');
  }

  onChange(key, value) {
    const newForm = Object.assign({}, this.state.form, { [key]: value });
    this.setState({ form: newForm });
  }

  hasMinPhoneLength() {
    return this.state.form.nationalNumber.replace(/\D/g, '').length < 11;
  }

  handleRecoverAccount() {
    const { history } = this.props;

    try {
      window.track({
        mixpanel: {
          name: 'Click Login Problem',
        },
        googleTagManager: {
          global: {
            name: 'clickLoginProblem',
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }

    history.push('/signup/phone/issue');
  }

  handleGoToRegisterOnboarding() {
    window.track({
      mixpanel: {
        name: 'Onboarding Continue As Guest',
      },
    });
    const { history } = this.props;
    history.push('/register-onboarding');
  }

  renderErrorMessage(message) {
    return `Olá! ${
      (message.indexOf('Network') !== -1 &&
        `Parece que há um problema com a sua internet. Verifique a conexão, por favor.`) ||
      `Parece que o seu número está errado. Favor incluir o DDD e o número completo.`
    }`;
  }

  render() {
    const { history } = this.props;
    const { countryCode } = this.state;
    const lastRoute = history.location?.state?.from === 'shoppings';

    return (
      <Screen className={styles.root}>
        <Navbar title="Entrar no Multi" showBackButton />
        <Mutation
          mutation={CREATE_VERIFICATION_CODE}
          update={(cache, { data }) => this.savePhoneNumber({ cache, data })}
        >
          {(createVerificationCode, { loading, error }) => (
            <Form
              className={styles.form}
              rules={this.rules}
              model={this.state.form}
              show-message={false}
              ref={this.form}
            >
              <Form.Item prop="nationalNumber" className={classNames(styles.formItem, 'form-item-phone')}>
                <label>
                  <strong>Insira seu número de celular</strong>
                </label>
                <p className={styles.text}>
                  <b>O celular é sua chave de acesso no Multi e vamos te enviar um código para confirmação.</b> Seus
                  dados ficam seguros e o login é mais rápido.
                </p>
                <div className={styles.inputs}>
                  <Input className={styles.countryCodeInput} readOnly value={countryCode} />
                  <InputMask
                    placeholder="Número do seu celular"
                    format="(##) #####-####"
                    type="tel"
                    onChange={(e) => this.onChange('nationalNumber', e.target.value)}
                    id="phoneNumber"
                  />
                </div>
                {error?.message && (
                  <div className={classNames(styles.error, 'error')}>{this.renderErrorMessage(error.message)}</div>
                )}
              </Form.Item>
              <Button
                round
                type="primary"
                nativeType="submit"
                disabled={loading || this.hasMinPhoneLength()}
                className={styles.submitButton}
                id="submitPhoneNumberButton"
                onClick={(e) => this.onSubmit(e, createVerificationCode, 'sms')}
              >
                <SMS className={styles.icon} />
                {'Receber por SMS'}
              </Button>
              <Button
                flat
                className={styles.recoverButton}
                onClick={() => this.handleRecoverAccount()}
                id={'btnIssueSignIn'}
              >
                Tenho um problema para entrar
                <AngleRight className={styles.arrowRight} />
              </Button>
            </Form>
          )}
        </Mutation>
        {lastRoute && (
          <div className={styles.footer}>
            <div className={styles.divider}>
              <div className={styles.dividerLine} />
              <span className={styles.dividerText}>ou</span>
              <div className={styles.dividerLine} />
            </div>

            <button
              type="button"
              data-testid="visitantButton"
              className={styles.visitantButton}
              onClick={() => this.handleGoToRegisterOnboarding()}
            >
              continuar como visitante
            </button>
          </div>
        )}
      </Screen>
    );
  }
}

PhoneLogin.propTypes = {
  history: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};

export default withApollo(PhoneLogin);
