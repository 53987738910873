import { useMemo, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { useCurrentShopping, useUser, useSubscription, useUserPlan, useSelectedVehicle } from 'hooks';
import { bugsnag, shoppingsWithoutTicketGate, virtualTicketShoppings, shoppingWithMvcDiscount } from 'utils';

import { LPR_REGISTRATIONS, PARKING_TRANSACTIONS, PARKING_SHOPPING } from 'screens/apps/parking/graphql/queries';
import { PARKING_LIVE_UPDATED } from 'screens/apps/parking/graphql/subscriptions';

const useParking = () => {
  const user = useUser();
  const { variant } = useUserPlan();

  const currentShopping = useCurrentShopping();
  const { selectedVehicleId, setSelectedVehicle } = useSelectedVehicle();
  const [licensePlateActivity, setLicensePlateActivity] = useState({});

  const handleCompletedRegistration = (data) => {
    const enabledNfseCpf = data.lprRegistrations.every(({ nfseCpf }) => nfseCpf === true);
    multiStorage.setItem('enabledNfseCpf', enabledNfseCpf);
  };

  const {
    loading: loadingLprRegistration,
    data: { lprRegistrations } = {},
    refetch,
  } = useQuery(LPR_REGISTRATIONS, {
    skip: !user,
    variables: {
      shoppingId: currentShopping?.id,
      active: true,
    },
    onCompleted: (data) => {
      handleCompletedRegistration(data);
    },
    onError: (e) => {
      bugsnag?.notify(e);
    },
  });

  const refetchLprRegistration = async () => {
    await refetch();
    handleCompletedRegistration({ lprRegistrations });
  };

  const licensePlates = lprRegistrations?.map(({ licensePlate }) => licensePlate);
  const userVehicles = lprRegistrations;
  const userVehiclesCount = userVehicles?.length;
  const selectedVehicle = useMemo(() => {
    if (!lprRegistrations) return null;
    const selectedVehicle = lprRegistrations.find((vehicle) => vehicle.id === selectedVehicleId);

    if (!selectedVehicle) {
      return lprRegistrations[0];
    }

    return selectedVehicle;
  }, [selectedVehicleId, lprRegistrations]);

  const currentLicensePlate = selectedVehicle?.licensePlate || lprRegistrations?.[0]?.licensePlate;

  const isLprRegistrationBlocked = licensePlates?.find(
    (licensePlate) => licensePlate?.status === 'BLOCKED' || licensePlate?.status === 'PENDING_BLOCKED',
  );

  const licensePlateStatus = useMemo(() => {
    if (isLprRegistrationBlocked) {
      return isLprRegistrationBlocked?.status;
    }
    return currentLicensePlate?.status;
  }, [licensePlates]);

  const userHasLprRegistration = !!lprRegistrations?.length;
  const userPaymentMethod = lprRegistrations?.[0]?.paymentMethod;

  const { loading: loadingLprTransactions, data: { lprTransactions } = {} } = useQuery(PARKING_TRANSACTIONS, {
    skip: !userHasLprRegistration,
    variables: {
      descending: 'transactionDate',
      isNotOpenOrClosed: false,
    },
    fetchPolicy: 'network-only',
    onError: (e) => {
      bugsnag?.notify(e);
    },
  });

  const { data: { parkingShopping } = {} } = useQuery(PARKING_SHOPPING, {
    variables: { shoppingId: currentShopping?.id },
  });

  const lastEntranceDate = currentLicensePlate?.lastEntranceDate;
  const hasPendingTransaction = lprTransactions?.some((transaction) => transaction?.status === 'PENDING');
  const failedTransactions = lprTransactions?.filter((transaction) => transaction?.status === 'FAILED');
  const hasFailedTransaction = !!failedTransactions?.length;
  const paymentIsProcessing = lprTransactions?.some(({ paymentRequest }) => paymentRequest?.status === 'PROCESSING');
  const hasVehicleInUse = lprRegistrations?.some(
    (lprRegistration) => lprRegistration?.licensePlate?.activity?.event === 'ENTRADA',
  );
  const hasLooseTicket = userVehicles?.length ? userVehicles?.some((vehicle) => vehicle?.hasLooseTicket) : undefined;

  useEffect(() => {
    const { client, subscription } = useSubscription({
      query: PARKING_LIVE_UPDATED,
      variables: { licensePlateId: currentLicensePlate?.id },
      nextCallback: ({ subscriptionData }) => {
        const { activity } = subscriptionData.data.parkingLicensePlateLiveUpdated;
        setLicensePlateActivity(activity);
        refetchLprRegistration();
      },
    });

    return () => {
      subscription.unsubscribe();
      client.dispose();
    };
  }, [currentLicensePlate?.id]);

  const isBlocked =
    licensePlateStatus && (licensePlateStatus === 'BLOCKED' || licensePlateStatus === 'PENDING_BLOCKED');
  const isLprShopping = currentShopping?.state !== 'Alagoas';
  const isTicketGate = shoppingsWithoutTicketGate.includes(currentShopping?.slug);
  const isVirtualTicketAvaible = virtualTicketShoppings?.includes(currentShopping?.slug);
  const hasMarketAndLprRegistration = isVirtualTicketAvaible && userHasLprRegistration;
  const showMvcDiscount = variant === 'gold' && shoppingWithMvcDiscount.includes(currentShopping?.slug);
  const shoppingWithMvcGoldDiscount = shoppingWithMvcDiscount.includes(currentShopping?.slug);

  const creditCard = lprRegistrations?.[0]?.creditCard;

  return {
    isLprShopping,
    userVehicles,
    licensePlates,
    userHasLprRegistration,
    currentLicensePlate,
    currentLicensePlateStatus: currentLicensePlate?.status,
    selectedVehicle,
    loadingLprRegistration,
    userVehiclesCount,
    hasPendingTransaction,
    failedTransactions,
    hasFailedTransaction,
    isLprRegistrationBlocked,
    loadingLprTransactions,
    licensePlateStatus,
    isBlocked,
    licensePlateActivity,
    creditCard,
    lastEntranceDate,
    isTicketGate,
    isVirtualTicketAvaible,
    parkingShopping,
    paymentIsProcessing,
    hasVehicleInUse,
    setSelectedVehicle,
    refetchLprRegistration,
    hasLooseTicket,
    lprTransactions,
    userPaymentMethod,
    hasMarketAndLprRegistration,
    showMvcDiscount,
    shoppingWithMvcGoldDiscount,
  };
};

export { useParking };
