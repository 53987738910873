import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from '../modal';
import Button from '../button';
import Scroller from 'components/scroller';
import { useCurrentShopping, useUser } from 'hooks';
import { classNames, goToApp, bugsnag } from 'utils';

import styles from './index.module.scss';
import './index.scss';

const Announcement = ({ id, onClose, isOpen, image, children, title, button, buttonText, buttonLink }) => {
  const history = useHistory();
  const currentShopping = useCurrentShopping();
  const user = useUser();

  const handleCallToAction = (e) => {
    try {
      window.track({
        mixpanel: {
          name: 'Click Announcement Button',
          properties: {
            'Announcement ID': id,
            'Annoucement Title': title,
            'User Id': user?.id,
            'Shopping Slug': currentShopping?.slug,
            'Virtual Page Path': history?.location.pathname,
            defaultBehavior: !button,
            buttonText: button ? buttonText : 'Continuar',
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }

    const trackClose = false;
    onClose(trackClose);

    if (!e.target.className.includes('inactive')) {
      goToApp({ history, path: buttonLink });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className={'annoucement-dialog'}
      onClose={onClose}
      fullscreen={true}
      portalClassName={styles.reactModalPortal}
    >
      <Scroller className={classNames(styles.root, !image && styles.withoutImage)}>
        {image && <img src={image} />}
        <div className={classNames(styles.info, image && styles.infoImage)}>
          {title && <h1 className={image ? styles.textSpacingImage : styles.textSpacingNoImage}>{title}</h1>}
          <h2>{children}</h2>
          {button ? (
            <Button type={'primary'} onClick={(e) => handleCallToAction(e)} className={styles.cta}>
              {buttonText}
            </Button>
          ) : (
            <Button
              type={'primary'}
              onClick={(e) => handleCallToAction(e)}
              className={classNames(styles.cta, styles.inactive)}
            >
              Continuar
            </Button>
          )}
        </div>
      </Scroller>
    </Modal>
  );
};

Announcement.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  image: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  button: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
};

Announcement.defaultProps = {
  onClose: () => {},
  image: null,
  title: null,
  button: false,
  buttonText: '',
  buttonLink: '',
};

export default Announcement;
