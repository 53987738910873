import client from 'services/apollo';
import querystring from 'query-string';

import { CAMPAIGN } from '../graphql/queries';
import { CAMPAIGN_STARTED } from 'services/apollo/queries';

const setCampaign = async (location = null) => {
  const urlString = (location && location.search.split('?')) || window.location.href.split('?');
  if (!urlString[1]) return;

  const { campaignId = '' } = querystring.parse(urlString[1]);

  if (campaignId !== '' && !multiStorage.getItem(`campaign_${campaignId}`)) {
    const {
      data: { campaign = null },
    } = await client.query({
      query: CAMPAIGN,
      variables: { id: campaignId },
    });

    if (campaign) client.writeQuery({ query: CAMPAIGN_STARTED, data: { campaignStarted: campaign } });
  }
};

export { setCampaign };
