const cleanPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return null;

  const basicNumber = phoneNumber.replace(/\+55|\D/g, '');

  if (basicNumber.substr(0, 4) === '0800' && basicNumber.length === 11)
    return {
      basicNumber,
      linkNumber: basicNumber,
      readableNumber: `${basicNumber.substr(0, 4)}-${basicNumber.substr(4, 3)}-${basicNumber.substr(-4)}`,
    };

  if (
    basicNumber.match(/^([14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])/) &&
    (basicNumber.length === 10 || basicNumber.length === 11)
  )
    return {
      basicNumber,
      linkNumber: `+55${basicNumber}`,
      readableNumber: `${basicNumber.substr(0, 2)} ${basicNumber.substr(
        2,
        basicNumber.length - 6,
      )}-${basicNumber.substr(-4)}`,
    };

  if ((basicNumber.length === 8 && basicNumber[0] !== '9') || (basicNumber.length === 9 && basicNumber[0] === '9'))
    return {
      basicNumber,
      linkNumber: basicNumber,
      readableNumber: `${basicNumber.substr(0, basicNumber.length - 4)}-${basicNumber.substr(-4)}`,
    };

  return null;
};

export default cleanPhoneNumber;
