import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { PAYMENT_SELECTED } from 'services/apollo/queries';

const useSelectedPayment = () => {
  const client = useApolloClient();
  const getPaymentSelected = () => {
    const { data: { paymentSelected } = {} } = useQuery(PAYMENT_SELECTED);

    return paymentSelected;
  };

  const setSelectedPayment = (paymentSelected) => {
    client.writeQuery({
      query: PAYMENT_SELECTED,
      data: { paymentSelected },
    });
    if (paymentSelected) multiStorage.setItem('lastPaymentType', JSON.stringify(paymentSelected));
    else multiStorage.removeItem('lastPaymentType');
  };

  return [getPaymentSelected(), setSelectedPayment];
};

export default useSelectedPayment;
