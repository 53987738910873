import { lazy } from 'react';

const Home = lazy(() => import(/* webpackChunkName: "gastronomy" */ 'screens/apps/gastronomy/home'));
const Store = lazy(() => import(/* webpackChunkName: "gastronomy" */ 'screens/apps/gastronomy/store'));
const Item = lazy(() => import(/* webpackChunkName: "gastronomy" */ 'screens/apps/gastronomy/store/item'));
const Search = lazy(() => import(/* webpackChunkName: "gastronomy" */ 'screens/apps/gastronomy/home/search'));
const CategoriesFilter = lazy(() =>
  import(/* webpackChunkName: "gastronomy" */ 'screens/apps/gastronomy/home/categoriesFilter'),
);
const Checkout = lazy(() => import(/* webpackChunkName: "marketplace-order" */ 'screens/checkout'));

export default [
  {
    path: '/apps/gastronomia',
    exact: true,
    component: Home,
  },
  {
    path: '/apps/gastronomia/busca',
    exact: true,
    component: Search,
  },
  {
    path: '/apps/gastronomia/categorias',
    exact: true,
    component: CategoriesFilter,
  },
  {
    path: '/apps/gastronomia/:slug/:tab?',
    exact: true,
    component: Store,
  },
  {
    path: '/apps/gastronomia/:slug/item',
    exact: true,
    component: Item,
  },
  {
    path: '/apps/gastronomia/:slug/checkout',
    exact: true,
    component: Checkout,
  },
];
