import React, { useState, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import { isAndroid } from 'react-device-detect';
import _ from 'lodash';

import { useCurrentShopping, useUser } from 'hooks';
import { bugsnag, classNames } from 'utils';

import { ACTUAL_PRIVACY_POLICY, USER } from 'services/apollo/queries';
import { SAVE_PRIVACY_ON_USER } from './graphql/mutations';

import Modal from 'components/modal';
import Button from 'components/button';

import CloseIcon from 'icons/close.svg';
import FileMulti from 'icons/file-multi.svg';
import ReloadIcon from 'icons/loyalty-reload.svg';
import AlertIcon from 'icons/loyalty-alert.svg';
import ConfirmIcon from 'icons/loyalty-confirm.svg';
import Help from 'icons/life-ring.svg';
import ChevronRight from 'icons/chevron-right.svg';

import styles from './index.module.scss';

const PrePrivacyPolicy = ({ isError, isModal }) => {
  const client = useApolloClient();
  const currentShopping = useCurrentShopping();
  const user = useUser();
  const history = useHistory();
  const { pathname } = useLocation();
  const [seePolicy, setSeePolicy] = useState(false);
  const [seeTerms, setSeeTerms] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [screen, setScreen] = useState(isError ? 'reportError' : 'default');
  const [errorMessage, setErrorMessage] = useState(isError ? 'reportError' : 'default');

  const [savePrivacyOnUser] = useMutation(SAVE_PRIVACY_ON_USER, {
    refetchQueries: USER,
    onCompleted: () => handleSuccess(),
    onError: (error) => {
      handleError();
      setErrorMessage(error.graphQLErrors[0]?.message?.split(':').pop() || 'Ops, tivemos um erro inesperado por aqui');
    },
  });

  const { data: { actualPrivacyPolicy } = {} } = useQuery(ACTUAL_PRIVACY_POLICY, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      actualPrivacyPolicy && actualPrivacyPolicy.version === user?.privacyPolicy?.version
        ? multiStorage.setItem('lastSeenPrivacyPolicy', Date.now())
        : multiStorage.removeItem('lastSeenPrivacyPolicy');
    },
  });

  const version = actualPrivacyPolicy?.version;
  const content = actualPrivacyPolicy?.content;
  const termsOfUse = actualPrivacyPolicy?.termsOfUse;

  const handleSuccess = () => {
    setIsOpen(false);

    const lastPath = sessionStorage.getItem('lastPath');
    sessionStorage.removeItem('lastPath');

    const updatedUser = _.cloneDeep(user);
    updatedUser.privacyPolicy = { version, __typename: 'privacyPolicy' };

    multiStorage.setItem('user', JSON.stringify(updatedUser));
    client.writeQuery({ query: USER, data: { user: updatedUser } });

    trackSavePrivacyPolicy();

    if (lastPath) {
      history.push(lastPath);
    }
  };

  const handleError = () => {
    setLoading(false);
    trackSavePrivacyPolicy(true);
    if (screen === 'default') setScreen('reTry');
    if (screen === 'reTry') setScreen('reportError');
  };

  const handleReportError = () => {
    setLoading(false);
    setScreen('errorReported');
    trackClickedSendErrorReportAgreement();
  };

  const savePrivacyPolicyHandler = async () => {
    setLoading(true);

    if (!currentShopping || !currentShopping.slug)
      history.push({ pathname: '/shoppings', state: { lastRoute: pathname } }); // implementação temporária

    await savePrivacyOnUser({
      variables: {
        shoppingSlug: currentShopping.slug,
      },
    });
  };

  const setTextOff = () => {
    setSeePolicy(false);
    setSeeTerms(false);
  };

  const handleOpenHelpCenter = () => {
    window.openExternalContent(process.env.SALES_FORCE_URL, { forceBrowser: isAndroid });
    try {
      const data = {
        mixpanel: {
          name: 'Contacted Us from Modal',
        },
      };
      window.track(data);
    } catch (e) {
      bugsnag.notify(e);
    }
  };

  const trackClickedOpenTermsAgreement = () => {
    try {
      const data = {
        mixpanel: {
          name: 'Clicked Open Terms Agreement',
        },
      };
      window.track(data);
    } catch (e) {
      bugsnag.notify(e);
    }
  };

  const trackSavePrivacyPolicy = (error) => {
    try {
      const properties = error
        ? {
            'Policy and Terms Version': version,
            Source: 'Modal',
            'Error Message': errorMessage,
          }
        : {
            'Policy and Terms Version': version,
            Source: 'Modal',
          };

      if (screen !== 'default') properties['Try Again'] = true;

      const data = {
        mixpanel: {
          name: error ? 'Error Policy and Terms' : 'Policy and Terms Accepted',
          properties,
        },
      };

      window.track(data);
    } catch (e) {
      bugsnag.notify(e);
    }
  };

  const trackClickedSendErrorReportAgreement = () => {
    try {
      const data = {
        mixpanel: {
          name: 'Send Error Report Agreement',
          'Error Message': errorMessage,
        },
      };
      window.track(data);
    } catch (e) {
      bugsnag.notify(e);
    }
  };

  const trackUpdateRegisterModal = () => {
    try {
      const data = {
        mixpanel: {
          name: 'Privacy Policy Modal',
        },
      };
      window.track(data);
    } catch (e) {
      bugsnag?.notify(e);
    }
  };

  useEffect(() => {
    trackUpdateRegisterModal();
  }, []);

  const SeePolicyOrTerms = () => (
    <div className={styles.modalText}>
      <div className={styles.closeButtonContainer}>
        <button className={styles.closeButton} onClick={() => setTextOff()}>
          <CloseIcon className={styles.closeButtonIcon} />
        </button>
      </div>
      <div className={styles.scroller}>
        {seeTerms && termsOfUse && <div className={styles.textHolder}>{ReactHtmlParser(termsOfUse)}</div>}
        {seePolicy && content && <div className={styles.textHolder}>{ReactHtmlParser(content)}</div>}
      </div>
    </div>
  );

  const Default = () => (
    <div className={classNames(styles.modalRoot, { [styles.fullscreen]: !isModal })}>
      <FileMulti className={styles.shieldCheck} />
      <h1 className={styles.title}>Atualizamos nossos Termos de Uso</h1>
      <p className={styles.description}>
        <strong>Novidade!</strong> <br /> Agora todos os usuários do Multi <br /> participam das vantagens do <br />
        MultiVocê!
      </p>
      <strong className={styles.instructions}>
        Consulte nossos Termos de Uso com o <br /> novo regulamento
      </strong>
      <Button
        flat
        className={styles.flatButton}
        onClick={() => {
          setSeeTerms(true);
          trackClickedOpenTermsAgreement();
        }}
      >
        Ver Termos de Uso
      </Button>
      <Button
        id="buttonAcceptNewPrivacyPolicy"
        className={styles.acceptButton}
        disabled={loading}
        onClick={() => savePrivacyPolicyHandler()}
      >
        {loading ? <div className={styles.loading} /> : 'entendi'}
      </Button>
    </div>
  );

  const ReTry = () => (
    <div className={classNames(styles.modalRoot, { [styles.fullscreen]: !isModal })}>
      <ReloadIcon className={styles.icon} />
      <h1 className={styles.reTryTitle}>Não foi possível concluir essa ação</h1>
      <Button className={styles.acceptButton} disabled={loading} onClick={() => savePrivacyPolicyHandler()}>
        {loading ? <div className={styles.loading} /> : 'tentar novamente'}
      </Button>
    </div>
  );

  const ReportError = () => (
    <div className={classNames(styles.modalRoot, { [styles.fullscreen]: !isModal })}>
      <AlertIcon className={styles.icon} />
      <h1 className={styles.reTryTitle}>Não foi possível concluir essa ação</h1>
      <Button className={styles.acceptButton} disabled={loading} onClick={() => handleReportError()}>
        {loading ? <div className={styles.loading} /> : 'reportar erro'}
      </Button>
    </div>
  );

  const ErrorReported = () => (
    <div className={classNames(styles.modalRoot, { [styles.fullscreen]: !isModal })}>
      <ConfirmIcon className={styles.icon} />
      <h1 className={styles.reTryTitle}>Recebemos sua solicitação</h1>
      <p className={styles.reTryText}>Estamos analisando essa ocorrência e breve entraremos em contato.</p>
      <div className={styles.helpCenter} onClick={handleOpenHelpCenter}>
        <Help className={styles.iconHelp} /> Ver Central de Ajuda <ChevronRight className={styles.iconHelp} />
      </div>
    </div>
  );

  const ComponentToShow = useMemo(() => {
    if (seePolicy || seeTerms) return SeePolicyOrTerms;
    switch (screen) {
      case 'default':
        return Default;
      case 'reTry':
        return ReTry;
      case 'reportError':
        return ReportError;
      case 'errorReported':
        return ErrorReported;
    }
  }, [seePolicy, seeTerms, screen]);

  if (!isModal) {
    return <ComponentToShow />;
  } else {
    return (
      <Modal
        isOpen={isOpen}
        className={styles.modal}
        hasOutsideClick={false}
        onClose={() => setTextOff()}
        hasScroll={seePolicy || seeTerms}
      >
        <ComponentToShow />
      </Modal>
    );
  }
};

PrePrivacyPolicy.propTypes = {
  content: PropTypes.string || PropTypes.node,
  termsOfUse: PropTypes.string,
  user: PropTypes.object,
  version: PropTypes.number,
  isError: PropTypes.bool,
  isModal: PropTypes.bool,
};

PrePrivacyPolicy.defaultProps = {
  content: '',
  termsOfUse: '',
  user: {},
  version: null,
  isError: false,
  isModal: false,
};

export default PrePrivacyPolicy;
