import React, { createContext, useContext } from 'react';
import { node } from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { APPS } from 'services/apollo/queries';
import { useCurrentShopping } from 'hooks';

export const AppsContext = createContext();

const AppsProvider = ({ children }) => {
  const currentShopping = useCurrentShopping(true);
  const appsList = {};

  const { data: { apps = [] } = {}, loading } = useQuery(APPS, {
    variables: {
      shoppingId: currentShopping.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  apps.forEach((app) => (appsList[app.slug] = true));

  const shoppingHasApp = (appSlug) => {
    return appsList[appSlug];
  };

  return <AppsContext.Provider value={{ apps, loading, shoppingHasApp }}>{children}</AppsContext.Provider>;
};

const useApps = () => {
  const context = useContext(AppsContext);

  if (!context) {
    throw new Error("You don't have access to apps context.");
  }

  return context;
};

export { AppsProvider, useApps };

AppsProvider.propTypes = {
  children: node.isRequired,
};
