import React, { useEffect, useState } from 'react';

import { useUser } from 'hooks';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { SignInMultiModal } from 'components/SignInMultiModal';

import { isIphoneX, bugsnag, classNames } from 'utils';
import { useSafeArea } from 'hooks';

import styles from './index.module.scss';

const BottomMenu = ({ badge, className, icon, href, disabled, active, text, highlighted, index }) => {
  const location = useLocation();
  const history = useHistory();
  const user = useUser();
  const safeArea = useSafeArea();
  const [logInModal, setLogInModal] = useState(false);
  const { pathname } = location;
  const [svgText, setSvgText] = useState();

  useEffect(() => {
    (async () => {
      const request = await fetch(icon);
      const svgTextReq = await request?.text();
      setSvgText(svgTextReq);
    })();
  }, []);

  const handleOptionClick = () => {
    if (href === '/apps/multivoce/enviar-notas' && !user) {
      setLogInModal(true);
    } else {
      try {
        window.track({
          mixpanel: {
            name: 'Clicked Tabs Option',
            properties: {
              'Option Text': text,
              'Virtual Page Path': history?.location.pathname,
            },
          },
          googleTagManager: {
            global: {
              name: 'clickedTabsOption',
              properties: {
                optionText: text,
              },
            },
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }
      if (pathname !== href) {
        history.push(href);
      }
    }
  };

  const handleCloseLogInModal = () => {
    setLogInModal(false);
  };

  return (
    <>
      <div
        className={classNames(
          styles.container,
          styles.natalIcon,
          {
            [styles.containerIphoneX]: isIphoneX(),
            [styles.safeArea]: safeArea,
          },
          className,
        )}
        data-testid={`bottomMenu${text}`}
      >
        <button className={styles.containerButton} disabled={disabled} onClick={handleOptionClick}>
          <div
            className={classNames(
              styles.item,
              { [styles.active]: active },
              { [styles.containerBadge]: badge },
              highlighted && index === 2 && styles.notes,
            )}
          >
            {svgText && (
              <div
                dangerouslySetInnerHTML={{ __html: svgText }}
                className={highlighted && index === 2 && styles.icon}
              ></div>
            )}
            {!svgText && <div className={styles.itemPlaceholder} />}
            <span className={styles.itemText}>{text}</span>
          </div>
        </button>
        <SignInMultiModal show={logInModal} onClose={handleCloseLogInModal} />
      </div>
    </>
  );
};

BottomMenu.propTypes = {
  icon: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  badge: PropTypes.bool,
  highlighted: PropTypes.bool,
  index: PropTypes.number,
};

BottomMenu.defaultProps = {
  className: null,
  badge: false,
  disabled: false,
  active: false,
  highlighted: false,
  index: null,
};

export default BottomMenu;
