import React from 'react';

import { classNames } from 'utils';
import { useHistory } from 'react-router-dom';

import { useApps } from '../../contexts/apps';

import AppSlider from 'components/appSlider';
import BannerSlider from 'components/bannerSlider';
import { useUser, useUserPlan, useCurrentShopping } from 'hooks';
import FeaturedEvents from 'components/featuredEvents';
import FeaturedMovies from 'components/featuredMovies';
import { SelectedBenefitCarrousel } from 'components/SelectedBenefitCarrousel';
import SummaryCard from 'components/summaryCard';

import LlvSlider from '../llvSlider';
import WelcomeModal from '../welcomeModal';

import TheaterSlider from '../theaterSlider';
import MultiTheaterSlider from '../multiTheaterSlider';
import FeaturedStores from '../featuredStores';

import { clickedWelcomeBanner } from '../../tracking';
import MultiplanLogo from 'icons/multiplan_logo.svg';

import styles from './index.module.scss';

const MultiTab = () => {
  const user = useUser();
  const plan = useUserPlan();
  const currentShopping = useCurrentShopping();
  const isPKJ = currentShopping?.slug === 'parkjacarepagua';
  const history = useHistory();
  const variant = plan.variant;

  const { shoppingHasApp } = useApps();

  const handleClick = () => {
    clickedWelcomeBanner();
    history.push('/apps/multivoce');
  };

  return (
    <>
      <div className={styles.welcome}>
        <div className={styles.appSlider}>
          <AppSlider variant="slim" from={'showcase'} showAllAppsButton numAppsToShow={7} />
        </div>
        {!!user && plan?.name ? (
          <div className={styles.multivcContainer}>
            <div className={classNames(styles.header, styles[variant])}>
              <div className={styles.headerFirstDiv}>
                <MultiplanLogo className={styles.logo} /> Benefícios{' '}
                <strong className={styles.slogan}>MultiVocê</strong>
              </div>
              <span className={styles.seeMore} onClick={handleClick}>
                Ver mais
              </span>
            </div>

            <SummaryCard path="showcase" />
          </div>
        ) : (
          <WelcomeModal />
        )}
        <div className={styles.selectedBenefitCarrouselContainer}>
          <SelectedBenefitCarrousel focus="showcaseHome" doots={false} />
        </div>
      </div>
      <BannerSlider path="/showcase" position={2} />
      {shoppingHasApp('eventos') && <FeaturedEvents />}
      {isPKJ && shoppingHasApp('teatro-multi') && <MultiTheaterSlider />}
      <BannerSlider path="/showcase" position={3} slidesToShow={1.7} slidesToScroll={1.7} />
      {shoppingHasApp('cinema') && <FeaturedMovies />}
      <BannerSlider path="/showcase" position={1} />

      {shoppingHasApp('teatro') && !shoppingHasApp('teatro-multi') && <TheaterSlider />}
      {!isPKJ && shoppingHasApp('teatro-multi') && <MultiTheaterSlider />}
      {shoppingHasApp('lapis-vermelho') && <LlvSlider />}
      {shoppingHasApp('gastronomia') && <FeaturedStores slug={'gastronomia'} />}
      {(shoppingHasApp('marketplace') || shoppingHasApp('lojas')) && <FeaturedStores slug={'marketplace'} />}
    </>
  );
};

export default MultiTab;
