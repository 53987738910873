import React, { useState } from 'react';
import Flex from 'components/flex';
import Typography from 'components/typography';
import Modal from 'components/modal';
import { getVariant, classNames } from 'utils';
import { useUserPlan } from 'hooks';

import { colors } from 'theme';
import ChevronRight from 'icons/chevron-right.svg';
import CloseIcon from 'icons/close.svg';

import IconStar from 'icons/star.svg';

import styles from './styles.module.scss';

const FavoriteBenefitTutorial = () => {
  const plan = useUserPlan(true);

  const variant = plan?.name ? getVariant(plan?.name) : 'green';

  const storeTutorialValue = multiStorage.getItem('@multivc:hide-favorite-tutorial-value');
  const lastRoute = sessionStorage.getItem('lastRoute');
  const lastRouteObj = JSON.parse(lastRoute);
  const comesFromBenefit = lastRouteObj?.route.includes('/apps/multivoce/beneficios/tickets/');

  const showTutorial = !storeTutorialValue && comesFromBenefit;

  const [isOpen, setIsOpen] = useState(showTutorial);

  const variantColors = {
    green: colors.olive[5],
    silver: colors.gray[5],
    gold: colors.yellow[13],
  };

  const handleClose = () => {
    multiStorage.setItem('@multivc:hide-favorite-tutorial-value', true);

    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} className={classNames(styles.modal, styles[variant])} onClose={handleClose}>
      <Flex direction="column" bgColor={colors.common.white} padding="16px" borderRadius="15px">
        <Flex justify="flex-end">
          <div onClick={handleClose}>
            <CloseIcon width="12px" height="12px" fill={colors.gray[5]} />
          </div>
        </Flex>
        <Flex direction="column" mb="16px">
          <div
            className={styles.numberContainer}
            style={{
              background: variantColors[variant],
            }}
          >
            <Typography color={colors.common.white} variant="body3">
              1
            </Typography>
          </div>
          <div className={styles.starContainer}>
            <IconStar width="20px" height="20px" fill={variantColors[variant]} />
          </div>
          <Typography align="center" color={colors.gray[7]} mt="8px" mb="8px">
            <strong>
              Seus benefícios <br /> favoritos a um clique
            </strong>
          </Typography>
          <Typography align="center">
            Use a estrela para marcar benefícios e os acesse sempre que desejar, clicando em {'"favoritos"'}.
          </Typography>
        </Flex>

        <button className={styles.modalButton} onClick={handleClose}>
          <Typography color={variantColors[variant]}>Entendi</Typography>
          <ChevronRight width="5px" height="9px" fill={variantColors[variant]} />
        </button>
      </Flex>
    </Modal>
  );
};

export { FavoriteBenefitTutorial };
