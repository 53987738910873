const phoneNumberMask = (tel) => {
  let telTemp = tel;

  telTemp = telTemp.replace(/\D/g, '');
  telTemp = telTemp.replace(/^(\d)/, '+$1');
  telTemp = telTemp.replace(/(.{3})(\d)/, '$1($2');
  telTemp = telTemp.replace(/(.{6})(\d)/, '$1)$2');
  if (telTemp.length === 12) {
    telTemp = telTemp.replace(/(.{1})$/, '-$1');
  } else if (telTemp.length === 13) {
    telTemp = telTemp.replace(/(.{2})$/, '-$1');
  } else if (telTemp.length === 14) {
    telTemp = telTemp.replace(/(.{3})$/, '-$1');
  } else if (telTemp.length === 15) {
    telTemp = telTemp.replace(/(.{4})$/, '-$1');
  } else if (telTemp.length > 15) {
    telTemp = telTemp.replace(/(.{4})$/, '-$1');
  }
  return telTemp.replace('+55', '');
};

export default phoneNumberMask;
