const avatarInitials = (name) => {
  if (!name) return;
  const usernameInitials = name
    .split(' ')
    .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
    .join('');
  return usernameInitials.toUpperCase();
};

export default avatarInitials;
