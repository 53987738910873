import { useCallback } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { useUserPlan, useCurrentShopping, useCountBenefits } from 'hooks';
import { BENEFITS_CAROUSEL } from '../components/SelectedBenefitCarrousel/graphql/queries';
import { FAVORITE_BENEFIT } from '../screens/apps/fidelity/benefit/graphql/mutations';
import { useBenefitListProvider } from '../reducer/benefitList';

export const useFavoriteBenefit = () => {
  const client = useApolloClient();
  const plan = useUserPlan();
  const currentShopping = useCurrentShopping();
  const { increment, decrease } = useCountBenefits();
  const { dispatch, toggleFavorite } = useBenefitListProvider();

  const changeFavoriteValue = (id, isFavorite) => {
    dispatch(toggleFavorite(id, isFavorite));
  };

  const [fetchFavorite] = useMutation(FAVORITE_BENEFIT);

  const { data: { benefits: selectedsBenefits } = [] } = useQuery(BENEFITS_CAROUSEL, {
    variables: {
      limit: 4,
      shoppingSlug: currentShopping?.slug,
      modalities: [plan.variant],
      featured: true,
    },
  });

  const toggleFavoriteInList = useCallback(
    async (id, isFavorite) => {
      const benefitExists = selectedsBenefits?.some((item) => item.id === id);

      if (benefitExists) {
        const selectedBenefitsUpdated = selectedsBenefits?.map((item) =>
          item.id === id ? { ...item, isFavorite } : item,
        );

        client.writeQuery({ query: BENEFITS_CAROUSEL, data: { benefits: selectedBenefitsUpdated } });
      }

      changeFavoriteValue(id, isFavorite);

      if (isFavorite) {
        increment();
      } else {
        decrease();
      }

      await fetchFavorite({
        variables: { benefitId: id, isFavorite, shoppingSlug: currentShopping?.slug },
      });
    },
    [client, selectedsBenefits, fetchFavorite, currentShopping, increment, decrease],
  );

  return toggleFavoriteInList;
};
