import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import styles from './index.module.scss';

const Scroller = ({ className, vertical, horizontal, children, endPosition, withShadow, elRef }) => (
  <div
    ref={elRef}
    className={classNames(styles.root, className, {
      [styles.vertical]: vertical,
      [styles.horizontal]: horizontal,
      [styles.withShadow]: withShadow,
      [styles.endPosition]: endPosition,
    })}
  >
    {children}
  </div>
);

const FancyScroller = ({ pageName, withShadow, ...props }, ref) => {
  const elRef = useRef();
  const [endPosition, setEndPosition] = useState(false);
  const scrollPosition = sessionStorage.getItem('scrollPosition') || '{}';
  const positionStorage = JSON.parse(scrollPosition)[pageName];

  useEffect(() => {
    const handleScroll = () => {
      if (elRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = elRef.current;
        const atBottom = scrollTop + clientHeight >= scrollHeight;
        setEndPosition(atBottom);
      }
    };

    const currentEl = elRef.current;
    if (currentEl && withShadow) {
      const { scrollHeight, clientHeight } = currentEl;
      setEndPosition(scrollHeight <= clientHeight);

      currentEl.addEventListener('scroll', handleScroll);
    }

    if (positionStorage) {
      elRef.current?.scrollTo(0, positionStorage);
    }

    return () => {
      if (currentEl) {
        currentEl.removeEventListener('scroll', handleScroll);
      }

      if (pageName) {
        const newScrollPosition = elRef.current?.scrollTop;
        if (newScrollPosition) {
          const newScrollPositionPages = {
            ...JSON.parse(scrollPosition),
            [pageName]: newScrollPosition,
          };
          sessionStorage.setItem('scrollPosition', JSON.stringify(newScrollPositionPages));
        }
      }
    };
  }, [elRef, pageName]);

  const scrollTo = (top) => {
    elRef.current.scrollTo({
      top,
      behavior: 'smooth',
    });
  };

  const scrollToTop = () => {
    elRef.current.scrollTo(0, 0);
  };

  const scrollToBottom = () => {
    scrollTo(elRef.current.scrollHeight);
  };

  useImperativeHandle(ref, () => ({
    ...{ scrollTo, scrollToTop, scrollToBottom },
  }));

  return <Scroller elRef={elRef} endPosition={endPosition} withShadow={withShadow} {...props} />;
};

FancyScroller.propTypes = {
  pageName: PropTypes.string,
  withShadow: PropTypes.bool,
};

FancyScroller.defaultProps = {
  pageName: null,
  withShadow: false,
};

Scroller.propTypes = {
  children: PropTypes.any.isRequired,
  vertical: PropTypes.bool,
  horizontal: PropTypes.bool,
  elRef: PropTypes.object,
  withShadow: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endPosition: PropTypes.bool,
};

Scroller.defaultProps = {
  vertical: true,
  horizontal: false,
  fullScreen: false,
  className: null,
  withShadow: false,
  elRef: null,
  endPosition: false,
};

export default forwardRef(FancyScroller);
