import { useApolloClient, useQuery } from '@apollo/react-hooks';

import { useCurrentShopping, useUserLocation } from 'hooks';

import { CART } from './graphql/queries';
import { CURRENT_CART_STORE_ID } from 'services/apollo/queries';

const useCart = ({ current = false } = {}) => {
  const client = useApolloClient();
  const currentShopping = useCurrentShopping();
  const location = useUserLocation();
  const { data: { cart } = {} } = useQuery(CART, {
    variables: {
      shoppingId: currentShopping.id,
      location: location || undefined,
    },
    fetchPolicy: 'cache-and-network',
  });
  const { currentCartStoreId } = client.readQuery({ query: CURRENT_CART_STORE_ID });

  if (current) {
    const currentItems = cart?.items.filter((item) => item.product.store.id === currentCartStoreId);
    const currentAmount = currentItems?.reduce((total, item) => item.price * item.quantity + total, 0);
    return { ...cart, items: currentItems, amount: currentAmount };
  }

  return cart;
};

export default useCart;
