const goToStoreDetails = ({ history, appSlug, store, hasMarketplace, hasReservation = false }) => {
  const finalAppSlug = store.gastronomy
    ? 'gastronomia'
    : appSlug === 'gastronomia'
    ? hasMarketplace
      ? 'marketplace'
      : appSlug
    : appSlug;
  const isMarketplace = finalAppSlug === 'marketplace';
  const appPath = `apps/${finalAppSlug}${isMarketplace ? '/lojas' : ''}`;
  hasReservation && multiStorage.setItem('lastRoute', 'reservas');
  history.push(`/${appPath}/${store.slug}`);
};

export default goToStoreDetails;
