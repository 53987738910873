import React from 'react';
import { Waypoint } from 'react-waypoint';
import { Switch, Route } from 'react-router-dom';
import { object } from 'prop-types';

import { classNames } from 'utils';

import Navbar from 'components/navbar';
import Screen from 'components/screen';
import Scroller from 'components/scroller';
import SummaryCard from 'components/summaryCard';
import ShoppingDisplay from 'components/shoppingDisplay';
import IconButtonPopUpNumber from 'components/iconButtonPopUpNumber';
import { FavoriteBenefitTutorial } from 'components/favoriteBenefitTutorial';
import { SelectedBenefitCarrousel } from 'components/SelectedBenefitCarrousel';
import { BenefitListComponent } from './components/BenefitListComponent';
import SellerCodeModal from './components/sellerCodeModal';
import FidelityModal from './components/fidelityModal';
import BannerSlider from '../../../../components/bannerSlider';

import { TutorialModal } from './TutorialModal';
import { TutorialFilterModal } from './tutorialFilterModal';
import { useFidelityHome } from './useFidelityHome';

import MultiLettersSvg from './images/multi-letters.svg?url';
import NoLoggedBannerSvg from './images/no-logged-banner.svg?url';

import styles from './index.module.scss';
import './index.scss';
import { ResearchModalityModal } from './components/ResearchModalityModal';

const FidelityHome = ({ history }) => {
  const {
    variant,
    variantStyle,
    isLogged,
    destinyBackButton,
    showShoppingDisplay,

    fidelityPageLoading,

    showFidelityModal,
    countBenefits,
    tutorialModal,

    countUsedBenefits,
    benefitsAdvantageCount,

    handleCloseFidelityModal,
    goToStatement,
    goToFavorite,
    goToClaimed,
    goToReadyToUse,
    goToUsed,
    handleShowShoppingDisplay,

    handleSeeInvoices,
    handleCloseLoginModal,
    handleGoToSignIn,
    handleCloseTutorial,
  } = useFidelityHome();

  const SummaryCardInfo = () => {
    if (!isLogged) {
      return (
        <div className={styles.noLoggedContainer}>
          <img src={MultiLettersSvg} alt="" className={styles.multiLetterSvg} />
          <strong className={styles.noLoggedTitle}>Venha para o MultiVocê!</strong>
          <span className={styles.noLoggedText}>
            Aproveite os benefícios exclusivos do nosso programa de relacionamento
          </span>
          <button className={styles.signInButton} data-testid={`enterBtnMvc`} onClick={handleGoToSignIn}>
            Entrar
          </button>

          <img
            className={styles.noLoggedBannerImage}
            src={NoLoggedBannerSvg}
            alt=""
            onClick={() => history.push('/apps/multivoce/abas/green')}
          />
        </div>
      );
    }

    return (
      <>
        <div className={styles.summaryCardContainer}>
          <SummaryCard />
        </div>

        <div className={styles.buttonsOutlineContainer}>
          <button className={styles.buttonOutline} onClick={goToStatement}>
            Ver extrato
          </button>
          <button className={styles.buttonOutline} onClick={handleSeeInvoices}>
            Notas enviadas
          </button>
        </div>
        <SelectedBenefitCarrousel focus="mvcHome" />

        <BannerSlider path="/apps/multivoce" position={0} />
      </>
    );
  };

  return (
    <Screen className={classNames(styles.screen, styles[variantStyle])} hasBottomMenu loading={fidelityPageLoading}>
      <>
        <Navbar showBackButton destinyBackButton={destinyBackButton} title="MultiVocê" titleClass={styles.title} />

        <ShoppingDisplay
          className={classNames(styles.locationContainer, {
            [styles.isHidden]: !showShoppingDisplay,
          })}
          variant={variantStyle}
        />
        <Scroller className={styles.scroller}>
          <div className={styles.top}>
            <SummaryCardInfo />
            {isLogged && (
              <>
                <div className={styles.benefits}>
                  <strong className={styles.benefitsTitle}>Meus Benefícios</strong>

                  <div className={styles.buttonsContainer}>
                    {variant !== 'green' && Number(benefitsAdvantageCount.countBenefits) !== 0 && (
                      <IconButtonPopUpNumber
                        type={'ticket'}
                        color={variant}
                        popUpCount={benefitsAdvantageCount.countBenefits}
                        onClick={goToClaimed}
                      />
                    )}
                    <IconButtonPopUpNumber
                      type={'star'}
                      color={variant}
                      popUpCount={countBenefits}
                      onClick={goToFavorite}
                    />
                    <IconButtonPopUpNumber
                      type={'wallet'}
                      color={variant}
                      popUpCount={countUsedBenefits}
                      onClick={goToReadyToUse}
                    />
                    <IconButtonPopUpNumber type={'check'} color={variant} onClick={goToUsed} />
                  </div>
                </div>
                <div className={styles.benefitsBoxContainerDivider} />
              </>
            )}
          </div>
          <BenefitListComponent variant={variant} path="apps/multivoce" focus="home" />
        </Scroller>
        <FidelityModal show={showFidelityModal} onClose={handleCloseFidelityModal} />
        <Switch>
          <Route
            path="/apps/multivoce/seller-code-modal"
            exact
            render={() => <SellerCodeModal isOpen onClose={handleCloseLoginModal} />}
          />
        </Switch>
        {tutorialModal && isLogged && (
          <TutorialModal
            isOpen={tutorialModal}
            handleClose={handleCloseTutorial}
            variant={variantStyle}
            summaryCard={<SummaryCard disableClick={true} />}
          />
        )}
        {isLogged && <FavoriteBenefitTutorial />}
        {isLogged && <TutorialFilterModal />}
        {isLogged && <ResearchModalityModal />}
      </>
    </Screen>
  );
};

FidelityHome.propTypes = {
  history: object.isRequired,
};

export default FidelityHome;
