import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { bool, node } from 'prop-types';

import { bugsnag } from 'utils';

import ShoppingDisplay from 'components/shoppingDisplay';

import CloseIcon from 'icons/close.svg';

import styles from './index.module.scss';

const TutorialWrapper = ({ children, closeButton }) => {
  const history = useHistory();

  const sendClickedSkipOnboardingTracking = () => {
    try {
      window.track({
        mixpanel: {
          name: 'Clicked Skip Onboarding',
        },
        googleTagManager: {
          global: {
            name: 'clickedSkipOnboarding',
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
  };

  const handleCloseClick = useCallback(() => {
    sendClickedSkipOnboardingTracking();
    history.replace('/showcase');
  }, [history]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.welcomeBox}>
        <ShoppingDisplay />
      </div>
      {closeButton && (
        <div className={styles.closeButton} onClick={handleCloseClick}>
          fechar <CloseIcon />
        </div>
      )}
      {children}
    </div>
  );
};

TutorialWrapper.propTypes = {
  children: node.isRequired,
  closeButton: bool,
};

TutorialWrapper.defaultProps = {
  closeButton: true,
};

export default TutorialWrapper;
