import { useQuery } from '@apollo/react-hooks';
import { useCurrentShopping } from 'hooks';
import { SELECTED_DELIVERY_TYPE, DELIVERY_TYPE } from 'services/apollo/queries';

const useSelectedDeliveryType = () => {
  const currentShopping = useCurrentShopping();
  const { data: { selectedDeliveryType } = {} } = useQuery(SELECTED_DELIVERY_TYPE);
  const { data: { deliveryType } = {} } = useQuery(DELIVERY_TYPE, {
    variables: { slug: 'receber-em-casa', shoppingSlug: currentShopping.slug },
  });

  return selectedDeliveryType || deliveryType;
};

export default useSelectedDeliveryType;
