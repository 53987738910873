import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/modal';
import Button from 'components/button';

import Thumb from 'icons/thumb_rating_up_icon.svg';

import styles from './index.module.scss';

const SellerCodeModal = ({ isOpen, onClose }) => (
  <Modal className={styles.modal} {...{ onClose, isOpen }}>
    <Thumb className={styles.modalThumb} />
    <p className={styles.modalText}>
      Obrigado, você ajudou <br />
      um(a) vendedor(a) do seu <br />
      shopping favorito
    </p>
    <Button size={'full'} onClick={onClose} type="primary" className={styles.modalButton}>
      FECHAR
    </Button>
  </Modal>
);
SellerCodeModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

SellerCodeModal.defaultProps = {
  isOpen: true,
  onClose: () => null,
};

export default SellerCodeModal;
