import gql from 'graphql-tag';

const CHANGE_PHONE_NUMBER = gql`
  query changePhoneNumber {
    changePhoneNumber @client {
      cpf
      email
      provider
      oldPhoneNumber
      newPhoneNumber
    }
  }
`;

export default CHANGE_PHONE_NUMBER;
export { CHANGE_PHONE_NUMBER };
