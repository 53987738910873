import { lazy } from 'react';

const Home = lazy(() => import(/* webpackChunkName: "store" */ 'screens/apps/store/home'));
const Store = lazy(() => import(/* webpackChunkName: "store" */ 'screens/apps/store/store'));
const Search = lazy(() => import(/* webpackChunkName: "store" */ 'screens/apps/store/home/search'));
const CategoriesFilter = lazy(() => import(/* webpackChunkName: "store" */ 'screens/apps/store/home/categoriesFilter'));

export default [
  {
    path: '/apps/lojas',
    exact: true,
    component: Home,
  },
  {
    path: '/apps/lojas/busca',
    exact: true,
    component: Search,
  },
  {
    path: '/apps/lojas/categorias',
    exact: true,
    component: CategoriesFilter,
  },
  {
    path: '/apps/lojas/:slug',
    exact: true,
    component: Store,
  },
];
