const formatDateRange = (startDate, endDate) => {
  const formatDayAndMonth = (date) => {
    return new Date(date).toLocaleString('pt-BR', {
      month: '2-digit',
      day: '2-digit',
    });
  };

  if (startDate === endDate) return formatDayAndMonth(startDate);

  const start =
    new Date(startDate).getUTCMonth() === new Date(endDate).getUTCMonth()
      ? new Date(startDate).getUTCDate()
      : formatDayAndMonth(startDate);
  const end = formatDayAndMonth(endDate);

  return `De ${start} a ${end}`;
};

export default formatDateRange;
