import { useQuery, useMutation } from '@apollo/react-hooks';

import { useUser } from 'hooks';

import { GET_USER_VIEW_HISTORY } from 'services/apollo/queries';
import { SAVE_USER_VIEW_HISTORY } from 'services/apollo/mutations';

const useUserViewHistory = () => {
  const user = useUser();

  const getHistory = (entity, entityId, viewSlug) => {
    const { data: { getUserViewHistory } = {}, loading } = useQuery(GET_USER_VIEW_HISTORY, {
      fetchPolicy: 'network-only',
      variables: { entity, entityId, viewSlug },
      skip: !user || !entity || !entityId,
    });

    return { data: getUserViewHistory, loading };
  };

  const saveHistory = (entity, entityId, viewSlug) => {
    const [saveUserViewHistory] = useMutation(SAVE_USER_VIEW_HISTORY, {
      variables: {
        entity,
        entityId,
        viewSlug,
      },
    });

    return saveUserViewHistory;
  };

  return { getHistory, saveHistory };
};

export default useUserViewHistory;
