import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import Modal from 'components/modal';
import styles from './index.module.scss';

const SlideModal = ({ isOpen, onClose, children, className, rightSide, hasOutsideClick }) => (
  <Modal
    {...{ isOpen, onClose, rightSide, hasOutsideClick }}
    className={classNames(!rightSide ? styles.slideModal : styles.slideModalRight, className)}
    slide={!rightSide}
  >
    {children}
  </Modal>
);

SlideModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  rightSide: PropTypes.bool,
  hasOutsideClick: PropTypes.bool,
};

SlideModal.defaultProps = {
  className: '',
  rightSide: false,
  hasOutsideClick: true,
};

export default SlideModal;
