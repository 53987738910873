const defaultText = 'Oi, consegui seu contato pelo app Multi e gostaria de fazer um pedido.';

const handleWhatsapp = (whatsappNumber, text = defaultText) => {
  if (!whatsappNumber) return;
  const splitWA = whatsappNumber.split('+');
  whatsappNumber = splitWA.length > 1 ? splitWA[1] : splitWA[0];
  window.openExternalContent(`https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${text}`, {
    forceBrowser: true,
  });
};

export default handleWhatsapp;
