import gql from 'graphql-tag';

const CART = gql`
  query cart($appSlug: String, $shoppingId: ID!, $location: LocationInput) {
    cart(appSlug: $appSlug, shoppingId: $shoppingId) {
      badge
      amount
      items {
        id
        price
        quantity
        description
        product {
          ... on Menu {
            id
            name
            items {
              id
              options {
                id
                name
              }
            }
            store(location: $location) {
              id
              name
              hasContract
              shippingValue
              location {
                token
              }
              delivery
              opened
            }
          }
          ... on Product {
            id
            name
            cover
            quantity
            variations {
              type
              values {
                label
                value
              }
            }
            customAttributes {
              label
              value
            }
            store(location: $location) {
              id
              name
              hasContract
              shippingValue
              location {
                token
              }
              delivery
              opened
            }
          }
        }
      }
    }
  }
`;

export { CART };
