const DDDRemove = (phone) => {
  let number = phone.split('/')[0].trim().replace('+55', '');
  if (number.indexOf('(') >= 0) {
    number = number.split(')')[1].trim();
  } else {
    const beforeSpace = number.split(' ')[0];
    if (beforeSpace.length === 2 || beforeSpace.length === 3) {
      number = number.split(' ')[1];
    }
  }
  return number.replace(/-/g, '').trim();
};

export default DDDRemove;
