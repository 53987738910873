import React from 'react';
import { element, func, node, oneOf, string } from 'prop-types';
import styles from './index.module.scss';
import Flex from '../flex';
import { colors } from '../../theme';

const AlertBox = ({ children, variant }) => {
  const status = {
    success: colors.green[5],
    error: colors.red[5],
    info: colors.blue[5],
  };

  return (
    <Flex justify={'space-between'} gap={'16px'} bgColor={status[variant]} padding={'22px 16px'} position={'fixed'}>
      {children}
    </Flex>
  );
};

AlertBox.propTypes = {
  children: node.isRequired,
  variant: oneOf(['success', 'error', 'info']).isRequired,
};

const Divider = ({ height }) => <div style={{ maxHeight: height }} className={styles.divider}></div>;
Divider.propTypes = { height: string.isRequired };

const Icon = ({ icon: ElIcon, onClick, height, width, className }) => {
  const style = {
    height,
    width,
    color: colors.common.white,
  };
  return <ElIcon style={style} onClick={onClick} className={className} />;
};

Icon.propTypes = {
  icon: element.isRequired,
  onClick: func,
  height: string,
  width: string,
  className: string,
};
Icon.defaultProps = {
  onClick: () => {},
  height: '16px',
  width: '16px',
  className: '',
};

const Card = ({ children, ...props }) => (
  <Flex direction="column" align="flex-start" {...props}>
    {children}
  </Flex>
);
Card.propTypes = { children: node.isRequired };

AlertBox.Divider = Divider;
AlertBox.Icon = Icon;
AlertBox.Card = Card;

export default AlertBox;
