import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const SessionTokenContext = createContext({});

const SessionTokenProvider = ({ children }) => {
  const [sessionToken] = useState(uuidv4());

  return <SessionTokenContext.Provider value={{ sessionToken }}>{children}</SessionTokenContext.Provider>;
};

const useSessionToken = () => {
  const context = useContext(SessionTokenContext);

  if (!context) {
    throw new Error("You don't have access to session token context");
  }

  return context;
};

SessionTokenProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { SessionTokenProvider, useSessionToken };
