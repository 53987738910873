const trackClickedStoreReservationContact = ({ type, storeName }) => {
  const data = {
    mixpanel: {
      name: 'Clicked Store Modal Contact',
      properties: {
        'Contact Type': type,
        'Store Name': storeName,
      },
    },
  };

  window.track(data);
};

export default trackClickedStoreReservationContact;
