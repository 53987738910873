import React, { useState } from 'react';
import { bool, number, string } from 'prop-types';
import Slider from 'react-slick';
import { useHistory } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useQuery } from '@apollo/react-hooks';

import { useCurrentShopping, useUserPlan, useUser } from 'hooks';
import { classNames, bugsnag } from 'utils';

import Banner from '../banner';
import { BANNERS } from './graphql/queries';
import NoLoggedBannerSvg from './images/no-logged-banner.svg?url';

import styles from './index.module.scss';

import './index.scss';

const BannerSlider = ({ position, path, slidesToShow, slidesToScroll, className, autoplay, autoplaySpeed }) => {
  const currentShopping = useCurrentShopping();
  const plan = useUserPlan();

  const isFirstBanner = position === 1;
  const isThirdBanner = position === 3;
  const history = useHistory();
  const variant = plan?.name?.toLowerCase();
  const user = useUser();
  const modality = ['default'];
  if (variant) modality.push(variant);
  const { data: { banners } = {}, loading } = useQuery(BANNERS, {
    skip: !currentShopping || (user && !plan),
    variables: { shoppingsIds: [currentShopping.id], limit: 4, position, path, modalities: modality },
  });

  const [currentSlide, setCurrentSlide] = useState(0);

  const bannerSliderSettings = {
    arrows: false,
    autoplay,
    dots: !isThirdBanner ? true : false,
    infinite: true,
    speed: 750,
    autoplaySpeed,
    centerPadding: '60px',
    slidesToShow: banners?.length > 1 ? slidesToShow : 1,
    slidesToScroll,
    beforeChange: (current) => {
      setCurrentSlide(current);
    },
    customPaging: (i) => (
      <div
        className={i === currentSlide ? 'slick-active' : ''}
        style={{
          width: i === currentSlide ? '24px' : '6px',
          height: '6px',
          borderRadius: i === currentSlide ? '26px' : '50%',
          marginRight: '10px',
          marginLeft: '10px',
          backgroundColor: '#D9D9D9',
          position: 'relative',
        }}
      >
        <div
          style={{
            width: i === currentSlide ? '100%' : '0',
            height: '100%',
            borderRadius: '26px',
            backgroundColor: '#57CC78',
            position: 'absolute',
            transition: i === currentSlide && 'width 3.5s',
            top: 0,
            left: 0,
          }}
        />
      </div>
    ),
  };

  if (loading) return null;

  if (path === '/apps/multivoce' && (!banners || banners.length <= 0)) {
    const handleClickBanner = () => {
      try {
        window.track({
          mixpanel: {
            name: 'Clicked Banner',
            properties: {
              'Banner Position': position,
              'Banner Path': path,
            },
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }

      history.push('/apps/multivoce/abas/green');
    };
    return <img src={NoLoggedBannerSvg} alt="" onClick={handleClickBanner} className={styles.noLoggedBannerImage} />;
  }

  const isSingleBanner = banners?.length <= 1;
  const isSingleThirdBanner = isSingleBanner && isThirdBanner;

  if (!banners || banners.length <= 0) return null;

  return (
    <div
      className={classNames(styles.header, className, 'header-banner-showcase', {
        [styles.single]: isSingleBanner,
        [styles.firstBanner]: isFirstBanner,
        [styles.headerBottom]: isThirdBanner,
        [styles.headerBottomSingle]: isSingleThirdBanner,
        'third-slider': isThirdBanner,
      })}
    >
      <Slider className={styles.slider} {...bannerSliderSettings}>
        {banners.map((banner, index) => (
          <Banner
            key={banner.id}
            index={index}
            position={position}
            path={path}
            showLoginScreen={banner.showLoginScreen}
            {...banner}
          />
        ))}
      </Slider>
    </div>
  );
};

BannerSlider.propTypes = {
  position: number.isRequired,
  className: string,
  slidesToShow: number,
  slidesToScroll: number,
  path: string,
  autoplay: bool,
  autoplaySpeed: number,
};

BannerSlider.defaultProps = {
  className: null,
  slidesToShow: 1,
  slidesToScroll: 1,
  path: undefined,
  autoplay: false,
  autoplaySpeed: 3000,
};

export default BannerSlider;
