import propTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';

const BannerBox = ({ description, banner }) => {
  return (
    <div className={styles.bannerContainer}>
      <p className={styles.bannerDescription}>{description}</p>
      <div className={styles.bannerLine} />
      <img src={banner} />
    </div>
  );
};

BannerBox.propTypes = {
  description: propTypes.string.isRequired,
  banner: propTypes.string.isRequired,
};

export default BannerBox;
