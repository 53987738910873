import gql from 'graphql-tag';

const USE_BENEFIT = gql`
  mutation useBenefit($benefitId: ID!, $shoppingSlug: String!) {
    useBenefit(benefitId: $benefitId, shoppingSlug: $shoppingSlug) {
      id
      issueDate
      endDate
    }
  }
`;

const REDEEM_USER_VIP_BENEFIT = gql`
  mutation SaveRedeemUserVipBenefit($id: ID!, $shoppingSlug: String!, $storeCode: String) {
    saveRedeemUserVipBenefit(id: $id, shoppingSlug: $shoppingSlug, storeCode: $storeCode) {
      id
      issueDate
    }
  }
`;

const FAVORITE_BENEFIT = gql`
  mutation favoriteBenefit($shoppingSlug: String!, $benefitId: ID!, $isFavorite: Boolean!) {
    favoriteBenefit(shoppingSlug: $shoppingSlug, benefitId: $benefitId, isFavorite: $isFavorite) {
      id
    }
  }
`;

export { USE_BENEFIT, FAVORITE_BENEFIT, REDEEM_USER_VIP_BENEFIT };
