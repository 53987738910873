import { getDCFee, getTempForm } from 'utils';
import { useCart, useSelectedDeliveryType, useCurrentCoupon, useIsAnything } from 'hooks';

const useDCFee = () => {
  const isAnything = useIsAnything();
  const currentCoupon = useCurrentCoupon();
  const selectedDeliveryType = useSelectedDeliveryType();
  const { stores = [], price } = getTempForm() || {};
  const { items = [] } = useCart({ current: true }) || {};
  return getDCFee({ isAnything, currentCoupon, selectedDeliveryType, stores, price, items });
};

export default useDCFee;
