import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCurrentShopping, useUserPlan } from 'hooks';
import { useQuery } from '@apollo/react-hooks';
import { getFilterURLParams } from '../../screens/apps/fidelity/home/utils/getFilterURLParams';
import { BENEFITS } from '../../screens/apps/fidelity/benefits/graphql/queries';

import { useBenefitListProvider } from '../../reducer/benefitList';

const useBenefits = (variant, focus) => {
  const plan = useUserPlan();
  const currentShopping = useCurrentShopping(true);

  const { state, dispatch, updateBenefits } = useBenefitListProvider();
  const { benefits } = state;

  const setBenefits = (newBenefits) => {
    dispatch(updateBenefits(newBenefits));
  };

  const { filter } = useParams();
  const { categoriesParam, pointsFilterParam, recentParam, redeemParam, storesParam } = getFilterURLParams(filter);

  const [storesFilter, setStoresFilter] = useState(storesParam);

  const [categoriesFilter, setCategoriesFilter] = useState(categoriesParam);

  const [pointsFilter, setPointsFilter] = useState(pointsFilterParam);

  const [recentFilter, setRecentFilter] = useState(recentParam);
  const [redeemedFilter, setRedeemedFilter] = useState(redeemParam);

  const userPlan = plan.variant;

  const limit = 10;

  const { recentOrdering, redeemedOrdering } = useMemo(() => {
    return {
      recentOrdering: recentFilter ? { field: 'createdAt', sort: 'DESC' } : null,
      redeemedOrdering: redeemedFilter ? { field: 'endDate', sort: 'ASC' } : null,
    };
  }, [recentFilter, redeemedFilter]);

  const defaultQueryVariables = {
    limit,
    shoppingSlug: currentShopping?.slug,
    categories: categoriesFilter,
    storeCodes: storesFilter,
    startPoints: Number(pointsFilter.initialValue),
    endPoints: Number(pointsFilter.lastValue),
    isWithoutPoint: pointsFilter.isWithoutPoint,
    ordering: recentOrdering || redeemedOrdering,
  };

  const homeQueryVariables = {
    ...defaultQueryVariables,
    modalities: [variant],
  };

  const variantArrayText = {
    green: variant === userPlan ? ['green'] : ['green', 'silver', 'gold'],
    silver: userPlan === 'gold' ? ['silver', 'gold'] : ['silver'],
    gold: ['gold'],
  };

  const tierNameAndModalitiesLogic =
    variant === userPlan || variant === 'gold' || (variant === 'silver' && userPlan === 'green');

  const tableBenfitListQueryVariable = {
    ...defaultQueryVariables,
    modalitiesExact: tierNameAndModalitiesLogic ? false : variant !== userPlan,
    tierName: tierNameAndModalitiesLogic ? null : variant,
    modalities: variantArrayText[variant],
  };

  const queryVariables = {
    home: homeQueryVariables,
    'benefit-list': tableBenfitListQueryVariable,
  };

  const {
    data,
    fetchMore,
    loading: benefitLoading,
  } = useQuery(BENEFITS, {
    fetchPolicy: 'network-only',
    variables: queryVariables[focus],
    onCompleted: (data) => setBenefits(data.benefits),
  });

  return {
    data,
    benefits,
    setBenefits,
    fetchMore,
    benefitLoading,
    limit,

    storesFilter,
    setStoresFilter,
    categoriesFilter,
    setCategoriesFilter,
    pointsFilter,
    setPointsFilter,
    recentFilter,
    setRecentFilter,
    redeemedFilter,
    setRedeemedFilter,
  };
};

export { useBenefits };
