import gql from 'graphql-tag';

const BENEFITS = gql`
  query benefits(
    $shoppingSlug: String!
    $onlyFavorites: Boolean
    $modalities: [LoyaltyModality]
    $categories: [String]
    $storeCodes: [String]
    $type: BenefitType
    $limit: Int
    $skip: Int
    $ordering: [Sort]
    $isWithoutPoint: Boolean
    $startPoints: Int
    $endPoints: Int
    $storeId: ID
    $modalitiesExact: Boolean
    $modalityPrincipal: LoyaltyModality
  ) {
    benefits(
      shoppingSlug: $shoppingSlug
      onlyFavorites: $onlyFavorites
      modalities: $modalities
      categories: $categories
      modalitiesExact: $modalitiesExact
      modalityPrincipal: $modalityPrincipal
      storeCodes: $storeCodes
      type: $type
      limit: $limit
      skip: $skip
      ordering: $ordering
      isWithoutPoint: $isWithoutPoint
      startPoints: $startPoints
      endPoints: $endPoints
      storeId: $storeId
    ) {
      id
      title
      description
      categories
      image {
        url
        type
      }
      store {
        name
        logoColor {
          original
        }
      }
      modality {
        tierName
        eligibleTiers
      }
      isFavorite
      startDate
      daysLeftToRedeem
      parkingDiscountId
      type
      points
      salePointsFrom
      endDate
      isSoldOut
    }
  }
`;

const BENEFITS_COUNT = gql`
  query countBenefits(
    $shoppingSlug: String!
    $onlyFavorites: Boolean
    $modalities: [LoyaltyModality]
    $type: BenefitType
  ) {
    countBenefits(shoppingSlug: $shoppingSlug, onlyFavorites: $onlyFavorites, type: $type, modalities: $modalities)
  }
`;

const USED_BENEFIT = gql`
  query usedBenefit($id: ID!, $shoppingSlug: String!) {
    usedBenefit(id: $id, shoppingSlug: $shoppingSlug) {
      id
      benefit {
        title
        id
        store {
          name
        }
        image {
          url
          type
        }
        description
        type
        modality {
          tierName
          tierId
          eligibleTiers
        }
        points
        salePointsFrom
        parkingDiscountId
        daysLeftToRedeem
      }
      status
      issueDate
      endDate
      redeemDate
    }
  }
`;

const USED_BENEFITS = gql`
  query usedBenefits($limit: Int, $skip: Int, $shoppingSlug: String!, $statusesValues: [String], $ordering: [Sort]) {
    usedBenefits(
      limit: $limit
      skip: $skip
      shoppingSlug: $shoppingSlug
      statusesValues: $statusesValues
      ordering: $ordering
    ) {
      id
      benefit {
        id
        title
        description
        image {
          url
          type
        }
        store {
          name
          logoColor {
            original
          }
        }
        points
        salePointsFrom
        isFavorite
        startDate
        endDate
        daysLeftToRedeem
        parkingDiscountId
        type
        points
        categories
      }
      status
      issueDate
      endDate
      redeemDate
      promotion {
        id
      }
    }
  }
`;

const COUNT_USED_BENEFITS = gql`
  query countUsedBenefits($shoppingSlug: String!, $statusesValues: [String]) {
    countUsedBenefits(shoppingSlug: $shoppingSlug, statusesValues: $statusesValues)
  }
`;

export { BENEFITS, BENEFITS_COUNT, USED_BENEFITS, USED_BENEFIT, COUNT_USED_BENEFITS };
