const getCartToken = () => {
  if (!multiStorage.getItem('cartToken')) {
    const currentDate = new Date().valueOf().toString();
    const random = Math.random().toString();
    multiStorage.setItem('cartToken', `${currentDate}${random}`);
  }
  return multiStorage.getItem('cartToken');
};

export default getCartToken;
