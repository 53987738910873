const productsByStore = (items) => {
  if (!items) return { foods: [], goods: [] };
  const foods = items.filter((item) => item.product.__typename === 'Menu');
  const goods = items.filter((item) => item.product.__typename !== 'Menu');

  let storesIdsAdded = [];
  const foodsItems = [];
  const foodsItemsObj = {};
  if (foods.length > 0) {
    foods.forEach((item) => {
      const storeId = item.product.store.id;
      if (storesIdsAdded.includes(storeId)) {
        const newArray = foodsItemsObj[storeId];
        newArray.push(item);
        foodsItemsObj[storeId] = newArray;
      } else {
        storesIdsAdded.push(storeId);
        foodsItemsObj[storeId] = [item];
      }
    });

    for (const storeId in foodsItemsObj) foodsItems.push(foodsItemsObj[storeId]);
  }

  storesIdsAdded = [];
  const goodsItems = [];
  const goodsItemsObj = {};
  if (goods.length > 0) {
    goods.forEach((item) => {
      const storeId = item.product.store.id;
      if (storesIdsAdded.includes(storeId)) {
        const newArray = goodsItemsObj[storeId];
        newArray.push(item);
        goodsItemsObj[storeId] = newArray;
      } else {
        storesIdsAdded.push(storeId);
        goodsItemsObj[storeId] = [item];
      }
    });

    for (const storeId in goodsItemsObj) goodsItems.push(goodsItemsObj[storeId]);
  }

  return { foods: foodsItems, goods: goodsItems };
};

export default productsByStore;
