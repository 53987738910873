import { useSelectedDeliveryType, useCart, useCurrentCoupon } from 'hooks';

import { getDeliveryFee } from 'utils';

const useDeliveryFee = () => {
  const currentCoupon = useCurrentCoupon();
  const selectedDeliveryType = useSelectedDeliveryType();
  const { items = [] } = useCart({ current: true }) || {};

  return getDeliveryFee({ currentCoupon, selectedDeliveryType, items });
};

export default useDeliveryFee;
