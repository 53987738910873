import React from 'react';
import { bool, arrayOf, shape, string, func, oneOfType, element } from 'prop-types';

import ProgressiveImage from 'components/progressiveImage';

import { classNames } from 'utils';

import styles from './index.module.scss';
import genericSliderStyle from '../../index.module.scss';

const DefaultAppSlider = ({ apps, onClick, className }) => {
  return (
    <div className={classNames(styles.defaultAppSlider, className)}>
      {apps.map((app) => {
        const { id, slug, icon, name } = app;

        return (
          <div
            className={genericSliderStyle.app}
            key={id}
            data-testid={`defaultapp-container-${slug}`}
            onClick={() => onClick(app)}
            id={`${slug}AppSlider`}
          >
            <div className={genericSliderStyle.card} id="appCard">
              {icon && (
                <ProgressiveImage withLoader={false} src={icon.original} className={genericSliderStyle.cardIcon} />
              )}
            </div>
            <div className={genericSliderStyle.appName} id="appName">
              {name}
            </div>
          </div>
        );
      })}
    </div>
  );
};

DefaultAppSlider.propTypes = {
  apps: arrayOf(
    shape({
      slug: string,
      name: oneOfType([string, element]),
      featured: bool,
      icon: shape({
        original: string,
      }),
      cover: shape({
        original: string,
      }),
    }),
  ).isRequired,
  onClick: func,
  className: string,
};

DefaultAppSlider.defaultProps = {
  onClick: () => null,
  className: '',
};

export default DefaultAppSlider;
