import compareVersions from 'compare-versions';

const checkStoreVersion = async (payload) => {
  const response = await fetch('/appVersion.json', { cache: 'no-cache' });
  if (response.ok) {
    const responseJson = await response.json();
    multiStorage.setItem('@multi:app-version-data', JSON.stringify(responseJson));
    const { android: androidVersion, ios: iosVersion, 'force-update': forceUpdate } = responseJson;
    if (payload.platform === 'Android' && compareVersions(androidVersion, payload.versionCode) === 1) {
      return { showModal: true, force: forceUpdate };
    }
    if (payload.platform === 'iOS' && compareVersions(iosVersion, payload.versionCode) === 1) {
      return { showModal: true, force: forceUpdate };
    }
    return { showModal: false, force: false };
  }
};

export default checkStoreVersion;
