import getDaysInMonth from '../getDaysInMonth';

const groupingTransactionsByMonth = (lprTransactions) => {
  const currentDate = new Date(Date.now());
  const nextDate = new Date(currentDate);
  nextDate.setDate(getDaysInMonth(nextDate.getMonth() + 1, nextDate.getFullYear()));
  nextDate.setHours(23);
  nextDate.setMinutes(59);
  nextDate.setSeconds(59);
  nextDate.setMilliseconds(999);

  const monthPayments = [];

  const lastTransaction = lprTransactions[lprTransactions.length - 1];
  const firstDate = (lprTransactions.length && new Date(lastTransaction.transactionDate)) || currentDate;
  firstDate.setDate(1);
  firstDate.setHours(0);
  firstDate.setMinutes(0);
  firstDate.setSeconds(0);
  firstDate.setMilliseconds(0);

  while (firstDate < nextDate) {
    const year = firstDate.getFullYear();
    const month = firstDate.getMonth() + 1;

    const paymentMonthCount = lprTransactions?.filter(
      (transation) =>
        new Date(transation.transactionDate).getMonth() + 1 === month &&
        new Date(transation.transactionDate).getFullYear() === year,
    ).length;

    const failedPayment = lprTransactions?.some(
      (transation) =>
        new Date(transation.transactionDate).getMonth() + 1 === month &&
        new Date(transation.transactionDate).getFullYear() === year &&
        transation.status === 'FAILED',
    );

    monthPayments.push({
      year,
      month,
      payment: paymentMonthCount,
      failedPayment,
    });

    if (month === 12 && year < currentDate.getFullYear()) {
      firstDate.setMonth(0);
      firstDate.setYear(year + 1);
    } else firstDate.setMonth(month);
  }
  return monthPayments.reverse();
};

export default groupingTransactionsByMonth;
