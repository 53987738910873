import React from 'react';
import PropTypes from 'prop-types';
import BannerSlider from 'components/bannerSlider';
import ShoppingDisplay from 'components/shoppingDisplay';

import styles from './index.module.scss';

const Header = ({ backgroundColor }) => {
  return (
    <div className={styles.header} style={{ backgroundColor }}>
      <ShoppingDisplay variant="home" />
      <BannerSlider path="/showcase" position={0} className={styles.firstBanner} autoplay autoplaySpeed={6000} />
    </div>
  );
};

Header.propTypes = {
  backgroundColor: PropTypes.string,
};

Header.defaultProps = {
  backgroundColor: null,
};

export default Header;
