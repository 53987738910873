import { useQuery } from '@apollo/react-hooks';
import { USER } from 'services/apollo/queries';
import { getUser } from 'services/storage';

const useUser = () => {
  const { data: { user } = {} } = useQuery(USER);

  if (user) return user;
  else return getUser();
};

export default useUser;
