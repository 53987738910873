import { useEffect, useState } from 'react';
import { bugsnag, capitalize } from 'utils';
import { useHistory } from 'react-router-dom';

import numberFix from '../../../utils/balanceFixValue';

export const useFilterByPointsModal = ({ onClose, variant, handleApplyPointsFilterModal, startValue }) => {
  const history = useHistory();

  const { initialValue, lastValue, isWithoutPoint } = startValue;

  const [firstInput, setFirstInput] = useState(initialValue ? initialValue : '');
  const [secondInput, setSecondInput] = useState(lastValue ? lastValue : '');
  const [isChecked, setIsChecked] = useState(isWithoutPoint);
  const [errorInputs, setErrorInputs] = useState(false);

  const pathname = history?.location.pathname;

  const source = pathname.includes('lista-de-beneficios') ? `${capitalize(variant)} benefit list` : 'home MVC';

  const inputMaskNumber = (value) => {
    const valueClean = inputCleanNumber(value);

    const separatedString = numberFix(valueClean);

    if (isNaN(separatedString)) {
      return;
    }

    return separatedString;
  };

  const inputCleanNumber = (value) => {
    const valueClean = value.replace(/\./g, '');
    return valueClean;
  };

  const handleFirstInput = (value) => {
    try {
      window.track({
        mixpanel: {
          name: 'MVC Points Searched',
          properties: {
            'Search Query From': inputCleanNumber(value),
            Source: source,
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }

    const valueClean = inputCleanNumber(value);

    const isNanValue = numberFix(valueClean);

    if (isNaN(isNanValue)) {
      return;
    }

    if (valueClean.length <= 6) {
      setFirstInput(valueClean);
    }
  };

  const handleSecondInput = (value) => {
    try {
      window.track({
        mixpanel: {
          name: 'MVC Points Searched',
          properties: {
            'Search Query To': inputCleanNumber(value),
            Source: source,
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }

    const valueClean = inputCleanNumber(value);

    const isNanValue = numberFix(valueClean);

    if (isNaN(isNanValue)) {
      return;
    }

    if (valueClean.length <= 6) {
      setSecondInput(valueClean);
    }
  };

  const handleClean = () => {
    setIsChecked(false);
    setErrorInputs(false);

    setFirstInput('');
    setSecondInput('');

    handleApplyPointsFilterModal({
      isWithoutPoint: false,
      initialValue: 0,
      lastValue: 0,
    });

    onClose();
  };

  const handleCheck = () => {
    try {
      window.track({
        mixpanel: {
          name: 'Benefits Without Points Selected',
          properties: {
            Source: source,
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }

    setIsChecked(!isChecked);
    setFirstInput('');
    setSecondInput('');
  };

  const handleApply = () => {
    if (firstInput && secondInput && Number(firstInput) > Number(secondInput)) {
      setErrorInputs(true);
    } else {
      if (isChecked) {
        try {
          window.track({
            mixpanel: {
              name: 'Points Filter Applied',
              properties: {
                'Selection Without Point': isChecked,
                Source: source,
              },
            },
          });
        } catch (e) {
          bugsnag?.notify(e);
        }
      } else {
        try {
          window.track({
            mixpanel: {
              name: 'Points Filter Applied',
              properties: {
                'Selection From': Number(firstInput),
                'Selection To': Number(secondInput),
                Source: source,
              },
            },
          });
        } catch (e) {
          bugsnag?.notify(e);
        }
      }

      setErrorInputs(false);
      handleApplyPointsFilterModal({
        initialValue: Number(firstInput),
        lastValue: Number(secondInput),
        isWithoutPoint: isChecked,
      });

      onClose();
    }
  };

  const isButtonCleanDisabled = () => {
    if (isChecked || secondInput) {
      return false;
    } else {
      return true;
    }
  };

  const isButtonDisabled = () => {
    if (isWithoutPoint !== isChecked) return false;
    if (
      (initialValue !== Number(firstInput) || lastValue !== Number(secondInput)) &&
      Number(firstInput) <= Number(secondInput)
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (firstInput || secondInput) {
      setIsChecked(false);
    }
  }, [firstInput, secondInput]);

  return {
    firstInput,
    secondInput,
    isChecked,
    errorInputs,

    inputMaskNumber,
    handleFirstInput,
    handleSecondInput,
    handleClean,
    handleCheck,
    handleApply,
    isButtonCleanDisabled,
    isButtonDisabled,
  };
};
