import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { Route } from 'react-router-dom';

import Home from 'screens/home';
import { CURRENT_SHOPPING } from 'services/apollo/queries';

const ShoppingRoute = ({ component: Component, ...rest }) => {
  const renderScreen = (props, currentShopping) => (currentShopping ? <Component {...props} /> : <Home {...props} />);

  return (
    <Query query={CURRENT_SHOPPING}>
      {({ data: { currentShopping } }) => <Route {...rest} render={(props) => renderScreen(props, currentShopping)} />}
    </Query>
  );
};

ShoppingRoute.propTypes = {
  component: PropTypes.object.isRequired,
  location: PropTypes.object,
};

ShoppingRoute.defaultProps = {
  location: null,
};

export default ShoppingRoute;
