import React, { useState } from 'react';
import { bool, func, number, string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { SHOPPING_EVENTS } from 'services/apollo/queries';
import { useCurrentShopping } from 'hooks';

import { clickedTreadItemTracking } from '../../screens/showcase/tracking';

import Loading from 'components/loading';
import TreadSlider from 'components/treadSlider';
import EventsCard from './components/eventsCard';
import EventsLogo from 'icons/ic-events.svg';
import Slider from 'components/slider';

import styles from './index.module.scss';

const FeaturedEvents = ({ limit }) => {
  const currentShopping = useCurrentShopping();
  const [events, setEvents] = useState([]);
  const history = useHistory();
  const path = '/apps/eventos';
  const treadSlug = 'eventos';

  const { loading } = useQuery(SHOPPING_EVENTS, {
    variables: { shoppingId: currentShopping?.id, active: true, limit, categoriesIds: [] },
    onCompleted: (data) => {
      setEvents(data.getShoppingEvents);
    },
    fetchPolicy: 'cache-and-network',
  });

  const numEvents = events?.length;

  const handleTreadItemClick = (path, itemSlug, treadSlug, title, position) => {
    clickedTreadItemTracking(treadSlug, title, position);
    history.push(`${path}/${itemSlug}`);
  };

  if (!events || numEvents < 1) return null;

  return (
    <TreadSlider
      color={'#548560'}
      icon={<EventsLogo className={styles.icon} />}
      path={'/apps/eventos'}
      title={'Eventos e Atrações'}
      wordEmphasis={'first'}
      slug={treadSlug}
    >
      <>
        {numEvents === 1 ? (
          <EventsCard
            key={events[0].id}
            image={events[0].carousel[0]?.original}
            title={events[0].title}
            duration={events[0]?.duration}
            className={styles.featuredEventCardList}
            onClick={() => handleTreadItemClick(path, events[0].slug, treadSlug, events[0].title, 0)}
            categories={events[0]?.categories}
            isSingleEvent={numEvents === 1}
          />
        ) : (
          <Slider autoScrollbarHeight>
            {events.map(
              (event, index) =>
                event && (
                  <EventsCard
                    key={event.id}
                    image={event.carousel[0]?.original}
                    title={event.title}
                    duration={event?.duration}
                    className={styles.eventCardList}
                    onClick={() => handleTreadItemClick(path, event.slug, treadSlug, event.title, index)}
                  />
                ),
            )}
          </Slider>
        )}
        {loading && <Loading className={styles.loading} />}
      </>
    </TreadSlider>
  );
};

FeaturedEvents.propTypes = {
  className: string,
  limit: number,
  disablePagination: bool,
  onCompleted: func,
  featured: bool,
  twoColumns: bool,
  categoryId: string,
};

FeaturedEvents.defaultProps = {
  className: null,
  disablePagination: false,
  onCompleted: () => {},
  limit: 10,
  featured: undefined,
  twoColumns: false,
  categoryId: undefined,
};

export default FeaturedEvents;
