import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { useCurrentShopping, useUserLocation } from 'hooks';
import { STORE_SEGMENTS } from 'components/storeScreen/graphql/queries';

const usePreload = () => {
  const userLocation = useUserLocation();
  const {
    location: { search },
  } = useHistory();
  const currentShopping = useCurrentShopping();
  const params = new URLSearchParams(search);
  const preLoad = params.get('preload') !== 'false';

  const [fetchStores] = useLazyQuery(STORE_SEGMENTS, {
    variables: {
      appSlug: 'marketplace',
      shoppingId: currentShopping.id,
      segmentIds: [],
      gastronomy: false,
      location: userLocation || undefined,
      limit: 20,
      skip: 0,
    },
  });

  const [fetchRestaurants] = useLazyQuery(STORE_SEGMENTS, {
    variables: {
      appSlug: 'gastronomia',
      shoppingId: currentShopping.id,
      segmentIds: [],
      gastronomy: true,
      location: userLocation || undefined,
      limit: 20,
      skip: 0,
    },
  });

  useEffect(() => {
    if (preLoad) {
      fetchStores();
      fetchRestaurants();
    }
  }, [preLoad]);

  return null;
};

export default usePreload;
