import gql from 'graphql-tag';

const PLAYS = gql`
  query shows($shoppingId: ID!, $typeSlug: String!, $limit: Int, $skip: Int) {
    shows(shoppingId: $shoppingId, typeSlug: $typeSlug, limit: $limit, skip: $skip) {
      id
      name
      slug
      images
      startDate
      endDate
    }
  }
`;

export default PLAYS;
export { PLAYS };
