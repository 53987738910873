import React from 'react';
import { func, string } from 'prop-types';

import { classNames, formatDateRange } from 'utils';

import styles from './index.module.scss';

const PlayCard = ({ title, image, onClick, startDate, endDate, period, className }) => (
  <div className={classNames(styles.playCard, className)} onClick={onClick} data-testid="button">
    <div className={styles.playCardImageContainer}>
      <img src={image} alt={title} className={styles.playCardImage} />
    </div>
    {startDate && endDate && <div className={styles.playCardDate}>{formatDateRange(startDate, endDate)}</div>}
    {period && <div className={styles.playCardDate}>{period}</div>}
    <div className={styles.playCardTitle}>{title}</div>
  </div>
);

PlayCard.propTypes = {
  title: string.isRequired,
  image: string.isRequired,
  onClick: func.isRequired,
  period: string,
  startDate: string,
  endDate: string,
  className: string,
};

PlayCard.defaultProps = {
  className: '',
  period: null,
  startDate: null,
  endDate: null,
};

export default PlayCard;
