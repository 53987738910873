import gql from 'graphql-tag';

const PLAYS = gql`
  query shows($shoppingId: ID!, $typeSlug: String!, $limit: Int, $skip: Int) {
    shows(shoppingId: $shoppingId, typeSlug: $typeSlug, limit: $limit, skip: $skip) {
      id
      name
      slug
      images
      startDate
      endDate
    }
  }
`;

export const MULTI_THEATERS = gql`
  query getMultiTheaters(
    $shoppingId: ID
    $title: String
    $active: Boolean
    $periodStart: Date
    $periodEnd: Date
    $limit: Int
    $skip: Int
    $ordering: MultiTheaterOrdering
  ) {
    getMultiTheaters(
      shoppingId: $shoppingId
      active: $active
      title: $title
      periodStart: $periodStart
      periodEnd: $periodEnd
      limit: $limit
      skip: $skip
      ordering: $ordering
    ) {
      id
      updatedAt
      createdAt
      active
      title
      period
      shopping {
        id
        name
      }
      carousel {
        id
        original
      }
    }
  }
`;

export default PLAYS;
export { PLAYS };
