import React from 'react';
import { classNames } from 'utils';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Toast } from 'components/Toast';

import ChevronRight from 'icons/chevron-right.svg';
import GiftSvg from './images/gift.svg';

import styles from './index.module.scss';

const node = document.createElement('div');

export function winBenefitPromotionToast({ variant, handleClick }) {
  document.body.appendChild(node);
  const screenWidth = window.screen.width;
  const WinBenefitPromotionToastComponent = () => {
    setTimeout(() => {
      return clear();
    }, 10000);
    return (
      <Toast animationType="bottom" autoDeleteTime={10000}>
        <div className={classNames(styles.container, styles[variant])} onClick={handleClick}>
          <div className={styles.content}>
            <div>
              <GiftSvg className={styles.image} />
            </div>

            <div className={styles.containerText}>
              <span className={styles.title}>
                Especial {screenWidth <= 320 && <br />} <strong>Dia da Mulher</strong>
              </span>
              <span className={styles.text}>Confira seu bônus</span>
            </div>
          </div>
          <div>
            <ChevronRight className={styles.chevronIcon} />
          </div>
        </div>
      </Toast>
    );
  };

  const clear = () => {
    ReactDOM.unmountComponentAtNode(node);
    node.remove();
  };

  ReactDOM.render(<WinBenefitPromotionToastComponent />, node);
}

winBenefitPromotionToast.propTypes = {
  variant: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};
