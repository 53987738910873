const trackProceedWithOfflinePayment = (paymentType) => {
  window.track({
    mixpanel: {
      name: 'Proceed With Offline Payment',
      properties: {
        'Payment Name': paymentType.name,
        'Payment Slug': paymentType.slug,
      },
    },
    googleTagManager: {
      global: {
        name: 'proceedWithOfflinePayment',
        properties: {
          paymentName: paymentType.name,
          paymentSlug: paymentType.slug,
        },
      },
    },
  });
};

export default trackProceedWithOfflinePayment;
