import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { cold } from 'react-hot-loader';
import { Transition } from 'react-transition-group';

import { classNames } from 'utils';
import Navbar from 'components/navbar';
import GoBack from 'components/goBack';
import OutsideClickHandler from 'components/outsideClickHandler';

import CloseSvg from 'icons/close.svg';

import styles from './index.module.scss';
import './index.scss';

const Modal = ({
  children,
  isOpen,
  className,
  onClose,
  withNavbar,
  navbarTitle,
  fullscreen,
  withCloseButton,
  slide,
  hasOutsideClick,
  rightSide,
  hasScroll,
  portalClassName,
  screenClassName,
}) => {
  const handleOutsideClick = () => {
    if (hasOutsideClick) onClose();
  };

  return (
    (fullscreen && (
      <ReactModal
        onRequestClose={onClose}
        closeTimeoutMS={150}
        parentSelector={() => document.getElementById('overlay-root')}
        ariaHideApp={false}
        {...{ isOpen, className, portalClassName }}
      >
        {(withNavbar && (
          <>
            <Navbar noShaddow title={navbarTitle} leftComponent={<GoBack onClick={onClose} />} />
            <div className={styles.modalFullscreen}>{children}</div>
          </>
        )) || (
          <>
            {children}
            <button className={styles.closeButton} onClick={onClose}>
              <CloseSvg className="svg-default" />
            </button>
          </>
        )}
      </ReactModal>
    )) ||
    ReactDOM.createPortal(
      <Transition in={isOpen} timeout={900}>
        {(state) =>
          state !== 'exited' && (
            <div
              data-testid="modal-close"
              className={classNames(
                !rightSide ? styles.screen : styles.screenRight,
                hasScroll && styles.scroll,
                screenClassName,
                'animate__fadeIn animate__animated animate__fast',
                {
                  'animate__fadeOut animate__animated animate__fast delay-fast': state === 'exiting',
                },
              )}
            >
              <OutsideClickHandler
                onOutsideClick={handleOutsideClick}
                className={hasScroll && styles.outsideClickWithScroll}
              >
                <div
                  className={classNames(
                    !rightSide ? styles.modal : styles.modalRight,
                    className,
                    `${
                      slide ? 'animate__slideInUp' : rightSide ? 'animate__slideInRight' : 'animate__fadeIn'
                    } animate__animated animate__faster delay-fast`,
                    {
                      [`${
                        slide
                          ? 'animate__slideOutDown animate__animated'
                          : rightSide
                          ? 'animate__slideOutRight animate__animated '
                          : 'animate__fadeOut animate__animated'
                      } animate__fast no-delay`]: state === 'exiting',
                    },
                  )}
                >
                  {(withCloseButton && (
                    <button className={styles.timesCloseButton} onClick={onClose}>
                      <CloseSvg />
                    </button>
                  )) ||
                    (rightSide && (
                      <button className={styles.closeButtonLeft} data-testid="close-button" onClick={onClose}>
                        <CloseSvg className={styles.icon} />
                      </button>
                    ))}
                  {children}
                </div>
              </OutsideClickHandler>
            </div>
          )
        }
      </Transition>,
      document.getElementById('overlay-root'),
    ) ||
    null
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  withNavbar: PropTypes.bool,
  navbarTitle: PropTypes.string,
  fullscreen: PropTypes.bool,
  withCloseButton: PropTypes.bool,
  slide: PropTypes.bool,
  hasOutsideClick: PropTypes.bool,
  rightSide: PropTypes.bool,
  hasScroll: PropTypes.bool,
  portalClassName: PropTypes.string,
  screenClassName: PropTypes.string,
};

Modal.defaultProps = {
  withNavbar: false,
  navbarTitle: '',
  fullscreen: false,
  slide: false,
  withCloseButton: false,
  hasOutsideClick: true,
  rightSide: false,
  hasScroll: false,
  portalClassName: '',
  screenClassName: '',
};

export default cold(Modal);
