import compareVersions from 'compare-versions';

const isDeviceUpdated = (feature) => {
  const deviceVersionInfo = JSON.parse(multiStorage.getItem('@multi:device-version-data'));
  const appVersionInfo = JSON.parse(multiStorage.getItem('@multi:app-version-data'));

  if (deviceVersionInfo === null || appVersionInfo === null) {
    return false;
  }

  const device = deviceVersionInfo.platform;
  const featureIOSVersion = appVersionInfo[feature].ios;
  const featureAndroidVersion = appVersionInfo[feature].android;

  if (device === 'Android' && compareVersions(featureAndroidVersion, deviceVersionInfo.versionCode) === 1) {
    return false;
  }
  if (device === 'iOS' && compareVersions(featureIOSVersion, deviceVersionInfo.versionCode) === 1) {
    return false;
  }

  return true;
};
export default isDeviceUpdated;
