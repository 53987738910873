import { bugsnag } from 'utils';

export const sendTracking = (slug, externalUrl, showLoginScreen, from) => {
  try {
    window.track({
      mixpanel: {
        name: 'Clicked App Slider',
        properties: {
          Slug: slug,
          loginScreen: !!externalUrl && !!showLoginScreen,
          from,
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};
