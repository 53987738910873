import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import SlideModal from 'components/slideModal';

import Button from 'components/button';
import GenericLogo from './images/walkingOut.png';
import IngressoComLogo from './images/ingressoCom_logo.png';
import CinemarkLogo from './images/cinemark_logo.png';

import styles from './index.module.scss';

function getBaseUrl(urlString) {
  try {
    const decodedString = decodeURIComponent(urlString);
    const url = new URL(encodeURI(decodedString));

    if (url.hostname === 'checkout.ingresso.com' || url.hostname === 'cinemark.com.br') {
      return `Ir direto para ${url.protocol + '//' + url.hostname + '/'}`;
    } else {
      return 'Ir direto para o link';
    }
  } catch (error) {
    console.error('Invalid URL:', urlString);
    return null;
  }
}

function getTypeFromUrl(url) {
  const decodedString = decodeURIComponent(url);
  const urlDecoded = new URL(encodeURI(decodedString));
  const hostname = urlDecoded.hostname;

  if (hostname === 'cinemark.com.br') {
    return 'cinemark';
  }
  if (hostname === 'checkout.ingresso.com') {
    return 'ingresso';
  }
  return 'default';
}

const RedirectModal = ({ url, forceBrowser, history }) => {
  const [open, setOpen] = useState(true);
  const [countdown, setCountdown] = useState(5);
  const [fastRedirect, setFastRedirect] = useState(false);
  const baseUrl = useMemo(() => getBaseUrl(url), [url]);
  const type = useMemo(() => getTypeFromUrl(url), [url]);

  const handleClose = () => {
    setOpen(false);
  };

  const isMovie = type === 'ingresso' || type === 'cinemark';

  const logos = {
    ingresso: IngressoComLogo,
    cinemark: CinemarkLogo,
    default: GenericLogo,
  };

  const imgLogo = logos[type];

  useEffect(() => {
    if (countdown <= 0 && !fastRedirect && open) {
      handleRedirect();
    } else {
      const interval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [countdown, open, fastRedirect]);

  const handleRedirect = useCallback(() => {
    const decodedString = decodeURIComponent(url);
    window.openExternalContent(encodeURI(decodedString), { forceBrowser: forceBrowser === 'true' });
    if (countdown > 0) {
      setFastRedirect(true);
      setTimeout(() => setCountdown(0), 700);
    }
    if (countdown === 0) {
      handleClose();
    }
    history.goBack();
  }, [url, forceBrowser, countdown, history]);

  return (
    <SlideModal isOpen={open} onClose={handleClose} className={styles.container}>
      <div className={styles.logoContainer}>
        <img src={imgLogo} className={isMovie ? styles.movieLogo : styles.genericLogo} />
      </div>
      <p className={styles.description}>
        Você será redirecionado para um <strong>parceiro</strong>
      </p>
      {isMovie && (
        <p className={styles.descriptionComplement}>
          Verifique os dados da sala, sessão e filme antes de comprar o seu ingresso.
        </p>
      )}
      <div className={styles.countdown}>{countdown}s</div>
      <Button onClick={handleClose} size="full" round>
        Continuar no Multi
      </Button>
      <p className={styles.moviePartnerDescription}>
        <span onClick={handleRedirect} data-testid={'redirectModalLink'} data-url={decodeURIComponent(url)}>
          {baseUrl}
        </span>
      </p>
    </SlideModal>
  );
};

RedirectModal.propTypes = {
  url: PropTypes.string.isRequired,
  forceBrowser: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

RedirectModal.defaultProps = {
  forceBrowser: false,
};

export default RedirectModal;
