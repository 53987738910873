const currencyFormat = (number) => {
  const formattedNumber = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
    .format(number)
    .replace('\xa0', '')
    .replace(/^(\D+)/, '$1 ');
  const pointPosition = formattedNumber.length - 3;
  return formattedNumber.replaceAt(pointPosition, ',');
};

export default currencyFormat;
