import gql from 'graphql-tag';

const LLV_SHOWCASE = gql`
  query llvShowcase($shoppingId: ID!) {
    llvShowcase(shoppingId: $shoppingId) {
      llvShowcase {
        id
        name
        products {
          id
          slug
          name
        }
      }
    }
  }
`;

export { LLV_SHOWCASE };
