const getImageFileExtension = (fileName) => {
  if (!fileName || typeof fileName !== 'string') return null;
  const parts = fileName?.split('.');
  if (parts.length > 1 && /\.(jpg|jpeg|png|bmp|gif|svg)$/i.test(fileName)) {
    return parts[parts.length - 1].toLowerCase();
  } else {
    return null;
  }
};

export default getImageFileExtension;
