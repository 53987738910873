import gql from 'graphql-tag';

const ANNOUNCEMENT = gql`
  query announcement($shoppingSlug: String, $path: String) {
    announcement(shoppingSlug: $shoppingSlug, path: $path) {
      content
      image {
        id
        original
      }
      title
      frequencyToShow
      path
      hash
      id
      button
      buttonText
      buttonLink
    }
  }
`;

export { ANNOUNCEMENT };
