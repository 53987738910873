import React, { useCallback } from 'react';
import { Waypoint } from 'react-waypoint';
import { string } from 'prop-types';

import { classNames } from 'utils';

import EmptyBenefits from 'components/emptyBenefits';
import Loading from 'components/loading';

import ChevronDown from 'icons/chevron-down.svg';
import CheckIcon from 'icons/check.svg';

import { StoresFilterModal } from '../StoresFilterModal';
import { BenefitMvcCard } from '../BenefitMvcCard';
import { CategoriesFilterModal } from '../CategoriesFilterModal';
import { FilterByPointsModal } from '../FilterByPointsModal';
import { useBenefitListComponent } from './useBenefitListComponent';

import styles from './index.module.scss';

export function BenefitListComponent({ variant, path, focus }) {
  const {
    showLoadingMore,
    hasMoreBenefits,
    benefits,
    storesFilterModalIsOpen,
    storesFilter,
    categoriesFilterModalIsOpen,
    categoriesFilter,
    pointsFilterModalIsOpen,
    pointsFilter,
    recentFilter,
    redeemedFilter,
    isFilterTabsFixed,
    data,
    getMoreData,
    fetchMore,
    benefitLoading,

    handleOpenStoresModal,
    handleCloseStoresModal,
    handleOpenCategoriesModal,
    handleCloseCategoriesModal,
    handleApplyStoreFilterModal,
    handleApplyCategoryFilterModal,
    handleClosePointsModal,
    handleOpenPointsModal,
    handleApplyPointsFilterModal,
    handleClickRedeemed,
    handleClickMoreRecent,
    handleLetFilterTabsFixed,
    pointsFilterActive,
    hasSomeFilterActive,
    pointsFilterText,
  } = useBenefitListComponent({ path, focus, variant });

  const BenefitMvcCardComponent = useCallback(() => {
    if (benefits.length) {
      return benefits.map((item, index) => (
        <div key={item.id}>
          <BenefitMvcCard benefit={item} endDate={item.endDate} variant={variant} focus={focus} index={index + 1} />
          <div className={styles.benefitsBoxDivider} />
        </div>
      ));
    }
    if (!benefitLoading) {
      return <EmptyBenefits hasFilter={hasSomeFilterActive()} focus="Home" />;
    }

    return null;
  }, [benefits, benefitLoading]);

  const WaypointComponent = () => {
    if (hasMoreBenefits) {
      return (
        <>
          {showLoadingMore && <Loading className={styles.loading} />}
          <Waypoint
            bottomOffset={-100}
            onEnter={() => {
              getMoreData({ fetchMore, data });
            }}
          />
        </>
      );
    }
    return null;
  };

  return (
    <>
      <div
        className={classNames(styles.benefitsBoxContainer, styles[variant])}
        id="benefitFilters"
        data-show={isFilterTabsFixed}
      >
        <div className={styles.filtersContainerbackground}>
          <div className={styles.filtersContainer} data-show={isFilterTabsFixed}>
            <button
              data-testid="storeBenefitTest"
              className={styles.filterButton}
              onClick={handleOpenStoresModal}
              data-active={storesFilter.length > 0}
            >
              Lojas {storesFilter.length > 0 ? `(${storesFilter.length})` : ''}{' '}
              <ChevronDown className={styles.chevronDown} />
            </button>
            <button
              data-testid="categoryBenefitTest"
              className={styles.filterButton}
              onClick={handleOpenCategoriesModal}
              data-active={categoriesFilter.length > 0}
            >
              Categorias {categoriesFilter.length > 0 ? `(${categoriesFilter.length})` : ''}{' '}
              <ChevronDown className={styles.chevronDown} />
            </button>
            <button
              data-testid="pointsBenefitTest"
              className={styles.filterButton}
              onClick={handleOpenPointsModal}
              data-active={pointsFilterActive()}
            >
              {pointsFilterText()} <ChevronDown className={styles.chevronDown} />
            </button>
            <button
              data-testid="recentBenefitTest"
              className={styles.filterButton}
              onClick={() => handleClickMoreRecent(!recentFilter)}
              data-active={recentFilter}
            >
              {recentFilter && <CheckIcon className={styles.checkIcon} />} Mais recentes
            </button>
            <button
              data-testid="dateBenefitTest"
              className={styles.filterButton}
              onClick={() => handleClickRedeemed(!redeemedFilter)}
              data-active={redeemedFilter}
            >
              {redeemedFilter && <CheckIcon className={styles.checkIcon} />} Data de resgate
            </button>
          </div>
        </div>
        <div className={styles.benefitsBoxContent}>
          <>
            <BenefitMvcCardComponent />

            {benefitLoading && <Loading className={styles.loading} />}

            <WaypointComponent />
          </>
        </div>
      </div>
      <StoresFilterModal
        isOpen={storesFilterModalIsOpen}
        onClose={handleCloseStoresModal}
        variant={variant}
        handleApplyFilterModal={handleApplyStoreFilterModal}
        initialValue={storesFilter}
      />
      <CategoriesFilterModal
        isOpen={categoriesFilterModalIsOpen}
        onClose={handleCloseCategoriesModal}
        variant={variant}
        handleApplyCategoryFilterModal={handleApplyCategoryFilterModal}
        initialValue={categoriesFilter}
      />
      <FilterByPointsModal
        isOpen={pointsFilterModalIsOpen}
        onClose={handleClosePointsModal}
        variant={variant}
        handleApplyPointsFilterModal={handleApplyPointsFilterModal}
        initialValue={pointsFilter}
      />
    </>
  );
}

BenefitListComponent.propTypes = {
  variant: string.isRequired,
  path: string.isRequired,
  focus: string.isRequired,
};
