import React, { useRef, useState } from 'react';
import { classNames } from 'utils';
import { bool, string } from 'prop-types';
import { useCurrentShopping, useUserPlan } from 'hooks';
import { useQuery } from '@apollo/react-hooks';
import { SelectedBenefit } from 'components/SelectedBenefit';
import Loading from 'components/loading';

import { useSwipeable } from 'react-swipeable';
import { BENEFITS_CAROUSEL } from './graphql/queries';

import styles from './styles.module.scss';

function SelectedBenefitCarrousel({ doots, focus }) {
  const currentShopping = useCurrentShopping(true);
  const plan = useUserPlan();

  const selectedBenefitContainerRef = useRef(null);
  const [pageSelectedBenefitIndex, setPageSelectedBenefitIndex] = useState(0);

  const variant = plan.variant;

  const { data: { benefits: selectedsBenefits } = [], loading } = useQuery(BENEFITS_CAROUSEL, {
    variables: {
      limit: 4,
      shoppingSlug: currentShopping?.slug,
      modalities: [variant],
      featured: true,
      skip: !plan,
    },
  });

  const totalPages = selectedsBenefits?.length;

  const handlers = useSwipeable({
    onSwiped: (eventData) => (eventData.dir !== 'Left' ? handleScrollLeft() : handleScrollRight()),
  });

  const handleScroll = () => {
    const container = selectedBenefitContainerRef.current;

    const { scrollLeft, scrollWidth } = container;
    const cardWidht = Math.round(scrollWidth / totalPages);
    const positionValue = scrollLeft / cardWidht;
    const pageIndex = Math.round(positionValue);

    setPageSelectedBenefitIndex(pageIndex);
  };

  const handleScrollLeft = () => {
    const container = selectedBenefitContainerRef.current;
    const { scrollLeft, scrollWidth } = container;
    const cardWidht = Math.round(scrollWidth / totalPages);
    const pagePosition = scrollLeft - cardWidht;

    container.scrollTo({
      left: pagePosition,
    });
  };

  const handleScrollRight = () => {
    const container = selectedBenefitContainerRef.current;
    const { scrollLeft, scrollWidth } = container;
    const cardWidht = Math.round(scrollWidth / totalPages);
    const pagePosition = scrollLeft + cardWidht;

    container.scrollTo({
      left: pagePosition,
    });
  };

  const dootIsFocused = (positionDoot) => {
    if (positionDoot === pageSelectedBenefitIndex) {
      return true;
    } else {
      return false;
    }
  };

  if (loading) {
    return <Loading className={styles.loading} />;
  }

  if (selectedsBenefits?.length === 0) {
    return null;
  }

  return (
    <>
      <div className={classNames(styles.selectedBenefitsContainer, styles[variant])}>
        <strong className={styles.selectedBenefitTitle}>
          {selectedsBenefits?.length > 1 ? 'Selecionados para você' : 'Selecionado para você'}
        </strong>

        <div {...handlers}>
          <div ref={selectedBenefitContainerRef} className={styles.selectedBenefitsContent} onScroll={handleScroll}>
            {selectedsBenefits?.map((benefit, index) => (
              <SelectedBenefit
                key={benefit.id}
                data={benefit}
                variant={variant}
                onlyOne={selectedsBenefits?.length === 1}
                focus={focus}
                index={index + 1}
              />
            ))}
          </div>
        </div>

        {doots && selectedsBenefits?.length > 1 && (
          <div className={styles.dootsContainer}>
            {selectedsBenefits.map((value, index) => (
              <div key={value.id} className={classNames(styles.doot, dootIsFocused(index) && styles.dootFocused)} />
            ))}
          </div>
        )}
      </div>
    </>
  );
}

SelectedBenefitCarrousel.propTypes = {
  doots: bool,
  focus: string.isRequired,
};

SelectedBenefitCarrousel.defaultProps = {
  doots: true,
};

export { SelectedBenefitCarrousel };
