import React, { createContext, useContext, useReducer } from 'react';
import { node } from 'prop-types';
import reducer, { initialState, toggleFavorite, updateBenefits } from './reducer';

const BenefitListContext = createContext();

const BenefitListProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <BenefitListContext.Provider value={{ state, dispatch }}>{children}</BenefitListContext.Provider>;
};

BenefitListProvider.propTypes = {
  children: node.isRequired,
};

const useBenefitListProvider = () => {
  const { dispatch, state } = useContext(BenefitListContext);

  return { dispatch, state, toggleFavorite, updateBenefits };
};

export { BenefitListProvider, useBenefitListProvider };
