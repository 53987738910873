import { lazy } from 'react';

const Landing = lazy(() => import(/* webpackChunkName: "landing" */ 'screens/landingMarketing'));

export default [
  {
    path: '/landing/:slug',
    exact: true,
    component: Landing,
  },
];
