import gql from 'graphql-tag';

const ONBOARDINGSTEPS = gql`
  query onboardingSteps($shoppingsIds: [ID]) {
    onboardingSteps(shoppingsIds: $shoppingsIds) {
      id
      entity {
        ... on App {
          name
          icon {
            original
          }
          color
        }
        ... on Banner {
          link
          cover {
            original
          }
        }
      }
      subtitle
      description
      icon {
        original
      }
      active
      order
    }
  }
`;

export { ONBOARDINGSTEPS };
