import React, { Fragment, useRef } from 'react';
import { arrayOf, bool, element, func, number, oneOfType, shape, string } from 'prop-types';

import ProgressiveImage from 'components/progressiveImage';
import Slider from 'components/slider';
import AllAppsModal from '../allAppsModal';

import { sendTracking } from '../../tracking';

import Dots from 'icons/dots.svg';

import LinesEllipsis from 'react-lines-ellipsis';

import styles from './index.module.scss';
import genericSliderStyle from '../../index.module.scss';

const SlimAppSlider = ({ apps, onClick, showAllAppsButton, numAppsToShow }) => {
  const numApps = showAllAppsButton ? numAppsToShow - 1 : numAppsToShow;
  const filteredApps = apps.slice(0, numApps);

  const modalRef = useRef();

  const handleSeeMoreClick = () => {
    modalRef.current.open();
    sendTracking('ver-todos', false, false, 'showcase');
  };

  return (
    <Slider fixedItemWidth className={styles.slider}>
      {showAllAppsButton && (
        <>
          <div data-testid="container-button-all-apps" className={genericSliderStyle.app} onClick={handleSeeMoreClick}>
            <div className={genericSliderStyle.card}>{<Dots className={genericSliderStyle.cardIcon} />}</div>
            <div className={genericSliderStyle.appName}>Todos os atalhos</div>
          </div>
          <AllAppsModal ref={modalRef} isOpen={false} />
        </>
      )}

      {filteredApps.map((app) => {
        const { id, icon, name, slug } = app;

        return (
          <Fragment key={id}>
            <div
              data-testid={`container-button-${slug}`}
              onClick={() => onClick(app)}
              className={genericSliderStyle.app}
            >
              <div className={genericSliderStyle.card}>
                {icon && (
                  <ProgressiveImage className={genericSliderStyle.cardIcon} src={icon.original} withLoader={false} />
                )}
              </div>
              <LinesEllipsis maxLine={2} text={name} className={genericSliderStyle.appName} basedOn="letters" />
            </div>
          </Fragment>
        );
      })}
    </Slider>
  );
};

SlimAppSlider.propTypes = {
  apps: arrayOf(
    shape({
      slug: string,
      name: oneOfType([string, element]),
      icon: shape({
        original: string,
      }),
    }),
  ).isRequired,
  onClick: func,
  showAllAppsButton: bool,
  numAppsToShow: number,
};

SlimAppSlider.defaultProps = {
  onClick: () => null,
  showAllAppsButton: false,
  numAppsToShow: 7,
};

export default SlimAppSlider;
