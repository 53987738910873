import React, { useState, useRef, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import LinesEllipsis from 'react-lines-ellipsis';
import PropTypes from 'prop-types';

import { classNames, bugsnag, resetSearchQueries } from 'utils';

import Input from 'components/input';
import Button from 'components/button';

import { SEARCH_TEXT } from 'services/apollo/queries';

import ArrowLeft from 'icons/arrow-left.svg';
import Times from 'icons/times.svg';

import styles from './index.module.scss';
import './index.scss';

const Navbar = ({
  noZindex,
  noShaddow,
  transparent,
  noBorder,
  backButtonStyle,
  float,
  slideClass,
  search,
  searchKeyUp,
  showBackButton,
  dark,
  title,
  leftComponent,
  showCloseButton,
  onClose,
  rightComponent,
  titleClass,
  history,
  destinyBackButton,
  fallbackGoBackUrl,
  onChange,
  autoFocus,
  tracking,
  beforeUnload,
}) => {
  const client = useApolloClient();
  const [searchInput, setSearchInput] = useState('');

  const titleSlug = useMemo(() => {
    let titleSlug = '';
    try {
      titleSlug = typeof title === 'string' ? title : title?.props?.app?.name;
      titleSlug = String(titleSlug || '').replace(/ /g, '');
      return titleSlug;
    } catch (e) {
      bugsnag?.notify(e, {
        severity: 'error',
        beforeSend: (report) => {
          report.updateMetaData('params', { typeof: typeof title, title, titleSlug });
        },
      });
    }
  }, [title]);

  const input = useRef();

  useEffect(() => {
    if (search) {
      const { searchText } = client.readQuery({ query: SEARCH_TEXT });

      setSearchInput(searchText);
    }
  }, [search]);

  const titleClassName = classNames('navbar-title', styles.title, { [titleClass]: titleClass });

  const containerClassName = classNames(styles.navbar, {
    [styles.noShaddow]: noShaddow,
    [styles.shaddow]: !noShaddow,
    [styles.float]: float,
    [slideClass]: slideClass,
    [styles.noZindex]: noZindex,
    [styles.noBorder]: noBorder,
    [styles.transparent]: transparent,
    'navbar-with-search': search,
    [styles.withSearch]: search,
    [styles.dark]: dark,
  });

  const renderRightComponent = () => {
    if (showCloseButton) {
      const handleClose = () => {
        if (onClose) onClose();
        else history.push('/showcase');
      };

      return (
        <Button className={styles.closeButton} flat onClick={handleClose}>
          <Times />
        </Button>
      );
    }

    return rightComponent;
  };

  const navBack = async () => {
    const lastRoute = sessionStorage.getItem('lastRoute');
    const lastRouteObj = JSON.parse(lastRoute);
    const goToParkingHome = history?.location?.state?.lastRoute === '/apps/estacionamento/home';
    const notFromParkingHome = lastRouteObj?.route !== '/apps/estacionamento/home';

    const lastPath = sessionStorage.getItem('lastPath');
    const lastPathObj = JSON.parse(lastPath);
    sessionStorage.removeItem('lastPath');

    const fromWrongShopping = lastPathObj?.route === '/wrong-shopping';

    if (search) resetSearchQueries(client);
    if (tracking) tracking();

    const stopAction = await beforeUnload();

    if (stopAction) return;
    else if (destinyBackButton) history.replace(destinyBackButton);
    else if (goToParkingHome) history.replace('/apps/estacionamento/home');
    else if (fallbackGoBackUrl && fromWrongShopping) history.push(fallbackGoBackUrl);
    else if (lastRoute && notFromParkingHome) history.goBack();
    else if (fallbackGoBackUrl) history.replace(fallbackGoBackUrl);
    else history.replace('/showcase');
  };

  const onSearchChange = (e) => {
    setSearchInput(e);
    searchKeyUp(e);
    client.writeQuery({ query: SEARCH_TEXT, data: { searchText: e } });

    onChange(e);
  };

  const removeKeyboard = (e) => {
    const pressEnter = e.key === 'Enter' || e.keyCode === 13;

    if (pressEnter) input.current.blur();
  };

  return (
    <>
      <div className={containerClassName} data-testid={'NavBar'}>
        {(showBackButton && (
          <Button className={classNames(styles.backButton, 'navBarBackButton')} flat onClick={() => navBack()}>
            <ArrowLeft
              className={classNames(styles.backButtonIcon, {
                [backButtonStyle]: backButtonStyle,
              })}
            />
          </Button>
        )) ||
          (leftComponent && <div className={styles.leftContent}>{leftComponent}</div>)}
        {(search && (
          <Input
            icon="search"
            value={searchInput}
            placeholder={title}
            type="search"
            autoFocus={autoFocus}
            ref={input}
            onChange={onSearchChange}
            onKeyUp={(e) => removeKeyboard(e)}
            onKeyPress={(e) => removeKeyboard(e)}
            onIconClick={() => searchKeyUp(searchInput)}
          />
        )) || (
          <>
            {title && (
              <div id={`nav${titleSlug}`} className={titleClassName}>
                <LinesEllipsis maxLine={2} text={typeof title === 'string' ? title : title?.props?.app?.name} />
              </div>
            )}
            <div className={styles.rightContent}>{renderRightComponent()}</div>
          </>
        )}
      </div>
    </>
  );
};

Navbar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  titleClass: PropTypes.string,
  showBackButton: PropTypes.bool,
  leftComponent: PropTypes.element,
  rightComponent: PropTypes.element,
  history: PropTypes.object.isRequired,
  destinyBackButton: PropTypes.string,
  noShaddow: PropTypes.bool,
  transparent: PropTypes.bool,
  noBorder: PropTypes.bool,
  backButtonStyle: PropTypes.string.isRequired,
  float: PropTypes.bool,
  slideClass: PropTypes.string.isRequired,
  search: PropTypes.bool,
  searchKeyUp: PropTypes.func,
  dark: PropTypes.bool,
  noZindex: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  fallbackGoBackUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  tracking: PropTypes.func,
  beforeUnload: PropTypes.func,
};

Navbar.defaultProps = {
  title: '',
  titleClass: '',
  showBackButton: false,
  leftComponent: null,
  rightComponent: null,
  destinyBackButton: undefined,
  noShaddow: false,
  transparent: false,
  noBorder: false,
  backButtonStyle: '',
  float: false,
  slideClass: '',
  showCloseButton: false,
  onClose: undefined,
  search: false,
  searchKeyUp: () => null,
  dark: false,
  noZindex: false,
  fallbackGoBackUrl: undefined,
  onChange: () => null,
  autoFocus: false,
  tracking: () => null,
  beforeUnload: () => null,
};

export default withRouter(Navbar);
