import gql from 'graphql-tag';

const SHOWS = gql`
  query shows($shoppingId: ID!, $typeSlug: String!, $date: Date, $limit: Int, $skip: Int) {
    shows(shoppingId: $shoppingId, typeSlug: $typeSlug, date: $date, limit: $limit, skip: $skip) {
      id
      name
      slug
      images
    }
  }
`;

const NEXT_SHOWS = gql`
  query nextShows($shoppingId: ID!, $typeSlug: String!, $startDate: Date!, $excludeShowIds: [ID!]!) {
    nextShows(shoppingId: $shoppingId, typeSlug: $typeSlug, startDate: $startDate, excludeShowIds: $excludeShowIds) {
      id
      name
      slug
      images
    }
  }
`;

const MAX_DATE_SHOWS = gql`
  query maxDateShows($shoppingId: ID!, $typeSlug: String!, $startDate: Date!) {
    maxDateShows(shoppingId: $shoppingId, typeSlug: $typeSlug, startDate: $startDate) {
      endDate
    }
  }
`;

export { SHOWS, NEXT_SHOWS, MAX_DATE_SHOWS };
