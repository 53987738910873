import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import Button from 'components/button';

import { useCurrentShopping } from 'hooks';
import { CART } from 'services/apollo/queries';

import { classNames, currencyFormat, bugsnag } from 'utils';
import { tracking } from './helper';

import ShoppingBag from 'icons/shopping-bag.svg';

import styles from './index.module.scss';

const CartSummary = ({ size }) => {
  const currentShopping = useCurrentShopping();
  const history = useHistory();

  const { data: { cart } = {} } = useQuery(CART, {
    variables: { shoppingId: currentShopping?.id },
  });

  const goToCart = () => {
    try {
      tracking('Clicked Open Bag', 'clickedOpenBag');
    } catch (e) {
      bugsnag?.notify(e);
    }

    history.push('/carrinho');
  };

  return (
    cart?.badge > 0 && (
      <div className={classNames(styles.wrapper, styles[size])}>
        <Button onClick={goToCart} flat className={styles.content}>
          <div className={styles.bagInfos}>
            <ShoppingBag className={styles.bag} />
            <span className={styles.label}>Ver sacola</span>
          </div>
          <span className={styles.amount}>{`${currencyFormat(cart?.amount)}`}</span>
        </Button>
      </div>
    )
  );
};

CartSummary.propTypes = {
  size: PropTypes.string,
};

CartSummary.defaultProps = {
  size: 'big',
};

export default CartSummary;
