import { ME, ANONYMOUS_MULTIPLAN_ID } from '../../queries';

import { bugsnag } from 'utils';

const checkUser = async (client, user) => {
  try {
    if (user) {
      const {
        data: { me },
      } = await client.query({ query: ME });
      if (me) {
        multiStorage.setItem('user', JSON.stringify(me));
        window.identifyUser();
      }
    } else {
      const {
        data: { anonymousMultiplanId },
      } = await client.query({ query: ANONYMOUS_MULTIPLAN_ID });
      if (anonymousMultiplanId) {
        window.identify({
          salesForce: {
            profileId: anonymousMultiplanId,
          },
        });
        window.peopleSet({
          salesForce: {
            properties: {
              FirstName: '',
              Email: '',
              Phone: '',
            },
          },
        });
      }
    }
  } catch (e) {
    bugsnag?.notify(e);
  }
};

export default checkUser;
