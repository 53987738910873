import { lazy } from 'react';

const WrongShopping = lazy(() => import(/* webpackChunkName: "checkin" */ 'screens/share/wrongShopping'));
const UnavailableContent = lazy(() => import(/* webpackChunkName: "checkin" */ 'screens/share/unavailableContent'));

export default [
  {
    path: '/wrong-shopping',
    exact: true,
    component: WrongShopping,
  },
  {
    path: '/unavailable-content/:from',
    exact: true,
    component: UnavailableContent,
  },
];
