import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { useCurrentShopping } from 'hooks';
import PlayCard from 'components/playCard';
import Slider from 'components/slider';
import TreadSlider from 'components/treadSlider';

import TheaterIcon from 'icons/theater.svg';
import styles from './index.module.scss';
import { MULTI_THEATERS } from './graphql/queries';
import { clickedTreadItemTracking } from '../../tracking';

const MAX_PLAYS_TO_SHOW = 5;
const SLUGS = ['barrashopping', 'villagemall'];
const COLOR = '#d73a4e';
const PATH = '/apps/teatro-multi';
const TREAD_SLUG = 'teatro-multi';

const MultiTheaterSlider = () => {
  const currentShopping = useCurrentShopping();
  const history = useHistory();

  const isBRSorVLM = SLUGS.includes(currentShopping.slug);
  const isPKJ = currentShopping?.slug === 'parkjacarepagua';

  const { data: { getMultiTheaters } = [], loading } = useQuery(MULTI_THEATERS, {
    variables: {
      active: true,
      shoppingId: currentShopping?.id,
      limit: MAX_PLAYS_TO_SHOW,
    },
  });

  const handleTreadItemClick = useCallback(
    ({ path, itemSlug, treadSlug, title, position }) => {
      clickedTreadItemTracking(treadSlug, title, position);
      history.push(`${path}/${itemSlug}`);
    },
    [history],
  );

  const treadTitle = useMemo(() => {
    if (isBRSorVLM) {
      return 'Agenda Teatro Multiplan';
    }
    if (isPKJ) {
      return 'Agenda Multiplan Hall';
    }
    return 'Em cartaz no Teatro';
  }, [isBRSorVLM, isPKJ]);

  const wordEmphasis = isBRSorVLM || isPKJ ? 'last two' : 'last';

  if (!getMultiTheaters || getMultiTheaters.length < 1) return null;

  return (
    <>
      {!loading && (
        <TreadSlider
          color={COLOR}
          icon={<TheaterIcon className={styles.icon} />}
          path={PATH}
          title={treadTitle}
          wordEmphasis={wordEmphasis}
          slug={TREAD_SLUG}
        >
          <Slider className={styles.slider} fixedItemWidth autoScrollbarHeight>
            {getMultiTheaters.map((play, index) => (
              <PlayCard
                key={play.id}
                title={play.title}
                image={play.carousel[0].original}
                onClick={() =>
                  handleTreadItemClick({
                    path: PATH,
                    itemSlug: play.id,
                    treadSlug: TREAD_SLUG,
                    title: play.title,
                    position: index,
                  })
                }
                period={play.period}
                className={styles.playCard}
              />
            ))}
          </Slider>
        </TreadSlider>
      )}
    </>
  );
};

export default MultiTheaterSlider;
