const openChat = (user, currentShopping, order) => {
  window.Kustomer.describeCustomer({
    attributes: {
      emails: [user?.email],
    },
    customAttributes: {
      name: user?.name,
      organization: currentShopping?.name,
      orderNumber: order?.orderNumber,
    },
  });
  window.Kustomer.open();
};

const loadAndOpenDcChat = (user, currentShopping, order) => {
  if (window.Kustomer) {
    openChat(user, currentShopping, order);
  } else {
    window.addEventListener('kustomerLoaded', () => {
      window.Kustomer.start(
        {
          hideChatIcon: true,
          brandId: process.env.CHAT_KUSTOMER_BRAND_ID,
          assistantId: process.env.CHAT_KUSTOMER_ASSISTANT_ID,
        },
        () => openChat(user, currentShopping, order),
      );
    });

    const script = document.createElement('script');
    script.src = 'https://cdn.kustomerapp.com/chat-web/widget.js';
    script.setAttribute('data-kustomer-api-key', process.env.CHAT_KUSTOMER_DC);
    window.document.body.appendChild(script);
  }
};

export default loadAndOpenDcChat;
