import { bugsnag } from 'utils';

const trackingProductCard = ({ name, price, storeName }) => {
  try {
    window.track({
      mixpanel: {
        name: 'Product Card',
        properties: {
          'Product Name': name,
          'Product Price': price,
          'Product Store Name': storeName,
        },
      },
      googleTagManager: {
        global: {
          name: 'productCard',
          properties: {
            productName: name,
            productPrice: price,
            productStoreName: storeName,
          },
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

export default trackingProductCard;
