import { useEffect } from 'react';
import { useApolloClient, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useCurrentShopping, useUser } from 'hooks';

import { CURRENT_ADDRESS, SELECTED_ADDRESS } from 'services/apollo/queries';

const useSelectedAddress = () => {
  const user = useUser();
  const currentShopping = useCurrentShopping();
  const client = useApolloClient();
  const { data: { selectedAddress } = {} } = useQuery(SELECTED_ADDRESS);

  const setSelectedAddress = (data) => {
    if (data?.currentAddress) {
      client.writeQuery({
        query: SELECTED_ADDRESS,
        data: { selectedAddress: data.currentAddress },
      });
    }
  };

  const [getCurrentAddress, { data }] = useLazyQuery(CURRENT_ADDRESS, {
    variables: { shoppingId: currentShopping?.id },
  });

  const updateAddress = () => {
    getCurrentAddress();
    setSelectedAddress(data);
  };

  useEffect(() => {
    if (!selectedAddress && user) {
      updateAddress();
    }
  }, [selectedAddress, data, getCurrentAddress]);

  return selectedAddress;
};

export default useSelectedAddress;
