import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

const useIsAnything = () => {
  const history = useHistory();
  const getVars = queryString.parse(history.location.search);
  const isAnything = getVars?.isAnything === '1';

  return isAnything;
};

export default useIsAnything;
