import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Times from 'icons/times.svg';
import styles from './index.module.scss';

const CloseButton = ({ onClick }) => {
  const history = useHistory();

  return (
    <div className={styles.root}>
      <button onClick={onClick || history.goBack} data-testid="close-button">
        <Times />
      </button>
    </div>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func,
};

CloseButton.defaultProps = {
  onClick: () => {},
};

export default CloseButton;
