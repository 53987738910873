const PARKING_LIVE_UPDATED = `
  subscription parkingLicensePlateLiveUpdated($licensePlateId: ID) {
    parkingLicensePlateLiveUpdated(licensePlateId: $licensePlateId) {
      id
      activity {
        event
        timestamp
      }
      status
      lastEntranceDate
    }
  }
`;

export { PARKING_LIVE_UPDATED };
