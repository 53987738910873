import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { any, number, object, string, oneOf } from 'prop-types';

import { classNames, formatDatePtBR } from 'utils';

import formatBenefitDescription from 'screens/apps/fidelity/utils/formatBenefitDescription';
import { SignInMultiModal } from 'components/SignInMultiModal';
import { PromotionTag } from 'components/promotionTag';
import { BlockedBenefitModal } from '../blockedBenefitModal';
import { useBenefitMvcCard } from './useBenefitMvcCard';
import formatBenefitTile from '../../../utils/formatBenefitTitle';
import numberFix from '../../../utils/balanceFixValue';

import Star from 'icons/star.svg';
import StarSolid from 'icons/star-solid.svg';
import coinIcon from 'icons/coin-icon.svg?url';
import ClockSvg from 'icons/clock.svg';
import CheckSvg from 'icons/check.svg';
import SoldOutIcon from 'icons/close-flat.svg';
import EmptyImageSvg from 'icons/ticket-multi.svg';
import LockIcon from 'icons/lock-update-icon.svg';

import styles from './index.module.scss';

const BenefitMvcCard = ({
  benefit,
  redeemDate,
  endDate,
  userVipBenefitId,
  status,
  variant,
  onClickFavorite,
  focus,
  breakWord,
  index,
}) => {
  const {
    openModal,
    openBlockedBenefitModal,
    imageUrl,
    difference,
    showAvailableBenefit,
    isExpired,
    isBlocked,
    showPoints,
    showFavoriteButton,
    showIsSoldOut,
    showLimitDate,
    isFavorite,

    handleClickBenefit,
    handleFavorite,
    handleCloseModal,
    removeOneDay,
    differenceDayText,
    handleCloseBlockedBenefitModal,
  } = useBenefitMvcCard({
    benefit,
    userVipBenefitId,
    status,
    variant,
    endDate,
    onClickFavorite,
    focus,
    index,
    redeemDate,
  });

  const LimitDate = () => {
    if (showAvailableBenefit) {
      return <strong className={styles.time}>Benefício disponível</strong>;
    }

    if (focus === 'used') {
      return !isExpired ? (
        <div className={styles['check-container']}>
          <CheckSvg className={styles.check} />
          <p>
            Utilizado em <strong className={styles.time}>{formatDatePtBR(redeemDate)}</strong>
          </p>
        </div>
      ) : (
        <>
          <ClockSvg className={styles.clock} />
          <p>
            Expirado em <strong className={styles.time}>{formatDatePtBR(endDate)}</strong>
          </p>
        </>
      );
    }

    if (showIsSoldOut) {
      return (
        <>
          <SoldOutIcon className={styles.soldOut} /> Benefício Esgotado
        </>
      );
    }

    if (difference > 0) {
      if (difference <= 7) {
        if (focus === 'favorite') {
          return (
            <>
              <ClockSvg className={styles.clock} />
              <p>
                Resgate em
                <strong className={styles.time}>
                  {difference === 1 ? ` ${difference} dia` : ` ${difference} dias!`}
                </strong>
              </p>
            </>
          );
        } else {
          return (
            <>
              <ClockSvg className={styles.clock} />
              <p>
                {focus === 'ready' ? (
                  <>
                    {difference - 1 === 0 ? (
                      <span>
                        Utilize <strong className={styles.time}>Hoje</strong>
                      </span>
                    ) : (
                      <>Utilize em</>
                    )}
                    <strong className={styles.time}>{differenceDayText(difference - 1)}</strong>
                  </>
                ) : (
                  <>
                    Resgate em
                    <strong className={styles.time}>{differenceDayText(difference)}</strong>
                  </>
                )}
              </p>
            </>
          );
        }
      } else {
        return (
          <p>
            {focus === 'ready' ? (
              <>
                Utilize até
                <strong className={styles.time}> {formatDatePtBR(removeOneDay(endDate))}</strong>
              </>
            ) : (
              <>
                Resgate até
                <strong className={styles.time}> {formatDatePtBR(endDate)}</strong>
              </>
            )}
          </p>
        );
      }
    }

    if (difference === 0) {
      return (
        <>
          <ClockSvg className={styles.clock} />
          <p>
            Expira <strong className={styles.time}>Hoje</strong>
          </p>
        </>
      );
    }

    if (difference < 0) {
      return (
        <>
          <ClockSvg className={styles.clock} />
          <p>
            Expirado em <strong className={styles.time}>{formatDatePtBR(endDate)}</strong>
          </p>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <div
        data-testid={`benefit-${benefit.id}`}
        className={classNames(styles.card, styles[variant], {
          [styles.disabled]: isExpired || benefit.isSoldOut,
          [styles.table]: focus === 'table',
        })}
        onClick={(event) => handleClickBenefit(event)}
      >
        {imageUrl ? (
          <div>
            <img src={imageUrl} className={styles.photo} />
          </div>
        ) : (
          <div>
            <EmptyImageSvg className={styles.photo} />
          </div>
        )}

        <div className={styles.cardInfoContainer}>
          <div className={styles.headerInfo}>
            <strong className={styles.title}>{formatBenefitTile({ benefit, variant, isFromList: true })}</strong>
            {showFavoriteButton && (
              <button
                className={styles.starContainer}
                onClick={!isExpired ? (event) => handleFavorite(event) : () => {}}
              >
                {isFavorite ? <StarSolid className={styles.star} /> : <Star className={styles.star} />}
              </button>
            )}
            {isBlocked && (
              <div>
                <LockIcon className={styles.lockIcon} />
              </div>
            )}
          </div>
          <LinesEllipsis
            maxLine={3}
            text={formatBenefitDescription({ benefit, showAll: true })}
            className={classNames(styles.text, breakWord)}
            basedOn="letters"
          />

          {showLimitDate && (
            <div className={styles.limit}>
              <LimitDate />
            </div>
          )}
          {showPoints && (
            <div className={styles.balanceValue}>
              <img src={coinIcon} className={styles.coinIcon} />
              <div className={styles.balanceValueContainer}>
                {!!benefit.salePointsFrom && (
                  <span className={styles.disccount}>{numberFix(benefit.salePointsFrom)} pts</span>
                )}
                <p>
                  {numberFix(benefit.points)}
                  <span className={styles.balanceValuePoints}>{benefit.points <= 1 ? 'pt' : 'pts'}</span>
                </p>
              </div>
            </div>
          )}
          <PromotionTag categories={benefit.categories} points={benefit.salePointsFrom} />
        </div>
      </div>
      <SignInMultiModal show={openModal} onClose={handleCloseModal} />
      <BlockedBenefitModal
        isOpen={openBlockedBenefitModal}
        onClose={handleCloseBlockedBenefitModal}
        variant={variant}
        focus={focus}
      />
    </>
  );
};

BenefitMvcCard.propTypes = {
  variant: oneOf(['green', 'silver', 'gold', 'black']).isRequired,
  userVipBenefitId: string,
  benefit: object.isRequired,
  onClickFavorite: any,
  focus: string,
  shoppingSlug: string.isRequired,
  redeemDate: string,
  endDate: string,
  issueDate: string,
  status: string,
  breakWord: object,
  index: number,
  promotionId: string,
};

BenefitMvcCard.defaultProps = {
  focus: '',
  userVipBenefitId: '',
  redeemDate: '',
  issueDate: '',
  endDate: null,
  status: '',
  breakWord: null,
  index: null,
  promotionId: null,
  onClickFavorite: null,
};

export { BenefitMvcCard };
