import { lazy } from 'react';

const Home = lazy(() => import(/* webpackChunkName: "fidelity" */ 'screens/apps/fidelity/home'));
const Terms = lazy(() => import(/* webpackChunkName: "fidelity" */ 'screens/apps/fidelity/terms'));
const SendTicket = lazy(() => import(/* webpackChunkName: "fidelity" */ 'screens/apps/fidelity/tickets/send'));
const viewTickets = lazy(() => import(/* webpackChunkName: "fidelity" */ 'screens/apps/fidelity/tickets/view'));
const NoteDetailsProvider = lazy(() =>
  import(/* webpackChunkName: "fidelity" */ 'screens/apps/fidelity/tickets/NoteDetails'),
);
const Statement = lazy(() => import(/* webpackChunkName: "fidelity" */ 'screens/apps/fidelity/statement'));
const Login = lazy(() => import(/* webpackChunkName: "fidelity" */ 'screens/apps/fidelity/login'));
const Benefit = lazy(() => import(/* webpackChunkName: "fidelity" */ 'screens/apps/fidelity/benefit/screens/benefit'));
const Benefits = lazy(() => import(/* webpackChunkName: "fidelity" */ 'screens/apps/fidelity/benefits'));
const Favorites = lazy(() => import(/* webpackChunkName: "fidelity" */ 'screens/apps/fidelity/benefits/favorites'));
const UserVipBenefit = lazy(() =>
  import(/* webpackChunkName: "fidelity" */ 'screens/apps/fidelity/benefit/screens/userVipBenefit'),
);
const InactiveUser = lazy(() => import(/* webpackChunkName: "fidelity" */ 'screens/apps/fidelity/inactiveBenefits'));
const PointsExplanation = lazy(() =>
  import(/* webpackChunkName: "fidelity" */ 'screens/apps/fidelity/pointsExplanation'),
);
const BenefitsTable = lazy(() => import(/* webpackChunkName: "fidelity" */ 'screens/apps/fidelity/BenefitsTable'));
const FidelityTabs = lazy(() => import(/* webpackChunkName: "fidelity" */ 'screens/apps/fidelity/FidelityTabs'));
const PromotionFidelity = lazy(() =>
  import(/* webpackChunkName: "fidelity" */ 'screens/apps/fidelity/PromotionFidelity'),
);
const BenefitList = lazy(() => import(/* webpackChunkName: "fidelity" */ 'screens/apps/fidelity/benefitList'));

export default [
  {
    path: '/apps/multivoce/enviar-notas',
    exact: true,
    component: SendTicket,
    vip: true,
    privateRoute: true,
  },
  {
    path: '/apps/multivoce/regulamento',
    exact: true,
    component: Terms,
  },
  {
    path: '/apps/multivoce/ver-notas',
    exact: true,
    component: viewTickets,
    vip: true,
    privateRoute: true,
  },
  {
    path: '/apps/multivoce/ver-detalhes-notas/:id',
    exact: true,
    component: NoteDetailsProvider,
    vip: true,
    privateRoute: true,
  },
  {
    path: '/apps/multivoce/consulta',
    exact: true,
    component: Statement,
    vip: true,
    privateRoute: true,
  },
  {
    path: '/apps/multivoce/login',
    exact: true,
    component: Login,
    vip: true,
    privateRoute: false,
  },
  {
    path: '/apps/multivoce/beneficios/:modality',
    exact: true,
    component: BenefitsTable,
    vip: true,
    privateRoute: false,
  },
  {
    path: '/apps/multivoce/beneficios/resgatados/ticket/:id',
    exact: true,
    component: UserVipBenefit,
    vip: true,
    privateRoute: true,
  },
  {
    path: '/apps/multivoce/beneficios/favoritos',
    exact: true,
    component: Favorites,
    vip: true,
    privateRoute: true,
  },
  {
    path: '/apps/multivoce/beneficios/tickets/:id/:variant?',
    exact: true,
    component: Benefit,
    vip: true,
    privateRoute: false,
  },
  {
    path: '/apps/multivoce/meusbeneficios/:type/:redeemedDate?',
    exact: true,
    component: Benefits,
    vip: true,
    privateRoute: false,
  },
  {
    path: '/apps/multivoce/usuario-inativo',
    exact: true,
    component: InactiveUser,
  },
  {
    path: '/apps/multivoce/abas/:tab',
    exact: true,
    component: FidelityTabs,
    vip: true,
    privateRoute: false,
  },
  {
    path: '/apps/multivoce/explicacao-pontos',
    exact: true,
    component: PointsExplanation,
    vip: true,
    privateRoute: false,
  },
  {
    path: '/apps/multivoce/loyalty-promotion',
    exact: true,
    component: PromotionFidelity,
    vip: true,
    privateRoute: false,
  },
  {
    path: '/apps/multivoce/lista-de-beneficios/:variant',
    exact: true,
    component: BenefitList,
    vip: true,
    privateRoute: false,
  },
  {
    path: '/apps/multivoce/:filter',
    exact: true,
    component: Home,
    vip: true,
    privateRoute: true,
  },
  {
    path: '/apps/multivoce/home',
    exact: true,
    component: Home,
    vip: true,
    privateRoute: true,
  },
  {
    path: '/apps/multivoce',
    component: Home,
    vip: true,
    privateRoute: true,
  },
];
