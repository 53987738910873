export const createFilterURL = (path, values) => {
  const pointsURL = `${values.initialValue ? `&initialValue=${values.initialValue}` : ''}${
    values.lastValue ? `&lastValue=${values.lastValue}` : ''
  }${values.isWithoutPoint ? `&isWithoutPoint=${values.isWithoutPoint}` : ''}${
    values.recentFilter ? '&recent=true' : ''
  }${values.redeemedFilter ? '&redeem=true' : ''}${
    values.categoriesFilter.length > 0 ? `&categories=${values.categoriesFilter}` : ''
  }${values.storesFilter.length > 0 ? `&stores=${values.storesFilter}` : ''}`;

  window.history.pushState(null, null, `${window.location.origin + window.location.pathname}#/${path}/${pointsURL}`);
};
