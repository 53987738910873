import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useHistory, withRouter } from 'react-router-dom';
import { useUserPlan, useUser, useCurrentShopping } from 'hooks';
import { classNames, formatDatePtBR, bugsnag } from 'utils';

import numberFix from 'screens/apps/fidelity/utils/balanceFixValue';

import { useQuery } from '@apollo/react-hooks';
import { SHOPPINGS_MODALITIES } from 'services/apollo/queries';

import ChevronRight from 'icons/chevron-right.svg';
import CoinIcon from 'icons/coin-icon.svg';

import { clickPointsExplanationTracking } from './tracking';

import styles from './index.module.scss';
import { MultivcBanner } from '../MultivcBanner';

const SummaryCardProvider = (props) => {
  const user = useUser();
  const plan = useUserPlan();

  return <SummaryCard user={user} planInfo={plan} {...props} />;
};

const SummaryCard = ({ user, planInfo, disableClick, path }) => {
  const history = useHistory();
  const variant = planInfo.variant;
  const currentShopping = useCurrentShopping(true);

  const isValueSmallerThenZero = useMemo(
    () => (planInfo?.currentTierMaintenanceBalance < 0 ? true : false),
    [planInfo],
  );

  const { data, loading } = useQuery(SHOPPINGS_MODALITIES, {
    variables: {
      shoppingSlug: currentShopping?.slug,
    },
    skip: path !== 'showcase',
  });

  const isPathFromShowcase = path === 'showcase';

  const silverValue = data?.plan?.modalities?.find((modality) => modality.name === 'silver').upgradePoints;

  const currentBalance = numberFix(planInfo?.currentBalance);

  const hideShowcasePoints =
    isPathFromShowcase && variant === 'green' && 0.8 * planInfo?.currentBalance < silverValue + 20;

  const nameShortener = () => {
    const nameBreak = user.name.split(' ');
    const nameLast = parseInt(nameBreak.length - 1);

    return nameLast > 0 ? nameBreak[0] + ' ' + nameBreak[nameLast] : user.name;
  };

  const handleClickCard = () => {
    if (disableClick) return;

    if (isPathFromShowcase) {
      try {
        window.track({
          mixpanel: {
            name: 'Clicked Fidelity Showcase',
            properties: {
              Modality: variant,
            },
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }
      history.push('/apps/multivoce');
      return;
    }

    clickPointsExplanationTracking(variant);
    history.push('/apps/multivoce/abas/pontos');
  };

  const UserBalane = () => {
    return (
      <>
        <span className={styles.balance} data-variant={isPathFromShowcase}>
          Saldo Atual
        </span>
        <div className={styles.balanceValue} data-variant={isPathFromShowcase}>
          <CoinIcon className={styles.balanceValueCoin} />
          <span data-testid="balance">{currentBalance}</span>
          <span className={styles.balanceValuePoints}>{currentBalance <= 1 ? 'pt' : 'pts'}</span>
        </div>
      </>
    );
  };
  if (hideShowcasePoints) {
    return <MultivcBanner type={variant} user={user} plan={planInfo} />;
  }

  return (
    <SkeletonTheme color="#c0c4cc" highlightColor="#DADADA">
      <div
        className={classNames(styles.root, styles[variant])}
        onClick={handleClickCard}
        data-variant={isPathFromShowcase}
      >
        <div className={styles.box}>
          <div>
            <strong id="multiVoceNameStrong" className={styles.name}>
              {nameShortener()}
            </strong>
            <div className={styles.textContainer} data-default={!isPathFromShowcase}>
              {(!planInfo?.name && loading && <Skeleton count={1} />) || (
                <div className={styles.plan} data-testid="planNameDiv">
                  {`MultiVocê ${planInfo?.name}`}
                </div>
              )}
              {variant !== 'green' && !isValueSmallerThenZero && (
                <div className={styles.planExpireDate} data-testid="planExpireDateDiv">
                  até {formatDatePtBR(planInfo?.nextTierAssessmentDate)}
                </div>
              )}
            </div>
          </div>
          <div className={styles.howItWorks}>
            {path !== 'showcase' && <strong>Como funciona</strong>}
            <ChevronRight className={styles.chevronIcon} />
          </div>
        </div>

        <div className={styles.footerCard}>
          <UserBalane />
        </div>
      </div>
    </SkeletonTheme>
  );
};

SummaryCard.propTypes = {
  user: PropTypes.object.isRequired,
  planInfo: PropTypes.object.isRequired,
  path: PropTypes.string,
  disableClick: PropTypes.bool,
};

SummaryCard.defaultProps = {
  disableClick: false,
  path: '',
};

export { SummaryCard };
export default withRouter(SummaryCardProvider);
