import React, { useMemo, useState } from 'react';
import { bool, number, array, oneOf, string } from 'prop-types';

import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks';
import { isAndroid } from 'react-device-detect';

import { useCurrentShopping, useRedirectModal, useUser, useUserPlan } from 'hooks';
import { openParking, goToApp } from 'utils';

import DefaultAppSlider from './components/defaultAppSlider';
import SlimAppSlider from './components/slimAppSlider';
import ParkingModal from '../parkingModal';
import Loading from 'components/loading';

import { sendTracking } from './tracking';

import { APPS } from './graphql/queries';
import { SAVE_USER_VIEW_TOKEN } from 'services/apollo/mutations';

import styles from './index.module.scss';

const AppSlider = ({
  variant,
  withTitle,
  filter,
  toRemove: toRemoveProp,
  className,
  from,
  showAllAppsButton,
  numAppsToShow,
}) => {
  const [parkingModal, setParkingModal] = useState(false);
  const [flugoURL, setFlugoURL] = useState('');
  const currentShopping = useCurrentShopping();
  const openRedirectModal = useRedirectModal();

  const plan = useUserPlan();

  const toRemove = useMemo(() => {
    return toRemoveProp;
  }, [currentShopping, plan, toRemoveProp, className]);

  const user = useUser();
  const history = useHistory();
  const client = useApolloClient();

  let { data: { apps = [] } = {} } = useQuery(APPS, {
    variables: {
      shoppingId: currentShopping.id,
    },
  });

  const [getUserViewToken, { loading: loadingFlugoToken }] = useMutation(SAVE_USER_VIEW_TOKEN, {
    variables: {
      partnerSlug: 'flugo',
    },
    onCompleted: (data) => {
      openRedirectModal({
        url: encodeURIComponent(`${flugoURL}&usertoken=${data.saveUserViewToken.token}`),
        forceBrowser: isAndroid,
      });
    },
  });

  const redirectHandler = async ({ slug, name, externalUrl, showLoginScreen }) => {
    sendTracking(slug, externalUrl, showLoginScreen, from);
    if (externalUrl) {
      if (externalUrl.includes('api.whatsapp.com') || externalUrl.includes('wa.me'))
        await goToApp({ link: externalUrl, currentShopping, user, history, client });
      else if (externalUrl.includes('barracadabra')) return history.push('/check-in/ic5edyj2fo-ingresso-barracadabra');
      else if (!user && showLoginScreen) {
        client.writeData({ data: { pathname: `/redirect/${encodeURIComponent(externalUrl)}/${isAndroid}` } });
        history.push('/signup/phone');
      } else if (externalUrl.includes('flugo') && user) {
        setFlugoURL(externalUrl);
        getUserViewToken();
      } else {
        openRedirectModal({ url: encodeURIComponent(externalUrl), forceBrowser: isAndroid });
      }
    } else {
      await goToApp({ link: slug, appName: name, currentShopping, user, history, client });
    }
  };

  const ComponentAppSlider = (variant === 'slim' && SlimAppSlider) || DefaultAppSlider;

  const getApps = () => {
    return apps.slice().filter((app) => {
      if (filter.length && !filter.includes(app.slug)) return false;
      if (toRemove.length && toRemove.includes(app.slug)) return false;
      if (app.slug === 'estacionamento') {
        if (openParking(currentShopping.slug, user?.isBeta)) {
          return true;
        }
        return false;
      }
      return true;
    });
  };

  return (
    <>
      {apps?.length > 0 && (
        <ComponentAppSlider
          onClick={redirectHandler}
          hideTitle={withTitle}
          shoppingName={currentShopping.name}
          apps={getApps()}
          className={className}
          showAllAppsButton={showAllAppsButton}
          numAppsToShow={numAppsToShow}
        />
      )}
      <ParkingModal isOpen={parkingModal} onClose={() => setParkingModal(false)} />
      {loadingFlugoToken &&
        ReactDOM.createPortal(<Loading className={styles.loading} />, document.getElementById('overlay-root'))}
    </>
  );
};

AppSlider.propTypes = {
  skip: number,
  withTitle: bool,
  variant: oneOf(['slim', 'default']),
  filter: array,
  toRemove: array,
  className: string,
  from: string.isRequired,
  showAllAppsButton: bool,
  numAppsToShow: number,
};

AppSlider.defaultProps = {
  withTitle: true,
  skip: 0,
  variant: 'slim',
  firstTime: false,
  filter: [],
  toRemove: [],
  className: '',
  showAllAppsButton: false,
  numAppsToShow: 7,
};

export default AppSlider;
