import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Modal from 'components/modal';

import { getCurrentPath } from 'utils';

import { useApolloClient } from '@apollo/react-hooks';
import modalSignInImage from 'images/fidelity/sign-in-modal.webp';

import styles from './styles.module.scss';

function SignInMultiModal({ show, onClose, hasOutsideClick }) {
  const history = useHistory();
  const client = useApolloClient();

  function handleGoToSignIn(event) {
    onClose(event);
    const currentPath = getCurrentPath();
    client.writeData({ data: { pathname: currentPath } });
    history.push('/signup/phone');
  }
  return (
    <div>
      <Modal className={styles.modalContainer} isOpen={show} onClose={onClose} hasOutsideClick={hasOutsideClick} slide>
        <img src={modalSignInImage} className={styles.image} />

        <strong className={styles.title}>
          Falta pouco para você fazer <br /> parte do MultiVocê!
        </strong>
        <span className={styles.text}>
          <strong>Cadastre-se</strong> e comece a aproveitar os benefícios especiais do nosso programa de
          relacionamento.
        </span>

        <button className={styles.signInButton} data-testid={`enterBtnMvc`} onClick={handleGoToSignIn}>
          Entrar
        </button>
        <button className={styles.notYetButton} onClick={onClose}>
          Agora não
        </button>
      </Modal>
    </div>
  );
}

SignInMultiModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  hasOutsideClick: PropTypes.bool,
};

SignInMultiModal.defaultProps = {
  hasOutsideClick: true,
};

export { SignInMultiModal };
