const SHOPPINGSTATE = new Map([
  ['Alagoas', 'AL'],
  ['Distrito Federal', 'DF'],
  ['Minas Gerais', 'MG'],
  ['Paraná', 'PR'],
  ['Rio de Janeiro', 'RJ'],
  ['Rio Grande do Sul', 'RS'],
  ['São Paulo', 'SP'],
]);
export default SHOPPINGSTATE;
