import React, { createContext, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

const FavoriteCheckInRedirectContext = createContext({});

const FavoriteCheckInRedirectProvider = ({ children }) => {
  const [checkInPendingFavorite, setCheckInPendingFavorite] = useState('');

  const cleanFavorite = useCallback(() => {
    setCheckInPendingFavorite('');
  }, [setCheckInPendingFavorite]);

  const favoriteNextLoad = useCallback(
    (checkInId) => {
      setCheckInPendingFavorite(checkInId);
    },
    [setCheckInPendingFavorite],
  );

  return (
    <FavoriteCheckInRedirectContext.Provider value={{ checkInPendingFavorite, cleanFavorite, favoriteNextLoad }}>
      {children}
    </FavoriteCheckInRedirectContext.Provider>
  );
};

const useFavoriteCheckInRedirect = () => {
  const context = useContext(FavoriteCheckInRedirectContext);

  if (!context) {
    throw new Error("You don't have access to favorite check-in redirect context");
  }

  return context;
};

FavoriteCheckInRedirectProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { FavoriteCheckInRedirectProvider, useFavoriteCheckInRedirect };
