import gql from 'graphql-tag';

const PARKING_SHOPPING = gql`
  query parkingShopping($shoppingId: ID!) {
    parkingShopping(shoppingId: $shoppingId) {
      id
      image {
        id
        original
      }
    }
  }
`;

const PARKING_LICENSE_PLATES = gql`
  query parkingLicensePlates {
    parkingLicensePlates {
      id
      user {
        id
      }
      status
      shopping {
        id
        slug
        regionCode
      }
      licensePlate
      active {
        mobile {
          active
        }
        lpr {
          active
        }
      }
      activity {
        event
        timestamp
      }
      lastEntranceDate
    }
  }
`;

const HAS_PARKING_LICENSE_PLATE = gql`
  query hasParkingLicensePlate($licensePlate: String) {
    hasParkingLicensePlate(licensePlate: $licensePlate)
  }
`;

const PARKING_TRANSACTION = gql`
  query lprTransaction($id: ID!) {
    lprTransaction(id: $id) {
      id
      shopping {
        name
        slug
      }
      ticket
      transactionDate
      entranceDate
      exitDate
      exitGateId
      amount
      status
      rpsUsn
      rpsSeries
      rpsNumber
      parkingShopping {
        cnpj
      }
      parkingLicensePlate {
        licensePlate
      }

      paymentRequest {
        id
        paymentSource
        creditCard {
          lastNumbers
          brand
        }
        status
        paidAmount
        refundedAmount
        refundDate
        cancellationDate
        canBeRetried
      }
    }
  }
`;

const PARKING_TRANSACTIONS = gql`
  query lprTransactions($isNotOpenOrClosed: Boolean, $descending: String) {
    lprTransactions(isNotOpenOrClosed: $isNotOpenOrClosed, descending: $descending) {
      id
      shopping {
        name
      }
      transactionDate
      entranceDate
      exitDate
      amount
      status
      paymentRequest {
        id
        paymentSource
        creditCard {
          brand
        }
        status
        paidAmount
      }
      parkingLicensePlate {
        lprRegistration {
          name
        }
      }
    }
  }
`;

const HAS_ACTIVE_OSA = gql`
  query hasActiveOsa($licensePlate: String!) {
    hasActiveOsa(licensePlate: $licensePlate) {
      semParar
      taggy
      conectcar
      veloe
    }
  }
`;

const HAS_ACTIVE_OSAS = gql`
  query hasActiveOsas {
    hasActiveOsas {
      taggy
      semParar
      conectcar
      veloe
    }
  }
`;

const LPR_REGISTRATION = gql`
  query lprRegistration($shoppingId: ID!, $active: Boolean) {
    lprRegistration {
      id
      licensePlate(shoppingId: $shoppingId) {
        id
        user {
          id
        }
        status
        shopping {
          id
          slug
        }
        licensePlate
        active {
          lpr {
            active
          }
        }
        activity {
          event
          timestamp
        }
        lastEntranceDate
      }
      taggyStatus
      semPararStatus
      conectcarStatus
      veloeStatus
      paymentMethod
      active
      creditCard {
        id
        name
        brand
        lastNumbers
        validThru
      }
      name
      nfseCpf
    }
  }
`;

const LPR_REGISTRATIONS = gql`
  query lprRegistrations($shoppingId: ID) {
    lprRegistrations {
      id
      licensePlate(shoppingId: $shoppingId) {
        id
        licensePlate
        status
        shopping {
          id
          name
        }
        creditCard {
          brand
          lastNumbers
        }
        createdAt
        updatedAt
        activity {
          event
          timestamp
        }
        lastEntranceDate
        active {
          lpr {
            active
          }
        }
      }
      name
      taggyStatus
      semPararStatus
      conectcarStatus
      veloeStatus
      paymentMethod
      active
      creditCard {
        id
        name
        brand
        lastNumbers
        validThru
      }
      hasLooseTicket
      nfseCpf
    }
  }
`;

const GET_VIRTUAL_TICKET = gql`
  query checkTicketByLicensePlate(
    $licensePlate: String!
    $shoppingSlug: String!
    $source: String!
    $captchaToken: String
  ) {
    checkTicketByLicensePlate(
      licensePlate: $licensePlate
      shoppingSlug: $shoppingSlug
      source: $source
      captchaToken: $captchaToken
    ) {
      amount
      amountPaid
      amountWithoutDiscount
      discountValue
      entranceDate
      licensePlate
      ticketNumber
    }
  }
`;

const GET_LPR_TRANSACTION_INVOICE = gql`
  query lprTransactionNfse($id: ID!) {
    lprTransactionNfse(id: $id)
  }
`;

const IS_OSA_USER = gql`
  query IsOsaUser {
    isOsaUser {
      semParar
      conectcar
      veloe
    }
  }
`;

const GET_OSA_USER_AVAILABLE_LICENSE_PLATES = gql`
  query osaUserAvailableLicensePlates($osa: String!) {
    osaUserAvailableLicensePlates(osa: $osa)
  }
`;

const IS_LICENSE_PLATE_FROM_OSA_USER = gql`
  query isLicensePlateFromOsaUser($licensePlate: String!, $osa: String!) {
    isLicensePlateFromOsaUser(licensePlate: $licensePlate, osa: $osa) {
      semParar
      conectcar
      veloe
    }
  }
`;

export {
  PARKING_SHOPPING,
  PARKING_LICENSE_PLATES,
  HAS_PARKING_LICENSE_PLATE,
  PARKING_TRANSACTION,
  PARKING_TRANSACTIONS,
  HAS_ACTIVE_OSA,
  HAS_ACTIVE_OSAS,
  LPR_REGISTRATION,
  LPR_REGISTRATIONS,
  GET_VIRTUAL_TICKET,
  GET_LPR_TRANSACTION_INVOICE,
  IS_OSA_USER,
  GET_OSA_USER_AVAILABLE_LICENSE_PLATES,
  IS_LICENSE_PLATE_FROM_OSA_USER,
};
