import { lazy } from 'react';

const Home = lazy(() => import(/* webpackChunkName: "home" */ 'screens/home'));
const ShoppingList = lazy(() => import(/* webpackChunkName: "home" */ 'screens/shoppingList'));
const SignUpPhoneLogin = lazy(() => import(/* webpackChunkName: "home" */ 'screens/signup/phone'));
const SignUpAdditional = lazy(() => import(/* webpackChunkName: "home" */ 'screens/signup/additional'));
const UpdateRegistration = lazy(() => import(/* webpackChunkName: "home" */ 'screens/updateRegistration'));
const SignUpPhoneConfirmation = lazy(() => import(/* webpackChunkName: "home" */ 'screens/signup/phoneConfirmation'));
const SignUpIdentityConfirmation = lazy(() =>
  import(/* webpackChunkName: "home" */ 'screens/signup/identityConfirmation'),
);
const SignUpResendCode = lazy(() => import(/* webpackChunkName: "home" */ 'screens/signup/resendCode'));
const SignUpRecoverAccount = lazy(() => import(/* webpackChunkName: "home" */ 'screens/signup/recoverAccount'));
const SignUpNewPhone = lazy(() => import(/* webpackChunkName: "home" */ 'screens/signup/recoverAccount/newPhone'));
const SignUpNewPhoneConfirmation = lazy(() =>
  import(/* webpackChunkName: "home" */ 'screens/signup/recoverAccount/newPhoneConfirmation'),
);
const SignUpNewCpf = lazy(() => import(/* webpackChunkName: "home" */ 'screens/signup/recoverAccount/newCpf'));
const SignUpRecoverAccountResendToPhone = lazy(() =>
  import(/* webpackChunkName: "home" */ 'screens/signup/recoverAccount/resendToPhone'),
);
const SignUpRecoverAccountResendToEmail = lazy(() =>
  import(/* webpackChunkName: "home" */ 'screens/signup/recoverAccount/resendToEmail'),
);
const SignUpChangeCodeConfirmation = lazy(() =>
  import(/* webpackChunkName: "home" */ 'screens/signup/recoverAccount/codeConfirmation'),
);
const SignUpIssue = lazy(() => import(/* webpackChunkName: "home" */ 'screens/signup/recoverAccount/issue'));

const App = lazy(() => import(/* webpackChunkName: "showcase" */ 'screens/app'));
const Menu = lazy(() => import(/* webpackChunkName: "showcase" */ 'screens/menu'));
const Cart = lazy(() => import(/* webpackChunkName: "showcase" */ 'screens/cart'));
const Showcase = lazy(() => import(/* webpackChunkName: "showcase" */ 'screens/showcase'));
const AddAddress = lazy(() => import(/* webpackChunkName: "showcase" */ 'screens/apps/marketplace/addAddress'));
const FeaturedDeliveries = lazy(() => import(/* webpackChunkName: "showcase" */ 'screens/delivery'));
const Redirect = lazy(() => import(/* webpackChunkName: "redirect" */ 'screens/redirect'));
const ChargingStation = lazy(() => import(/* webpackChunkName: "showcase" */ 'screens/apps/parking/chargingStation'));
// const Parking = lazy(() => import(/* webpackChunkName: "showcase" */ 'screens/apps/parking'));

const Terms = lazy(() => import(/* webpackChunkName: "menu" */ 'screens/terms'));
const Adresses = lazy(() => import(/* webpackChunkName: "menu" */ 'screens/adressess'));
const EditProfile = lazy(() => import(/* webpackChunkName: "menu" */ 'screens/editProfile'));
const PrivacyPolicy = lazy(() => import(/* webpackChunkName: "menu" */ 'screens/privacyPolicy'));
const ContactUs = lazy(() => import(/* webpackChunkName: "menu" */ 'screens/contactUs'));
const WiFi = lazy(() => import(/* webpackChunkName: "menu" */ 'screens/wifi'));
const ModalPromo = lazy(() => import(/* webpackChunkName: "menu" */ 'screens/apps/fidelity/modalPromo'));
// const ModalPromoEngagement = lazy(() =>
//   import(/* webpackChunkName: "menu" */ 'screens/apps/fidelity/modalPromoEngagement'),
// );
const PrePrivacyPolicy = lazy(() => import(/* webpackChunkName: "menu" */ 'screens/prePrivacyPolicy'));

export default [
  {
    path: '/',
    exact: true,
    component: Home,
    noMall: true,
  },
  {
    path: '/home',
    exact: true,
    component: Home,
    noMall: true,
  },
  {
    path: '/terms',
    exact: true,
    component: Terms,
    noMall: true,
  },
  {
    path: '/privacy-policy',
    exact: true,
    component: PrivacyPolicy,
    noMall: true,
  },
  {
    path: '/shoppings',
    exact: true,
    component: ShoppingList,
    noMall: true,
  },
  {
    path: '/showcase/:tab?',
    component: Showcase,
  },
  {
    path: '/menu',
    exact: true,
    component: Menu,
  },
  {
    path: '/meus-enderecos',
    exact: true,
    component: Adresses,
  },
  {
    path: '/adicionar-endereco',
    exact: true,
    component: AddAddress,
  },
  {
    path: '/editar-perfil',
    exact: true,
    component: EditProfile,
  },
  {
    path: '/carrinho',
    exact: true,
    component: Cart,
  },
  {
    path: '/signup/phone',
    exact: true,
    component: SignUpPhoneLogin,
    login: true,
    noMall: true,
  },
  {
    path: '/signup/phone/confirmation',
    exact: true,
    component: SignUpPhoneConfirmation,
    login: true,
    noMall: true,
  },
  {
    path: '/signup/identity/confirmation',
    exact: true,
    component: SignUpIdentityConfirmation,
    login: false,
    noMall: true,
  },
  {
    path: '/signup/phone/resend',
    exact: true,
    component: SignUpResendCode,
    login: true,
    noMall: true,
  },
  {
    path: '/signup/phone/change',
    exact: true,
    component: SignUpRecoverAccount,
    login: true,
    noMall: true,
  },
  {
    path: '/signup/phone/issue',
    exact: true,
    component: SignUpIssue,
    login: true,
    noMall: true,
  },
  {
    path: '/signup/phone/change/new-phone',
    exact: true,
    component: SignUpNewPhone,
    login: true,
    noMall: true,
  },
  {
    path: '/signup/phone/change/new-cpf',
    exact: true,
    component: SignUpNewCpf,
    login: true,
    noMall: true,
  },
  {
    path: '/signup/phone/change/resend-phone',
    exact: true,
    component: SignUpRecoverAccountResendToPhone,
    login: true,
    noMall: true,
  },
  {
    path: '/signup/phone/change/resend-email',
    exact: true,
    component: SignUpRecoverAccountResendToEmail,
    login: true,
    noMall: true,
  },
  {
    path: '/signup/phone/change/confirm-new-phone',
    exact: true,
    component: SignUpNewPhoneConfirmation,
    login: true,
    noMall: true,
  },
  {
    path: '/signup/phone/change/confirmation',
    exact: true,
    component: SignUpChangeCodeConfirmation,
    login: true,
    noMall: true,
  },
  {
    path: '/signup/phone/additional',
    exact: true,
    component: SignUpAdditional,
    login: true,
    noMall: true,
  },
  {
    path: '/signup/update-registration',
    exact: true,
    component: UpdateRegistration,
  },
  {
    path: '/apps/:slug',
    exact: true,
    component: App,
  },
  {
    path: '/redirect',
    exact: true,
    component: Redirect,
  },
  {
    path: '/fale-conosco',
    exact: true,
    component: ContactUs,
  },
  {
    path: '/wifi',
    exact: true,
    component: WiFi,
  },
  {
    path: '/delivery',
    exact: true,
    component: FeaturedDeliveries,
  },
  // {
  //   path: '/apps/estacionamento/home',
  //   exact: true,
  //   component: Parking,
  // },
  {
    path: '/apps/estacionamento/home/eletroposto',
    exact: true,
    component: ChargingStation,
  },
  {
    path: '/apps/estacionamento/home/eletroposto/estacao/:slug',
    exact: true,
    component: ChargingStation,
  },
  {
    path: '/apps/promotion/modal',
    exact: true,
    component: ModalPromo,
  },
  // {
  //   path: '/apps/promotion/modal',
  //   exact: true,
  //   component: ModalPromoEngagement,
  // },
  {
    path: '/policy-and-terms',
    exact: true,
    component: PrePrivacyPolicy,
  },
];
