import React, { useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { LLV_SHOWCASE } from './graphql';
import { useCurrentShopping } from 'hooks';

import Loading from 'components/loading';
import ProductShowcase from 'components/productShowcase';
import TreadSlider from 'components/treadSlider';

import PencilIcon from 'icons/red-pencil.svg';

import styles from './index.module.scss';

const LlvSlider = () => {
  const currentShopping = useCurrentShopping();

  const [showLlvTread, setShowLlvTread] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(true);

  const stringOnlyHasNumbers = (str) => /^\d+$/.test(str);

  useQuery(LLV_SHOWCASE, {
    variables: {
      shoppingId: currentShopping.id,
    },
    onCompleted: (data) => {
      const llvShowcase = data?.llvShowcase.llvShowcase;

      if (
        llvShowcase.length !== 0 &&
        !stringOnlyHasNumbers(llvShowcase[0]?.id) &&
        llvShowcase[0]?.products.length !== 0
      )
        setShowLlvTread(true);
    },
  });

  const handleCompletedLlv = (isLoadingComplete) => {
    setLoadingProducts(!isLoadingComplete);
  };

  return (
    <>
      {showLlvTread && (
        <TreadSlider
          color={'#c1362f'}
          icon={<PencilIcon className={styles.icon} />}
          path={'/apps/lapis-vermelho'}
          title={'Ofertas Lápis Vermelho'}
          wordEmphasis={'first'}
          slug={'lápis vermelho'}
        >
          <>
            {loadingProducts && <Loading />}
            <ProductShowcase
              className={styles.productShowcase}
              path="/vantagens"
              position={1}
              withCard={false}
              llv
              from={'llvTread'}
              handleHasProducts={handleCompletedLlv}
            />
          </>
        </TreadSlider>
      )}
    </>
  );
};

export default LlvSlider;
