import React, { useRef, useEffect } from 'react';
import _ from 'lodash';
import { bool, node, number, string } from 'prop-types';
import { classNames } from 'utils';
import styles from './index.module.scss';

const Slider = ({
  children,
  className,
  itemClassName,
  itemsToShow,
  limitWidth,
  autoScrollbarHeight,
  fixedItemWidth,
  ...restProps
}) => {
  const sliderRef = useRef('slider');
  const SCROLLBAR_HEIGHT = 10;
  const calcItemWidth = itemsToShow ? `calc(100vw/${itemsToShow})` : 'auto';

  const removeScrollBarHeight = () => {
    if (sliderRef?.current) {
      const { current: slider } = sliderRef;
      const sliderHeight = slider.offsetHeight;

      slider.style.height = !autoScrollbarHeight && `${sliderHeight - SCROLLBAR_HEIGHT}px`;
    }
  };

  const resetScrollBarHeight = () => {
    if (sliderRef?.current) {
      const { current: slider } = sliderRef;
      slider.style.height = 'auto';
    }
  };

  useEffect(() => {
    removeScrollBarHeight();
    return () => resetScrollBarHeight();
  }, [children]);

  const renderItems = _.flatten(children).map((item) => {
    if (!item) return null;
    return (
      <div
        key={item?.key}
        className={classNames(styles.sliderWrapper, itemClassName, {
          [styles.width]: children.length < 4 && limitWidth,
        })}
        style={!fixedItemWidth ? { minWidth: calcItemWidth } : {}}
      >
        {item}
      </div>
    );
  });

  return (
    <div ref={sliderRef} className={classNames(styles.slider, className)} {...restProps}>
      <div className={styles.wrapper}>{renderItems}</div>
    </div>
  );
};

Slider.propTypes = {
  children: node.isRequired,
  className: string,
  itemClassName: string,
  itemsToShow: number,
  limitWidth: bool,
  autoScrollbarHeight: bool,
  fixedItemWidth: bool,
};

Slider.defaultProps = {
  className: null,
  itemClassName: null,
  itemsToShow: null,
  limitWidth: false,
  autoScrollbarHeight: false,
  fixedItemWidth: false,
};

export default Slider;
