export default (qty, text, showQty = true, uppercase = false) => {
  const rules = {
    add: {
      s: ['a', 'e', 'i', 'o', 'u', 'ã', 'ãe'],
      es: ['r', 'z', 'n', 'ás'],
      '': ['is', 'us', 'os'],
    },
    replace: {
      ais: 'al',
      eis: 'el',
      ois: 'ol',
      uis: 'ul',
      is: 'il',
      ns: 'm',
      eses: 'ês',
      ões: 'ão',
    },
    exceptions: {
      males: 'mal',
      cônsules: 'cônsul',
      méis: 'mel',
      féis: 'fel',
      cais: 'cal',
    },
    without_plural: ['não'],
  };

  const makePlural = (words) => {
    const plural = (word) => {
      let pluralize;
      const regexReplace = '^([a-zA-Zà-úÀ-Ú]*)(%s)$';

      for (const rule in rules) {
        switch (rule) {
          case 'add':
            for (const addition in rules[rule]) {
              const regex = new RegExp(regexReplace.replace('%s', rules[rule][addition].join('|')), 'i');

              if (regex.exec(word) !== null) {
                pluralize = word + addition;
                break;
              }
            }
            break;

          case 'replace':
            for (const replacement in rules[rule]) {
              const regex = new RegExp(regexReplace.replace('%s', rules[rule][replacement]), 'i');

              if (regex.exec(word) !== null) {
                if (word.match(/([áéíóú])/) !== null && regex.exec(word)[2] === 'il') {
                  pluralize = word.replace('il', 'eis');
                  break;
                } else {
                  pluralize = word.replace(new RegExp(regex.exec(word)[2] + '$', 'i'), replacement);
                  break;
                }
              }
            }
            break;

          case 'exceptions':
            for (const exception in rules[rule]) {
              if (word === rules[rule][exception]) {
                pluralize = exception;
                break;
              }
            }
            break;

          case 'without_plural':
            rules[rule].forEach((wordRule) => {
              if (word === wordRule) pluralize = wordRule;
            });
            break;
        }
      }

      return pluralize || word;
    };

    const wordsPlural = words.split(' ');

    wordsPlural.forEach((word, i) => {
      wordsPlural[i] = plural(word);
    });

    return wordsPlural.join(' ');
  };

  const showPlural = (quantity, text) => (quantity > 1 ? makePlural(text) : text);

  return `${showQty ? `${qty} ` : ''}${
    uppercase ? showPlural(qty, text).toString().toUpperCase() : showPlural(qty, text)
  }`;
};
