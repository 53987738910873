import { bugsnag } from 'utils';

const checkProjectVersion = () => {
  const version = multiStorage.getItem('version');
  if (!version || version !== VERSION) {
    multiStorage.setItem('version', VERSION);
    try {
      window.identifyWebVersion();
    } catch (e) {
      bugsnag?.notify(e);
    }
  }
};

export default checkProjectVersion;
