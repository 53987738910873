import React from 'react';
import { array, bool, func, string } from 'prop-types';

import { classNames } from 'utils';

import Modal from 'components/modal';
import Scroller from 'components/scroller';

import { useStoresFilterModal } from './useStoresFilterModal';

import CloseIcon from 'icons/times.svg';
import SearchIcon from 'icons/search.svg';

import styles from './index.module.scss';

export const StoresFilterModal = ({ isOpen, onClose, variant, handleApplyFilterModal, initialValue }) => {
  const {
    benefitFilters,
    loading,

    selectedStoreCodes,
    inputValue,
    filteredStores,

    onChange,
    handleClearInput,
    handleSelectStore,
    activeButton,
    activeApllyButton,
    handleClean,
    handleApply,
  } = useStoresFilterModal({
    onClose,
    variant,
    handleApplyFilterModal,
    initialValue,
  });

  const CountText = () => {
    if (benefitFilters?.stores.length === undefined) {
      return <></>;
    }

    if (selectedStoreCodes.length) {
      return (
        <>
          {selectedStoreCodes.length > 1 ? (
            <>
              <strong>{selectedStoreCodes.length} lojas</strong> selecionadas
            </>
          ) : (
            <>
              <strong>1 loja</strong> selecionada
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {filteredStores.length > 1 && (
            <>
              <strong>{filteredStores.length} lojas</strong> com benefícios disponíveis
            </>
          )}
          {filteredStores.length === 1 && (
            <>
              <strong>1 loja</strong> com benefícios disponíveis
            </>
          )}
        </>
      );
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={classNames(styles.modal, styles[variant])} slide>
      {loading ? (
        <></>
      ) : (
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <div />
            <h2 className={styles.modalTitle}>Lojas</h2>
            <button className={styles.xButton} onClick={onClose}>
              <CloseIcon className={styles.closeIcon} />
            </button>
          </div>

          <div
            className={styles.searchContainer}
            data-error={filteredStores.length === 0 && benefitFilters?.stores.length > 0}
          >
            <SearchIcon className={styles.searchIcon} />{' '}
            <input
              className={styles.search}
              type="text"
              placeholder="Buscar Loja"
              value={inputValue}
              onChange={onChange}
            />
            {inputValue.length !== 0 && (
              <button className={styles.xButton} onClick={handleClearInput}>
                <CloseIcon className={styles.xIconSearch} />
              </button>
            )}
          </div>

          <div className={styles.modalContent} data-search={inputValue.length > 0}>
            {filteredStores.length === 0 && (
              <p className={styles.emptySearchText}>
                <strong>Não encontramos lojas</strong> com benefícios disponíveis{' '}
                {inputValue.length > 0 && 'para a sua busca'}
              </p>
            )}
            <Scroller>
              {inputValue.length === 0 && (
                <div className={styles.storesCountText}>
                  <CountText />
                </div>
              )}
              <div className={styles.container}>
                {filteredStores.map((store) => (
                  <>
                    {store && (
                      <button
                        key={store.code}
                        className={styles.button}
                        onClick={() => handleSelectStore(store)}
                        data-active={activeButton(store)}
                        data-testid={store}
                      >
                        {store.name}
                      </button>
                    )}
                  </>
                ))}
              </div>
            </Scroller>

            {inputValue.length === 0 && (
              <div className={styles.buttonsContainer}>
                <button
                  className={styles.cleanButton}
                  onClick={handleClean}
                  disabled={selectedStoreCodes.length === 0}
                  data-testid="cleanStoreFilter"
                >
                  Limpar seleção
                </button>
                <button
                  className={styles.applyButton}
                  disabled={activeApllyButton()}
                  onClick={handleApply}
                  data-testid="applyStoreFilter"
                >
                  APLICAR
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

StoresFilterModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  variant: string.isRequired,
  handleApplyFilterModal: func.isRequired,
  initialValue: array.isRequired,
};
