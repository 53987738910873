const showGraphQLError = (error) => {
  const message = (
    !error?.message
      ? 'Erro inesperado. Por favor, tente novamente mais tarde.'
      : error.networkError
      ? 'Você está com um problema de conexão. Por favor, verifique sua internet.'
      : error?.message
  )
    .replace('GraphQL error: ', '')
    .replace('Unexpected error value:', '')
    .trim();
  return message.substr(0, 1) === '"' && message.substr(message.length - 1) === '"'
    ? message.substr(1, message.length - 2)
    : message;
};

export default showGraphQLError;
