const getDeliveryFee = ({ currentCoupon, selectedDeliveryType, items }) => {
  if (currentCoupon?.freeShipping || selectedDeliveryType?.slug === 'retirar-no-locker') {
    return 0;
  }
  const shippingValue = items && items[0]?.product?.store?.shippingValue;
  if (shippingValue === 0) return shippingValue;
  return shippingValue || selectedDeliveryType?.amount || 0;
};

export default getDeliveryFee;
