import React, { useState } from 'react';
import Modal from 'components/modal';
import CloseSvg from 'icons/close.svg';

import { classNames } from 'utils';
import { useUser } from 'hooks';

import RegisterWelcomeImg from '../../images/register-welcome-modal.svg';

import styles from './welcome-register-modalContainer.module.scss';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
};

export function WelcomeRegisterModal() {
  const user = useUser();
  const lastRoute = sessionStorage.getItem('lastRoute');
  const lastRouteObj = JSON.parse(lastRoute);
  const lastPath = lastRouteObj?.route === '/signup/phone/additional';

  const [modalIsOpen, setIsOpen] = useState(lastPath);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      onClose={closeModal}
      style={customStyles}
      className={classNames(styles.modal)}
      slide
    >
      <div className={styles.modalContent}>
        <div className={styles.modalCloseContainer}>
          <button className={styles.modalCloseContainerButton} onClick={closeModal}>
            <CloseSvg />
          </button>
        </div>
        <img src={RegisterWelcomeImg} alt="" className={styles.icon} />
        <h1 className={styles.modalTitle}>Olá {user ? user.name.split(' ')[0] : ''},</h1>
        <h2 className={styles.modalSecondTitle}>É bom ter você aqui!</h2>
        <p className={styles.modalText}>
          <span className={styles.modalTextWeight}>Agora você já pode aproveitar</span> as facilidades e os benefícios
          exclusivos do <span>Multi!</span>
        </p>

        <button type="button" data-testid="explorerButton" className={styles.button} onClick={closeModal}>
          Explorar
        </button>
      </div>
    </Modal>
  );
}
