import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import Check from 'icons/check.svg';
import Times from 'icons/times.svg';

import styles from './index.module.scss';

const SUCCESS = 'success';
const ERROR = 'error';
const PRIMARY = 'primary';
const LLV = 'llv';
const DEFAULT = 'default';
const CUSTOM_ICON = 'customIcon';

const Tag = ({ children, icon, variant, withIcon, size, flat, className }) => {
  const icons = new Map([
    [CUSTOM_ICON, icon],
    [SUCCESS, Check],
    [ERROR, Times],
  ]);

  const Icon = icons.get(icon ? CUSTOM_ICON : variant);

  return (
    <div
      className={classNames(styles.tag, styles[variant], className, {
        [styles.flat]: flat,
        [styles.big]: size === 'big',
      })}
    >
      {withIcon && Icon && (
        <span className={styles.icon}>
          <Icon />
        </span>
      )}
      <span className={styles.text}>{children}</span>
    </div>
  );
};

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.element,
  withIcon: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'big']),
  className: PropTypes.string,
  variant: PropTypes.oneOf([SUCCESS, ERROR, DEFAULT, PRIMARY, LLV]),
  flat: PropTypes.bool,
};

Tag.defaultProps = {
  icon: null,
  className: null,
  size: 'default',
  withIcon: true,
  variant: DEFAULT,
  flat: false,
};

export default Tag;
