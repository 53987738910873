import React from 'react';
import { node, object, string } from 'prop-types';

import Lottie from 'lottie-react';

import Flex from 'components/flex';
import Screen from 'components/screen';

import IconIdea from 'icons/iconIdea.svg';

import { colors } from 'theme';

const FancyLoadingComponent = ({ animation, children, animationWidth, animationHeight, ...rest }) => {
  return (
    <Screen>
      <Flex direction="column" align="center" gap={'32px'} {...rest}>
        <Flex style={{ width: animationWidth, height: animationHeight }}>
          <Lottie animationData={animation} />
        </Flex>
        {children}
      </Flex>
    </Screen>
  );
};

FancyLoadingComponent.propTypes = {
  animation: object.isRequired,
  styleAnimation: object,
  children: node,
  animationWidth: string,
  animationHeight: string,
};
FancyLoadingComponent.defaultProps = {
  styleAnimation: null,
  children: null,
  animationWidth: null,
  animationHeight: null,
};

const Tip = ({ children }) => {
  return (
    <Flex direction={'column'} gap={'8px'}>
      <IconIdea height="24px" width="24px" fill={colors.turquoise[2]} path={colors.turquoise[2]} />
      <Flex>{children}</Flex>
    </Flex>
  );
};
Tip.propTypes = { children: node.isRequired };

FancyLoadingComponent.Tip = Tip;

export default FancyLoadingComponent;
