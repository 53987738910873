import { useMutation } from '@apollo/react-hooks';

import { bugsnag } from 'utils';

import { FEEDBACK } from 'services/apollo/mutations';

const useFeedback = ({ onError } = {}) => {
  const handleErrorUpload = (err) => {
    if (onError) onError(err);
  };

  const [createFeedbackResponse, { loading, error }] = useMutation(FEEDBACK, {
    onError: handleErrorUpload,
  });

  const saveFeedback = async (feedback) => {
    try {
      await createFeedbackResponse({
        variables: feedback,
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
  };
  return [saveFeedback, { loading, error }];
};

export default useFeedback;
