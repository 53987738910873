import { Component } from 'react';
import PropTypes from 'prop-types';
import { bugsnag } from 'utils';

class ErrorBoundary extends Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    console.error('ErrorBoundary capturou um erro:', error, errorInfo);
    bugsnag?.notify(error);
    const errorMessage = error?.toString() || 'Erro desconhecido';

    window.track({
      mixpanel: {
        name: 'ErrorBoundary',
        properties: {
          errorMessage,
          pathname: window?.location?.href?.split('#')?.[1],
        },
      },
    });

    this.setState({ hasError: true });
  }

  onClickReload = () => {
    window.location.reload();
  };

  onClickReturn = () => {
    window.history.back();
  };

  render() {
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
