import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useCurrentShopping, useUser } from 'hooks';
import { bugsnag, getVariant } from 'utils';
import { PLAN, SUMMARY } from 'services/apollo/queries';

const fiveMinutes = 1000 * 60 * 5;

const useUserPlan = () => {
  const client = useApolloClient();
  const user = useUser();
  const currentShopping = useCurrentShopping();
  const { data: { plan } = {} } = useQuery(PLAN);

  let changedShopping = false;
  let storageIsOld = false;

  let storageUserPlan = JSON.parse(sessionStorage.getItem('@multivc:userPlan'));
  if (storageUserPlan) {
    changedShopping = storageUserPlan.shoppingSlug !== currentShopping.slug;

    const lastModified = new Date(storageUserPlan.date);
    const dateDiff = Date.now() - lastModified.getTime();
    storageIsOld = dateDiff > fiveMinutes;
  }

  const reloadUserPlan = !!user && !!currentShopping && (!storageUserPlan || changedShopping || storageIsOld);

  const { data, loading } = useQuery(SUMMARY, {
    variables: { shoppingSlug: currentShopping?.slug },
    fetchPolicy: 'network-only',
    skip: !reloadUserPlan,
  });
  if (reloadUserPlan && !loading && data?.fidelitySummary) {
    const fidelitySummary = data.fidelitySummary;
    client.writeQuery({
      query: PLAN,
      data: {
        plan: {
          name: fidelitySummary.plan,
          shoppingSlug: currentShopping.slug,
          __typename: 'Plan',
        },
      },
    });

    const objUserPlan = {
      shoppingSlug: currentShopping.slug,
      fidelitySummary,
      date: new Date(),
    };

    storageUserPlan = objUserPlan;
    sessionStorage.setItem('@multivc:userPlan', JSON.stringify(objUserPlan));

    try {
      window.register({
        mixpanel: {
          properties: {
            Modality: fidelitySummary.plan,
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
  } else if (storageUserPlan) {
    client.writeQuery({
      query: PLAN,
      data: {
        plan: {
          name: storageUserPlan?.fidelitySummary?.plan,
          shoppingSlug: currentShopping.slug,
          __typename: 'Plan',
        },
      },
    });
  }

  const userPlan = {
    name: storageUserPlan?.fidelitySummary?.plan,
    shoppingSlug: currentShopping?.slug,
    variant: getVariant(storageUserPlan?.fidelitySummary?.plan),
    ...storageUserPlan?.fidelitySummary,
  };

  const visitantPlan = {
    variant: 'green',
  };

  return plan?.shoppingSlug === currentShopping?.slug ? userPlan : visitantPlan;
};

export default useUserPlan;
